import * as api from './api';
import { GetTripHistories } from './api/taxiTrip';
import * as googleFunc from './utils/googleFunction';
import TagManager from 'react-gtm-module';
import { useContext } from 'react';
import { placeContext, UPDATE_ORIGIN_PLACE_LATLNG, UPDATE_DESTINATION_PLACE_LATLNG } from './place';
import { convertFullToHalf } from './utils/unit';

import { useRecord } from './useRecord';

export function useCallTaxi(setTraceRegionId) {
  const { recordActionCallTaxiFlow, recordActionYoxiFlow } = useRecord();
  const { dispatch } = useContext(placeContext);
  // * 修正有時 google 回傳地址片段沒有 '號' 問題
  function formatAddressCode({ long_name, types }) {
    if (types[0] === 'street_number' && long_name.indexOf('號') === -1) {
      return long_name + '號';
    }
    return long_name;
  }

  // * 重構之前的經緯度資料補正
  function formalizationAddressWithLatLng(callTaxiReq) {
    const promiseTaskArray: Array<Promise<any>> = [];

    console.log('formalizationAddressWithLatLng', callTaxiReq);

    if (+callTaxiReq.outset_lat && +callTaxiReq.outset_lon) {
      // * 都有上車經緯度 - 正常狀況
      promiseTaskArray.push(
        Promise.resolve({ lat: +callTaxiReq.outset_lat, lng: +callTaxiReq.outset_lon })
      );
    } else {
      recordActionCallTaxiFlow();
      promiseTaskArray.push(
        new Promise((resolve) => {
          googleFunc
            .geocodeAddress(new window.google.maps.Geocoder(), callTaxiReq.outset)
            .then((data) => {
              console.log('上車地址轉經緯度', data);
              const formattedAddress = data.address_components
                .reverse()
                .map((addressObj) => formatAddressCode(addressObj))
                .join('');
              const [, ...restRemoveGeoCodeAddress] = formattedAddress.split('台灣');
              dispatch({
                type: UPDATE_ORIGIN_PLACE_LATLNG,
                payload: {
                  lat: data.geometry.location.lat(),
                  lng: data.geometry.location.lng(),
                },
              });
              resolve({
                lat: data.geometry.location.lat(),
                lng: data.geometry.location.lng(),
                outset: restRemoveGeoCodeAddress.join('台灣'),
              });
            });
        })
      );
    }

    if (+callTaxiReq.destination_lat && +callTaxiReq.destination_lon) {
      // * 都有下車經緯度 - 正常狀況
      promiseTaskArray.push(
        Promise.resolve({ lat: +callTaxiReq.destination_lat, lng: +callTaxiReq.destination_lon })
      );
    } else {
      if (callTaxiReq.destination) {
        recordActionCallTaxiFlow();
        promiseTaskArray.push(
          new Promise((resolve) => {
            googleFunc
              .geocodeAddress(new window.google.maps.Geocoder(), callTaxiReq.destination)
              .then((data) => {
                console.log('下車地址轉經緯度', data);
                const formattedAddress = data.address_components
                  .reverse()
                  .map((addressObj) => formatAddressCode(addressObj))
                  .join('');
                const [, ...restRemoveGeoCodeAddress] = formattedAddress.split('台灣');
                dispatch({
                  type: UPDATE_DESTINATION_PLACE_LATLNG,
                  payload: {
                    lat: data.geometry.location.lat(),
                    lng: data.geometry.location.lng(),
                  },
                });
                resolve({
                  lat: data.geometry.location.lat(),
                  lng: data.geometry.location.lng(),
                  destination: restRemoveGeoCodeAddress.join('台灣'),
                });
              });
          })
        );
      } else {
        //  * 沒有填下車地址 - 正常狀況
        promiseTaskArray.push(
          Promise.resolve({ lat: +callTaxiReq.destination_lat, lng: +callTaxiReq.destination_lon })
        );
      }
    }

    return Promise.all(promiseTaskArray).then(([outsetObj, destinationObj]) => {
      const { lat: outset_lat, lng: outset_lon, outset } = outsetObj;
      const { lat: destination_lat, lng: destination_lon, destination } = destinationObj;
      return {
        ...callTaxiReq,
        formatOutsetBuffer: outset,
        formatDestinationBuffer: destination,
        outset_lat,
        outset_lon,
        destination_lat,
        destination_lon,
      };
    });
  }

  // * 因為 Yoxi 要付更多錢給古鴿ej3了
  function formalizationAddress(callTaxiReq) {
    const geocoder = new window.google.maps.Geocoder();
    const promiseTaskArray: Array<Promise<any>> = [];

    const isNeedTransOutset = checkAddressIsNeedFormalization(callTaxiReq.outset);
    const isNeedTransDestination = checkAddressIsNeedFormalization(callTaxiReq.destination);

    console.table({ isNeedTransOutset, isNeedTransDestination });

    // * 上車地點先判斷有沒有上一次轉換過的
    if (isNeedTransOutset) {
      promiseTaskArray.push(
        new Promise((resolve) => {
          if (callTaxiReq.formatOutsetBuffer) {
            if (checkAddressIsNeedFormalization(callTaxiReq.formatOutsetBuffer))
              resolve(callTaxiReq.region_name + callTaxiReq.formatOutsetBuffer);
            else resolve(callTaxiReq.formatOutsetBuffer);
            return;
          }
          recordActionYoxiFlow();
          googleFunc
            .geocodeLatLng(geocoder, {
              lat: +callTaxiReq['outset_lat'],
              lng: +callTaxiReq['outset_lon'],
            })
            .then((d) => {
              const formattedAddress = d.address_components
                .reverse()
                .map((addressObj) => formatAddressCode(addressObj))
                .join('');
              const [, ...restRemoveGeoCodeAddress] = formattedAddress.split('台灣');
              const transGeoCodeAddress = restRemoveGeoCodeAddress.join('台灣');
              if (transGeoCodeAddress) {
                if (checkAddressIsNeedFormalization(transGeoCodeAddress))
                  resolve(callTaxiReq.region_name + transGeoCodeAddress);
                else resolve(transGeoCodeAddress);
              } else {
                resolve(callTaxiReq.region_name + callTaxiReq.outset);
              }
            });
        })
      );
    } else {
      promiseTaskArray.push(Promise.resolve(callTaxiReq.outset));
    }

    // * 下車地點都要, 先判斷有幾個地址要轉
    if (isNeedTransDestination) {
      promiseTaskArray.push(
        new Promise((resolve) => {
          if (callTaxiReq.formatDestinationBuffer) {
            resolve(callTaxiReq.formatDestinationBuffer);
            return;
          }
          recordActionYoxiFlow();
          googleFunc
            .geocodeLatLng(geocoder, {
              lat: +callTaxiReq['destination_lat'],
              lng: +callTaxiReq['destination_lon'],
            })
            .then((d) => {
              const formattedAddress = d.address_components
                .reverse()
                .map((addressObj) => formatAddressCode(addressObj))
                .join('');
              const [, ...restRemoveGeoCodeAddress] = formattedAddress.split('台灣');
              resolve(restRemoveGeoCodeAddress.join('台灣'));
            });
        })
      );
    } else {
      promiseTaskArray.push(Promise.resolve(callTaxiReq.destination));
    }

    return Promise.all(promiseTaskArray).then(([outset, destination]) => {
      let convertOutSet, convertDestination;
      if (callTaxiReq.outset !== outset) {
        convertOutSet = outset;
      }
      if (callTaxiReq.destination !== destination) {
        convertDestination = destination;
      }
      console.log('formalizationAddress callTaxiReq.outset', callTaxiReq.outset);
      console.log('formalizationAddress callTaxiReq.destination', callTaxiReq.destination);

      // convert address
      callTaxiReq.outset = convertFullToHalf(callTaxiReq.outset);
      callTaxiReq.destination = convertFullToHalf(callTaxiReq.destination);
      if (convertOutSet) convertOutSet = convertFullToHalf(convertOutSet);
      if (convertDestination) convertDestination = convertFullToHalf(convertDestination);

      return {
        ...callTaxiReq,
        convertOutSet,
        convertDestination,
      };
    });
  }

  function checkAddressIsNeedFormalization(addressString) {
    if (!addressString) return false;
    const addressRegex =
      /^((台灣|臺灣)?([\u4E00-\u9FFF]{2}[縣市]))([\u4E00-\u9FFF]+(市區|鎮區|鎮市|[鄉鎮市區]))/g;
    return !addressRegex.test(addressString);
  }

  function addRegionId(callTaxiReq) {
    return api
      .kingnetTaxiGetCityByCoordinate({
        lat: callTaxiReq['outset_lat'],
        lng: callTaxiReq['outset_lon'],
      })
      .then((res) => {
        // * 紀錄叫車地區 ID
        if (res.data.Data) setTraceRegionId(res.data.Data.AreaId);
        if (!res.data.Data) {
          throw new Error('需要重新建立常用地址');
        }
        return {
          ...callTaxiReq,
          option: {
            ...callTaxiReq.option,
          },
          region_id: res.data.Data ? res.data.Data.AreaId : 0,
          region_name: res.data.Data.CityName + res.data.Data.AreaName,
        };
      });
  }

  function callTaxiHistoryCheck(callTaxiReq) {
    return GetTripHistories({ maxCount: 1, offset: 0 }).then((res) => {
      const firstOrder = res?.[0]?.data?.data?.orders?.[0];
      if (firstOrder) {
        // * 前一筆訂單被取消 => 合法叫車
        if (firstOrder.is_cancel) return callTaxiReq;
        // * 前一筆訂單已經是五分鐘以前的事 => 合法叫車
        if (new Date().getTime() - new Date(firstOrder.create_time).getTime() > 300000) {
          return callTaxiReq;
        }
        // * 訂單顯示已抵達 => 合法叫車
        if (firstOrder.get_to?.create_time) {
          return callTaxiReq;
        }
        TagManager.dataLayer({
          dataLayer: {
            event: 'fatal-retry-call-taxi',
            enter: callTaxiReq.service_account_a_Id,
          },
        });
        throw new Error('重複叫車');
      }
      // * 第一次叫車的用戶沒有訂單
      return callTaxiReq;
    });
  }

  return {
    callTaxiHistoryCheck,
    addRegionId,
    formalizationAddress,
    formalizationAddressWithLatLng,
  };
}
