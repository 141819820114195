import { useEffect, useState, useContext } from 'react';
import { FirebaseContext } from '../utils/firebase';

export const useGoogleAdBanner = () => {
  const firebase = useContext(FirebaseContext);
  const ref = firebase.database();
  const [isAdShow, setIsAdShow] = useState(false);

  useEffect(() => {
    ref.ref(`TaxiNotification/ad`).on('value', (snapshot) => {
      const { show = false } = snapshot.val();
      console.log(`TaxiNotification/ad`, snapshot.val());
      setIsAdShow(show);
    });
    return () => ref.ref(`TaxiNotification/ad`).off();
  }, []);

  useEffect(() => {
    if (isAdShow) {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
  }, [isAdShow]);

  const GoogleAdBanner = isAdShow ? (
    <div>
      <ins
        className="adsbygoogle"
        style={{ display: 'block', height: '60px' }}
        data-ad-client="ca-pub-9449560331897859"
        data-full-width-responsive="true"
        data-ad-slot="6768723174"
      ></ins>
    </div>
  ) : null;

  return {
    GoogleAdBanner,
  };
};
