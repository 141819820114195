import VISA from 'assets/creditCard/Visa.png';
import Mastercard from 'assets/creditCard/Mastercard.png';
import JCB from 'assets/creditCard/JCB.png';
import Amex from 'assets/creditCard/Amex.png';
import UnionPay from 'assets/creditCard/UnionPay.png';

import { CreditCardType } from 'types/creditCard';

const creditCardImages: Record<CreditCardType, string> = {
  VISA: VISA,
  Mastercard: Mastercard,
  JCB: JCB,
  Amex: Amex,
  UnionPay: UnionPay,
};
interface CreditCardImageProps {
  type: CreditCardType;
  alt: string;
  height?: string;
}
export const CreditCardImage = ({ type, alt, height }: CreditCardImageProps) => {
  const imagePath = creditCardImages[type];
  return <img style={{ height: height }} src={imagePath} alt={alt}></img>;
};
