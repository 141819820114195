import { Route } from 'react-router-dom';
import WaitTaxiPage from './status/waitTaxiPage';
import CarRedispatchPage from './status/carRedispatch';
import NoCarPage from './status/noCarPage';
import CarPage from './status/carPage';
import ArrivedPage from './status/carArrivedPage';
import SharePage from './status/sharePage';
import DriverErrorPage from './status/driverCancelPage';
import DriverAccidentPage from './status/driverAccidentPage';
import EstimatedPriceChangePage from './status/estimatedPriceChangePage';

import './statusPage.scss';

export default function statusPage(statusProps) {
  return (
    <>
      <Route
        path="/WaitTaxiPage"
        render={(props) => <WaitTaxiPage {...props} {...statusProps} />}
      />
      <Route
        path="/CarRedispatchPage"
        render={(props) => <CarRedispatchPage {...props} {...statusProps} />}
      />
      <Route
        path="/DriverErrorPage"
        render={(props) => <DriverErrorPage {...props} {...statusProps} />}
      />
      <Route
        path="/DriverAccidentPage"
        render={(props) => <DriverAccidentPage {...props} {...statusProps} />}
      />
      <Route path="/SharePage" render={(props) => <SharePage {...props} {...statusProps} />} />
      <Route path="/CarPage" render={(props) => <CarPage {...props} {...statusProps} />} />
      <Route path="/ArrivedPage" render={(props) => <ArrivedPage {...props} {...statusProps} />} />
      <Route path="/NoCarPage" render={(props) => <NoCarPage {...props} {...statusProps} />} />
      <Route
        path="/EstimatedPriceChangePage"
        render={(props) => <EstimatedPriceChangePage {...props} {...statusProps} />}
      />
    </>
  );
}
