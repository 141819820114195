import React, { useState, ReactNode } from 'react';
import './DiscountInput.scss';

import { useDiscount, UPDATE_DISCOUNT } from 'context/DiscountProvider';
import { useDiscountInputFocus } from 'context/DiscountInputFocusProvider';
import { useEstimatedFare } from 'context/EstimatedFareProvider';

import { useFetchDiscount } from 'hooks/useFetchDiscount';

import { Button } from 'components/Button/Button';
import { setToastAppear } from 'components/Toast/Toast';
import { useAlertPopupModal } from '../AlertPopupModal';
import Modali, { useModali } from '../../components/Modal';

import { ReceiveDiscountCode } from 'api/taxiTrip';
import { ReceiveDiscountResult } from 'api/taxiTrip/model/FEModel';

import GiftQuokka from 'assets/quokka/gift-quokka.png';

let resolveModalPromise: (value: boolean) => void;

const DiscountInput: React.FC = () => {
  const { discountState, discountDispatch } = useDiscount();
  const { setDiscountInputFocus } = useDiscountInputFocus();
  const { checkTicketAndBonusDiscountAvailable, updateDiscountUsage, updateDiscountBonus } =
    useFetchDiscount();

  const [isLoading, setIsLoading] = useState(false);
  const [discountCode, setDiscountCode] = useState('');
  const [discountStatus, setDiscountStatus] = useState('');
  const [statusMessage, setStatusMessage] = useState('');
  const [discountResult, setDiscountResult] = useState<ReceiveDiscountResult | null>(null);

  const [giftModalContent, setGiftModalContent] = useState<ReactNode | null>(null);
  const {
    toggleAlertPopupModal: toggleGiftReceivedModal,
    alertPopupModal: giftReceivedModalComponent,
  } = useAlertPopupModal({
    content: giftModalContent,
    imgSrc: GiftQuokka,
    imgAlt: '獲得專屬好禮',
    title: '獲得專屬好禮',
    buttonText: '我知道了',
  });

  // 重複領取推薦/折扣碼提示
  const [repeatCodeModal, toggleRepeatCodeModal] = useModali({
    title: '系統提示',
    message: '折扣碼與推薦碼不可同時使用，確定套用新的折扣內容嗎？',
    animated: true,
    buttons: [
      <Modali.Button
        key="ModaliButton"
        label="確認"
        isTwoButton
        isStyleDestructive
        onClick={() => confirmUseNewDiscount()}
      />,
      <Modali.Button
        key="ModaliButton"
        label="取消"
        isTwoButton
        isStyleDestructive
        onClick={() => cancelUseNewDiscount()}
      />,
    ],
  });

  // 原優惠無法使用
  const [discountUnavailableModal, toggleDiscountUnavailableModal] = useModali({
    title: '系統提示',
    message: '使用優惠後，當前的優惠券或紅利點數將無法繼續適用，確定要使用嗎？',
    animated: true,
    buttons: [
      <Modali.Button
        key="ModaliButton"
        label="確認"
        isTwoButton
        isStyleDestructive
        onClick={() => resolveModalPromise(true)}
      />,
      <Modali.Button
        key="ModaliButton"
        label="取消"
        isTwoButton
        isStyleDestructive
        onClick={() => resolveModalPromise(false)}
      />,
    ],
  });

  const { estimatedFare } = useEstimatedFare();
  const fareId = estimatedFare?.fareId;
  const orderAmount = estimatedFare?.fare;
  const DISCOUNT_ERROR_MESSAGES: Record<number, string> = {
    411066: '當前服務不適用此代碼',
    411067: '當前服務不適用此代碼',
    400002: '此服務無折扣碼活動',
    404015: '無效的代碼，可能為輸入錯誤（請注意英文大小寫）或是代碼無法再使用',
    404016: '推薦碼已操作會員轉換',
    404021: '請勿輸入自己的推薦代碼',
    404022: '當前帳號不符合使用條件',
    404030: '當前服務不適用此代碼',
    404031: '無效的代碼，可能為輸入錯誤（請注意英文大小寫）或是代碼無法再使用',
    404032: '當前帳號已超過使用次數',
    404033: '當前折扣碼已超過總使用次數',
    404000: '無效的代碼，可能為輸入錯誤（請注意英文大小寫）或是代碼無法再使用',
    411001: '無效的代碼，可能為輸入錯誤（請注意英文大小寫）或是代碼無法再使用',
    411002: '無效的代碼，可能為輸入錯誤（請注意英文大小寫）或是代碼無法再使用',
    411003: '優惠尚未開放領取，請等待至指定時間再領取',
    411004: '優惠已經停止領取囉，下回請早',
    411005: '您已經領取過這個優惠，不能再領取囉',
    411006: '慢一步了，優惠已經被領取完畢囉',
    404008: '無效的代碼，可能為輸入錯誤（請注意英文大小寫）或是代碼無法再使用',
    409008: '此代碼已經被領取過囉！',
    404036: '無效的代碼，可能為輸入錯誤（請注意英文大小寫）或是代碼無法再使用',
    409003: '此代碼已經被領取過囉！',
  };

  function handleReceiveDiscountError(error: any) {
    setDiscountStatus('error');
    const errorMessage = DISCOUNT_ERROR_MESSAGES[error?.response?.data?.ErrorMessage?.Code];
    if (errorMessage) {
      setStatusMessage(errorMessage);
    } else {
      setStatusMessage('使用失敗，請稍候再試');
    }
  }

  async function handleDiscountCodeSubmit() {
    if (!validateDiscountInput()) return;
    try {
      setIsLoading(true);
      const [res, error] = await ReceiveDiscountCode({ code: discountCode, fareId, orderAmount });
      if (res) {
        setDiscountResult(res.data);
        if (!res.data) return;
        switch (true) {
          case !!res.data.giftDiscount:
            setDiscountCode('');
            handleGiftDiscount(res.data.giftDiscount);
            break;
          case !!res.data.normalDiscount:
            handleDiscount('discount', res.data.normalDiscount);
            break;
          case !!res.data.recommendDiscount:
            handleDiscount('recommend', res.data.recommendDiscount);
            break;
        }
      }
      if (error) {
        handleReceiveDiscountError(error);
      }
    } catch (error) {
      console.log('handleDiscountCodeSubmit error:', error);
    } finally {
      setIsLoading(false);
    }
  }

  function handleGiftDiscount(giftDiscount) {
    setDiscountStatus('');
    if (giftDiscount.tickets) {
      setGiftModalContent(
        <>
          <p>
            恭喜您獲得了【社區叫車優惠券
            <span className="primary bold count">{giftDiscount.tickets.length}</span>張】
          </p>
          <p>優惠券已存入您的帳戶！</p>
        </>
      );
    } else if (giftDiscount.bonus) {
      setGiftModalContent(
        <>
          <p>
            恭喜您獲得了【紅利點數
            <span className="primary bold count">{giftDiscount.bonus.bonusAmount}</span>點】
          </p>
          <p>點數已存入您的帳戶！</p>
        </>
      );
      updateDiscountBonus();
    }
    toggleGiftReceivedModal();
  }

  function validateDiscountInput() {
    if (discountCode === discountState?.code) {
      const errorMessage = discountState?.key === 'discount' ? '優惠已套用' : '推薦優惠已套用';
      setDiscountStatus('error');
      setStatusMessage(errorMessage);
      return false;
    }
    return true;
  }

  async function checkUseDiscount() {
    toggleDiscountUnavailableModal();
    return new Promise((resolve) => {
      resolveModalPromise = resolve;
    });
  }

  async function handleDiscount(discountType, discount) {
    const canUseDiscount = checkCanUseDiscount(discount);
    if (!canUseDiscount) return;

    const ticketAndBonusAvailable = await checkTicketAndBonusDiscountAvailable({
      updatedOrderAmount: orderAmount - discount.discountAmount,
    });

    if (!ticketAndBonusAvailable) {
      const confirmUseDiscount = await checkUseDiscount();
      toggleDiscountUnavailableModal();
      if (!confirmUseDiscount) return;
    }

    const conflictingType = discountType === 'discount' ? 'recommend' : 'discount';
    if (discountState?.key === conflictingType) {
      toggleRepeatCodeModal();
      return;
    }

    setDiscountCode('');
    setToastAppear('優惠領取成功！');
    discountDispatch({
      type: UPDATE_DISCOUNT,
      payload: {
        key: discountType,
        price: discount.discountAmount,
        info: discount,
        code: discountCode,
      },
    });
    updateDiscountUsage({
      updatedOrderAmount: orderAmount - discount.discountAmount,
      startTrigger: 'ticket',
    });
  }

  function checkCanUseDiscount(discountInfo) {
    if (discountInfo?.discountAmount > 0) return true;
    setDiscountStatus('error');
    setStatusMessage('當前訂單金額不滿足折扣條件');
    return false;
  }

  function confirmUseNewDiscount() {
    const newDiscount = discountResult?.normalDiscount || discountResult?.recommendDiscount;
    const newDiscountType = discountResult?.normalDiscount ? 'discount' : 'recommend';
    if (newDiscount) {
      discountDispatch({
        type: UPDATE_DISCOUNT,
        payload: {
          key: newDiscountType,
          price: newDiscount.discountAmount,
          info: newDiscount,
          code: discountCode,
        },
      });
      updateDiscountUsage({
        updatedOrderAmount: orderAmount + (discountState?.price || 0) - newDiscount.discountAmount,
        startTrigger: 'discount',
        discountCode,
      });
    }
    toggleRepeatCodeModal();
    setDiscountCode('');
  }

  function cancelUseNewDiscount() {
    toggleRepeatCodeModal();
    setDiscountCode('');
  }

  return (
    <div className="discount">
      <div
        className={`discount__input-wrapper ${
          discountStatus === 'error' ? 'discount__input-wrapper--error' : ''
        }`}
      >
        <input
          className="discount__input-wrapper__input"
          type="text"
          placeholder="請輸入優惠代碼或推薦碼"
          value={discountCode}
          onChange={(e) => {
            setDiscountCode(e.target.value);
          }}
          onFocus={() => {
            setDiscountStatus('');
            setDiscountInputFocus(true);
          }}
          onBlur={() => {
            setTimeout(() => {
              // 避免button click事件沒有觸發
              setDiscountInputFocus(false);
            }, 80);
          }}
        />
        {discountStatus === 'error' && (
          <p className="discount__input-wrapper__error">{statusMessage}</p>
        )}
      </div>
      <Button
        className="discount__button"
        width="72px"
        height="38px"
        size="small"
        disabled={discountCode.length <= 0 || isLoading}
        onButtonClick={() => {
          handleDiscountCodeSubmit();
        }}
        isLoading={isLoading}
      >
        {!isLoading && <span>確認</span>}
      </Button>
      {giftReceivedModalComponent}
      <Modali.Modal {...repeatCodeModal} />
      <Modali.Modal {...discountUnavailableModal} />
    </div>
  );
};

export default DiscountInput;
