export default function CarRedispatchPage() {
  return (
    <div className="statusPage">
      <div className="statusPage-header busy-notice">
        <span>{'因司機取消行程 \n 系統正在為您重新找尋車輛中 \n 請稍候...'}</span>
      </div>
      <div className="statusPage-body">
        <div className="statusPage-img rwd-scale">
          <div className="statusPage-asset statusPage-back" />
          <div className="statusPage-asset statusPage-phone" />
          <div className="statusPage-asset statusPage-waitTaxi-taxi" />
          <div className="statusPage-asset statusPage-glass" />
        </div>
      </div>
      <div className="statusPage-footer">
        <button className="btn-cancel" onClick={() => window.history.back()}>
          取消叫車
        </button>
      </div>
    </div>
  );
}
