import React from 'react';

import './FeeDetail.scss';

import { TripBonusDetail } from 'types/trip';

interface FeeDetailProps {
  subtotal: number;
  bonusDetail: TripBonusDetail;
  tripTotalAmount: number;
  cancelFare: number;
}

export const FeeDetail: React.FC<FeeDetailProps> = ({
  subtotal,
  bonusDetail,
  tripTotalAmount,
  cancelFare,
}) => {
  return (
    <div className="history-detail-block history-fee-detail">
      <p className="history-detail-block__title">費用詳情</p>
      <div className="history-detail-block__content">
        <div className="history-fee-detail__item">
          <p>小計：</p>
          <p>${subtotal}</p>
        </div>
        {cancelFare > 0 && (
          <div className="history-fee-detail__item">
            <p>取消費用：</p>
            <p>${cancelFare}</p>
          </div>
        )}
        <div className="history-fee-detail__item">
          <p>優惠折抵：</p>
          <p>-${bonusDetail.discountAmount + bonusDetail.ticketDiscountAmount}</p>
        </div>
        <div className="history-fee-detail__item">
          <p>紅利：</p>
          <p>-${bonusDetail.bonusAmount}</p>
        </div>
      </div>
      <div className="history-fee-detail__footer">
        <div className="history-fee-detail__item">
          <p className="history-fee-detail__item__title">行程費用</p>
          <p>${tripTotalAmount}</p>
        </div>
      </div>
    </div>
  );
};
