import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ReactLoading from 'react-loading';

export default function NoCarPage(props) {
  const history = useHistory();

  useEffect(() => {
    // v1.1.0 不會出現無車
    props.setHistoryResult((oldArray) => {
      if (oldArray.length !== 0 && oldArray[0].isCancel && oldArray[0].cancelSource === 'fake-1') {
        oldArray.shift();
        return [...oldArray];
      } else {
        return oldArray;
      }
    });
  }, []);

  const backToHomePage = () => {
    props.setOrderPlaceDetail();
    history.push('/');
  };

  return (
    <div className="statusPage">
      <div className="statusPage-header">
        <span>目前搜尋不到車</span>
        <p>
          {props.isOnlinePay
            ? '很抱歉，目前找不到車輛為您服務，請返回首頁重新叫車。'
            : '很抱歉，目前找不到適合車輛為您服務，您可用相同條件再搜一次，或是返回首頁調整搜車條件。'}
        </p>
      </div>
      <div className="statusPage-body">
        <div className="statusPage-img margin-top-0 rwd-scale">
          <div className="statusPage-asset statusPage-back" />
          <div className="statusPage-asset statusPage-phone_glass" />
          <div className="statusPage-asset statusPage-error" />
        </div>
      </div>
      <div className="statusPage-footer">
        {!props.isOnlinePay && (
          <button className="btn-fill" onClick={props.callTaxi}>
            {props.callTaxiLock ? (
              <div style={{ width: 0, transform: 'translateX(-25px)' }}>
                <ReactLoading type="spokes" color={'#fff'} height={20} width={20} />
              </div>
            ) : null}
            <span>相同條件，再搜一次</span>
          </button>
        )}
        <button className="btn-default" onClick={backToHomePage}>
          返回首頁，重新叫車
        </button>
      </div>
    </div>
  );
}
