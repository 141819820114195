interface TabItem {
  title: string;
  subtitle: string;
}

interface TabBarProps {
  selectedTabIndex: number;
  tabItems: TabItem[];
  onSelect: (index: number) => void;
}
import './TabBar.scss';
import Ripple from 'components/Ripple';
export const TabBar = ({ selectedTabIndex, tabItems, onSelect }: TabBarProps) => {
  return (
    <div className="tab-bar">
      {tabItems.map((item, i) => {
        const selected = i === selectedTabIndex;
        return (
          <div
            key={i}
            className={`tab-bar__headerItem ${selected ? 'selected' : ''}`}
            onClick={() => onSelect(i)}
          >
            <Ripple duration={200} color="#D2D2D2" />
            <div className="tab-bar__headerItem__text-container">
              <span className="tab-bar__headerItem__title">{item.title}</span>
              <span className="tab-bar__headerItem__subtitle">{item.subtitle}</span>
            </div>
          </div>
        );
      })}
      <div className="tab-bar__slider"></div>
    </div>
  );
};
