import React, { useState, useEffect } from 'react';

const DURATION = 1200;

type GlobalSetActiveToast = (_toastText: string | undefined) => void;

let globalSetActiveToast: GlobalSetActiveToast | undefined;
let toastTimeout: NodeJS.Timeout | undefined;

export const ToastContainer: React.FC = () => {
  const [toastText, setToastText] = useState<string | undefined>();

  useEffect(() => {
    globalSetActiveToast = (t: string | undefined) => {
      setToastText(t);
    };
  }, []);

  return <div className={`toast ${toastText ? 'active' : ''}`}>{toastText}</div>;
};

export function setToastAppear(text: string) {
  if (toastTimeout) {
    clearTimeout(toastTimeout);
  }
  globalSetActiveToast?.(text);
  toastTimeout = setTimeout(() => {
    globalSetActiveToast?.(undefined);
  }, DURATION);
}
