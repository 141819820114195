import { useState, useMemo } from 'react';
import { GetDiscountTickets, GetDiscountBonus, ReceiveDiscountCode } from 'api/taxiTrip';
import { DiscountBonusModel } from 'api/taxiTrip/model/BEModel';

import {
  useDiscount,
  BONUS_STATES,
  UPDATE_BONUS,
  UPDATE_DISCOUNT,
  REMOVE_DISCOUNT,
  UPDATE_TICKET,
  REMOVE_TICKET,
} from 'context/DiscountProvider';
import { useEstimatedFare } from 'context/EstimatedFareProvider';

interface DiscountParams {
  updatedOrderAmount?: number;
  fareId?: string;
}

export function useFetchDiscount() {
  const [isTicketLoading, setIsTicketLoading] = useState(false);
  const [isBonusLoading, setIsBonusLoading] = useState(false);

  const { estimatedFare } = useEstimatedFare();
  const {
    ticketState,
    ticketDispatch,
    bonusState,
    discountState,
    bonusDispatch,
    discountDispatch,
  } = useDiscount();

  const codeDiscountAmount = discountState?.price || 0;
  const ticketDiscountAmount = useMemo(() => ticketState?.price || 0, [ticketState]);
  const orderAmount = estimatedFare?.fare || 0;
  const estimatedFareId = estimatedFare?.fareId || '';

  function filterAvailableDiscountTickets(
    ticketData,
    calculatedOrderAmount = orderAmount - codeDiscountAmount
  ) {
    return ticketData.orderDiscount <= calculatedOrderAmount;
  }

  function getAvailableTickets({ updatedOrderAmount, ticketData }) {
    return ticketData.filter((item) => filterAvailableDiscountTickets(item, updatedOrderAmount));
  }

  function findUsedTicket(availableTickets) {
    return availableTickets.find(
      (ticket) => ticket.ticketInstanceId === ticketState.info.ticketInstanceId
    );
  }

  async function fetchDiscountTickets({
    fareId = estimatedFareId,
    updatedOrderAmount = orderAmount - codeDiscountAmount,
  }: DiscountParams = {}) {
    if (!fareId || (orderAmount <= 0 && !updatedOrderAmount)) {
      return { res: null, error: 'no fareId or orderAmount' };
    }

    setIsTicketLoading(true);

    const [res, error] = await GetDiscountTickets({
      fareId,
      orderAmount: updatedOrderAmount,
    });

    setIsTicketLoading(false);

    return { res: res?.data?.ticketResults, error };
  }

  function getBonusInfo(bonusData: DiscountBonusModel) {
    if (!bonusData) return null;

    const hasPersonalBonus =
      bonusData?.personalBonus?.bonusSum > 0 || bonusData?.personalEnterpriseCoin?.amount > 0;
    const serviceCanUsePersonalBonus = bonusData?.bonusResult?.bonusAmount || 0;
    const serviceCanUseEnterpriseBonus = bonusData?.enterpriseCoinResult?.coinAmount || 0;
    const canUseBonus = serviceCanUsePersonalBonus || serviceCanUseEnterpriseBonus;

    const bonusInfo = {
      state: BONUS_STATES.EMPTY,
      price: serviceCanUsePersonalBonus,
      info: {
        canUseBonusAmount: serviceCanUsePersonalBonus,
        canUseEnterpriseAmount: serviceCanUseEnterpriseBonus,
      },
    };

    if (hasPersonalBonus) {
      bonusInfo.state = BONUS_STATES.DISABLE;
    }

    if (canUseBonus) {
      bonusInfo.state = BONUS_STATES.NOT_USED;
    }

    if (canUseBonus && [BONUS_STATES.NOT_USED, BONUS_STATES.USED].includes(bonusState?.state)) {
      bonusInfo.state = bonusState.state;
    }

    return bonusInfo;
  }

  async function fetchDiscountBonus({
    fareId = estimatedFareId,
    updatedOrderAmount,
  }: DiscountParams = {}) {
    if (!fareId || (orderAmount <= 0 && !updatedOrderAmount)) {
      return { res: null, error: 'no fareId or orderAmount' };
    }

    setIsBonusLoading(true);
    const [res, error] = await GetDiscountBonus({
      fareId,
      orderAmount:
        updatedOrderAmount !== undefined
          ? updatedOrderAmount
          : orderAmount - codeDiscountAmount - ticketDiscountAmount,
    });

    setIsBonusLoading(false);

    return { res: res?.data ? getBonusInfo(res.data) : null, error };
  }

  async function updateDiscountBonus({
    updatedOrderAmount,
    fareId = estimatedFareId,
  }: DiscountParams = {}) {
    const { res: bonusData, error: _bonusError } = await fetchDiscountBonus({
      fareId,
      updatedOrderAmount,
    });
    if (bonusData) {
      bonusDispatch({
        type: UPDATE_BONUS,
        payload: {
          key: 'bonus',
          state: bonusData.state,
          price: bonusData.price,
          info: bonusData.info,
        },
      });
    } else {
      bonusDispatch({
        type: UPDATE_BONUS,
        payload: {
          key: 'bonus',
          state: BONUS_STATES.EMPTY,
          price: 0,
          info: {},
        },
      });
    }
  }

  // 檢查優惠券及紅利點數是否可用
  async function checkTicketAndBonusDiscountAvailable({
    updatedOrderAmount = orderAmount - codeDiscountAmount,
    fareId = estimatedFareId,
  }: DiscountParams = {}): Promise<boolean> {
    if (!ticketState?.price && bonusState.state !== BONUS_STATES.USED) return true;
    let ticketDiscountAmount = 0;
    if (ticketState?.price > 0) {
      ticketDiscountAmount = await checkTicketDiscountAmount({ fareId, updatedOrderAmount });
      if (ticketDiscountAmount <= 0) return false;
    }
    if (bonusState?.state === BONUS_STATES.USED) {
      const canUseBonus = await checkBonusDiscountAmount({
        fareId,
        updatedOrderAmount: updatedOrderAmount - ticketDiscountAmount,
      });
      return canUseBonus > 0;
    }
    return true;
  }

  async function checkTicketDiscountAmount({
    fareId = estimatedFareId,
    updatedOrderAmount,
  }: DiscountParams = {}) {
    const { res: ticketData, error: _ticketError } = await fetchDiscountTickets({
      fareId,
      updatedOrderAmount,
    });
    if (ticketData) {
      const availableTickets = getAvailableTickets({
        updatedOrderAmount,
        ticketData,
      });
      const usedTicket = findUsedTicket(availableTickets);
      return usedTicket?.orderDiscount || 0;
    }
    return 0;
  }

  async function checkBonusDiscountAmount({
    fareId = estimatedFareId,
    updatedOrderAmount,
  }: DiscountParams = {}): Promise<number> {
    if (bonusState?.state !== BONUS_STATES.USED) return 0;
    const { res: bonusData, error } = await fetchDiscountBonus({ fareId, updatedOrderAmount });
    if (error) return 0;
    return bonusData?.info?.canUseBonusAmount || 0;
  }

  // 優惠計算機 - 更新所有優惠使用狀態及金額
  async function updateDiscountUsage({
    updatedOrderAmount = orderAmount,
    startTrigger = 'discount',
    fareId = estimatedFareId,
    discountCode = discountState?.code,
  }: {
    updatedOrderAmount?: number;
    startTrigger?: 'discount' | 'ticket' | 'bonus';
    fareId?: string;
    discountCode?: string;
  } = {}) {
    try {
      const updatedAmountAfterDiscount = await checkAndUpdateDiscount({
        updatedOrderAmount,
        startTrigger,
        fareId,
        discountCode,
      });
      const updatedAmountAfterTicket = await checkAndUpdateTicket({
        updatedOrderAmount: updatedAmountAfterDiscount,
        startTrigger,
        fareId,
      });
      await updateDiscountBonus({ updatedOrderAmount: updatedAmountAfterTicket, fareId });
    } catch (err) {
      console.error('Error updating discount usage:', err);
    }
  }

  async function checkAndUpdateDiscount({
    updatedOrderAmount,
    startTrigger,
    fareId = estimatedFareId,
    discountCode = '',
  }: {
    updatedOrderAmount: number;
    startTrigger: string;
    fareId?: string;
    discountCode?: string;
  }) {
    const shouldApplyDiscount =
      discountState && discountState.price > 0 && startTrigger === 'discount';
    if (!shouldApplyDiscount) return updatedOrderAmount;

    const [res, error] = await ReceiveDiscountCode({
      code: discountCode,
      fareId,
      orderAmount: updatedOrderAmount,
    });
    if (error || !res) {
      console.error('Error fetching discount code:', error);
      discountDispatch({
        type: REMOVE_DISCOUNT,
      });
      return updatedOrderAmount;
    }

    const { normalDiscount, recommendDiscount } = res?.data ?? {};
    const activeDiscount = normalDiscount || recommendDiscount;
    const activeDiscountKey = normalDiscount ? 'discount' : 'recommend';

    if (discountState && activeDiscount && activeDiscount.discountAmount > 0) {
      discountDispatch({
        type: UPDATE_DISCOUNT,
        payload: {
          key: activeDiscountKey,
          price: activeDiscount.discountAmount,
          info: activeDiscount,
          code: discountCode,
        },
      });
      return updatedOrderAmount - activeDiscount.discountAmount;
    }
    discountDispatch({
      type: REMOVE_DISCOUNT,
    });
    return updatedOrderAmount;
  }

  async function checkAndUpdateTicket({
    updatedOrderAmount,
    startTrigger,
    fareId = estimatedFareId,
  }: {
    updatedOrderAmount: number;
    startTrigger: string;
    fareId?: string;
  }) {
    const shouldApplyTicket = ['discount', 'ticket'].includes(startTrigger);

    if (!shouldApplyTicket) return updatedOrderAmount;

    const { res: ticketData, error: _ticketError } = await fetchDiscountTickets({
      fareId,
      updatedOrderAmount,
    });

    if (_ticketError || !ticketData?.length) {
      ticketDispatch({
        type: REMOVE_TICKET,
      });
      return updatedOrderAmount;
    }

    const availableTickets = getAvailableTickets({
      updatedOrderAmount,
      ticketData,
    });

    const ticketToUse = findUsedTicket(availableTickets) || availableTickets?.[0];

    if (ticketToUse) {
      ticketDispatch({
        type: UPDATE_TICKET,
        payload: {
          key: 'ticket',
          price: ticketToUse.orderDiscount,
          info: ticketToUse,
        },
      });
      return updatedOrderAmount - ticketToUse.orderDiscount;
    }

    ticketDispatch({
      type: REMOVE_TICKET,
    });
    return updatedOrderAmount;
  }

  // 取得折扣碼折扣金額
  async function getDiscountAmount({ fareId, discountCode, fare }) {
    const [res, error] = await ReceiveDiscountCode({
      code: discountCode,
      fareId,
      orderAmount: fare,
    });
    if (error || !res) {
      console.error('Error fetching discount code:', error);
      return 0;
    }
    return (
      res?.data?.normalDiscount?.discountAmount || res?.data?.recommendDiscount?.discountAmount || 0
    );
  }

  async function applyDiscount({
    updatedOrderAmount,
    discountFetcher,
    discountGetter,
    discountContext,
  }) {
    const discountData = await discountFetcher(discountContext);
    const discountAmount = discountGetter(discountData);
    return updatedOrderAmount - discountAmount;
  }

  //取得折扣後的車資
  async function getDiscountedOrderAmount({
    fare,
    fareId,
    discountCode,
    ticketInstanceId,
    useBonus,
  }) {
    let updatedOrderAmount = fare;

    if (discountCode) {
      updatedOrderAmount = await applyDiscount({
        updatedOrderAmount,
        discountFetcher: getDiscountAmount,
        discountGetter: (discount) => discount,
        discountContext: {
          fareId,
          discountCode,
          fare,
        },
      });
    }

    if (ticketInstanceId) {
      updatedOrderAmount = await applyDiscount({
        updatedOrderAmount,
        discountFetcher: fetchDiscountTickets,
        discountGetter: (discount) => {
          const availableTickets = getAvailableTickets({
            updatedOrderAmount,
            ticketData: discount?.res || [],
          });
          return (
            availableTickets?.find((ticket) => ticket.ticketInstanceId === ticketInstanceId)
              ?.orderDiscount || 0
          );
        },
        discountContext: {
          fareId,
          updatedOrderAmount,
          ticketInstanceId,
        },
      });
    }

    if (useBonus) {
      updatedOrderAmount = await applyDiscount({
        updatedOrderAmount,
        discountFetcher: fetchDiscountBonus,
        discountGetter: (discount) => {
          return discount?.res?.price || 0;
        },
        discountContext: {
          fareId,
          updatedOrderAmount,
        },
      });
    }

    return updatedOrderAmount;
  }

  return {
    isTicketLoading,
    fetchDiscountTickets,
    isBonusLoading,
    fetchDiscountBonus,
    updateDiscountBonus,
    checkTicketAndBonusDiscountAvailable,
    filterAvailableDiscountTickets,
    updateDiscountUsage,
    getDiscountedOrderAmount,
  };
}
