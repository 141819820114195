import { CreditCardImage } from './CreditCardImage';
import { CreditCardInfo } from '../selectCreditCardPage';
import './CreditCardSelect.scss';

export interface CreditCardProps {
  creditCard: CreditCardInfo;
  selectedCard: CreditCardInfo | null;
  onClick: (creditCard: CreditCardInfo) => void;
}

export const CreditCard = ({ creditCard, selectedCard, onClick }: CreditCardProps) => {
  return (
    <div className="credit-card" onClick={() => onClick(creditCard)}>
      <div className="credit-card__button">
        <div className="credit-card__radio">
          <input
            id={creditCard.id}
            type="radio"
            checked={!!selectedCard && selectedCard.id === creditCard.id}
            autoComplete="off"
            readOnly
          />
        </div>
      </div>
      <div className="credit-card__img">
        <CreditCardImage height="32px" type={creditCard.type} alt={creditCard.alt} />
      </div>
      <div className="credit-card__info">
        <p>{creditCard.bank}</p>
        <p>{creditCard.encryptedCardNumber}</p>
      </div>
      <div className="credit-card__label">
        {creditCard.isDefault && (
          <label htmlFor={creditCard.id}>{creditCard.radioButtonText}</label>
        )}
      </div>
    </div>
  );
};
