import { useState, useContext, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Offline } from 'react-detect-offline';
import { useTranslation } from 'react-i18next';
import 'firebase/database';

import ReactLoading from 'react-loading';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import './App.scss';
import * as googleFunc from './utils/googleFunction';
import Map from './components/Map';
import { TabBar } from './components/TabBar/TabBar';
import SideBar from './components/SideBar';
import Header from './components/Header';
import Form from './components/Form';
import Ripple from './components/Ripple';
import FooterConfirm from './components/Footer/FooterConfirm';
import DiscountPopup from './components/Discount/DiscountPopup';
import FeeDetailsBottomPopup from './components/FeeDetailsBottomPopup';
import { Button } from './components/Button/Button';
import Modali, { useModali } from './components/Modal';
import { setToastAppear, ToastContainer } from './components/Toast/Toast';

import { TAXI_TYPE, TRIP_PAYMENT_METHOD } from './constants';

import { Route } from 'react-router-dom';
import { CreditCardPaymentPage } from './pages/creditCard/creditCardPaymentPage';
import { SelectCreditCardPage } from 'pages/creditCard/selectCreditCardPage';
import { PaymentFailedPage } from 'pages/creditCard/paymentFailedPage';
import { HistoryDetailPage } from 'pages/history/historyDetailPage';
import StatusPage from './pages/statusPage';
import { useStatus } from './pages/useStatus';

import PreferList from './pages/preferList';
import HistoryList from './pages/historyList';
import LogList from './pages/logList';
import './pages/PageList.scss';
import * as api from './api';
import * as taxiTripAPI from './api/taxiTrip';
import { useAlertModal } from './components/AlertModal';
import { useAdBanner } from './components/AdBanner';
import { useCommentModal } from './components/CommentModal';
import { useCancelEventModal } from './components/CancelEventModal';
import { useAlertPopupModal } from './components/AlertPopupModal';
import { useSelectTicketModal } from './components/SelectTicketModal';
import { useCallTaxi } from './useCallTaxi';
import { useDiscountInputFocus } from 'context/DiscountInputFocusProvider';
import { useEstimatedFare } from 'context/EstimatedFareProvider';
import { useDiscount } from 'context/DiscountProvider';
import { useFetchDiscount } from 'hooks/useFetchDiscount';
import { isNullOrUnDef } from 'utils/is';
import { getParameterByName } from 'utils';

// ! 增加追蹤錯誤 GA
import TagManager from 'react-gtm-module';

// Context and hook
import {
  placeContext,
  UPDATE_ORIGIN_PLACE_LATLNG,
  UPDATE_ORIGIN_PLACE,
  UPDATE_DESTINATION_PLACE,
  UPDATE_DESTINATION_PLACE_LATLNG,
  RESET_PLACE,
} from './place';
import { useRecord } from './useRecord';
import { validateAndFormatAddress, extractAddress, isExpired, formatDiscountText } from 'utils';
import { processHistoryApiResult } from 'utils/historyHelper';

function App() {
  const history = useHistory();
  const location = useLocation();
  const [prevPath, setPrevPath] = useState('');

  const { discountInputFocus } = useDiscountInputFocus();
  const { updateDiscountUsage, getDiscountedOrderAmount } = useFetchDiscount();

  const { t } = useTranslation();
  const { recordActionPlaceUseCallTaxi } = useRecord();
  // about taxi mode
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const tabItems = [
    { id: 1, title: t('A077'), subtitle: t('A078') },
    { id: 2, title: t('A079'), subtitle: t('A080') },
  ];
  const [paymentType, setPaymentType] = useState(1);
  const [isOnlinePay, setIsOnlinePay] = useState(true);
  const [paymentOriginPage, setPaymentOriginPage] = useState('home');
  const [commentOrderIsOnlinePay, setCommentOrderIsOnlinePay] = useState(false);
  useEffect(() => {
    setIsOnlinePay(paymentType === 1);
    setCommentOrderIsOnlinePay(paymentType === 1);
  }, [paymentType]);
  // about fare
  const { estimatedFare, updateEstimatedFare, resetEstimatedFare } = useEstimatedFare();
  const [isLoadingFare, setIsLoadingFare] = useState(false);
  const [estimateFareTimeoutControl, setEstimateFareTimeoutControl] = useState({ action: 'idle' });
  // about display
  const [headerText, setHeaderText] = useState('');
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const [formDisplay, setFormDisplay] = useState(true);
  const [historyDisplay, setHistoryDisplay] = useState(false);
  const [preferDisplay, setPreferDisplay] = useState(false);
  const [loglistDisplay, setLoglistDisplay] = useState(false);
  const [menuBarBack, setMenuBarBack] = useState(false);
  const [showCancelFareWarning, setShowCancelFareWarning] = useState(false);
  const [showCancelFarePayWarning, setShowCancelFarePayWarning] = useState(false);
  const [isDiscountOpen, setIsDiscountOpen] = useState(false);
  const [isFeeDetailsOpen, setIsFeeDetailsOpen] = useState(false);
  // about browser history API
  const [isHistoryMake, setIsHistoryMake] = useState(false);
  const [internetErrorCheck, setInternetErrorCheck] = useState(false);

  // about trip
  const [needToNoticeTrip, setNeedToNoticeTrip] = useState(null);
  const hasRedDotDisplay = Boolean(needToNoticeTrip);

  // about user data
  const [user, setUser] = useState(null);
  const [historyResult, setHistoryResult] = useState([]);
  const [preferResult, setPreferResult] = useState([]);
  const [preferPlaceholder, setPreferPlaceholder] = useState('常用地點1');
  const [homeResult, setHomeResult] = useState([]);
  const [logListResult, setLogListResult] = useState(['log start']);
  const [userBindCards, setUserBindCards] = useState([]);
  const [isBindCardSuccess, setIsBindCardSuccess] = useState(null);
  const [orderDetail, setOrderDetail] = useState({
    ok: false,
    phone: '',
    comment: '',
    need: [
      { name: t('A024'), id: 'input1', isChecked: false },
      { name: t('A025'), id: 'input2', isChecked: false },
      { name: t('A026'), id: 'input3', isChecked: false },
    ],
    uberOptions: [
      { name: t('A024'), id: 'input1', isChecked: false },
      { name: t('A026'), id: 'input3', isChecked: false },
    ],
    paymentTypeList: [{ name: t('A084'), id: '1', isChecked: true }],
  });
  const { place, dispatch } = useContext(placeContext);

  // 優惠與推薦
  const { discountDetails, discountTotal, resetAllDiscount } = useDiscount();

  // 選擇的信用卡
  const [selectedCard, setSelectedCard] = useState(null);
  // 是否來自信用卡綁定
  const [isAddBindCard, setIsAddBindCard] = useState(false);

  // 車子頁面與分享頁面派車資料
  const [driverDispatch, setDriverDispatch] = useState({});

  // * 追蹤使用者地區用
  const [traceCallTaxiFail, setTraceCallTaxiFail] = useState(false);
  const [traceRegionId, setTraceRegionId] = useState(0);
  const [shouldRequestPosition, setShouldRequestPosition] = useState(false);

  // fetch firebase data
  const [GUID, setGUID] = useState('');
  const [orderFinish, setOrderFinish] = useState(false);
  const [offline, setOffline] = useState(false);
  const { TaxiNotificationModal, stopLoad } = useAlertModal();
  const { AdBannerComponent } = useAdBanner();

  // footer
  const footerRef = useRef(null);
  const [footerHeight, setFooterHeight] = useState(0);

  const [giftModalContent, setGiftModalContent] = useState('');
  const {
    toggleAlertPopupModal: toggleGiftReceivedModal,
    alertPopupModal: giftReceivedModalComponent,
  } = useAlertPopupModal({
    content: giftModalContent,
    imgSrc: '',
    imgAlt: '獲得專屬好禮',
    title: '獲得專屬好禮',
    buttonText: '我知道了',
  });
  const { toggleCancelEventModal, cancelEventModalComponent } =
    useCancelEventModal(showCancelFarePayWarning);
  const {
    addRegionId,
    formalizationAddress,
    formalizationAddressWithLatLng,
    callTaxiHistoryCheck,
  } = useCallTaxi(setTraceRegionId);
  const { SelectTicketModal, toggleSelectTicketModal } = useSelectTicketModal({ estimatedFare });

  // 感謝回饋 Modal ( 只會在分享頁面完成評價後出現 )
  const [thanksModal, toggleThanksModal] = useModali({
    title: t('E007'),
    animated: true,
    buttons: [
      <Modali.Button
        key="ModaliButton"
        label={t('002')}
        isTwoButton
        isStyleDestructive
        onClick={() => toggleThanksModal()}
      />,
    ],
  });

  // 取消叫車 Modal ( 等車頁面及有車頁面出現 ) 以返回鍵觸發顯示視窗
  const [drawerModal, toggleDrawerModal] = useModali({
    isDrawer: true,
    animated: true,
    overlayClose: false,
    buttons: [
      <Modali.Button
        key="ModaliButton"
        label={t('012')}
        isStyleDestructive
        onClick={() => window.history.back()}
      />,
    ],
  });

  // 車子到達頁返回鎖定 Modal
  const [lockModal, toggleLockModal] = useModali({
    animated: true,
    message: t('D012'),
    buttons: [
      <Modali.Button key="ModaliButton" label={t('002')} onClick={() => toggleLockModal()} />,
    ],
  });

  const [commentFareInfo, setCommentFareInfo] = useState({
    isOnlinePay: true,
    fare: 0,
    originFare: 0,
    showPrice: false,
  });

  // 評價 Modal
  const { CommentModal, evaluationModal, toggleEvaluationModal, setCommentQuestionGUID } =
    useCommentModal({
      setHistoryResult,
      commentFareInfo,
      commentOrderIsOnlinePay,
      setPaymentOriginPage,
    });

  const [monitorStatusAlready, setMonitorStatusAlready] = useState(true);
  // ! status 控制
  const { startStatusMonitor, startStatusMonitorOff } = useStatus(
    drawerModal,
    toggleDrawerModal,
    lockModal,
    toggleLockModal,
    setDriverDispatch,
    evaluationModal,
    toggleEvaluationModal,
    setCommentFareInfo,
    setShowCancelFareWarning,
    setShowCancelFarePayWarning,
    setCommentQuestionGUID,
    setMonitorStatusAlready
  );

  // 使用者條例 Modal
  const [userAgreementModal, toggleUserAgreementModal] = useModali({
    animated: true,
    title: t('W001'),
    overlayClose: false,
    buttons: [
      <Modali.Button
        key="ModaliButton"
        label="我知道了"
        isTwoButton
        isStyleDestructive
        onClick={() => userAgreementModalConfirm()}
      />,
    ],
  });

  const userAgreementModalConfirm = () => {
    let oldUserAgreementCollect = window.localStorage.getItem('userAgreement')
      ? window.localStorage.getItem('userAgreement')
      : '';
    let newUserAgreementCollect = oldUserAgreementCollect.split(',');
    newUserAgreementCollect.push(window.localStorage.getItem('ServiceAccountAId'));
    window.localStorage.setItem('userAgreement', newUserAgreementCollect.join());
    toggleUserAgreementModal();
  };

  // 司機出事 Modal ( 有車頁面出現, 觸發條件 : 已派車後無司機未見客且由廠商取消 )
  const [driverAccidentModal, toggleDriverAccidentModal] = useModali({
    animated: true,
    message: t('A070'),
    overlayClose: false,
    buttons: [
      <Modali.Button
        key="ModaliButton"
        label={t('002')}
        isTwoButton
        isStyleDestructive
        onClick={() => driverAccidentModalConfirm()}
      />,
    ],
  });

  const driverAccidentModalConfirm = () => {
    toggleDriverAccidentModal();
    startStatusMonitorOff();
    // 修正假資料狀態為系統取消
    api
      .kingnetTaxiUserSearchOrders({ MaxCount: 1 })
      .then((response) => {
        if (
          response.data &&
          response.data.Data &&
          response.data.Data.orders &&
          response.data.Data.orders.length === 0
        ) {
          if (window.localStorage.getItem('pageStatus') !== 'main') {
            return history.push('/');
          } else {
            return;
          }
        }
        // 修正假資料
        setHistoryResult((oldArray) => {
          if (
            oldArray.length !== 0 &&
            oldArray[0].isCancel &&
            oldArray[0].cancelSource === 'fake-1'
          ) {
            let newFake = {
              ...oldArray[0],
              isDispatch: true,
              isCancel: response.data.Data.orders[0].is_cancel,
              cancelSource: response.data.Data.orders[0].cancel_source,
            };
            oldArray.shift();
            return [newFake, ...oldArray];
          } else {
            return oldArray;
          }
        });

        if (window.localStorage.getItem('pageStatus') !== 'main') {
          return history.push('/');
        }
      })
      .catch(() => {
        // 錯誤未處理完全
        if (window.localStorage.getItem('pageStatus') !== 'main') {
          return history.push('/');
        } else {
          return;
        }
      });
  };

  // 首頁返回提示 Modal ( 防止首頁有機會返回到"分享頁面", "認證頁面", "無車頁面" )
  const [mainLockModal, toggleMainLockModal] = useModali({
    animated: true,
    message: '如要關閉網頁, 請由右上角 X 關閉',
    buttons: [
      <Modali.Button
        key="ModaliButton"
        label="確認"
        isTwoButton
        isStyleDestructive
        onClick={() => toggleMainLockModal()}
      />,
    ],
  });

  // 2020/11 更醒目的超出服務範圍提醒
  const [overServiceAreaModal, toggleOverServiceAreaModal] = useModali({
    animated: true,
    overlayClose: false,
    title: '服務範圍尚未開放',
    message: '親愛的用戶，您的上車地點目前尚未開放，若服務區域開通時，我們將主動通知您，敬請期待！',
    buttons: [
      <Modali.Button
        key="ModaliButton"
        label="確認"
        isTwoButton
        isStyleDestructive
        onClick={() => toggleOverServiceAreaModal()}
      />,
    ],
  });

  // 司機未見客 Modal ( 7/3 棄用 )
  const [driverCantSeeYouModal, toggleDriverCantSeeYouModal] = useModali({
    animated: true,
    message: '司機沒看到你耶, 發生什麼事了嗎',
    overlayClose: false,
    buttons: [
      <Modali.Button
        key="ModaliButton"
        label="看到你了"
        isTwoButton
        isStyleDestructive
        onClick={() => toggleDriverCantSeeYouModal()}
      />,
      <Modali.Button
        key="ModaliButton"
        label="我想取消"
        isTwoButton
        isStyleDestructive
        onClick={() => toggleDriverCantSeeYouConfirm()}
      />,
    ],
  });

  const toggleDriverCantSeeYouConfirm = () => {
    let guid = GUID ? GUID : window.localStorage.getItem('GUID');
    api
      .kingnetTaxiUserCancel({ guid: guid })
      .then(() => {
        toggleDriverCantSeeYouModal();
        startStatusMonitorOff();
        setGUID('');
        setOrderFinish(false);
        history.push('/');
      })
      .catch(() => {
        toggleDriverCantSeeYouModal();
        setToastAppear('系統錯誤');
      });
  };

  // 編輯提醒 Modal
  const [endEditModal, toggleEndEditModal] = useModali({
    animated: true,
    message: '現在離開將不保存所有修改',
    buttons: [
      <Modali.Button
        key="ModaliButton"
        label={t('003')}
        isTwoButton
        onClick={() => toggleEndEditModal()}
      />,
      <Modali.Button
        key="ModaliButton"
        label={t('002')}
        isStyleDestructive
        isTwoButton
        onClick={() => endEditModalConfirm()}
      />,
    ],
  });

  function endEditModalConfirm() {
    window.localStorage.setItem('pageStatus', 'edit');
    window.history.back();
    setPreferDisplay(true);
    setAddNewPreferMode(false);
    setMenuBarBack(false);
    setHeaderText('');
    toggleEndEditModal();
  }

  // 補款提醒 Modal
  const [unpaidAlert, toggleUnpaidAlertModal] = useModali({
    animated: true,
    title: '前筆行程費用異動',
    message: '因您前筆行程費用異動，請完成補款以繼續叫車',
    overlayClose: false,
    buttons: [
      <Modali.Button key="ModaliButton" label="前往補款" onClick={() => goPayUnpaidTrip()} />,
    ],
  });

  // 未付款行程提醒 Modal
  const [unpaidTripAlert, toggleUnpaidTripAlertModal] = useModali({
    animated: true,
    title: '前筆行程尚未完成付款',
    message: '因為您前筆行程尚未付款，請確認是否完成付款以繼續叫車',
    overlayClose: false,
    buttons: [
      <Modali.Button
        isTwoButton
        color="var(--mainTextColor)"
        key="ModaliButton"
        label="取消叫車"
        onClick={() => handleCancelCallTaxi()}
      />,
      <Modali.Button
        isTwoButton
        key="ModaliButton"
        label="前往付款"
        onClick={() => goPayUnpaidTrip()}
      />,
    ],
  });

  function goPayUnpaidTrip() {
    handlePaymentRedirect();
    if (unpaidTripAlert.status()) toggleUnpaidTripAlertModal();
    if (unpaidAlert.status()) toggleUnpaidAlertModal();
  }

  const [confirmFareChangedModal, toggleConfirmFareChangedModal] = useModali({
    animated: true,
    title: '車資異動',
    overlayClose: false,
    buttons: [
      <Modali.Button
        key="ModaliButton"
        label="取消叫車"
        isTwoButton
        onClick={() => handleCancelCallTaxi()}
      />,
      <Modali.Button
        key="ModaliButton"
        label="確認叫車"
        isStyleDestructive
        isTwoButton
        onClick={() => handleCallTaxiPay()}
      />,
    ],
  });
  const [estimatedFareErrorMessage, setEstimatedFareErrorMessage] = useState('');
  const [estimatedFareErrorModal, toggleEstimatedFareErrorModal] = useModali({
    animated: true,
    overlayClose: false,
    title: '連線異常',
    message: estimatedFareErrorMessage,
    buttons: [
      <Modali.Button
        key="ModaliButton"
        label="確認"
        isTwoButton
        isStyleDestructive
        onClick={() => toggleEstimatedFareErrorModal()}
      />,
    ],
  });

  const [cancelTaxiLocker, setCancelTaxiLocker] = useState(false);
  // 取消叫車 Modal 上的取消按鈕 ( 離線阻擋, 連擊阻擋 )
  function someReasonCancel(reason) {
    if (offline) return setToastAppear('取消失敗 請檢查您的網路');
    if (cancelTaxiLocker) return setToastAppear('系統處理中');

    setCancelTaxiLocker(true);
    setStatusAlready(true);
    // * 開啟取消叫車動畫
    toggleCancelEventModal();
    let guid = GUID ? GUID : window.localStorage.getItem('GUID');
    console.log(reason);
    let apiCall;
    console.log('someReasonCancel', isOnlinePay);
    if (isOnlinePay) {
      apiCall = taxiTripAPI.CancelCallUberTaxi({ tripGuid: guid });
    } else {
      apiCall = api.kingnetTaxiUserCancel({ guid: guid, reason: reason });
    }

    apiCall
      .then(() => {
        // * 關閉取消叫車動畫
        setTimeout(() => {
          toggleCancelEventModal();
        }, 1500);
        setCancelTaxiLocker(false);
        // 立即切斷 firebase 監聽, 防止延遲跳轉機制發生
        startStatusMonitorOff();
        setToastAppear(t('B002'));
        setGUID('');
        setOrderPlaceDetail();

        // * 取消叫車成功後回到首頁後, 重啟 Init Tab
        const location = {
          pathname: '/',
        };
        history.push(location);
        const initLocation = {
          pathname: '/',
          state: {
            initMainPage: true,
          },
        };
        history.push(initLocation);
      })
      .catch((error) => {
        if (drawerModal.status()) toggleDrawerModal();
        toggleCancelEventModal();
        setCancelTaxiLocker(false);
        if (isOnlinePay) {
          setToastAppear('取消叫車失敗');
        } else if (
          error.response &&
          error.response.data &&
          error.response.data.ErrorMessage &&
          (error.response.data.ErrorMessage.Code === 404001 ||
            error.response.data.ErrorMessage.Description === 'job_id is null')
        ) {
          setToastAppear('訂單還在成立中, 請稍後再試');
        } else {
          console.error(error);
          setToastAppear('系統錯誤');
        }
      });
  }
  // TOPTIP 顯示專用 ( 自帶 1.2 秒清除 )
  const [callTaxifail, setCallTaxifail] = useState('');
  const [callTaxiTimer, setCallTaxiTimer] = useState(null);
  function setToptipAppear(text, forever = false) {
    setCallTaxifail(text);
    if (callTaxiTimer) clearTimeout(callTaxiTimer);
    if (!forever) setCallTaxiTimer(setTimeout(() => setCallTaxifail(''), 3000));
  }
  // 控管 Token 合法性與訂單是否處於跳轉狀態 ( 是否渲染主元件 <Map> )
  const [tokenAlready, setTokenAlready] = useState(false);
  const [statusAlready, setStatusAlready] = useState(false);
  // 初始事件驅動區塊 ( 啟動觸發 )
  useEffect(() => {
    // * 增加智生活入口判斷 ( 給 GA 分析用 )
    if (getParameterByName('entrance'))
      window.sessionStorage.setItem('entrance-GA', getParameterByName('entrance'));

    if (getParameterByName('tab')) {
      window.sessionStorage.setItem('tab', getParameterByName('tab'));
    }
    setRoutePathToSessionStorage();
    window.localStorage.setItem('pageStatus', 'main');

    // 儲存付款返回參數
    const parameterGuid = getParameterByName('guid');
    const isCreditCardPay = getParameterByName('isCreditCardPay');
    const paymentOriginPage = getParameterByName('paymentOriginPage');
    if (parameterGuid && isCreditCardPay && paymentOriginPage) {
      const paymentSearch = { guid: parameterGuid, isCreditCardPay, paymentOriginPage };
      window.sessionStorage.setItem('paymentSearch', JSON.stringify(paymentSearch));
    }

    // 開始檢測網路異常
    setTimeout(() => setInternetErrorCheck(true), 5000);

    // 改版前補救機制
    // * 這裡為 APP 切換用戶時的清除資料區域, 畢竟也不能讓新用戶用舊用戶的身分叫車 ( 不做管控的話, 壞孩子可以盜用舊用戶身分 1 小時 )
    if (!getParameterByName('code')) {
      setTimeout(() => {
        if (window.sessionStorage.getItem('watchStatus')) {
          window.localStorage.removeItem('overServiceArea');
          window.localStorage.removeItem('getCodeTime');
          window.localStorage.removeItem('ecsToken');
          window.localStorage.removeItem('ServiceAccountAId');
          window.localStorage.removeItem('GUID');
          window.location.replace(
            `https://inapp.kingnetsmart.com.tw/?client=${process.env.REACT_APP_KNST_CLIENT}&targeturl=${process.env.REACT_APP_API_ENDPOINT}&browsertitle=${process.env.REACT_APP_BROWSER_TITLE}`
          );
        } else {
          console.log('無更換用戶');
        }
      }, 500);
    }

    // ! * 新版本返回判斷
    history.listen((newLocation, action) => {
      console.log('%c History Change', 'color: pink; font-size: 25px');
      console.table({
        ...newLocation,
        action,
      });
      if (
        action === 'POP' &&
        newLocation.pathname === '/' &&
        !newLocation.hash &&
        !newLocation.state
      ) {
        history.go(1);
        toggleMainLockModal();
      } else if (action === 'POP' && newLocation.state && newLocation.state['initMainPage']) {
        closeAllTab(true);
        setSideBarOpen(false);
        setIsHistoryMake(false);
        window.localStorage.setItem('pageStatus', 'main');
      }
    });

    const setAppHeight = () => {
      const app = document.documentElement;
      if (app) {
        app.style.setProperty('--app-height', `${window.innerHeight}px`);
      }
    };
    window.addEventListener('resize', setAppHeight);

    setAppHeight();

    // 歷史紀錄堆疊處理 所有的返回處理都在這裡 ( 依據 localStorage 狀態變換返回方式 )
    window.addEventListener(
      'popstate',
      function () {
        if (window.localStorage.getItem('pageStatus') === 'editing') {
          toggleEndEditModal();
          window.history.pushState('tab', null, `./editing`);
        } else if (window.localStorage.getItem('pageStatus') === 'edit') {
          window.localStorage.setItem('pageStatus', 'mainTab');
        }
      },
      false
    );

    // 黑科技
    const URLecsToken = getParameterByName('ecsToken');
    if (URLecsToken) {
      let now = new Date();
      now.setHours(new Date().getHours() + 1);
      window.localStorage.setItem('ecsTokenExpireTime', now);
      window.localStorage.setItem('ecsToken', URLecsToken);
    }

    // inapp 跳轉網頁的 url param
    const knstCode = getParameterByName('code');
    if (window.localStorage.getItem('ecsToken')) {
      let checkExpire = new Date(window.localStorage.getItem('ecsTokenExpireTime'));
      // Token 時效檢查機制 => 可以跳過 Token 認證不通過時重新跳轉的一段時間, 達到過場加速
      if (checkExpire < new Date()) {
        window.localStorage.removeItem('getCodeTime');
        window.localStorage.removeItem('ecsToken');
        window.localStorage.removeItem('ServiceAccountAId');
        window.location.replace(
          `https://inapp.kingnetsmart.com.tw/?client=${process.env.REACT_APP_KNST_CLIENT}&targeturl=${process.env.REACT_APP_API_ENDPOINT}&browsertitle=${process.env.REACT_APP_BROWSER_TITLE}`
        );
        return;
      }
      window.localStorage.removeItem('getCodeTime');
      api.setAuthHeader(window.localStorage.getItem('ecsToken'));
      taxiTripAPI.setAuthHeader(window.localStorage.getItem('ecsToken'));
      setTokenAlready(true);
    } else if (knstCode) {
      let ok = true;
      if (window.localStorage.getItem('getCodeTime')) {
        let date = window.localStorage.getItem('getCodeTime');
        date = new Date(date);
        let diff = Math.abs(new Date() - date);
        if (diff < 2000) {
          ok = false;
          setToptipAppear('系統錯誤 請稍後重啟頁面再試', true);
        }
      } else {
        window.localStorage.setItem('getCodeTime', new Date());
      }
      if (ok) {
        setLogListResult((pre) => [...pre, { inappCode: knstCode }]);
        api
          .kingnetTaxiToken({ code: knstCode })
          .then((response) => {
            if (response.data.Status === 1) {
              let now = new Date();
              now.setHours(new Date().getHours() + 1);
              window.localStorage.setItem('ecsToken', response.data.Data.Token);
              window.localStorage.setItem('ecsTokenExpireTime', now);
              setLogListResult((pre) => [...pre, { ecsToken: response.data.Data.Token }]);
              api.setAuthHeader(response.data.Data.Token);
              taxiTripAPI.setAuthHeader(response.data.Data.Token);
              setTokenAlready(true);
            } else {
              setLogListResult((pre) => [...pre, { ecsTokenERR: response.data.ErrorMessage }]);
            }
          })
          .catch((error) => {
            if (error.response) {
              setLogListResult((pre) => [...pre, error.response.data]);
              setLogListResult((pre) => [...pre, error.response.status]);
            }
          });
        window.history.pushState('tab', null, `./`);
      }
    } else {
      window.localStorage.removeItem('getCodeTime');
      window.localStorage.removeItem('ecsToken');
      window.localStorage.removeItem('ServiceAccountAId');
      window.location.replace(
        `https://inapp.kingnetsmart.com.tw/?client=${process.env.REACT_APP_KNST_CLIENT}&targeturl=${process.env.REACT_APP_API_ENDPOINT}&browsertitle=${process.env.REACT_APP_BROWSER_TITLE}`
      );
    }
    return () => {
      window.removeEventListener('resize', setAppHeight);
    };
  }, []);

  useEffect(() => {
    if (isOnlinePay) {
      if (estimatedFare?.fareId) {
        setCallTaxiLock(false);
        setCallTaxiIsLoading(false);
      } else {
        setCallTaxiLock(true);
      }
    } else {
      setCallTaxiLock(false);
      setCallTaxiIsLoading(false);
    }
  }, [isOnlinePay, estimatedFare]);

  useEffect(() => {
    const footerHeight = footerRef.current?.offsetHeight || 0;
    setFooterHeight(footerHeight);
  }, [isOnlinePay, discountInputFocus, formDisplay, monitorStatusAlready, statusAlready]);

  async function setSandboxRunId() {
    if (process.env.REACT_APP_TAXI_TRIP_API === 'https://taxi-trip-dev.smartdaily.com.tw/') {
      // Uber測試沙盒，僅用於dev
      const [result] = await taxiTripAPI.getSandboxRunId();
      if (result) {
        taxiTripAPI.setSendboxRunId(result.data.run_id);
      }
    }
  }
  // 此錯誤捕捉被 /Profile, /Tablet, /CA 使用, 極低機率跳一次以上
  function getKingnetTaxiUserDataErrorHandle(error) {
    if (error.response && error.response.status === 401) {
      window.localStorage.removeItem('ecsToken');
      window.localStorage.removeItem('ServiceAccountAId');
      window.location.replace(
        `https://inapp.kingnetsmart.com.tw/?client=${process.env.REACT_APP_KNST_CLIENT}&targeturl=${process.env.REACT_APP_API_ENDPOINT}&browsertitle=${process.env.REACT_APP_BROWSER_TITLE}`
      );
    } else if (error.response) {
      window.localStorage.removeItem('ecsToken');
      window.localStorage.removeItem('ServiceAccountAId');
      setToptipAppear(`伺服器錯誤 ${error.response.status}`, true);
      setLogListResult((pre) => [...pre, { UserProfileREJ: error.response.data }]);
    } else {
      // 伺服器拒絕連接 NetWork Error
      setToptipAppear('伺服器拒絕連接 NetWork Error', true);
    }
    return '錯誤暫時處理';
  }

  function setDefaultTabFromSessionStorage() {
    setTabByTabId(window.sessionStorage.getItem('tab') || 1);
    window.sessionStorage.removeItem('tab');
  }

  function setRoutePathToSessionStorage() {
    if (location.pathname === '/') return;
    window.sessionStorage.setItem('routePath', location.pathname);
  }

  function getSessionRoutePath() {
    return window.sessionStorage.getItem('routePath');
  }

  function clearSessionRoutePath() {
    window.sessionStorage.removeItem('routePath');
  }

  function handleRouteRedirect() {
    const pathname = getSessionRoutePath();
    if (!pathname || pathname === '/') return;
    if (/^\/HistoryDetail/.test(pathname)) {
      openTab('history');
    }
    history.push({
      pathname,
      state: { skipCheckUnpaid: true },
    });
    clearSessionRoutePath();
  }

  // tokenAlready 驅動 ( 認證頁面導向完或是LocalStorage取得可用的Token拿來花花 )
  useEffect(() => {
    async function initPage() {
      setDefaultTabFromSessionStorage();
      await checkAndUpdateOrderStatus();
      handleRouteRedirect();
    }
    if (tokenAlready) {
      initPage();
    }
  }, [tokenAlready]);

  useEffect(() => {
    if (statusAlready && !GUID && !isAddBindCard) {
      setShouldRequestPosition(true);
    }
  }, [statusAlready]);

  useEffect(() => {
    async function checkUnpaidOrder() {
      try {
        console.log('checkUnpaidOrder');
        await handleCheckHasUnPaidOrder();
      } catch (err) {
        console.log(err);
      }
    }
    const redirectPath = getSessionRoutePath();
    setPrevPath(location.pathname);
    if (!tokenAlready) return;
    if (
      location.pathname === '/' &&
      prevPath !== '/' &&
      !redirectPath &&
      location?.state &&
      !location?.state?.skipCheckUnpaid
    ) {
      checkUnpaidOrder();
    } else if (location?.state?.skipCheckUnpaid) {
      location.state.skipCheckUnpaid = false;
    }
  }, [location]);

  /**
   * Handles the credit card process based on the query parameters received.
   * If the query parameter 'isAddBindCard' is present, it redirects to the payment page.
   * If the query parameter 'isCreditCardPay' is present, it checks the payment status and performs the necessary actions.
   * @returns {Promise<string>} A promise that resolves to a string representing the result of the credit card process, or false if no action is required.
   */
  async function executeCreditCardTransaction(paymentSearch) {
    // 從綁定信用卡頁面過來的，準備去選擇信用卡頁
    const updatedIsAddBindCard = getParameterByName('isAddBindCard');
    setIsAddBindCard(updatedIsAddBindCard);
    const status = Number(getParameterByName('status'));
    // 從付款頁轉址過來的，準備去等待頁
    const isCreditCardPay =
      getParameterByName('isCreditCardPay') || (paymentSearch && paymentSearch.isCreditCardPay);
    // 如果有儲存的地點，直接跳轉到信用卡選擇頁
    if (sessionStorage.getItem('selectedPlace') && !updatedIsAddBindCard) {
      handlePaymentRedirect(true);
      return 'go-pay';
    }
    if (!updatedIsAddBindCard && !isCreditCardPay) return 'no-payment';
    const paramsGUID = getParameterByName('guid') || (paymentSearch && paymentSearch.guid);
    window.history.pushState({}, '', '/');
    if (paramsGUID) {
      setGUID(paramsGUID);
    }
    if (updatedIsAddBindCard) {
      handlePaymentRedirect(true, status, updatedIsAddBindCard);
      return 'go-pay';
    }
    if (isCreditCardPay) {
      //取得註冊叫車資料，確認付款狀態
      const [resultEstimateOrigin, error] = await taxiTripAPI.GetRegisteredUberTaxiTrip({
        tripGuid: paramsGUID,
      });
      if (resultEstimateOrigin) {
        console.log('resultEstimateOrigin', resultEstimateOrigin);
        if (!resultEstimateOrigin.data.data.ars.length) {
          // // 付款成功
          const trip = resultEstimateOrigin.data.data.trip;
          if (trip) {
            const { getOut } = trip;
            if (getOut) {
              setToastAppear('付款成功');
              setShouldRequestPosition(true);
            }
          }
          return 'pay-success';
        } else {
          // 付款失敗
          return 'pay-failed';
        }
      }
      if (error) {
        setToptipAppear('系統錯誤 請稍後重啟頁面再試', true);
        return 'system-error';
      }
    }
  }

  async function checkAndUpdateOrderStatus() {
    try {
      let hasUnpaid = false;
      let paymentSearch = null;
      if (window.sessionStorage.getItem('paymentSearch')) {
        try {
          paymentSearch = JSON.parse(window.sessionStorage.getItem('paymentSearch'));
        } catch (err) {
          console.log(err);
        } finally {
          window.sessionStorage.removeItem('paymentSearch');
        }
      }
      const paymentStatus = await executeCreditCardTransaction(paymentSearch);
      // 從行程紀錄付款
      const isHistoryListPay =
        getParameterByName('paymentOriginPage') === 'history' ||
        (paymentSearch && paymentSearch.paymentOriginPage === 'history');
      setHistoryDisplay(isHistoryListPay);
      if (!['pay-success', 'go-pay', 'pay-failed'].includes(paymentStatus)) {
        const result = await handleCheckHasUnPaidOrder();
        hasUnpaid = result === 'has-unpaid';
      }
      const monitorOrder = ['pay-success', 'no-payment'].includes(paymentStatus) && !hasUnpaid;
      await Promise.all([
        handleTaxiUserProfile(monitorOrder),
        handleTaxiUserTablet(),
        handleUserSearchCommonAddress(),
      ]);
      if (paymentStatus === 'pay-failed') {
        history.push('/PaymentFailed');
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      setStatusAlready(true);
    }
  }
  async function setUserProfile() {
    const [userProfile] = await api
      .getKingnetTaxiUserProfile()
      .then((res) => [res.data.Data, null])
      .catch((err) => [null, getKingnetTaxiUserDataErrorHandle(err)]);
    if (userProfile) {
      // * 當使用者資料有電話時, 才自動設定電話
      if (userProfile.Phone) {
        setOrderDetail((order) => ({
          ...order,
          phone: userProfile.Phone,
          ok: true,
        }));
      }
      // * 取得使用者資料的例行公事
      setUser(userProfile);
      console.log('%c 正常獲得使用者個人資訊', 'color: gold; font-size: 20px');
      window.localStorage.setItem('ServiceAccountAId', userProfile.ServiceAccountAId);
    }
    return userProfile;
  }

  function handleSetOrderField(order) {
    function checkOptionIsSelected(optionId) {
      return options.some((option) => option.service_option_id === optionId);
    }
    const { riderPhonephone, tripNotes, options } = order;
    const baggageIsSelect = checkOptionIsSelected(1);
    const wheelchairIsSelect = checkOptionIsSelected(2);
    const petIsSelect = checkOptionIsSelected(3);
    const orderDetail = {
      phone: riderPhonephone,
      comment: tripNotes,
      uberOptions: [
        { name: t('A024'), id: 'input1', isChecked: baggageIsSelect },
        { name: t('A026'), id: 'input3', isChecked: petIsSelect },
      ],
      need: [
        { name: t('A024'), id: 'input1', isChecked: baggageIsSelect },
        { name: t('A025'), id: 'input2', isChecked: wheelchairIsSelect },
        { name: t('A026'), id: 'input3', isChecked: petIsSelect },
      ],
    };
    setOrderDetail((order) => ({
      ...order,
      ...orderDetail,
    }));
  }

  async function fetchOrderHistory(maxCount, offset) {
    const [orders] = await taxiTripAPI
      .GetTripHistories({ maxCount, offset })
      .then((res) => [res[0].data.data.orders, null])
      .catch((err) => [null, getKingnetTaxiUserDataErrorHandle(err)]);

    return orders;
  }

  async function handleTaxiUserProfile(monitorOrder) {
    // * 設定 Router init history
    const initLocation = {
      pathname: '/',
      state: { initMainPage: true },
    };
    history.push(initLocation);
    // * 取得使用者身份 - 先不處理 error 狀況
    const userProfile = await setUserProfile();
    if (userProfile) {
      // * 取得歷史紀錄顯示的那前 20 筆訂單 ( 回傳 order 為陣列 )
      const orders = await fetchOrderHistory(10, 0);

      // * 針對第一筆訂單進行檢查 ( 有可能訂單還在進行中 )
      if (orders && orders.length !== 0 && monitorOrder) {
        const [firstOrder = {}] = orders;
        // * ureg 為 API 回傳格式就寫錯了
        const {
          is_cancel: firstOrderIsCancel,
          create_time: firstOrderCreateTime,
          payment_time: firstOrderPaymentTime = new Date(),
          order_ureg_phone = {},
          // get_to: { create_time: firstOrderGetToTime } = {},
          // get_into: { create_time: firstOrderGetIntoTime } = {},
          get_out: { create_time: firstOrderGetOutTime } = {},
          payMethod,
        } = firstOrder;
        const { create_time: firstOrderAccidentTime } = order_ureg_phone || {};
        const orderIsOnlinePay = payMethod !== TRIP_PAYMENT_METHOD.CASH;
        const comparedTimePeriod = orderIsOnlinePay ? firstOrderPaymentTime : firstOrderCreateTime;
        // * 假設第一筆訂單創建後超過 1 小時, 將不予再理會此訂單狀態
        if (new Date() - new Date(comparedTimePeriod) < 3600000) {
          if (firstOrderIsCancel) {
            window.localStorage.removeItem('GUID');
            // * 司機出意外的話 出意外 5 分後都會持續提示 ( 重開叫車頁面的話 )
            if (firstOrderAccidentTime && new Date() - new Date(firstOrderAccidentTime) < 300000) {
              toggleDriverAccidentModal();
            }
          } else if (!firstOrderGetOutTime) {
            const selectedTabIndex = orderIsOnlinePay ? 0 : 1;
            setIsOnlinePay(orderIsOnlinePay);
            onSelectTab(selectedTabIndex);
            setCommentOrderIsOnlinePay(orderIsOnlinePay);
            // * 乘客還沒下車前, 持續處理這張訂單
            setGUID(firstOrder.guid);
            setOrderFinish(true);
            // ! firebase 監聽啟動
            startStatusMonitor(firstOrder.guid, orderIsOnlinePay);
          } else {
            if (new Date() - new Date(comparedTimePeriod) < 3 * 24 * 60 * 60 * 1000) {
              checkOrderComment(firstOrder);
            }
          }
        } else {
          // * 3 天內完成的訂單如果還沒評價, 就彈視窗出來給使用者評價
          if (new Date() - new Date(comparedTimePeriod) < 3 * 24 * 60 * 60 * 1000) {
            checkOrderComment(firstOrder);
          }
        }

        // * 儲存 20 筆資料
      }

      if (orders) {
        setHistoryResult(processHistoryApiResult(orders));
      }
    }
  }

  async function handleTaxiUserTablet() {
    const [userTablet] = await api
      .getKingnetTaxiUserTablet()
      .then((res) => [res.data.Data.Communities, null])
      .catch((err) => [null, getKingnetTaxiUserDataErrorHandle(err)]);

    if (userTablet) {
      setHomeResult(
        userTablet
          .filter((e) => !(e.Address === '' || e.Latitude === 0 || e.Longitude === 0))
          .map((e) => ({
            ...e,
            FormatedAddress: validateAndFormatAddress(e.City, e.Area, e.Address),
            tag: 'home',
          }))
      );
      console.log('%c 正常獲得使用者社區資料', 'color: gold; font-size: 20px');
    }
  }

  async function handleUserSearchCommonAddress() {
    const [list] = await api
      .kingnetTaxiUserSearchCommonAddress()
      .then((res) => [res.data.Data.addresses, null])
      .catch((err) => [null, getKingnetTaxiUserDataErrorHandle(err)]);

    if (list) {
      list.sort((a, b) => a.sort - b.sort);
      setPreferResult(list.map((e) => ({ ...e, tag: 'prefer' })));
      console.log('%c 正常獲得使用者操作相關資訊', 'color: gold; font-size: 20px');
    }
  }

  async function handleCheckHasUnPaidOrder() {
    const [result, error] = await taxiTripAPI.GetAccountReceivableTrip();
    if (error) return 'has-error';

    if (result) {
      const unpaidTrips = result?.data?.data || [];
      if (!unpaidTrips.length) return 'no-unpaid';

      const needToPayTrip = unpaidTrips.find((item) => item.amount > 10);
      if (needToPayTrip) {
        const { tripGuid, hasCompleted } = needToPayTrip;
        setGUID(tripGuid);
        // 已完成的訂單不需叫車
        if (hasCompleted) {
          toggleUnpaidAlertModal();
          setPaymentOriginPage('payUnpaidTrip');
        } else {
          toggleUnpaidTripAlertModal();
          setPaymentOriginPage('home');
        }
        return 'has-unpaid';
      }

      const needToNoticeTrip = unpaidTrips.find(
        (item) => item.amount > 0 && item.amount <= 10 && item.hasCompleted
      );

      if (needToNoticeTrip) {
        setNeedToNoticeTrip({ ...needToNoticeTrip, showNotification: true });
        return 'no-unpaid';
      }
    }

    return 'no-unpaid';
  }

  async function handlePaymentRedirect(isRedirect, status, isAddBindCard) {
    const cards = await getUserBindCard();
    if (cards.length) {
      setUserBindCards(cards);
      if (isRedirect) {
        isAddBindCard && setIsBindCardSuccess(status === 0);
        setPaymentOriginPage('selectCreditCard');
      }
      history.push('/SelectCreditCard');
    } else {
      if (isRedirect && isAddBindCard) setIsBindCardSuccess(false);
      history.push('/CreditCardPayment');
    }
  }
  function getUserBindCard() {
    return new Promise((resolve) => {
      taxiTripAPI
        .GetUserBindCard()
        .then(([res]) => {
          console.log(res);
          if (res) {
            resolve(res.data.data.cards);
          }
        })
        .catch(() => {
          resolve([]);
        });
    });
  }

  async function updateHistoryResult() {
    const orders = await fetchOrderHistory(10, 0);
    if (orders) setHistoryResult(processHistoryApiResult(orders));
  }

  // 時間格式正規化
  function dateFormatRegular(dateString) {
    // 2020-07-06T18:24:23
    if (dateString.includes('-')) {
      const word = dateString.split(/\s|T|-/);
      let okdate = `${word[1]}/${word[2]}/${word[0]} ${word[3]}`;
      if (new Date(okdate).getDate()) return okdate;
    }
    return dateString;
    // "07/06/2020 18:24:23"
  }
  // 檢查訂單是否已評價
  function checkOrderComment(InspectingOrder) {
    if (
      InspectingOrder &&
      InspectingOrder.comment &&
      InspectingOrder.comment.length !== 0 &&
      InspectingOrder.comment[0].score !== null
    ) {
      return;
    } else if (
      InspectingOrder.is_cancel ||
      window.localStorage.getItem('commentDisableRenotify') === InspectingOrder.guid
    ) {
      // 不用評
    } else {
      // 沒評過
      // v1.1.0 紀錄 GUID 不管怎樣下次都不要在顯示同一份評價
      window.localStorage.setItem('commentDisableRenotify', InspectingOrder.guid);
      setCommentQuestionGUID(InspectingOrder.guid);
      toggleEvaluationModal();
      setGUID(InspectingOrder.guid);
    }
  }
  // 歷史紀錄評價擴充
  function historyEvaluation(historyGUID, payMethod) {
    setCommentOrderIsOnlinePay(payMethod !== TRIP_PAYMENT_METHOD.CASH);
    setCommentQuestionGUID(historyGUID);
    toggleEvaluationModal();
  }

  const [callTaxiLock, setCallTaxiLock] = useState(true);
  const [callTaxiIsLoading, setCallTaxiIsLoading] = useState(false);

  // * 拿歷史紀錄第一筆塞回input
  async function setOrderPlaceDetail() {
    const [result] = await taxiTripAPI.GetTaxiTrip({
      tripGuid: GUID,
      taxiType: isOnlinePay ? TAXI_TYPE.UBER : TAXI_TYPE.CLASSIC,
    });
    if (result) {
      const trip = result?.data?.data?.trip;
      if (!trip) return;
      const {
        pickupDesc,
        pickupAddress,
        pickupLat,
        pickupLon,
        dropoffDesc,
        dropoffAddress,
        dropoffLat,
        dropoffLon,
      } = trip;

      handleSetOrderField(trip);

      const actions = [
        { type: UPDATE_ORIGIN_PLACE_LATLNG, payload: { lat: pickupLat, lng: pickupLon } },
        {
          type: UPDATE_ORIGIN_PLACE,
          payload: {
            place: pickupAddress,
            placeTag: 'predict',
            placeDisplay: pickupDesc || extractAddress(pickupAddress || ''),
          },
        },
        {
          type: UPDATE_DESTINATION_PLACE,
          payload: {
            place: dropoffAddress,
            placeTag: 'predict',
            placeDisplay: dropoffDesc || extractAddress(dropoffAddress || ''),
          },
        },
        { type: UPDATE_DESTINATION_PLACE_LATLNG, payload: { lat: dropoffLat, lng: dropoffLon } },
      ];

      actions.forEach((action) => dispatch(action));

      if (!isOnlinePay) return;

      const updatedPlace = {
        originPlace: pickupAddress,
        originPlaceLat: pickupLat,
        originPlaceLng: pickupLon,
        originPlaceTag: 'predict',
        destinationPlace: dropoffAddress,
        destinationPlaceLat: dropoffLat,
        destinationPlaceLng: dropoffLon,
        destinationPlaceTag: 'predict',
      };
      const { res: updatedEstimatedFare, error: _error } = await updateEstimatedFare({
        updatedPlace,
        orderDetail,
      });
      updateDiscountUsage({
        updatedOrderAmount: updatedEstimatedFare.fare,
        fareId: updatedEstimatedFare.fareId,
        startTrigger: 'ticket',
      });
      setGUID('');
    }
  }

  // * 拿歷史紀錄第一筆來重新叫車
  async function retryCallTaxi() {
    if (callTaxiLock) {
      return;
    } else {
      startStatusMonitorOff();
      setCallTaxiLock(true);
      setCallTaxiIsLoading(true);
    }

    // * 取得第一筆歷史紀錄
    const [orders] = await api
      .kingnetTaxiGUIDSearchOrders({ MaxCount: 1, status: 5 })
      .then((res) => [res.data.Data.orders, null])
      .catch(() => [null, '先不處理這裡的錯誤']);

    if (orders === null) {
      // * 有可能只是網路斷線還是什麼的
      setToastAppear('重新叫車失敗，請再試一次');
      setCallTaxiLock(false);
      setCallTaxiIsLoading(false);
    }

    const [retryCallTaxiFirstOrder] = orders;

    let callTaxiReq = {
      service_account_a_Id: retryCallTaxiFirstOrder.service_account_a_Id,
      name: retryCallTaxiFirstOrder.name,
      phone: retryCallTaxiFirstOrder.phone,
      outset: retryCallTaxiFirstOrder.outset,
      outset_lat: retryCallTaxiFirstOrder.outset_lat,
      outset_lon: retryCallTaxiFirstOrder.outset_lon,
      destination: retryCallTaxiFirstOrder.target.destination,
      destination_lat: retryCallTaxiFirstOrder.target.destination_lat,
      destination_lon: retryCallTaxiFirstOrder.target.destination_lon,
      number_of_people: retryCallTaxiFirstOrder.number_of_people,
      region_id: retryCallTaxiFirstOrder.region_id,
      option: {
        baggage: retryCallTaxiFirstOrder.options.some((option) => option.service_option_id === 1),
        wheelchair: retryCallTaxiFirstOrder.options.some(
          (option) => option.service_option_id === 2
        ),
        pet: retryCallTaxiFirstOrder.options.some((option) => option.service_option_id === 3),
      },
      note: retryCallTaxiFirstOrder.note,
    };

    formalizationAddressWithLatLng(callTaxiReq)
      .then((req) => addRegionId(req))
      .then((req) => formalizationAddress(req))
      .then((req) => api.kingnetTaxiUserCall(req))
      .then((res) => handleCallTaxiSuccess(res))
      .catch((error) => handleCallTaxiFail(error));
  }

  async function callTaxi() {
    if (callTaxiLock || isLoadingFare) return;
    setEstimateFareTimeoutControl({ action: 'stop' });
    // 懲罰限制 v1.0.1 加入來源限制 10 分鐘取消 5 次鎖定狀態 5 小時
    if (historyResult.length > 5) {
      // cancel_source，[1使用者、2廠商、3平台管理者、NULL]
      let badguy = process.env.REACT_APP_ENV !== 'development';
      for (let i = 0; i < 5; i++) {
        if (
          !(
            badguy &&
            historyResult[i] &&
            historyResult[i].isCancel &&
            (historyResult[i].cancelSource === 1 || historyResult[i].cancelSource === 'fake-1')
          )
        ) {
          badguy = false;
        }
      }

      if (
        badguy &&
        new Date(dateFormatRegular(historyResult[0].origin.time)) -
          new Date(dateFormatRegular(historyResult[4].origin.time)) <
          600000 &&
        new Date() - new Date(dateFormatRegular(historyResult[0].origin.time)) < 18000000
      ) {
        setToastAppear(t('A041'));
        setToptipAppear(t('A071'));
        return;
      }
    }
    // 格式錯誤情形
    if (!(place.originPlace && place.originPlace !== '' && orderDetail.ok)) {
      if (orderDetail.errMsg === 'type') {
        setCallTaxifail(t('A047'));
      } else if (orderDetail.errMsg === 'empty') {
        setCallTaxifail(t('A043'));
      } else {
        setCallTaxifail(t('A043'));
      }
      setToastAppear(t('A041'));
      if (callTaxiTimer) clearTimeout(callTaxiTimer);
      setCallTaxiTimer(setTimeout(() => setCallTaxifail(''), 1200));
      return;
    }
    // 處理地址重複叫車
    if (place.originPlace === place.destinationPlace) {
      setCallTaxifail(t('A048'));
      setToastAppear(t('A041'));
      if (callTaxiTimer) clearTimeout(callTaxiTimer);
      setCallTaxiTimer(setTimeout(() => setCallTaxifail(''), 1200));
      return;
    }
    // API request slow 3G Gate
    if (callTaxiLock) {
      return;
    } else {
      setCallTaxiLock(true);
      setCallTaxiIsLoading(true);
      // ! 檢查最後一筆訂單是否還在上下車狀態
    }
    // phone 傳進後端前資料正規化
    let phone = orderDetail.phone;
    let phone1 = phone.replace(/^((\+886)|(886))/, '0');
    let phone2 = phone1.replace(/-|\s/gi, '');
    // 經緯度有可能是0 regin_id 也不確定
    let callTaxiReq = {
      service_account_a_Id: user.ServiceAccountAId,
      name: user.Name ? user.Name : '未設定用戶名',
      phone: phone2,
      outset: place.originPlace,
      outset_lat: place.originPlaceLat,
      outset_lon: place.originPlaceLng,
      destination: place.destinationPlace,
      destination_lat: place.destinationPlaceLat,
      destination_lon: place.destinationPlaceLng,
      number_of_people: 1,
      region_id: 0,
      option: {
        baggage: orderDetail.need[0].isChecked,
        wheelchair: orderDetail.need[1].isChecked,
        pet: orderDetail.need[2].isChecked,
      },
      note: orderDetail.comment,
    };

    recordActionPlaceUseCallTaxi(place.originPlaceTag);

    callTaxiHistoryCheck(callTaxiReq)
      .then((req) => formalizationAddressWithLatLng(req))
      .then((req) => addRegionId(req))
      .then((req) => (isOnlinePay ? req : formalizationAddress(req)))
      .then(async (req) => {
        if (isOnlinePay) {
          await setSandboxRunId();
          return handleUberCallTaxi(req);
        } else {
          return api.kingnetTaxiUserCall(req);
        }
      })
      .then((res) => handleCallTaxiSuccess(res))
      .catch((error) => handleCallTaxiFail(error));
  }

  function handleUberCallTaxi(req) {
    const callTaxiReq = {
      amount: estimatedFare.fare,
      name: user.Name ? user.Name : '未設定用戶名',
      phone: req.phone,
      regionId: req.region_id,
      pickupDesc: place.originPlaceDisplay,
      pickupAddress: req.outset,
      pickupLat: req.outset_lat,
      pickupLon: req.outset_lon,
      dropoffDesc: place.destinationPlaceDisplay,
      dropoffAddress: req.destination,
      dropoffLat: req.destination_lat,
      dropoffLon: req.destination_lon,
      notes: req.note,
      uberProductGuid: estimatedFare.productId,
      uberProductDisplayName: estimatedFare.displayName,
      uberFareGuid: estimatedFare.fareId,
      uberFareExpireUtc: estimatedFare.expiresUtc,
      uberCancelThresholdSecs: estimatedFare.cancelThresholdSecs,
      uberMinCalcelFee: estimatedFare.minCalcelFee,
      option: {
        baggage: orderDetail.uberOptions.find((option) => option.id === 'input1').isChecked,
        pet: orderDetail.uberOptions.find((option) => option.id === 'input3').isChecked,
      },
      payAmount: estimatedFare.fare - discountTotal,
      ...discountDetails,
    };
    console.log('callTaxiReq', callTaxiReq);
    return taxiTripAPI.CallUberTaxi(callTaxiReq);
  }

  function resetTripData() {
    resetAllDiscount();
    onBottomPopupClose();
    resetEstimatedFare();
    dispatch({ type: RESET_PLACE });
  }

  function getTripGuid(trip) {
    return trip?.data?.data?.trip?.tripGuid || trip?.data?.Data?.guid;
  }

  async function handleCallTaxiSuccess(res) {
    taxiTripAPI.setSendboxRunId('');
    setEstimateFareTimeoutControl({ action: 'stop' });
    resetTripData();
    // * 叫車時傳送進入點事件 - 成功叫到車才觸發
    TagManager.dataLayer({
      dataLayer: {
        event: 'entrance',
        enter: window.sessionStorage.getItem('entrance-GA'),
      },
    });
    const guid = getTripGuid(res);
    if (!guid) {
      throw new Error('GUID is not found');
    }

    const isZeroFare = estimatedFare.fare - discountTotal === 0;
    if (isOnlinePay && res && !isZeroFare) {
      setGUID(guid);
      // 有卡的情況直接跳TapPay，沒卡的情況跳轉填寫信用卡頁面
      if (selectedCard) {
        await checkEstimatedFareIsChanged(guid);
      } else {
        setCallTaxiLock(false);
        setCallTaxiIsLoading(false);
        history.push('/CreditCardPayment');
      }
      window.localStorage.setItem('GUID', guid);
      return true;
    }
    setGUID(guid);
    window.localStorage.setItem('GUID', guid);
    // sixtySecondCancelTaxiTimer()
    window.localStorage.setItem('pageStatus', 'wait');
    setCallTaxiLock(false);
    setCallTaxiIsLoading(false);
    // ! 新版 status hook
    startStatusMonitor(guid, isOnlinePay);
  }
  // * 獨立出來的追蹤策略
  useEffect(() => {
    if (traceCallTaxiFail) {
      setTraceCallTaxiFail(false);
      console.log({
        event: 'callTaxiError',
        userAccountAId: user.ServiceAccountAId,
        areaId: traceRegionId,
      });
      // * 增加追蹤, 每次叫車錯誤都進行使用者錯誤紀錄到 GA
      TagManager.dataLayer({
        dataLayer: {
          event: 'callTaxiError',
          userAccountAId: user.ServiceAccountAId,
          areaId: traceRegionId,
        },
      });
    }
  }, [traceRegionId, traceCallTaxiFail]);

  function handleCallTaxiFail(error) {
    taxiTripAPI.setSendboxRunId('');
    // * 叫車時傳送進入點事件 - 保險起見叫錯車也觸發
    TagManager.dataLayer({
      dataLayer: {
        event: 'entrance-fail',
        enter: window.sessionStorage.getItem('entrance-GA'),
      },
    });
    console.log('handleCallTaxiFail error', error);
    console.log('handleCallTaxiFail error.response', error.response);
    console.log('handleCallTaxiFail error.message', error.message);
    if (isOnlinePay) {
      setCallTaxiLock(false);
      setCallTaxiIsLoading(false);
      setToptipAppear(
        error?.response?.data?.ErrorMessage?.Description || '發生未知錯誤，請稍後再試'
      );
      updateDiscountUsage();
      return false;
    }
    if (error.response && error.response.data.ErrorMessage.Code === 400020) {
      setInternetErrorCheck(true);
      setCallTaxiLock(false);
      setCallTaxiIsLoading(false);
      setToastAppear(t('A041'));
      setCallTaxifail('地址請勿包含全型或特殊字元');
      if (callTaxiTimer) clearTimeout(callTaxiTimer);
      setCallTaxiTimer(setTimeout(() => setCallTaxifail(''), 1200));
      return;
    }
    if (error.response && error.response.data.ErrorMessage.Code === 500003) {
      // * Yoxi 叫車錯誤 不給予 guid
      sessionStorage.removeItem('page');
      const location = {
        pathname: '/DriverErrorPage',
        state: undefined,
      };
      history.push(location);
      // 打開鎖定的叫車按鈕
      setInternetErrorCheck(true);
      setCallTaxiLock(false);
      setCallTaxiIsLoading(false);
      return;
    }
    if (error.message === 'Request failed with status code 404') {
      setTraceCallTaxiFail(true);

      // ? 當初這裡頁太多規則...
      // 因為無網路可以關閉提示, 叫車時要再幫他手動打開一次
      setInternetErrorCheck(true);
      // 打開鎖定的叫車按鈕, 當初 API 特定時段會大延遲, 為防止一般的防彈跳是固定時間, 因此要自己控制
      setCallTaxiLock(false);
      setCallTaxiIsLoading(false);
      // ! 增加新規則, 為了使提示更加醒目, 在首次使用者叫車錯誤時, 會出現一大大提示視窗
      if (window.localStorage.getItem('overServiceArea') !== 'true') {
        window.localStorage.setItem('overServiceArea', 'true');
        toggleOverServiceAreaModal();
      } else {
        setToastAppear(t('A041'));
        setCallTaxifail('上車地點超出服務範圍');
        if (callTaxiTimer) clearTimeout(callTaxiTimer);
        setCallTaxiTimer(setTimeout(() => setCallTaxifail(''), 1200));
      }
    } else {
      setInternetErrorCheck(true);
      setCallTaxiLock(false);
      setCallTaxiIsLoading(false);
      setToastAppear(t('A041'));
      if (error.message === '需要重新建立常用地址')
        setCallTaxifail('再試一次還不行的話, 請試試刪除並重新建立常用地址');
      else if (error.message === '重複叫車')
        setCallTaxifail('系統發現您前一筆訂單還沒結束, 請稍晚再試一次吧');
      else setCallTaxifail('系統發生了小問題, 再試一次吧');
      if (callTaxiTimer) clearTimeout(callTaxiTimer);
      setCallTaxiTimer(setTimeout(() => setCallTaxifail(''), 1200));
    }
  }

  function closeAllTab(backSpaceKey) {
    setHistoryDisplay(false);
    setPreferDisplay(false);
    // setBlacklistDisplay(false)
    setLoglistDisplay(false);
    // setHeaderText(t('A001'))
    setFormDisplay(true);
    setIsHistoryMake(false);
    if (backSpaceKey) return;
    window.history.back();
  }

  function openTab(name) {
    window.localStorage.setItem('pageStatus', 'mainTab');
    switch (name) {
      case 'history':
        updateHistoryResult();
        setHistoryDisplay(true);
        setPreferDisplay(false);
        setLoglistDisplay(false);
        setEstimateFareTimeoutControl({ action: 'stop' });
        break;
      case 'prefer':
        setHistoryDisplay(false);
        setPreferDisplay(true);
        setLoglistDisplay(false);
        setEstimateFareTimeoutControl({ action: 'stop' });
        break;
      case 'close':
        setHistoryDisplay(false);
        setPreferDisplay(false);
        setLoglistDisplay(false);
        setIsHistoryMake(false);
        setEstimateFareTimeoutControl({ action: 'start' });
        break;
      case 'loglist':
        setHistoryDisplay(false);
        setPreferDisplay(false);
        setLoglistDisplay(true);
        setEstimateFareTimeoutControl({ action: 'stop' });
        break;
      default:
        break;
    }
    setSideBarOpen(false);
    if (!isHistoryMake) {
      window.history.pushState('tab', null, `./#${name}`);
      setIsHistoryMake(true);
    }
  }

  const [mapSequence, setMapSequence] = useState(-1);

  function toggleSideBar() {
    if (menuBarBack) {
      if (addNewPreferMode) {
        window.history.back();
      }
      setMapSequence((i) => i - 1);
    } else {
      setSideBarOpen(!sideBarOpen);
    }
  }

  function onSelectTab(index) {
    setActiveTabIndex(index);
    setPaymentType(tabItems[index].id);
  }

  function setTabByTabId(tabId) {
    const tabIndex = tabItems.findIndex((tab) => tab.id === Number(tabId));
    onSelectTab(tabIndex);
  }

  // v1.0.1 edit
  const [addNewPreferMode, setAddNewPreferMode] = useState(false);
  const [addNewPreferId, setAddNewPreferId] = useState('');
  const [addNewPreferName, setAddNewPreferName] = useState('');
  const [addNewPreferAddress, setAddNewPreferAddress] = useState('');
  const [addNewPreferLatlng, setAddNewPreferLatlng] = useState({});
  const [editPreferCurrentKey, setEditPreferCurrentKey] = useState(null);

  function addNewPreferHandle(updateElement = undefined) {
    if (updateElement.address) {
      setAddNewPreferId(updateElement.id);
      setAddNewPreferName(updateElement.memo);
      setAddNewPreferAddress(updateElement.address);
      setAddNewPreferLatlng({ lat: updateElement.lat, lon: updateElement.lon });
      setEditPreferCurrentKey(updateElement);
      setHeaderText('編輯常用地點');
    } else {
      setAddNewPreferId('');
      let companyFound = preferResult.find((e) => e.memo === '公司');
      if (companyFound) {
        if (preferResult.length > 19) {
          // 包含一筆公司
          setToastAppear('常用地點已達到19筆上限');
          return;
        }
      } else {
        if (preferResult.length > 18) {
          // 只剩一筆公司
          setAddNewPreferName('公司');
        }
      }
      setEditPreferCurrentKey(null);
      setAddNewPreferLatlng({});
      setAddNewPreferId('');
      setAddNewPreferName('');
      setAddNewPreferAddress('');
      setHeaderText('新增常用地點');
    }
    setPreferDisplay(false);
    setAddNewPreferMode(true);
    setMenuBarBack(true);
  }

  function addOrEditHandle() {
    if (addNewPreferMode) {
      if (addNewPreferId && addNewPreferId !== '') {
        // update
        if (
          addNewPreferAddress === editPreferCurrentKey.address &&
          addNewPreferName === editPreferCurrentKey.memo
        ) {
          setPreferDisplay(true);
          setAddNewPreferMode(false);
          setMenuBarBack(false);
          setHeaderText('');
          window.localStorage.setItem('pageStatus', 'edit');
          window.history.back();
          return;
        }

        let currentKey = preferResult.find((e) => e.id === addNewPreferId);

        if (!+addNewPreferLatlng.lat || !+addNewPreferLatlng.lng) {
          googleFunc
            .geocodeAddress(
              new window.google.maps.Geocoder(),
              addNewPreferAddress ? addNewPreferAddress : editPreferCurrentKey.address
            )
            .then((data) => ({
              ...currentKey,
              address: addNewPreferAddress ? addNewPreferAddress : editPreferCurrentKey.address,
              memo: addNewPreferName ? addNewPreferName : editPreferCurrentKey.memo,
              lat: data.geometry.location.lat(),
              lon: data.geometry.location.lng(),
            }))
            .then((target) =>
              Promise.all([api.kingnetTaxiUserUpdateAddress({ addresses: [target] }), target])
            )
            .then((values) => {
              const result = [...preferResult];
              result.splice(
                result.findIndex((e) => e.id === addNewPreferId),
                1,
                values[1]
              );
              setPreferResult(result);
              _addOrEditHandleResHandle();
            })
            .catch((err) => console.log(err));
        } else {
          const target = {
            ...currentKey,
            address: addNewPreferAddress ? addNewPreferAddress : editPreferCurrentKey.address,
            memo: addNewPreferName ? addNewPreferName : editPreferCurrentKey.memo,
            lat: addNewPreferLatlng.lat,
            lon: addNewPreferLatlng.lng,
          };
          api
            .kingnetTaxiUserUpdateAddress({
              addresses: [target],
            })
            .then(() => {
              const result = [...preferResult];
              result.splice(
                result.findIndex((e) => e.id === addNewPreferId),
                1,
                target
              );
              setPreferResult(result);
              _addOrEditHandleResHandle();
            })
            .catch((err) => console.log(err));
        }
      } else {
        if (!+addNewPreferLatlng.lat || !+addNewPreferLatlng.lng) {
          googleFunc
            .geocodeAddress(
              new window.google.maps.Geocoder(),
              addNewPreferAddress ? addNewPreferAddress : preferPlaceholder
            )
            .then((data) => ({
              city: '台中市',
              area: '北區',
              memo: addNewPreferName ? addNewPreferName : preferPlaceholder,
              address: addNewPreferAddress,
              sort: 0,
              lat: data.geometry.location.lat(),
              lon: data.geometry.location.lng(),
            }))
            .then((option) => Promise.all([api.kingnetTaxiUserPostAddress(option), option]))
            .then((values) => {
              setPreferResult((pre) => [{ ...values[1], id: values[0].data.Data.id }].concat(pre));
              _addOrEditHandleResHandle();
            })
            .catch((err) => console.log(err));
        } else {
          // new
          let option = {
            city: '台中市',
            area: '北區',
            memo: addNewPreferName ? addNewPreferName : preferPlaceholder,
            address: addNewPreferAddress,
            sort: 0,
            lat: addNewPreferLatlng.lat,
            lon: addNewPreferLatlng.lng,
          };
          api
            .kingnetTaxiUserPostAddress(option)
            .then((res) => {
              setPreferResult((pre) => [{ ...option, id: res.data.Data.id }].concat(pre));
              _addOrEditHandleResHandle();
            })
            .catch((err) => console.log(err));
        }
      }
    }

    function _addOrEditHandleResHandle() {
      setPreferDisplay(true);
      setAddNewPreferMode(false);
      setMenuBarBack(false);
      setHeaderText('');
      window.localStorage.setItem('pageStatus', 'edit');
      window.history.back();
      return;
    }
  }

  // 信用卡付款
  const [originTripInfo, setOriginTripInfo] = useState({});
  const [callTaxiIsPaying, setCallTaxiIsPaying] = useState(false);
  const [isCheckingEstimatedFare, setIsCheckingEstimatedFare] = useState(false);
  const [changedEstimateFare, setChangedEstimateFare] = useState({
    productId: '',
    displayName: '',
    fareId: '',
    fare: 0,
    expiresUtc: '',
    cancelThresholdSecs: 0,
    minCalcelFee: 0,
  });
  const [confirmFareChangedInfo, setConfirmFareChangedInfo] = useState({
    originFare: 0,
    changedFare: 0,
    options: { discountShowText: '' },
  });
  const guidRef = useRef(GUID); // 確保callback內的GUID是最新的
  useEffect(() => {
    guidRef.current = GUID;
  }, [GUID]);

  function getConfirmEstimatedFare(changedEstimateFare, callTaxiData) {
    // 如果有重取車資，則拿changedEstimateFare叫車，若無則直接取callTaxiData時的車資
    if (changedEstimateFare.fareId) {
      return changedEstimateFare;
    }
    const {
      uberProductId,
      uberProductName,
      uberFareId,
      oriUberFare,
      uberFareExpiresUtc,
      uberCancelThresholdSecs,
      uberMinCancelFee,
    } = callTaxiData;
    return {
      productId: uberProductId,
      displayName: uberProductName,
      fareId: uberFareId,
      fare: oriUberFare,
      expiresUtc: uberFareExpiresUtc,
      cancelThresholdSecs: uberCancelThresholdSecs,
      minCalcelFee: uberMinCancelFee,
    };
  }

  function handleCallTaxiResult(result) {
    const { paymentUrl, paymentInfo } = result?.data?.data || {};
    if (paymentUrl) {
      window.location.replace(result.data.data.paymentUrl);
      return;
    }
    if (paymentInfo) {
      updateConfirmFareChangedInfo(paymentInfo);
      toggleConfirmFareChangedModal();
    }
  }

  async function handleCallTaxiPay(callTaxiData = originTripInfo) {
    if (callTaxiIsPaying) {
      return;
    }
    setCallTaxiIsPaying(true);

    if (confirmFareChangedModal.status()) toggleConfirmFareChangedModal();

    const paymentData = {
      tripGuid: guidRef.current,
      cardGuid: selectedCard ? selectedCard.guid : '',
      redirectUrl:
        window.location.origin +
        '/?guid=' +
        guidRef.current +
        '&isCreditCardPay=true&paymentOriginPage=' +
        paymentOriginPage,
    };

    const [result, error] = await taxiTripAPI.CallCarAfterCreditCardPay({
      ...paymentData,
      ...getConfirmEstimatedFare(changedEstimateFare, callTaxiData),
    });

    try {
      if (error) throw error;
      if (result) {
        handleCallTaxiResult(result);
        setCallTaxiIsPaying(false);
      }
    } catch (error) {
      if (confirmFareChangedModal.status()) toggleConfirmFareChangedModal();
      setToastAppear(error?.response?.data?.ErrorMessage?.Description || '發生錯誤，請稍後再試');
      setCallTaxiLock(false);
      setCallTaxiIsLoading(false);
    } finally {
      setCallTaxiIsPaying(false);
    }
  }

  async function handleCancelCallTaxi() {
    try {
      await taxiTripAPI.CancelCallUberTaxi({ tripGuid: guidRef.current });
      setShouldRequestPosition(true);
      setToastAppear('取消叫車成功');
      setOrderPlaceDetail();
      if (unpaidTripAlert.status()) toggleUnpaidTripAlertModal();
      if (confirmFareChangedModal.status()) toggleConfirmFareChangedModal();
    } catch (error) {
      setToastAppear('取消叫車失敗');
    }
  }

  async function checkEstimatedFareIsChangedResult({
    changedEstimateFare,
    originDiscountedFare,
    bonusInfo,
  }) {
    const { fare, fareId } = changedEstimateFare;
    const { discountCode, ticketInstanceId, useBonus } = bonusInfo;
    const changedDiscountedFare = await getDiscountedOrderAmount({
      fare,
      fareId,
      discountCode,
      ticketInstanceId,
      useBonus,
    });
    changedDiscountedFare === originDiscountedFare
      ? await handleCallTaxiPay()
      : toggleConfirmFareChangedModal();
    setConfirmFareChangedInfo((info) => {
      return {
        ...info,
        originFare: originDiscountedFare,
        changedFare: changedDiscountedFare,
      };
    });
  }

  async function checkEstimatedFareIsChanged(guid) {
    if (isCheckingEstimatedFare) return;
    setIsCheckingEstimatedFare(true);
    const [resultEstimateOrigin] = await taxiTripAPI.GetRegisteredUberTaxiTrip({ tripGuid: guid });
    let callTaxiData;
    if (resultEstimateOrigin) {
      callTaxiData = resultEstimateOrigin.data.data.trip;
      setOriginTripInfo(callTaxiData);
      if (!isExpired(callTaxiData.uberFareExpiresUtc)) {
        handleCallTaxiPay(callTaxiData);
        setIsCheckingEstimatedFare(false);
        return;
      }
    } else {
      setEstimatedFareErrorMessage('服務目前異常，請稍後再試');
      toggleEstimatedFareErrorModal();
      setIsCheckingEstimatedFare(false);
      setCallTaxiLock(false);
      setCallTaxiIsLoading(false);
      return;
    }
    const { pickupLat, pickupLon, pickupAddress, dropoffLat, dropoffLon, dropoffAddress, options } =
      callTaxiData;
    function checkOptionExist(optionId) {
      return options.some((option) => option.service_option_id === optionId);
    }
    const postData = {
      pickupLat,
      pickupLon,
      dropoffLat,
      dropoffLon,
      pickupAddress,
      dropoffAddress,
      option: {
        baggage: checkOptionExist(1),
        wheelchair: checkOptionExist(2),
        pet: checkOptionExist(3),
      },
      checkPickupAdd: false,
      checkDropoffAdd: false,
    };
    const [result, error] = await taxiTripAPI.estimatedTripFare(postData);
    if (result) {
      const changedEstimateFare = result?.data?.data?.[0];
      const bonusInfo = resultEstimateOrigin?.data?.data?.bonusInfo || {};
      setChangedEstimateFare(changedEstimateFare);
      await checkEstimatedFareIsChangedResult({
        changedEstimateFare,
        originDiscountedFare: callTaxiData.uberFare,
        bonusInfo,
      });
    } else {
      console.log(error);
      setCallTaxiLock(false);
      setCallTaxiIsLoading(false);
      setEstimatedFareErrorMessage(error.response.data.ErrorMessage.Description);
      toggleEstimatedFareErrorModal();
    }
    setIsCheckingEstimatedFare(false);
  }

  function updateConfirmFareChangedInfo(paymentInfo) {
    // 檢查 originTripInfo.uberFare 是否存在
    if (isNullOrUnDef(originTripInfo?.uberFare)) {
      throw new Error('originTripInfo.uberFare 未定義或為 null');
    }

    const { payAmount = 0, discountAmount = 0, bonusUsed = 0 } = paymentInfo;
    const discountList = [
      { key: 'discountAmount', prefix: '優惠折抵$', value: discountAmount },
      { key: 'bonusUsed', prefix: '紅利$', value: bonusUsed },
    ];

    setConfirmFareChangedInfo((info) => ({
      ...info,
      originFare: originTripInfo.uberFare,
      changedFare: payAmount,
      options: {
        discountShowText: formatDiscountText(discountList),
      },
    }));
  }

  // FOR 緊急狀況測試 ( LOG LIST 以外勿用 )
  function testPage(pagename) {
    switch (pagename) {
      case 'car':
        history.push('/CarPage');
        break;
      case 'share':
        history.push('/SharePage');
        break;
      case 'nocar':
        history.push('/NoCarPage');
        break;
      case 'wait':
        history.push('/WaitTaxiPage');
        break;
      case 'arrived':
        history.push('/ArrivedPage');
        break;
      case 'driverError':
        history.push('/DriverErrorPage');
        break;
      case 'driverAccident':
        history.push('/DriverAccidentPage');
        break;
      default:
        break;
    }
  }

  function onBottomPopupClose() {
    setIsDiscountOpen(false);
    setIsFeeDetailsOpen(false);
  }

  return (
    <div className="App">
      <Offline>
        <OfflineTalk
          setOffline={setOffline}
          internetErrorCheck={internetErrorCheck}
          setInternetErrorCheck={setInternetErrorCheck}
        />
      </Offline>
      {AdBannerComponent}
      <div className={`callTaxiError ${callTaxifail !== '' ? 'active' : ''}`}>
        {callTaxifail ? <i className="icon-warning-2" /> : null}
        {callTaxifail}
      </div>
      <GoPayNotification
        needToNoticeTrip={needToNoticeTrip}
        setNeedToNoticeTrip={setNeedToNoticeTrip}
      />
      <Header
        headerText={headerText}
        menuBarBack={menuBarBack}
        toggleSideBar={() => toggleSideBar()}
        hasRedDotDisplay={hasRedDotDisplay}
        historyDisplay={historyDisplay}
        preferDisplay={preferDisplay}
        loglistDisplay={loglistDisplay}
        needToNoticeTrip={needToNoticeTrip}
      />

      <div className="container">
        <SideBar
          closeSideBar={() => setSideBarOpen(false)}
          isopen={sideBarOpen}
          openTab={(e) => openTab(e)}
          hasRedDotDisplay={hasRedDotDisplay}
        />
        {historyDisplay ? (
          <HistoryList
            historyResult={historyResult}
            historyEvaluation={historyEvaluation}
            handlePaymentRedirect={handlePaymentRedirect}
            setGUID={setGUID}
            setPaymentOriginPage={setPaymentOriginPage}
          />
        ) : null}
        {preferDisplay ? (
          <PreferList
            preferResult={preferResult}
            setPreferResult={setPreferResult}
            setLogListResult={setLogListResult}
            addNewPrefer={(e) => addNewPreferHandle(e)}
          />
        ) : null}
        {/*blacklistDisplay ? <BlackList blackListResult={blackListResult}/> : null*/}
        {loglistDisplay ? (
          <LogList
            testPage={testPage}
            toggleCancelEventModal={toggleCancelEventModal}
            toggleUserAgreementModal={toggleUserAgreementModal}
            setGiftModalContent={setGiftModalContent}
            toggleGiftReceivedModal={toggleGiftReceivedModal}
            toggleSelectTicketModal={toggleSelectTicketModal}
            logListResult={logListResult}
            toggleEvaluationModal={toggleEvaluationModal}
          />
        ) : null}
        {!stopLoad && tokenAlready && statusAlready && monitorStatusAlready ? (
          <>
            {!addNewPreferMode && (
              <div className="navbar">
                <div
                  className={`${menuBarBack ? 'header__backBar' : 'header__menuBar'}`}
                  onClick={toggleSideBar}
                >
                  <div />
                  {hasRedDotDisplay && !menuBarBack && <div className="header__redDot" />}
                </div>
                <TabBar
                  selectedTabIndex={activeTabIndex}
                  tabItems={tabItems}
                  onSelect={onSelectTab}
                ></TabBar>
              </div>
            )}
            <Map
              GUID={GUID}
              isOnlinePay={isOnlinePay}
              statusAlready={statusAlready}
              preferPlaceholder={preferPlaceholder}
              setPreferPlaceholder={setPreferPlaceholder}
              callTaxifail={callTaxifail}
              setToptipAppear={setToptipAppear}
              setMapSequence={setMapSequence}
              mapSequence={mapSequence}
              setHeaderText={setHeaderText}
              setMenuBarBack={setMenuBarBack}
              formDisplay={formDisplay}
              setFormDisplay={setFormDisplay}
              homeResult={homeResult}
              historyResult={historyResult}
              preferResult={preferResult}
              setPreferResult={setPreferResult}
              addNewPreferMode={addNewPreferMode}
              addNewPreferName={addNewPreferName}
              setAddNewPreferName={setAddNewPreferName}
              addNewPreferAddress={addNewPreferAddress}
              setAddNewPreferAddress={setAddNewPreferAddress}
              setAddNewPreferLatlng={setAddNewPreferLatlng}
              addNewPreferId={addNewPreferId}
              addOrEditHandle={() => addOrEditHandle()}
              setLogListResult={setLogListResult}
              estimateFareTimeoutControl={estimateFareTimeoutControl}
              setEstimateFareTimeoutControl={setEstimateFareTimeoutControl}
              setIsLoadingFare={setIsLoadingFare}
              orderDetail={orderDetail}
              shouldRequestPosition={shouldRequestPosition}
              selectedCard={selectedCard}
              setPaymentOriginPage={setPaymentOriginPage}
            >
              <Form
                isOnlinePay={isOnlinePay}
                callTaxifail={callTaxifail}
                orderDetail={orderDetail}
                setOrderDetail={setOrderDetail}
                formDisplay={formDisplay}
                setFormDisplay={setFormDisplay}
                setHeaderText={setHeaderText}
                toggleUserAgreementModal={toggleUserAgreementModal}
                selectedCard={selectedCard}
                setSelectedCard={setSelectedCard}
                setEstimateFareTimeoutControl={setEstimateFareTimeoutControl}
                setPaymentOriginPage={setPaymentOriginPage}
              />
            </Map>
            <DiscountPopup
              footerHeight={footerHeight}
              isOpen={formDisplay && isDiscountOpen}
              onClose={() => {
                setIsDiscountOpen(false);
              }}
            />
            <FeeDetailsBottomPopup
              footerHeight={footerHeight}
              isOpen={formDisplay && isFeeDetailsOpen}
              onClose={() => {
                setIsFeeDetailsOpen(false);
              }}
            />
            {formDisplay && !discountInputFocus && (
              <FooterConfirm
                ref={footerRef}
                isOnlinePay={isOnlinePay}
                isLoadingFare={isLoadingFare}
                isDiscountOpen={isDiscountOpen}
                isFeeDetailsOpen={isFeeDetailsOpen}
                setIsDiscountOpen={setIsDiscountOpen}
                setIsFeeDetailsOpen={setIsFeeDetailsOpen}
                onBottomPopupClose={onBottomPopupClose}
              >
                <Button
                  onButtonClick={callTaxi}
                  disabled={callTaxiLock || isLoadingFare}
                  isLoading={callTaxiIsLoading}
                  width={isOnlinePay ? 'auto' : 'full'}
                  color="primary"
                >
                  <span>立即叫車</span>
                </Button>
              </FooterConfirm>
            )}
          </>
        ) : (
          <div className="loading">
            <ReactLoading type="bars" color={'#46B49A'} height={50} width={50} />
          </div>
        )}
      </div>

      <StatusPage
        setHistoryResult={setHistoryResult}
        orderFinish={orderFinish}
        driverDispatch={driverDispatch}
        callTaxi={retryCallTaxi}
        callTaxiLock={callTaxiLock}
        isOnlinePay={isOnlinePay}
        setOrderPlaceDetail={setOrderPlaceDetail}
      />

      <Route
        path="/CreditCardPayment"
        render={() => (
          <CreditCardPaymentPage
            GUID={GUID}
            paymentOriginPage={paymentOriginPage}
            setShouldRequestPosition={setShouldRequestPosition}
            setEstimateFareTimeoutControl={setEstimateFareTimeoutControl}
            setOrderPlaceDetail={setOrderPlaceDetail}
          />
        )}
      />
      <Route
        path="/SelectCreditCard"
        render={() => (
          <SelectCreditCardPage
            GUID={GUID}
            tokenAlready={tokenAlready}
            userBindCards={userBindCards}
            isBindCardSuccess={isBindCardSuccess}
            setIsBindCardSuccess={setIsBindCardSuccess}
            paymentOriginPage={paymentOriginPage}
            setShouldRequestPosition={setShouldRequestPosition}
            setOrderPlaceDetail={setOrderPlaceDetail}
            selectedCard={selectedCard}
            setSelectedCard={setSelectedCard}
          />
        )}
      />
      <Route
        path="/PaymentFailed"
        render={() => (
          <PaymentFailedPage
            setOrderPlaceDetail={setOrderPlaceDetail}
            GUID={GUID}
            setPaymentOriginPage={setPaymentOriginPage}
          />
        )}
      />
      <Route
        path="/HistoryDetail/:tripGuid"
        render={() => (
          <HistoryDetailPage
            openTab={openTab}
            tokenAlready={tokenAlready}
            historyResult={historyResult}
            evaluationModal={evaluationModal}
            setCommentFareInfo={setCommentFareInfo}
            historyEvaluation={historyEvaluation}
            setGUID={setGUID}
            setPaymentOriginPage={setPaymentOriginPage}
            handlePaymentRedirect={handlePaymentRedirect}
          />
        )}
      />

      <Modali.Modal {...drawerModal}>
        <div className="drawerModal__image">
          <i className="icon-warning" aria-hidden="true" />
          {showCancelFareWarning && (
            <div className="drawerModal__warning">
              <p>若您欲取消行程可能須負擔40元取消費用</p>
            </div>
          )}
        </div>
        <div className="drawerModal__button" onClick={() => someReasonCancel(t('009'))}>
          <Ripple />
          {t('009')}
        </div>
        <div className="drawerModal__button" onClick={() => someReasonCancel(t('010'))}>
          <Ripple />
          {t('010')}
        </div>
        <div className="drawerModal__button" onClick={() => someReasonCancel(t('011'))}>
          <Ripple />
          {t('011')}
        </div>
      </Modali.Modal>
      <Modali.Modal {...endEditModal} />
      <Modali.Modal {...mainLockModal} />
      <Modali.Modal {...overServiceAreaModal} />
      <Modali.Modal {...thanksModal}>
        <div className="thanksModal">
          <div>{t('E008')}</div>
          <div />
        </div>
      </Modali.Modal>
      <Modali.Modal {...driverAccidentModal} />
      <Modali.Modal {...lockModal} />
      <Modali.Modal {...driverCantSeeYouModal} />
      <Modali.Modal {...unpaidAlert} />
      <Modali.Modal {...unpaidTripAlert} />
      <Modali.Modal {...userAgreementModal}>
        <UserAgreement />
      </Modali.Modal>
      <Modali.Modal {...estimatedFareErrorModal}></Modali.Modal>
      <Modali.Modal {...confirmFareChangedModal}>
        <div className="confirm-fare-changed-modal__content">
          <p>
            車資已從
            <span className="confirm-fare-changed-modal__content__price">
              ${confirmFareChangedInfo.originFare}
            </span>
            調整為
            <span className="confirm-fare-changed-modal__content__price primary">
              ${confirmFareChangedInfo.changedFare}
            </span>
            {confirmFareChangedInfo.options.discountShowText && (
              <p>{confirmFareChangedInfo.options.discountShowText}</p>
            )}
          </p>
          <p>是否確認叫車？</p>
        </div>
      </Modali.Modal>
      {CommentModal}
      {TaxiNotificationModal}
      {cancelEventModalComponent}
      {giftReceivedModalComponent}
      {SelectTicketModal}
      <ToastContainer />
    </div>
  );
}

export default App;

function UserAgreement() {
  const { t } = useTranslation();
  const ref = useRef(null);
  useEffect(() => {
    disableBodyScroll(ref, {
      allowTouchMove: (el) => {
        while (el && el !== document.body) {
          if (el.getAttribute('body-scroll-lock-ignore') !== null) {
            return true;
          }

          el = el.parentNode;
        }
      },
    });
    return () => clearAllBodyScrollLocks();
  }, []);
  return (
    <div className="userAgreement" ref={ref}>
      <div className="userAgreement--scroll" body-scroll-lock-ignore="true">
        <p>{t('W008')}</p>
        <p>{t('W002')}</p>
        <p>{t('W003')}</p>
        <p>{t('W004')}</p>
        <p>{t('W005')}</p>
        <p>{t('W006')}</p>
        <p>{t('W007')}</p>
        <p>{t('W009')}</p>
        <p>{t('W010')}</p>
        <p>{t('W010-1')}</p>
        <p>{t('W010-2')}</p>
        <p>{t('W010-3')}</p>
        <p>{t('W011')}</p>
        <p>{t('W012')}</p>
        <p>{t('W013')}</p>
        <p>{t('W014')}</p>
      </div>
    </div>
  );
}

//<div className="drawerModal__button"><LineShareButton url={'https://www.smartdailytw.com/'} title={shareText}>分享至 LINE聊天室</LineShareButton></div>
//<div className="drawerModal__button"><FacebookShareButton url={'https://www.smartdailytw.com/'} quote={shareText}>分享至 FB</FacebookShareButton></div>

const OfflineTalk = ({ setOffline, internetErrorCheck, setInternetErrorCheck }) => {
  const { t } = useTranslation();
  useEffect(() => {
    setOffline(true);
    return () => setOffline(false);
  });
  return (
    <div
      className="internetError"
      style={internetErrorCheck ? { display: 'flex' } : { display: 'none' }}
    >
      <p>{t('A042')}</p>
      <i className="icon-clear" onClick={() => setInternetErrorCheck(false)} />
    </div>
  );
};

const GoPayNotification = ({ needToNoticeTrip, setNeedToNoticeTrip }) => {
  const history = useHistory();
  const location = useLocation();

  function onCloseNotification() {
    setNeedToNoticeTrip((needToNoticeTrip) => {
      return {
        ...needToNoticeTrip,
        showNotification: false,
      };
    });
  }

  function goHistoryDetail(trip) {
    history.push(`/HistoryDetail/${trip.tripGuid}`);
    setNeedToNoticeTrip(null);
  }

  const isHomePage = location.pathname === '/';

  if (!needToNoticeTrip?.showNotification || !isHomePage) return null;

  return (
    <div className={`notification-banner`}>
      前筆行程費用異動，請至
      <a className="notification-banner__link" onClick={() => goHistoryDetail(needToNoticeTrip)}>
        行程記錄
      </a>
      重新付款
      <i className="icon-clear" onClick={onCloseNotification} />
    </div>
  );
};
