import ReactDOM from 'react-dom';
import './normalize.css';
import './index.css';
import App from './App';
import { Place } from './place';
import { DiscountProvider } from './context/DiscountProvider';
import { DiscountInputFocusProvider } from './context/DiscountInputFocusProvider';
import { EstimatedFareProvider } from 'context/EstimatedFareProvider';
import { BrowserRouter, Switch } from 'react-router-dom';
import FirebaseProvider from './utils/firebase';
import './i18n/i18n';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
  events: {
    callTaxiError: 'callTaxiError',
  },
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <FirebaseProvider>
        <Place>
          <DiscountProvider>
            <DiscountInputFocusProvider>
              <EstimatedFareProvider>
                <App />
              </EstimatedFareProvider>
            </DiscountInputFocusProvider>
          </DiscountProvider>
        </Place>
      </FirebaseProvider>
    </Switch>
  </BrowserRouter>,
  document.getElementById('root')
);

// register service worker
if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/sw.js')
    .then(function () {
      console.log('Service worker registered!');
    })
    .catch(function (err) {
      console.log(err);
    });
}

// var deferredPrompt;
// 使用者加到桌面的監聽
// window.addEventListener('beforeinstallprompt', function(event) {
//   console.log('beforeinstallprompt fired');
//   event.preventDefault();
//   deferredPrompt = event;
//   return false;
// });
