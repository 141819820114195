import Modali, { useModali } from './Modal';
import './CancelEventModal.scss';

export const useCancelEventModal = (showCancelFareWarning) => {
  // * 設定 Firebase 提示視窗
  const [cancelEventModal, toggleCancelEventModal] = useModali({
    animated: true,
    overlayClose: false,
    title: '取消處理中',
  });

  // * 渲染 Firebase 提示視窗
  const ModalComponent = (
    <Modali.Modal {...cancelEventModal}>
      {showCancelFareWarning && (
        <div className="cancel-event-tips">
          <p>本次將向您收取消費用40元，系統最晚將於三個工作天內退回其他預收車資</p>
        </div>
      )}
      <div className="cancel-event-img">
        <div className="cancel-event-back" />
        <div className="cancel-event-dialogue">
          <span>期待下次為您服務 ! </span>
        </div>
        <div className="cancel-event-hand" />
        <div className="cancel-event-front" />
      </div>
    </Modali.Modal>
  );

  return {
    toggleCancelEventModal,
    cancelEventModalComponent: ModalComponent,
  };
};
