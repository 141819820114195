import { useState, useEffect } from 'react';

// For animate Route line
export function animateLine(line) {
  let count = 0;
  window.setInterval(() => {
    count = (count + 1) % 60;
    let icons = line.get('icons');
    icons[0].offset = count / 2 + '%';
    line.set('icons', icons);
  }, 30); // speed
}

// For google map function
// @ return GEO position
// sometime the location will bad
export function geolocation(mapObject) {
  return new Promise((resolve) => {
    let watcher = navigator.geolocation.watchPosition(
      (position) => {
        console.log(
          `High Accuracy: lat: ${position.coords.latitude} lng: ${position.coords.longitude}`
        );
        mapObject &&
          mapObject.setCenter({ lat: position.coords.latitude, lng: position.coords.longitude });
        mapObject && mapObject.setZoom(18);
        resolve({ lat: position.coords.latitude, lng: position.coords.longitude });
      },
      (error) => {
        console.log('watchPosition error.code' + error.code);
        navigator.geolocation.getCurrentPosition(
          (position) => {
            console.log(
              `low Accuracy: lat: ${position.coords.latitude} lng: ${position.coords.longitude}`
            );
            mapObject &&
              mapObject.setCenter({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              });
            mapObject && mapObject.setZoom(18);
            resolve({ lat: position.coords.latitude, lng: position.coords.longitude });
          },
          (error) => {
            console.log('watchPosition error.code' + error.code);
          }
        );
      },
      {
        enableHighAccuracy: true,
        maximumAge: 10000,
        timeout: 1500, // 3s limit to get HighAccuracy location
      }
    );

    setTimeout(() => {
      console.log('stop to get user location');
      navigator.geolocation.clearWatch(watcher);
    }, 1700);
  }).catch((error) => error);
}

// GPS: refactoring code use hook
export const usePosition = (shouldRequestPosition) => {
  const [position, setPosition] = useState({});
  const [Lerror, setLError] = useState(null);
  const [watcher, setWatcher] = useState(null);

  const watchPositiononChange = ({ coords }) => {
    console.log(`Accuracy: lat: ${coords.latitude} lng: ${coords.longitude}`);
    setPosition({
      lat: coords.latitude,
      lng: coords.longitude,
    });
  };

  const currentPositiononChange = ({ coords }) => {
    console.log(`currentPositiononChange: lat: ${coords.latitude} lng: ${coords.longitude}`);
    setPosition({
      lat: coords.latitude,
      lng: coords.longitude,
    });
  };

  const onError = () => {
    navigator.geolocation.getCurrentPosition(currentPositiononChange, onCurrentPositionError);
  };

  const onCurrentPositionError = (error) => {
    setLError(error);
  };

  const geo_options = {
    enableHighAccuracy: true,
    maximumAge: 10000,
    timeout: 1500,
  };

  function clearWatch() {
    navigator.geolocation.clearWatch(watcher);
  }

  useEffect(() => {
    if (shouldRequestPosition) {
      if (navigator && navigator.geolocation) {
        // navigator.geolocation.getCurrentPosition(onChange, onCurrentPositionError)
        setWatcher(
          navigator.geolocation.watchPosition(watchPositiononChange, onError, geo_options)
        );
        return () => clearWatch();
      } else {
        setLError('Geolocation is broken');
      }
    }
    return () => clearWatch();
  }, [shouldRequestPosition]);

  return { ...position, clearWatch, Lerror };
};
// @ return marker Object
export function placeIcon(iconType, position, mapObject, label) {
  // a lot of iconType ===
  let markerobj = new window.google.maps.Marker({
    map: mapObject,
    // icon: iconType,
    label: label,
    animation: window.google.maps.Animation.DROP,
    position: position,
  });

  return markerobj;
}

export function geocodeAddress(geocoder, address) {
  console.log(' !!!!! Because Geocoding API, Google steal some money ( $0.005 ) from you');
  return new Promise((resolve, reject) => {
    geocoder.geocode({ address: address, region: 'tw' }, (results, status) => {
      if (status === 'OK') {
        console.log('geocodeAddress 地址轉換經緯', results);
        resolve(results[0]);
      } else {
        reject(`Geocoder not working because status ${status} , please Google to solve problem`);
      }
    });
  });
}

export function geocodeLatLng(geocoder, LatLng) {
  console.log(' !!!!! Because Geocoding API, Google steal some money ( $0.005 ) from you');
  return new Promise((resolve, reject) => {
    geocoder.geocode({ location: LatLng, region: 'tw' }, (results, status) => {
      if (status === 'OK') {
        console.log('geocodeLatLng 經緯轉換地址', results);
        results[0] ? resolve(results[0]) : reject('results[0] is null');
      } else {
        reject(status);
      }
    });
  });
}
// not sure map bound will effect or not ??
export function autocompletePredict(autocompleteService, bounds, text) {
  console.log(' !!!!! Because Places API, Google steal some money ( $0.00283 ) from you');
  let option;
  if (bounds) {
    option = {
      bounds: bounds,
      componentRestrictions: { country: 'tw' },
      input: text,
      type: 'establishment',
    };
  } else {
    option = {
      componentRestrictions: { country: 'tw' },
      input: text,
      type: 'establishment',
    };
  }
  console.log('autocompleteService 預測參數', option);
  return new Promise((resolve) => {
    autocompleteService.getPlacePredictions(option, (data) => {
      console.log('autocompleteService 預測結果', data);
      resolve(data);
    });
  }).catch((err) => console.log(err));
}

// places contain start and end
export function drawingRoute(service, displayService, { originPlaceId, destinationPlaceId }) {
  console.log(' !!!!! Because Direction API, Google steal some money ( $0.005 ) from you');
  return new Promise((resolve, reject) => {
    service.route(
      {
        origin: { placeId: originPlaceId },
        destination: { placeId: destinationPlaceId },
        travelMode: 'DRIVING', // ok!! :D just default
      },
      (res, status) => {
        if (status === 'OK') {
          displayService.setDirections(res);
          resolve(res);
        } else {
          reject('go home and play with your daddy');
        }
      }
    );
  });
}

export function lockMap(map) {
  map.set('draggable', false);
}

export function unlockMap(map) {
  //  maybe need a resize
  map.set('draggable', true);
}
