import { useContext, useState, useEffect, useRef, useCallback } from 'react';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import gpsOnMapIcon from '../assets/gps1.png';
import * as googleFunc from '../utils/googleFunction';
import * as mapStyles from '../mapStyle';
import Ripple from './Ripple';
import * as api from '../api';
import * as tripAPI from '../api/taxiTrip';
import './Map.scss';
import Modali, { useModali } from './Modal';
import { setToastAppear } from '../components/Toast/Toast';
import {
  placeContext,
  UPDATE_ORIGIN_PLACE,
  UPDATE_ORIGIN_PLACE_LATLNG,
  UPDATE_DESTINATION_PLACE,
  UPDATE_DESTINATION_PLACE_LATLNG,
} from '../place';

import { useRecord } from '../useRecord';
import { useEstimatedFare } from 'context/EstimatedFareProvider';
import { useDiscount } from 'context/DiscountProvider';
import { useFetchDiscount } from 'hooks/useFetchDiscount';

function Map({
  GUID,
  isOnlinePay,
  preferPlaceholder,
  setPreferPlaceholder,
  callTaxifail,
  setToptipAppear,
  setMapSequence,
  mapSequence,
  setMenuBarBack,
  formDisplay,
  setFormDisplay,
  homeResult,
  historyResult,
  preferResult,
  setPreferResult,
  addNewPreferMode,
  addNewPreferName,
  setAddNewPreferName,
  addNewPreferAddress,
  setAddNewPreferAddress,
  setAddNewPreferLatlng,
  addNewPreferId,
  addOrEditHandle,
  children,
  setLogListResult,
  estimateFareTimeoutControl,
  setEstimateFareTimeoutControl,
  setIsLoadingFare,
  orderDetail,
  shouldRequestPosition,
}) {
  const {
    recordActionMapCreateInitialFlow,
    recordActionLocationChargeFlow,
    recordActionMapGestureFlow,
    recordActionAutoCompletePredictFlow,
    recordActionPredictSetMapCenterFlow,
  } = useRecord();
  const { t } = useTranslation();
  const { place, dispatch } = useContext(placeContext);
  const [loaded] = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_KEY}&libraries=places`
  );
  //place is for display main + sub,  place id for Routing
  const [originPlace, setOriginPlace] = useState('');
  const [originPlaceForDisplay, setOriginPlaceForDisplay] = useState('');
  const [destinationPlace, setDestinationPlace] = useState('');
  const [destinationPlaceForDisplay, setDestinationPlaceForDisplay] = useState('');
  // autocomplete Result
  const [predictResult, setPredictResult] = useState([]);
  // which input is focus ?
  const [searchBarState, setSearchBarState] = useState('origin');
  const [mapCenter, setMapCenter] = useState({});
  const [isDraging, setIsDraging] = useState(false);
  const [isDragingUseDisableButton, setIsDragingUseDisableButton] = useState(false);
  // discount
  const { resetAllDiscount } = useDiscount();
  const { updateDiscountUsage } = useFetchDiscount();
  // google's service
  const [map, setMap] = useState(null);
  const [geocoder, setGeocoder] = useState({});
  // listener
  const [mapListener, setMapListener] = useState(null);

  const { estimatedFare, setEstimatedFare, resetEstimatedFare, updateEstimatedFare } =
    useEstimatedFare();
  const [storedUpdatedEstimatedFare, setStoredUpdatedEstimatedFare] = useState(null);
  const [updateEstimatedFareTimer, setUpdateEstimatedFareTimer] = useState(null);
  const [updateEstimatedFareTrigger, setUpdateEstimatedFareTrigger] = useState(0);
  // chinese keyin
  const [compositionEnd, setCompositionEnd] = useState(true);
  const position = googleFunc.usePosition(shouldRequestPosition);
  const {
    lat = null,
    lng = null,
    Lerror = null,
    clearWatch = null,
  } = shouldRequestPosition ? position : {};
  const [GPSplace, setGPSplace] = useState(null);
  // Debounce for GEOcode
  const [debounceCount, setDebounceCount] = useState(0);
  const [alert, setAlert] = useState(false);
  const [overAreaNotify, setOverAreaNotify] = useState('');
  const [overAreaModal, toggleOverAreaModal] = useModali({
    animated: true,
    message: overAreaNotify,
    buttons: [
      <Modali.Button
        key="ModaliButton"
        label={t('002')}
        isStyleDestructive
        isTwoButton
        onClick={() => overAreaModalHandle()}
      />,
    ],
  });
  const [generalModalErrorMsg, setGeneralModalErrorMsg] = useState('');
  const [generalErrorModal, toggleGeneralErrorModal] = useModali({
    animated: true,
    title: '連線異常',
    buttons: [
      <Modali.Button
        key="ModaliButton"
        label={t('002')}
        onClick={() => toggleGeneralErrorModal()}
      />,
    ],
  });

  const [gpsModal, toggleGpsModal] = useModali({
    animated: true,
    title: '系統資訊',
    message: t('A030'),
    buttons: [
      <Modali.Button
        key="ModaliButton"
        label={t('002')}
        isStyleDestructive
        isTwoButton
        onClick={() => toggleGpsModal()}
      />,
    ],
  });

  const [checkFareModal, toggleCheckFareModal] = useModali({
    animated: true,
    title: '系統提示',
    message: '預估車資有異動，請重新確認',
    overlayClose: false,
    buttons: [
      <Modali.Button
        key="ModaliButton"
        label={t('002')}
        isStyleDestructive
        isTwoButton
        onClick={() => confirmFareChangeHandle()}
      />,
    ],
  });

  // 假 Input 控制 Mobile 鍵盤在返回時收回
  const preferNameInput = useRef(null);
  const preferAddressInput = useRef(null);

  window.addEventListener('popstate', function () {
    if (window.localStorage.getItem('pageStatus') === 'main') {
      // 旁門左道 - 假 Input 控制 Mobile 鍵盤在返回時收回
      originInputRef.current.blur();
      destinationInputRef.current.blur();
    } else if (window.localStorage.getItem('pageStatus') === 'editing') {
      // 旁門左道 - 假 Input 控制 Mobile 鍵盤在返回時收回
      preferNameInput.current.blur();
      preferAddressInput.current.blur();
    }
  });

  useEffect(() => {
    if (isOnlinePay && formDisplay) {
      updateFareForPlace({
        canClearFare: !estimatedFare.fareId,
        updatedPlace: place,
      });
    } else {
      if (updateEstimatedFareTimer) {
        clearTimeout(updateEstimatedFareTimer);
      }
    }
  }, [isOnlinePay]);

  useEffect(() => {
    const { action } = estimateFareTimeoutControl;
    if (action === 'idle') return;

    clearTimeout(updateEstimatedFareTimer);

    const triggerValue = action === 'stop' ? 0 : action === 'start' ? 1 : null;
    if (triggerValue !== null) {
      setUpdateEstimatedFareTrigger(triggerValue);
    }

    setEstimateFareTimeoutControl({ action: 'idle' });
  }, [estimateFareTimeoutControl]);

  useEffect(() => {
    if (callTaxifail !== '') {
      setAlert(true);
    }
  }, [callTaxifail]);
  // 計算所在地旁的最近社區 驅動條件 : GPS 定位與用戶有效社區資料
  useEffect(() => {
    if (GPSplace && homeResult && homeResult.length !== 0) {
      let final = homeResult.reduce((ac, cr) => {
        if (cr.Latitude && cr.Longitude) {
          let distance = latlngDistance(GPSplace.lat, cr.Latitude, GPSplace.lng, cr.Longitude);
          if (distance < 100) {
            if (ac && ac.d < distance) {
              return ac;
            } else {
              return {
                ...cr,
                d: distance,
              };
            }
          }
          return ac;
        }
        return ac;
      }, false);

      if (final) {
        setTimeout(() => {
          dispatch({
            type: UPDATE_ORIGIN_PLACE,
            payload: {
              placeTag: 'community',
              place: final.FormatedAddress,
              placeId: 'home',
              placeDisplay: final.Community,
            },
          });
          dispatch({
            type: UPDATE_ORIGIN_PLACE_LATLNG,
            payload: {
              lat: final.Latitude,
              lng: final.Longitude,
            },
          });
          if (!predictDisplay && formDisplay) setSearchBarState('pendding');
        }, 0);
      }
    }
  }, [homeResult, GPSplace]);

  useEffect(() => {
    setPlaceInfoForInput({
      isOrigin: true,
      place: place.originPlace,
      placeForDisplay: place.originPlaceDisplay,
    });
    setPlaceInfoForInput({
      isOrigin: false,
      place: place.destinationPlace,
      placeForDisplay: place.destinationPlaceDisplay,
    });
  }, [
    place.originPlace,
    place.originPlaceDisplay,
    place.destinationPlace,
    place.destinationPlaceDisplay,
  ]);

  function latlngDistance(lat1, lat2, lng1, lng2) {
    let φ1 = (lat1 * Math.PI) / 180;
    let φ2 = (lat2 * Math.PI) / 180;
    let Δλ = ((lng1 - lng2) * Math.PI) / 180;
    let R = 6371e3;
    let d = Math.acos(Math.sin(φ1) * Math.sin(φ2) + Math.cos(φ1) * Math.cos(φ2) * Math.cos(Δλ)) * R;
    return d;
  }

  const [mapReady, setMapReady] = useState(false);
  // 按下地圖選位按鈕時才開始製作地圖, 減少初始程式加載效能損耗
  useEffect(() => {
    if (mapReady) {
      let map = new window.google.maps.Map(document.getElementById('map'), {
        disableDefaultUI: true,
        center: { lat: 25.033964, lng: 121.564472 },
        zoom: 17, // Vision size
        clickableIcons: false,
        gestureHandling: 'greedy',
        styles: mapStyles.defaultMode,
      });
      const _setListener = function () {
        map.addListener('drag', () => {
          setIsDraging(true);
          setIsDragingUseDisableButton(true);
        });
        map.addListener('dragend', () => {
          setIsDraging(false);
        });
      };
      // * 增加追蹤, 每次地圖初始建置都記錄到 GA - 地圖建置
      recordActionMapCreateInitialFlow();

      console.log('Dynamic Map API ( $0.007 )');
      _setListener();
      map.setZoom(18);
      if (GPSplace) {
        map.setCenter({ lat: GPSplace.lat, lng: GPSplace.lng });
        const userMarker = new window.google.maps.Marker({
          position: { lat: GPSplace.lat, lng: GPSplace.lng },
          icon: gpsOnMapIcon,
          map: map,
        });
        console.log('[User Marker is set up]', userMarker);
      } else if (homeResult && homeResult[0]) {
        map.setCenter({ lat: homeResult[0].latitude, lng: homeResult[0].longitude });
      } else {
        map.setCenter({ lat: 25.033964, lng: 121.564472 });
      }

      setMapListener(
        map.addListener(
          'dragend',
          debounce(() => {
            setIsDraging(false);
            if (isOverArea(map.getCenter())) {
              let geoOption = { lat: map.getCenter().lat(), lng: map.getCenter().lng() };
              // * 增加追蹤, 每次滑動地圖流量都記錄到 GA - 地圖選址
              recordActionMapGestureFlow();
              // 滑動瞬間關掉=>浪費一次
              googleFunc.geocodeLatLng(geocoder, geoOption).then(
                (data) => {
                  setIsDragingUseDisableButton(false);
                  if (data.formatted_address === '台灣') {
                    setOverAreaNotify(t('A037'));
                    toggleOverAreaModal();
                  } else if (data.formatted_address.indexOf('Unnamed Road') !== -1) {
                    setOverAreaNotify(t('A038'));
                    toggleOverAreaModal();
                  } else {
                    setMapCenter({
                      ...data,
                      ...geoOption,
                    });
                  }
                },
                (reject) => {
                  setIsDragingUseDisableButton(false);
                  console.log(reject);
                  if (reject === 'ZERO_RESULTS') {
                    setOverAreaNotify(t('A038'));
                    toggleOverAreaModal();
                  }
                }
              );
            } else {
              setIsDragingUseDisableButton(false);
              setOverAreaNotify(t('A039'));
              toggleOverAreaModal();
            }
          }, 1000)
        )
      );
      setMap(map);
    }
  }, [mapReady]);
  // google Map SDK 加載完畢後驅動, 進行初始設定及定位
  useEffect(() => {
    if (loaded) {
      let geocoder = new window.google.maps.Geocoder();

      setGeocoder(geocoder);
      let latlng = { lat: lat, lng: lng };
      // most of chorme will do in here
      if (lat && lng) {
        // * 增加追蹤, 每次 GPS 定位流量都記錄到 GA - 使用者地址預測流量
        recordActionLocationChargeFlow(lat, lng);
        googleFunc.geocodeLatLng(geocoder, latlng).then((data) => {
          setGPSplace({
            lat: lat,
            lng: lng,
            address: data,
          });
          deconstructAddress(
            {
              ...data,
              lat: lat,
              lng: lng,
            },
            'origin'
          );
          clearWatch();
        });
      }
    }
  }, [loaded]);
  // 蝦米需求 無特殊定位時採用第一個社區位置
  function noGPSAutoCompleteByCommunity() {
    if (originPlace === '' && homeResult && homeResult[0]) {
      console.log('homeResult[0]', homeResult[0]);
      dispatch({
        type: UPDATE_ORIGIN_PLACE_LATLNG,
        payload: { lat: homeResult[0].Latitude, lng: homeResult[0].Longitude },
      });
      dispatch({
        type: UPDATE_ORIGIN_PLACE,
        payload: {
          placeTag: 'community',
          place: homeResult[0].FormatedAddress,
          placeDisplay: homeResult[0].Community,
          placeId: 'home',
        },
      });
    }
    toggleGpsModal();
  }
  // 第二次 GPS 拒絕機制
  useEffect(() => {
    if (Lerror) {
      googleFunc.geolocation().then((latlng) => {
        googleFunc.geocodeLatLng(geocoder, latlng).then((data) => {
          setGPSplace({
            lat: latlng.lat,
            lng: latlng.lng,
            address: data,
          });
          setMapCenter({
            ...data,
            lat: lat,
            lng: lng,
          });
          clearWatch();
        });
      });
      switch (Lerror.code) {
        case 1:
          // iOS無定位觸發
          console.log('PERMISSION_DENIED');
          setLogListResult((pre) => [...pre, 'PERMISSION_DENIED']);
          noGPSAutoCompleteByCommunity();
          break;
        case 2:
          // 安卓無定位觸發
          console.log('POSITION_UNAVAILABLE');
          setLogListResult((pre) => [...pre, 'POSITION_UNAVAILABLE']);
          noGPSAutoCompleteByCommunity();
          break;
        case 3:
          console.log('TIMEOUT');
          setLogListResult((pre) => [...pre, 'TIMEOUT']);
          break;
        default:
          console.log('unknown error');
          setLogListResult((pre) => [...pre, 'LERROR unknown error']);
          break;
      }
    }
    // most of mobile browser will do in here
    if (loaded && !Lerror) {
      let latlng = { lat: lat, lng: lng };
      googleFunc.geocodeLatLng(geocoder, latlng).then((data) => {
        setGPSplace({
          lat: lat,
          lng: lng,
          address: data,
        });
        deconstructAddress(
          {
            ...data,
            lat: lat,
            lng: lng,
          },
          'origin'
        );
        clearWatch();
      });
    }
  }, [lat, lng, Lerror]);

  useEffect(() => {
    if (mapCenter && loaded) {
      // google API 延遲導致的地址錯誤回填
      if (!predictDisplay) {
        deconstructAddress(mapCenter);
      } else {
        console.log('Give up this selection by change focus');
      }
    }
  }, [mapCenter]);

  useEffect(() => {
    let value =
      searchBarState === 'origin'
        ? originPlace
        : searchBarState === 'destination'
        ? destinationPlace
        : addNewPreferAddress;
    if (predictDisplay && value) {
      // * 增加追蹤, 每次預測地址結果出現都記錄到 GA - 使用者地址預測流量
      recordActionAutoCompletePredictFlow();
      debouncedInputChange(value);
    } else {
      setHistoryDisplay(true);
      setPredictResult([]);
    }
  }, [debounceCount]);

  useEffect(() => {
    // 若sessionStorage有儲存地點，則自動將地點寫入
    setSavePlace();

    return () => {
      debouncedInputChange.cancel(); // 清除 debounce 函式
    };
  }, []);
  const debouncedInputChange = useCallback(
    debounce(async (value) => {
      if (value?.length <= 1) return;
      const [result] = await tripAPI.predictAddress({
        address: value,
      });
      if (result && result?.data?.addresses?.length !== 0) {
        let firstFilter = result.data.addresses
          .filter(predictAddressFilter)
          .map(predictAddressFormat);
        setHistoryDisplay(false);
        setPreferDisplay(false);
        setHomeDisplay(false);
        setPredictResult(firstFilter);
      }
    }, 500),
    []
  );

  // 篩選地址, 排除海外小島、非台灣地區
  function predictAddressFilter(predictAddress) {
    let reg =
      /\u91d1\u9580\u7e23|\u6f8e\u6e56|\u7da0\u5cf6\u9109|\u862d\u5dbc\u9109|\u7409\u7403\u9109|\u9023\u6c5f|(\u9f9c\u5c71[\u9109|\u5cf6])/;
    if (reg.test(predictAddress.full_address)) {
      return false;
    }
    if (
      !predictAddress.full_address.includes('台灣') &&
      !predictAddress.full_address.includes('臺灣')
    )
      return false;
    return true;
  }

  function predictAddressFormat(address) {
    const postalCodeRegex = /^\d{3,5}/; // Regex to match postal code at the start of the address
    const match = address.full_address.match(postalCodeRegex); // Check if postal code exists at the start of the address
    if (match) {
      address.full_address = address.full_address.slice(match[0].length).trim(); // Remove the postal code from the address and trim any leading/trailing spaces
    }
    return address;
  }

  function deconstructAddress(addressObj, exception) {
    let subAddress = '';
    let mainAddress = addressObj.address_components.reduce((ac, cr) => {
      if (cr.types[0] === 'street_number') {
        if (cr.short_name.indexOf('號') !== -1) {
          subAddress = cr.short_name + subAddress;
          return cr.short_name + ac;
        }
        subAddress = cr.short_name + '號' + subAddress;
        return cr.short_name + '號' + ac;
      }
      if (cr.types[0] === 'country' || cr.types[0] === 'postal_code') {
        return ac;
      }
      if (
        cr.types[0] === 'administrative_area_level_1' ||
        cr.types[0] === 'administrative_area_level_2' ||
        cr.types[0] === 'administrative_area_level_3' ||
        cr.types[0] === 'political'
      ) {
        return cr.short_name + ac;
      }
      subAddress = cr.short_name + subAddress;
      return cr.short_name + ac;
    }, '');
    switch (exception ? exception : searchBarState) {
      case 'origin':
        dispatch({
          type: UPDATE_ORIGIN_PLACE_LATLNG,
          payload: { lat: addressObj.lat, lng: addressObj.lng },
        });
        dispatch({
          type: UPDATE_ORIGIN_PLACE,
          payload: {
            placeTag: 'google',
            place: mainAddress,
            placeDisplay: subAddress,
            placeId: addressObj.place_id,
          },
        });
        if (!predictDisplay && formDisplay) setSearchBarState('pendding');
        break;
      case 'destination':
        dispatch({
          type: UPDATE_DESTINATION_PLACE_LATLNG,
          payload: { lat: addressObj.lat, lng: addressObj.lng },
        });
        dispatch({
          type: UPDATE_DESTINATION_PLACE,
          payload: {
            placeTag: 'map',
            place: mainAddress,
            placeDisplay: subAddress,
            placeId: addressObj.place_id,
          },
        });
        break;
      case 'edit':
        setAddNewPreferAddress(mainAddress);
        setAddNewPreferLatlng({ lat: addressObj.lat, lng: addressObj.lng });
        setIsValidAddress(true);
        break;
      default:
        console.log('Give up this selection');
        break;
    }
  }
  function searchBarHandler(e, who, spkey) {
    setSearchBarState(who);
    if (updateEstimatedFareTimer) {
      clearTimeout(updateEstimatedFareTimer);
    }
    switch (who) {
      case 'origin':
        // v1.1.0 上車地點輸入框暫存狀態不能由刪除按鈕主動變空
        // if (e.target.value === "") {
        //     dispatch({ type: UPDATE_ORIGIN_PLACE_LATLNG, payload: {lat: 0, lng: 0}})
        //     dispatch({ type: UPDATE_ORIGIN_PLACE, payload: { placeTag: '', place: '', placeId: '', placeDisplay: '' } })
        // }
        setDebounceCount((pre) => pre + 1);
        setPlaceInfoForInput({
          isOrigin: true,
          place: e.target.value,
        });
        break;
      case 'destination':
        setDebounceCount((pre) => pre + 1);
        if (e.target.value === '') {
          dispatch({ type: UPDATE_DESTINATION_PLACE_LATLNG, payload: { lat: 0, lng: 0 } });
          dispatch({
            type: UPDATE_DESTINATION_PLACE,
            payload: { placeTag: '', place: '', placeId: '', placeDisplay: '' },
          });
        }
        setPlaceInfoForInput({
          isOrigin: false,
          place: e.target.value,
        });
        break;
      case 'edit':
        setAddNewPreferAddress(e.target.value);
        setIsValidAddress(false);
        break;
      default:
        console.log('searchBarHandler function what happen ??');
        break;
    }
    if (spkey === undefined) {
      if (!compositionEnd) {
        return;
      }
    }
  }
  // 中文拼音處理 - 阻擋重複調用 Google API
  function compositioningHandle() {
    setCompositionEnd(false);
  }
  // 中文拼音處理 - 阻擋重複調用 Google API
  function compositionHandle(e, who) {
    setCompositionEnd(true);
    searchBarHandler(e, who, 'pass');
  }

  function clearFareAndDiscountUsage() {
    resetAllDiscount();
    resetEstimatedFare();
  }

  function clear(str) {
    if (str === 'origin') {
      // v1.1.0 上車地點輸入框暫存狀態不能由刪除按鈕主動變空
      setPlaceInfoForInput({
        isOrigin: true,
      });
      originInputRef.current.focus();
      clearFareAndDiscountUsage();
      // dispatch({ type: UPDATE_ORIGIN_PLACE_LATLNG, payload: {lat: 0, lng: 0}})
      // dispatch({ type: UPDATE_ORIGIN_PLACE, payload: { placeTag: '', place: '', placeId: '', placeDisplay: '' } })
    } else if (str === 'destination') {
      // v1.1.0 下車地點輸入框暫存狀態不能在此時被刪除按鈕主動變空
      setPlaceInfoForInput({
        isOrigin: false,
      });
      destinationInputRef.current.focus();
      clearFareAndDiscountUsage();
      // dispatch({ type: UPDATE_DESTINATION_PLACE_LATLNG, payload: {lat: 0, lng: 0}})
      // dispatch({ type: UPDATE_DESTINATION_PLACE, payload: { placeTag: '', place: '', placeId: '', placeDisplay: '' } })
    } else if (str === 'preferName') {
      setAddNewPreferName('');
    } else if (str === 'preferAddress') {
      setIsValidAddress(false);
      setAddNewPreferAddress('');
    }
    setPredictDisplay(true);
    if (!(historyDisplay || preferDisplay || homeDisplay)) setHistoryDisplay(true);
    setPredictResult([]);
  }
  //v1.1.0 新增神奇海螺正規化功能
  function regularOriginPlace() {
    if (predictResult.length !== 0) {
      let predict = predictResult[0];
      console.log('轉換上車');
      dispatch({
        type: UPDATE_ORIGIN_PLACE_LATLNG,
        payload: { lat: predict.latitude, lng: predict.longitude },
      });
      dispatch({
        type: UPDATE_ORIGIN_PLACE,
        payload: {
          placeTag: 'predict',
          place: predict.full_address,
          placeId: predict.place_id,
          placeDisplay: predict.name,
        },
      });
    } else if (originPlace !== place.originPlace) {
      setPlaceInfoForInput({
        isOrigin: true,
        place: place.originPlace,
        placeForDisplay: place.originPlaceDisplay,
      });
    }
  }
  function regularDestinationPlace() {
    if (predictResult.length !== 0) {
      console.log('轉換下車');
      let predict = predictResult[0];
      dispatch({
        type: UPDATE_DESTINATION_PLACE_LATLNG,
        payload: { lat: predict.latitude, lng: predict.longitude },
      });
      dispatch({
        type: UPDATE_DESTINATION_PLACE,
        payload: {
          placeTag: 'predict',
          place: predict.full_address,
          placeId: predict.place_id,
          placeDisplay: predict.name,
        },
      });
    } else if (destinationPlace !== place.destinationPlace) {
      setPlaceInfoForInput({
        isOrigin: false,
        place: place.destinationPlace,
        placeForDisplay: place.destinationPlaceDisplay,
      });
    }
  }
  // Uber Focus 模式開始調整 後續優化 -> 程式碼有點長
  function focusHandler(str) {
    setMapSequence(1);
    setFormDisplay(false);
    setMenuBarBack(true);
    setPredictDisplay(true);
    if (searchBarState !== str) {
      if (str === 'origin') {
        // 調查下車輸入框合法性
        // Uber 做法不刻意轉換為合法地址
        regularDestinationPlace();
      } else if (str === 'destination') {
        // 調查上車輸入框合法性 ,  8/27 更動為放第一個預測值

        regularOriginPlace();
      }
      if (!(homeDisplay || preferDisplay)) {
        setHistoryDisplay(true);
      }
      setPredictResult([]);
    }
    setSearchBarState(str);
    if (updateEstimatedFareTimer) {
      clearTimeout(updateEstimatedFareTimer);
    }
  }

  function checkPlaceIsChanged(originPlace, updatedPlace) {
    return (
      updatedPlace.originPlace !== originPlace.originPlace ||
      updatedPlace.destinationPlace !== originPlace.destinationPlace
    );
  }

  function getAttributesFromEvent(event) {
    return {
      description: event.currentTarget.getAttribute('data-description'),
      main: event.currentTarget.getAttribute('data-main'),
      id: event.currentTarget.getAttribute('data-id'),
      lat: event.currentTarget.getAttribute('data-lat'),
      lng: event.currentTarget.getAttribute('data-lng'),
      type: event.currentTarget.getAttribute('data-type'),
    };
  }

  // 點擊預測列表下方任一值即呼叫此方法 後續優化 -> 程式碼有點長
  function predictHandler(e) {
    let updatedPlace = { ...place };
    const { description, main, id, lat, lng, type } = getAttributesFromEvent(e);
    switch (searchBarState) {
      case 'origin':
        updatedPlace = {
          ...place,
          originPlace: description,
          originPlaceDisplay: main,
          originPlaceId: id,
          originPlaceLat: lat,
          originPlaceLng: lng,
          originPlaceTag: type,
        };
        // 起點保證一定合法
        setPlaceInfoForInput({
          isOrigin: true,
          place: description,
          placeForDisplay: main,
        });
        dispatch({
          type: UPDATE_ORIGIN_PLACE_LATLNG,
          payload: {
            lat,
            lng,
          },
        });
        dispatch({
          type: UPDATE_ORIGIN_PLACE,
          payload: {
            placeTag: type,
            place: description,
            placeId: id,
            placeDisplay: main,
          },
        });
        if (switchToForm({ guarantee: 'origin', updatedPlace })) {
          setPredictDisplay(false);
          setTimeout(() => setPredictResult([]), 800);
        }
        break;
      case 'destination':
        updatedPlace = {
          ...place,
          destinationPlace: description,
          destinationPlaceDisplay: main,
          destinationPlaceId: id,
          destinationPlaceLat: lat,
          destinationPlaceLng: lng,
          destinationPlaceTag: type,
        };
        // 終點保證一定合法
        setPlaceInfoForInput({
          isOrigin: false,
          place: description,
          placeForDisplay: main,
        });
        dispatch({
          type: UPDATE_DESTINATION_PLACE_LATLNG,
          payload: {
            lat,
            lng,
          },
        });
        dispatch({
          type: UPDATE_DESTINATION_PLACE,
          payload: {
            placeTag: type,
            place: description,
            placeId: id,
            placeDisplay: main,
          },
        });
        if (switchToForm({ guarantee: 'destination', updatedPlace })) {
          setPredictDisplay(false);
          setTimeout(() => setPredictResult([]), 800);
        }
        break;
      case 'edit':
        setEditFocusTarget('pendding');
        setAddNewPreferAddress(description);
        setAddNewPreferLatlng({
          lat,
          lng,
        });
        setIsValidAddress(true);
        break;
      default:
        console.log('predictHandler function what happen ??');
        break;
    }
  }

  function setSavePlace() {
    if (sessionStorage.getItem('selectedPlace')) {
      if (!GUID) {
        try {
          const updatedPlace = JSON.parse(sessionStorage.getItem('selectedPlace'));
          if (updatedPlace.originPlace) {
            setPlaceInfoForInput({
              isOrigin: true,
              place: updatedPlace.originPlace,
              placeForDisplay: updatedPlace.originPlaceDisplay,
            });
            dispatch({
              type: UPDATE_ORIGIN_PLACE,
              payload: {
                place: updatedPlace.originPlace,
                placeId: updatedPlace.originPlaceId,
                placeTag: updatedPlace.originPlaceTag,
                placeDisplay: updatedPlace.originPlaceDisplay,
              },
            });
            dispatch({
              type: UPDATE_ORIGIN_PLACE_LATLNG,
              payload: {
                lat: updatedPlace.originPlaceLat,
                lng: updatedPlace.originPlaceLng,
              },
            });
          }
          if (updatedPlace.destinationPlace) {
            setPlaceInfoForInput({
              isOrigin: false,
              place: updatedPlace.destinationPlace,
              placeForDisplay: updatedPlace.destinationPlaceDisplay,
            });
            dispatch({
              type: UPDATE_DESTINATION_PLACE,
              payload: {
                place: updatedPlace.destinationPlace,
                placeId: updatedPlace.destinationPlaceId,
                placeTag: updatedPlace.destinationPlaceTag,
                placeDisplay: updatedPlace.destinationPlaceDisplay,
              },
            });
            dispatch({
              type: UPDATE_DESTINATION_PLACE_LATLNG,
              payload: {
                lat: updatedPlace.destinationPlaceLat,
                lng: updatedPlace.destinationPlaceLng,
              },
            });
          }
          if (checkPlaceIsChanged(place, updatedPlace) || !estimatedFare?.fareId) {
            clearFareAndDiscountUsage();
            updateFareForPlace({ updatedPlace });
          }
        } catch (error) {
          console.log('error', error);
        }
      }
      sessionStorage.removeItem('selectedPlace');
    }
  }

  function setPlaceInfoForInput({ isOrigin, place = '', placeForDisplay = '' }) {
    const setPlace = isOrigin ? setOriginPlace : setDestinationPlace;
    const setPlaceForDisplay = isOrigin ? setOriginPlaceForDisplay : setDestinationPlaceForDisplay;
    setPlace(place);
    setPlaceForDisplay(placeForDisplay);
  }

  function updatePlaceData(updatedPlace, estimatedFare) {
    const {
      originPlaceId,
      originPlaceTag,
      originPlaceDisplay,
      destinationPlaceId,
      destinationPlaceTag,
      destinationPlaceDisplay,
    } = updatedPlace;
    const {
      pickupInfo: {
        fullAddress: originFullAddress,
        latitude: originPlaceLat,
        longitude: originPlaceLng,
      },
      dropoffInfo: {
        fullAddress: destinationFullAddress,
        latitude: destinationPlaceLat,
        longitude: destinationPlaceLng,
      },
    } = estimatedFare;
    dispatch({
      type: UPDATE_ORIGIN_PLACE,
      payload: {
        place: originFullAddress,
        placeId: originPlaceId,
        placeTag: originPlaceTag,
        placeDisplay: originPlaceDisplay,
      },
    });
    dispatch({
      type: UPDATE_ORIGIN_PLACE_LATLNG,
      payload: {
        lat: originPlaceLat,
        lng: originPlaceLng,
      },
    });
    dispatch({
      type: UPDATE_DESTINATION_PLACE,
      payload: {
        place: destinationFullAddress,
        placeId: destinationPlaceId,
        placeTag: destinationPlaceTag,
        placeDisplay: destinationPlaceDisplay,
      },
    });
    dispatch({
      type: UPDATE_DESTINATION_PLACE_LATLNG,
      payload: {
        lat: destinationPlaceLat,
        lng: destinationPlaceLng,
      },
    });
  }

  const ongoingRequests = useRef(0);
  async function updateFareForPlace({ canClearFare = true, updatedPlace }) {
    if (!isOnlinePay) return;

    ongoingRequests.current += 1;
    if (ongoingRequests.current === 1) setIsLoadingFare(true);

    const { res: updatedEstimatedFare, error } = await updateEstimatedFare({
      canClearFare,
      updatedPlace,
      orderDetail,
    });
    if (updatedEstimatedFare) {
      updatePlaceData(updatedPlace, updatedEstimatedFare);
      updateDiscountUsage({
        updatedOrderAmount: updatedEstimatedFare.fare,
        fareId: updatedEstimatedFare.fareId,
        startTrigger: 'discount',
      });
    }
    if (error) {
      if (canClearFare && window.history.state !== 'tab') {
        setGeneralModalErrorMsg(
          error?.response?.data?.ErrorMessage?.Description || '發生錯誤，請稍後再試'
        );
        if (!generalErrorModal.status()) toggleGeneralErrorModal();
      }
    }
    setUpdateEstimatedFareTrigger((prev) => prev + 1);

    ongoingRequests.current -= 1;
    if (ongoingRequests.current === 0) setIsLoadingFare(false);
  }

  function confirmFareChangeHandle() {
    toggleCheckFareModal();
    setEstimatedFare(storedUpdatedEstimatedFare);
    updateDiscountUsage({
      updatedOrderAmount: storedUpdatedEstimatedFare.fare,
      fareId: storedUpdatedEstimatedFare.fareId,
    });
    setStoredUpdatedEstimatedFare(null);
  }

  useEffect(() => {
    if (updateEstimatedFareTrigger > 0) {
      if (updateEstimatedFareTimer) {
        clearTimeout(updateEstimatedFareTimer);
      }
      setUpdateEstimatedFareTimer(
        setTimeout(async () => {
          const canClearFare = Boolean(!estimatedFare?.fareId);
          const { res: updatedEstimatedFare, error: _error } = await updateEstimatedFare({
            canClearFare,
            canUpdateFare: false,
            updatedPlace: place,
            orderDetail,
          });
          if (
            estimatedFare?.fareId &&
            updatedEstimatedFare &&
            updatedEstimatedFare.fare !== estimatedFare.fare
          ) {
            setStoredUpdatedEstimatedFare(updatedEstimatedFare);
            if (!checkFareModal.status()) toggleCheckFareModal();
          }
          setUpdateEstimatedFareTrigger((prev) => prev + 1);
        }, 4 * 60 * 1000)
      );
    }

    return () => {
      if (updateEstimatedFareTimer) {
        clearTimeout(updateEstimatedFareTimer);
      }
    };
  }, [updateEstimatedFareTrigger, updateEstimatedFare, estimatedFare, place, orderDetail]);
  // 地圖選位時滑動超出服務區域
  function overAreaModalHandle() {
    toggleOverAreaModal();
    if (GPSplace) {
      // 有開定位
      setMapCenter({
        ...GPSplace.address,
        lat: GPSplace.lat,
        lng: GPSplace.lng,
      });
      map.setCenter({ lat: GPSplace.lat, lng: GPSplace.lng });
      map.setZoom(18);
    } else if (homeResult && homeResult.length > 0) {
      // 有開通社區
      let type;
      if (searchBarState === 'origin') {
        dispatch({
          type: UPDATE_ORIGIN_PLACE_LATLNG,
          payload: { lat: homeResult[0].Latitude, lng: homeResult[0].Longitude },
        });
        type = UPDATE_ORIGIN_PLACE_LATLNG;
      } else {
        dispatch({
          type: UPDATE_DESTINATION_PLACE_LATLNG,
          payload: { lat: homeResult[0].Latitude, lng: homeResult[0].Longitude },
        });
        type = UPDATE_DESTINATION_PLACE;
      }
      dispatch({
        type: type,
        payload: {
          placeTag: 'home',
          place: homeResult[0].FormatedAddress,
          placeId: 'home',
          placeDisplay: homeResult[0].Community,
        },
      });
      map && map.setCenter({ lat: homeResult[0].Latitude, lng: homeResult[0].Longitude });
      map && map.setZoom(18);
    } else {
      // 回到預設地點
      map && map.setCenter({ lat: 25.047372, lng: 121.516675 });
      map && map.setZoom(18);
    }
  }
  // 常用編輯與普通上下車列表末端所呼叫的地圖選位模式 後續優化 -> 程式碼有點長
  function choosePositionByMap() {
    setMapReady(true);
    setEditFocusTarget('pendding');
    const position =
      searchBarState === 'origin'
        ? originPlace
        : searchBarState === 'destination'
        ? destinationPlace
        : addNewPreferAddress;
    if (
      searchBarState === 'origin' &&
      position === place.originPlace &&
      +place.originPlaceLat &&
      +place.originPlaceLng
    ) {
      let latlng = { lat: +place.originPlaceLat, lng: +place.originPlaceLng };
      map && map.setCenter(latlng);
      map && map.setZoom(18);
    } else if (
      searchBarState === 'origin' &&
      position === place.originPlace &&
      +place.destinationPlaceLat &&
      +place.destinationPlaceLng
    ) {
      let latlng = { lat: +place.destinationPlaceLat, lng: +place.destinationPlaceLng };
      map && map.setCenter(latlng);
      map && map.setZoom(18);
    } else {
      // * 增加追蹤, 每次地圖初始定位都記錄到 GA - 地圖初始定位
      recordActionPredictSetMapCenterFlow();
      googleFunc.geocodeAddress(geocoder, position).then(
        (data) => {
          // question ??
          deconstructAddress({
            ...data,
            lat: data.geometry.location.lat(),
            lng: data.geometry.location.lng(),
          });
          let latlng = { lat: data.geometry.location.lat(), lng: data.geometry.location.lng() };
          map && map.setCenter(latlng);
          map && map.setZoom(18);
        },
        () => {
          // 使用者亂打情況
          if (searchBarState === 'origin') {
            if (GPSplace) {
              // 開定位
              if (mapCenter === GPSplace.address) {
                deconstructAddress({
                  ...GPSplace.address,
                  lat: GPSplace.lat,
                  lng: GPSplace.lng,
                });
              } else {
                setMapCenter({
                  ...GPSplace.address,
                  lat: GPSplace.lat,
                  lng: GPSplace.lng,
                });
              }
              map && map.setCenter({ lat: GPSplace.lat, lng: GPSplace.lng });
              map && map.setZoom(18);
            } else if (homeResult && homeResult.length > 0) {
              // 有綁定社區
              dispatch({
                type: UPDATE_ORIGIN_PLACE_LATLNG,
                payload: { lat: homeResult[0].Latitude, lng: homeResult[0].Longitude },
              });
              dispatch({
                type: UPDATE_ORIGIN_PLACE,
                payload: {
                  placeTag: 'community',
                  place: homeResult[0].FormatedAddress,
                  placeId: 'liyo1242',
                  placeDisplay: homeResult[0].Community,
                },
              });

              map && map.setCenter({ lat: homeResult[0].Latitude, lng: homeResult[0].Longitude });
              map && map.setZoom(18);
            }
          } else if (searchBarState === 'destination') {
            dispatch({ type: UPDATE_DESTINATION_PLACE_LATLNG, payload: { lat: 0, lng: 0 } });
            dispatch({
              type: UPDATE_DESTINATION_PLACE,
              payload: { placeTag: '', place: '', placeId: '', placeDisplay: '' },
            });
            if (GPSplace) {
              // 開定位
              map && map.setCenter({ lat: GPSplace.lat, lng: GPSplace.lng });
              map && map.setZoom(18);
            }
          }
        }
      );
    }

    if (map && mapListener === null) {
      setMapListener(
        map.addListener(
          'dragend',
          debounce(() => {
            setIsDraging(false);
            if (isOverArea(map.getCenter())) {
              let geoOption = { lat: map.getCenter().lat(), lng: map.getCenter().lng() };
              // * 增加追蹤, 每次滑動地圖流量都記錄到 GA - 地圖選址
              recordActionMapGestureFlow();
              // 滑動瞬間關掉=>浪費一次
              googleFunc.geocodeLatLng(geocoder, geoOption).then(
                (data) => {
                  if (data.formatted_address === '台灣') {
                    setOverAreaNotify(t('A037'));
                    toggleOverAreaModal();
                  } else {
                    setMapCenter({
                      ...data,
                      ...geoOption,
                    });
                  }
                },
                (reject) => {
                  console.log(reject);
                  if (reject === 'ZERO_RESULTS') {
                    setOverAreaNotify(t('A038'));
                    toggleOverAreaModal();
                  }
                }
              );
            } else {
              setOverAreaNotify(t('A039'));
              toggleOverAreaModal();
            }
          }, 1500)
        )
      );
    }

    if (addNewPreferMode) {
      setPredictDisplay(false);
      return;
    }
    setMapSequence(2);
    setPredictDisplay(false);
  }

  function isOverArea(center) {
    let strictBounds = new window.google.maps.LatLngBounds(
      new window.google.maps.LatLng(21.715956, 120.008499),
      new window.google.maps.LatLng(25.37116, 122.024253)
    );

    let greenIslandBounds = new window.google.maps.LatLngBounds(
      new window.google.maps.LatLng(22.488978, 121.203062),
      new window.google.maps.LatLng(22.838345, 121.727502)
    );

    let turtleIslandBounds = new window.google.maps.LatLngBounds(
      new window.google.maps.LatLng(24.808629, 121.893844),
      new window.google.maps.LatLng(24.879268, 122.009697)
    );

    let lanyuBounds = new window.google.maps.LatLngBounds(
      new window.google.maps.LatLng(21.911979, 121.266465),
      new window.google.maps.LatLng(22.179865, 121.889543)
    );

    let ryukyuBounds = new window.google.maps.LatLngBounds(
      new window.google.maps.LatLng(22.281078, 120.277051),
      new window.google.maps.LatLng(22.399742, 120.480668)
    );

    if (strictBounds.contains(center)) {
      if (greenIslandBounds.contains(center)) return false;
      if (lanyuBounds.contains(center)) return false;
      if (ryukyuBounds.contains(center)) return false;
      if (turtleIslandBounds.contains(center)) return false;

      return true;
    }

    return false;
  }

  const [positiveSequence, setPositiveSequence] = useState(false);

  useEffect(() => {
    if (!positiveSequence) {
      if (mapSequence === 0) {
        // switchToForm('daddy')
        switchToForm();
      } else if (mapSequence === 1) {
        if (!document.body.scrollBy) {
          predictListRef.current.scrollTop = 0;
        } else {
          predictListRef.current.scrollTo(0, 0);
        }
        setPredictDisplay(true);
        setHistoryDisplay(true);
        setPreferDisplay(false);
        setHomeDisplay(false);
      }
    } else {
      console.log('PositiveSequence');
    }
    setPositiveSequence(false);
  }, [mapSequence]);

  const createUpdatedPlaceObject = ({ key = 'originPlace', result = {}, placeTag = '' } = {}) => {
    const { FormatedAddress = '', Community = '', Latitude = 0, Longitude = 0 } = result;
    return {
      [key]: FormatedAddress,
      [`${key}Display`]: Community,
      [`${key}Tag`]: placeTag,
      [`${key}Lat`]: Latitude,
      [`${key}Lng`]: Longitude,
    };
  };
  // 完成選位 => 發生情境 :  1. 按下返回 2. 按下下方預測欄位
  function switchToForm({ guarantee = '', updatedPlace = null } = {}) {
    if (!updatedPlace) {
      updatedPlace = { ...place };
    }
    if (
      (guarantee !== 'origin' && originPlace !== place.originPlace) ||
      place.originPlaceId === ''
    ) {
      if (place.originPlace !== '' && place.originPlaceDisplay !== '') {
        setPlaceInfoForInput({
          isOrigin: true,
          place: place.originPlace,
          placeForDisplay: place.originPlaceDisplay,
        });
      } else if (GPSplace) {
        // 有開定位
        deconstructAddress({ ...GPSplace.address, lat: GPSplace.lat, lng: GPSplace.lng }, 'origin');
        map && map.setCenter({ lat: GPSplace.lat, lng: GPSplace.lng });
        map && map.setZoom(18);
      } else if (homeResult && homeResult.length > 0) {
        // 有綁定社區
        // 社區地址不完整性極高
        dispatch({
          type: UPDATE_ORIGIN_PLACE_LATLNG,
          payload: { lat: homeResult[0].Latitude, lng: homeResult[0].Longitude },
        });
        dispatch({
          type: UPDATE_ORIGIN_PLACE,
          payload: {
            placeTag: 'community',
            place: homeResult[0].FormatedAddress,
            placeId: 'liyo1242',
            placeDisplay: homeResult[0].Community,
          },
        });
        updatedPlace = {
          ...place,
          ...createUpdatedPlaceObject({ result: homeResult[0], placeTag: 'community' }),
        };
        map && map.setCenter({ lat: homeResult[0].Latitude, lng: homeResult[0].Longitude });
        map && map.setZoom(18);
      } else {
        // 沒社區 沒定位
        if (guarantee !== 'origin') {
          dispatch({ type: UPDATE_ORIGIN_PLACE_LATLNG, payload: { lat: 0, lng: 0 } });
          dispatch({
            type: UPDATE_ORIGIN_PLACE,
            payload: { placeTag: '', place: '', placeId: '', placeDisplay: '' },
          });
          updatedPlace = {
            ...place,
            ...createUpdatedPlaceObject(),
          };
        }
      }
    }
    // 下車地點不為空時檢查
    if (
      guarantee !== 'destination' &&
      destinationPlace !== '' &&
      (destinationPlace !== place.destinationPlace || place.destinationPlaceId === '')
    ) {
      setPlaceInfoForInput({
        isOrigin: false,
        place: place.destinationPlace,
        placeForDisplay: place.destinationPlaceDisplay,
      });
    }
    if (guarantee !== 'destination' && destinationPlace === '') {
      dispatch({ type: UPDATE_DESTINATION_PLACE_LATLNG, payload: { lat: 0, lng: 0 } });
      dispatch({
        type: UPDATE_DESTINATION_PLACE,
        payload: { placeTag: '', place: '', placeId: '', placeDisplay: '' },
      });
      updatedPlace = {
        ...place,
        ...createUpdatedPlaceObject({ key: 'destinationPlace' }),
      };
      resetEstimatedFare();
    }
    if (checkPlaceIsChanged(place, updatedPlace) || !estimatedFare?.fareId) {
      clearFareAndDiscountUsage();
      updateFareForPlace({ updatedPlace });
    }
    setPredictResult([]);
    setSearchBarState('pendding');
    setMenuBarBack(false);
    setFormDisplay(true);
    setPredictDisplay(false);
    setPreferDisplay(false);
    setHomeDisplay(false);
    setHistoryDisplay(true);
    setPositiveSequence(true);
    setMapSequence(0);
    return true;
  }
  const [predictDisplay, setPredictDisplay] = useState(false);
  const [historyDisplay, setHistoryDisplay] = useState(true);
  const [preferDisplay, setPreferDisplay] = useState(false);
  const [homeDisplay, setHomeDisplay] = useState(false);

  function backToGPS() {
    if (GPSplace) {
      deconstructAddress(
        { ...GPSplace.address, lat: GPSplace.lat, lng: GPSplace.lng },
        searchBarState
      );
      map.setCenter({ lat: GPSplace.lat, lng: GPSplace.lng });
      map.setZoom(18);
    } else {
      toggleGpsModal();
    }
  }

  const predictListRef = useRef(null);

  function expendList(listName) {
    if (!document.body.scrollBy) {
      predictListRef.current.scrollTop = 0;
    } else {
      predictListRef.current.scrollTo(0, 0);
    }
    setHistoryDisplay(false);
    setPreferDisplay(false);
    setHomeDisplay(false);
    listName === 'prefer'
      ? setPreferDisplay(true)
      : listName === 'home'
      ? setHomeDisplay(true)
      : setHistoryDisplay(true);
  }
  // add to Prefer
  const [addModal, toggleAddModal] = useModali({
    animated: true,
    title: t('016'),
    message: t('017'),
    buttons: [
      <Modali.Button
        key="ModaliButton"
        label={t('019')}
        isMutiButton
        onClick={() => toggleAddModal()}
      />,
      <Modali.Button
        key="ModaliButton"
        label={t('018')}
        isMutiButton
        onClick={() => addToPreferModalConfirm('company')}
      />,
      <Modali.Button
        key="ModaliButton"
        label={t('021')}
        isMutiButton
        isStyleDestructive
        onClick={() => startAddToPrefer()}
      />,
    ],
  });
  const [addToPreferModalNameText, setAddToPreferModalNameText] = useState('');
  const [addToPreferModalTitle, setAddToPreferModalTitle] = useState('設立地點名稱');
  const [addToPreferModalBody, setAddToPreferModalBody] = useState(t('020'));
  const [addToPreferModal, toggleAddToPreferModal] = useModali({
    title: addToPreferModalTitle,
    animated: true,
    buttons: [
      <Modali.Button
        key="ModaliButton"
        label={t('003')}
        isTwoButton
        onClick={() => addToPreferModalCancel()}
      />,
      <Modali.Button
        key="ModaliButton"
        label={t('002')}
        isStyleDestructive
        isTwoButton
        onClick={() => addToPreferModalConfirm()}
      />,
    ],
  });
  const [deleteModal, toggleDeleteModal] = useModali({
    animated: true,
    title: `請確認是否刪除此『常用地點』
（這將從常用地點移除此位置）`,
    buttons: [
      <Modali.Button
        key="ModaliButton"
        label={t('003')}
        isTwoButton
        onClick={() => deleteModalCancel()}
      />,
      <Modali.Button
        key="ModaliButton"
        label={t('002')}
        isStyleDestructive
        isTwoButton
        onClick={() => deleteModalConfirm()}
      />,
    ],
  });
  const [currentKey, setCurrentKey] = useState();

  function startAddToPrefer() {
    if (preferResult.length > 18) {
      // 不包含公司
      setToastAppear(t('A068'));
      toggleAddModal();
      return;
    } else {
      setAddToPreferModalTitle('設立地點名稱');
      setAddToPreferModalBody(t('020'));
      setAddToPreferModalNameText(preferPlaceholder);
      toggleAddModal();
      toggleAddToPreferModal();
    }
  }

  function addToPrefer(addressItem, itemIndex, e) {
    e.stopPropagation();

    let companyFound = preferResult.find((e) => e.memo === '公司');

    // setCurrentKey
    setAddToPreferModalNameText('');
    setCurrentKey({ ...addressItem, itemIndex: itemIndex });
    // call add modal or delete modal
    if (addressItem.isPrefer) {
      toggleDeleteModal();
    } else if (!addressItem.isPrefer && companyFound) {
      if (preferResult.length > 19) {
        // 包含一筆公司
        setToastAppear('常用地點已達到19筆上限');
        return;
      }
      setAddToPreferModalTitle('設立地點名稱');
      setAddToPreferModalBody(t('020'));
      setAddToPreferModalNameText(preferPlaceholder);
      toggleAddToPreferModal();
    } else {
      toggleAddModal();
    }
  }

  function addToPreferModalCancel() {
    setCurrentKey(null);
    toggleAddToPreferModal();
  }

  function deleteModalCancel() {
    setCurrentKey(null);
    toggleDeleteModal();
  }

  function addToPreferModalConfirm(name) {
    let companyFound = undefined,
      found = undefined;
    if (name !== 'company' && addToPreferModalNameText === '') {
      // * do nothing
    } else if (name === 'company' || addToPreferModalNameText === '公司') {
      companyFound = preferResult.find((e) => e.memo === '公司');
    } else {
      found = preferResult.find((e) => e.memo === addToPreferModalNameText);
    }
    // need server return id
    if (!(companyFound && name === 'company') && found === undefined) {
      let option = {
        city: '台中市',
        area: '北區',
        memo:
          name === 'company'
            ? '公司'
            : addToPreferModalNameText
            ? addToPreferModalNameText
            : preferPlaceholder,
        address: currentKey.address,
        sort: 0,
        lat: currentKey.lat || 0,
        lon: currentKey.lng || 0,
      };
      api
        .kingnetTaxiUserPostAddress(option)
        .then((res) => {
          // let buf = preferResult
          let buf2 = processedHistoryResult;
          // buf.splice(0,0, {...option, id: res.data.Data.id})
          setPreferResult((pre) => [{ ...option, id: res.data.Data.id }].concat(pre));
          let newProcessedHistoryItem = {
            ...processedHistoryResult[currentKey.itemIndex],
            isPrefer: true,
            preferId: res.data.Data.id,
          };
          buf2.splice(currentKey.itemIndex, 1, newProcessedHistoryItem);
          setProcessedHistoryResult(buf2);
          if (name === 'company') {
            toggleAddModal();
          } else {
            toggleAddToPreferModal();
          }
        })
        .catch(() => {
          setToastAppear('加入失敗');
          if (name === 'company') {
            toggleAddModal();
          } else {
            toggleAddToPreferModal();
          }
        });
    } else {
      setAddToPreferModalTitle('名稱重複');
      setAddToPreferModalBody(t('005'));
    }
  }

  function deleteModalConfirm() {
    // gandalf
    api
      .kingnetTaxiUserDeleteAddress({ id: currentKey.preferId })
      .then(() => {
        setToastAppear(t('A040'));
        const result = preferResult.filter((e) => e.id !== currentKey.preferId);
        let buf2 = processedHistoryResult;
        let newProcessedHistoryItem = {
          ...processedHistoryResult[currentKey.itemIndex],
          isPrefer: false,
          preferId: '',
        };
        buf2.splice(currentKey.itemIndex, 1, newProcessedHistoryItem);
        setProcessedHistoryResult(buf2);
        setPreferResult(result);
      })
      .catch((err) => console.log(err));
    toggleDeleteModal();
  }

  const originInputRef = useRef(null);
  const destinationInputRef = useRef(null);
  const [processedHistoryResult, setProcessedHistoryResult] = useState([]);

  useEffect(() => {
    let processedHistory =
      historyResult &&
      historyResult.reduce((ac, cr) => {
        // prepare find
        const x = ac.find((item) => item.address === cr.origin.address);
        // add prefer tag
        if (!x) {
          let originIsPrefer = preferResult.find((e) => e.address === cr.origin.address);

          if (originIsPrefer) {
            ac.push({ ...cr.origin, isPrefer: true, preferId: originIsPrefer.id });
          } else {
            ac.push({ ...cr.origin, isPrefer: false });
          }
        }

        if (cr.destination && cr.destination.address) {
          const y = ac.find((item) => item.address === cr.destination.address);
          if (!y) {
            let destinationIsPrefer = preferResult.find(
              (e) => e.address === cr.destination.address
            );

            if (destinationIsPrefer) {
              ac.push({ ...cr.destination, isPrefer: true, preferId: destinationIsPrefer.id });
            } else {
              ac.push({ ...cr.destination, isPrefer: false });
            }
          }
        }
        return ac;
      }, []);
    setProcessedHistoryResult(processedHistory);
  }, [preferResult, historyResult]);
  // 每次常用地點更新, 動態判斷最新不重複 preferResult 預填入名稱 ( 常用地點 X )
  useEffect(() => {
    if (preferResult) {
      const regex = new RegExp(/^常用地點(20|1[0-9]|[1-9])$/);
      const result = preferResult.map((e) => {
        if (e.memo && e.memo.match(regex)) {
          return +e.memo.slice(4);
        }
        return undefined;
      });
      const pureResult = result.filter((e) => e !== undefined);
      if (pureResult.length === 0) {
        setPreferPlaceholder('常用地點1');
      } else if (pureResult.length === 1) {
        if (pureResult[0] !== 1) {
          setPreferPlaceholder('常用地點1');
        } else {
          setPreferPlaceholder('常用地點2');
        }
      } else {
        pureResult.sort((a, b) => a - b);
        let foundIndex = pureResult.findIndex((e, i) => e !== i + 1);
        if (foundIndex === -1) {
          setPreferPlaceholder(`常用地點${pureResult.length + 1}`);
        } else {
          setPreferPlaceholder(`常用地點${foundIndex + 1}`);
        }
      }
    }
  }, [preferResult]);
  // v1.0.1
  const editInputHandle = (e, type) => {
    if (type === 'name') {
      setAddNewPreferName(e.target.value);
    } else if (type === 'address') {
      searchBarHandler(e, 'edit');
    } else {
      console.log("i don't know what happen");
    }
  };

  const [lockAddNewInput, setLockAddNewInput] = useState(false);
  const [editLockNamePlaceholder, setEditLockNamePlaceholder] = useState('');
  const [editLockAddressPlaceholder, setEditLockAddressPlaceholder] = useState('');
  // 進入加入或編輯常用模式初始設定
  useEffect(() => {
    if (addNewPreferMode) {
      setEditFocusTarget('pendding');
      window.history.pushState('tab', null, `./tab`);
      window.localStorage.setItem('pageStatus', 'editing');
      preferModeInvalidHandle();
      setPredictResult([]);
      if (!document.body.scrollBy) {
        predictListRef.current.scrollTop = 0;
      } else {
        predictListRef.current.scrollTo(0, 0);
      }
      if (addNewPreferName === '公司') {
        setEditLockNamePlaceholder('公司');
        setLockAddNewInput(true);
      } else if (addNewPreferName !== '') {
        setEditLockNamePlaceholder(addNewPreferName);
        setLockAddNewInput(false);
      } else if (addNewPreferName === '') {
        setAddNewPreferName(preferPlaceholder);
        setLockAddNewInput(false);
      } else {
        setEditLockNamePlaceholder('');
        setLockAddNewInput(false);
      }

      if (addNewPreferAddress !== '') {
        setEditLockAddressPlaceholder(addNewPreferAddress);
        setPreInputValue(addNewPreferAddress);
        setIsValidAddress(true);
      } else {
        setEditLockAddressPlaceholder('');
        setIsValidAddress(false);
      }
      setPredictDisplay(true);
      setSearchBarState('edit');
      setFormDisplay(false);
    } else {
      setPredictResult([]);
      setFormDisplay(true);
      setPredictDisplay(false);
      setSearchBarState('pendding');
    }
  }, [addNewPreferMode]);

  const [isValidAddress, setIsValidAddress] = useState(false);
  const [preInputValue, setPreInputValue] = useState(false);
  const [preferModeNameRedBorderAlert, setPreferModeNameRedBorderAlert] = useState(false);
  const [preferModeAddressRedBorderAlert, setPreferModeAddressRedBorderAlert] = useState(false);
  const [editFocusTarget, setEditFocusTarget] = useState('pendding');

  function editFocusHandle(type) {
    if (type === 'focus') {
      setSearchBarState('edit');
      setEditFocusTarget('address');
      if (isValidAddress) setPreInputValue(addNewPreferAddress);
      setIsValidAddress(false);
      if (!document.body.scrollBy) {
        predictListRef.current.scrollTop = 0;
      } else {
        predictListRef.current.scrollTo(0, 0);
      }
      setPredictDisplay(true);
    } else if (type === 'namefocus') {
      setSearchBarState('pendding');
      setEditFocusTarget('name');
      if (!document.body.scrollBy) {
        predictListRef.current.scrollTop = 0;
      } else {
        predictListRef.current.scrollTo(0, 0);
      }
      setPredictDisplay(true);
      if (preInputValue === addNewPreferAddress) {
        setIsValidAddress(true);
      }
    } else {
      console.log('something bad mix');
    }
    preferModeInvalidHandle();
  }

  function preferModeInvalidHandle(nameInputStatus = false, addressInputStatus = false) {
    setPreferModeNameRedBorderAlert(nameInputStatus);
    setPreferModeAddressRedBorderAlert(addressInputStatus);
  }

  function prefixAddOrEdit() {
    if (addNewPreferId && addNewPreferId !== '') {
      // 編輯模式檢查
      let found,
        nameSame = false,
        addressSame = false;

      found = preferResult.reduce((ac, cr) => {
        if (ac === undefined) {
          if (cr.id !== addNewPreferId) {
            if (cr.memo === addNewPreferName) {
              nameSame = true;
            }
            if (cr.address === addNewPreferAddress) {
              addressSame = true;
            }
            return nameSame || addressSame ? cr : undefined;
          } else {
            return undefined;
          }
        }
        return ac;
      }, undefined);

      if (found) {
        if (nameSame && addressSame) {
          setToptipAppear(t('A065'));
          preferModeInvalidHandle(true, true);
        } else if (nameSame) {
          setToptipAppear(t('A063'));
          preferModeInvalidHandle(true, false);
        } else if (addressSame) {
          setToptipAppear(t('A064'));
          preferModeInvalidHandle(false, true);
        } else {
          console.log('what happen');
        }
      } else {
        addOrEditHandle();
      }
    } else {
      // 新增模式檢查
      if (!isValidAddress) {
        setToptipAppear('地址不合法');
        preferModeInvalidHandle(false, true);
        return;
      }
      let found,
        nameSame = false,
        addressSame = false;

      if (addNewPreferName === '') {
        found = preferResult.find((e) => e.address === addNewPreferAddress);
      } else {
        found = preferResult.reduce((ac, cr) => {
          if (ac === undefined) {
            if (cr.memo === addNewPreferName) {
              nameSame = true;
            }
            if (cr.address === addNewPreferAddress) {
              addressSame = true;
            }
            return nameSame || addressSame ? cr : undefined;
          }
          return ac;
        }, undefined);
      }

      if (found) {
        if (addNewPreferName === '') {
          setToptipAppear('地址重複');
          preferModeInvalidHandle(false, true);
        } else {
          if (nameSame && addressSame) {
            setToptipAppear(t('A065'));
            preferModeInvalidHandle(true, true);
          } else if (nameSame) {
            setToptipAppear(t('A063'));
            preferModeInvalidHandle(true, false);
          } else if (addressSame) {
            setToptipAppear(t('A064'));
            preferModeInvalidHandle(false, true);
          } else {
            console.log('what happen');
          }
        }
      } else {
        addOrEditHandle();
      }
    }
  }

  const searchBarKeypressHandler = (e) => {
    if (e.charCode === 13) {
      e.preventDefault();
      setDebounceCount((pre) => pre + 1);
    }
  };

  return (
    <div className="page">
      <form style={{ display: 'none' }}>
        <input
          style={{ display: 'none' }}
          autoComplete="on"
          type="text"
          name="fakeusernameremembered"
        />
        <input
          style={{ display: 'none' }}
          autoComplete="on"
          type="password"
          name="fakepasswordremembered"
        />
      </form>
      {addNewPreferMode ? (
        <div className="autocomplete">
          <div className="prefer_inputbar">
            <span>地點名稱</span>
            <div className="prefer_inputbar__input">
              <input
                ref={preferNameInput}
                maxLength="10"
                type="text"
                autoComplete="off"
                onFocus={() => editFocusHandle('namefocus')}
                disabled={lockAddNewInput ? 'disabled' : ''}
                value={addNewPreferName || ''}
                placeholder={editLockNamePlaceholder ? editLockNamePlaceholder : preferPlaceholder}
                onChange={(e) => editInputHandle(e, 'name')}
                className={editFocusTarget === 'name' ? 'active' : null}
              />
              <div style={preferModeNameRedBorderAlert ? { borderColor: 'red' } : null} />
              {editFocusTarget === 'name' && !lockAddNewInput && addNewPreferName !== '' ? (
                <i className="icon-clear" onClick={() => clear('preferName')} aria-hidden="true" />
              ) : null}
            </div>
          </div>
          <div className="prefer_inputbar">
            <span>常用地點</span>
            <div className="prefer_inputbar__input">
              <input
                ref={preferAddressInput}
                maxLength="50"
                type="text"
                autoComplete="off"
                value={addNewPreferAddress || ''}
                onFocus={() => editFocusHandle('focus')}
                placeholder={editLockAddressPlaceholder}
                onCompositionStart={compositioningHandle}
                onCompositionUpdate={compositioningHandle}
                onCompositionEnd={(e) => compositionHandle(e, 'edit')}
                onChange={(e) => editInputHandle(e, 'address')}
                className={editFocusTarget === 'address' ? 'active' : null}
              />
              <div style={preferModeAddressRedBorderAlert ? { borderColor: 'red' } : null} />
              {searchBarState !== 'edit' || addNewPreferAddress === '' ? null : (
                <i
                  className="icon-clear"
                  onClick={() => clear('preferAddress')}
                  aria-hidden="true"
                />
              )}
              {searchBarState !== 'edit' ||
              editFocusTarget !== 'address' ||
              addNewPreferAddress === '' ||
              !predictDisplay ? null : (
                <p onClick={() => setDebounceCount((pre) => pre + 1)}>搜尋</p>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="autocomplete">
          <div className="autocomplete__searchbar" id="getOn">
            <div className="autocomplete__searchbar__icon">
              <i
                style={
                  searchBarState === 'origin' ? { color: 'var(--primaryColor)' } : { color: '#999' }
                }
                className="icon-car-3"
                aria-hidden="true"
              />
            </div>
            <div
              className={`autocomplete__searchbar__input ${
                searchBarState === 'origin' ? 'active' : ''
              } ${alert && originPlace === '' ? 'error' : ''}`}
            >
              <div
                className="address__list"
                style={searchBarState === 'origin' ? null : { maxWidth: '100%' }}
                onClick={() => originInputRef.current.focus()}
              >
                <span>{originPlaceForDisplay}</span>
                <input
                  maxLength="50"
                  type="text"
                  ref={originInputRef}
                  autoComplete="off"
                  value={originPlace || ''}
                  placeholder={t('006')}
                  onFocus={() => focusHandler('origin')}
                  onCompositionStart={compositioningHandle}
                  onCompositionUpdate={compositioningHandle}
                  onCompositionEnd={(e) => compositionHandle(e, 'origin')}
                  onChange={(e) => searchBarHandler(e, 'origin')}
                  onKeyPress={searchBarKeypressHandler}
                />
              </div>
              {searchBarState !== 'origin' || originPlace === '' ? null : (
                <i className="icon-clear" onClick={() => clear('origin')} aria-hidden="true" />
              )}
              {searchBarState !== 'origin' || originPlace === '' || !predictDisplay ? null : (
                <p onClick={() => setDebounceCount((pre) => pre + 1)}>搜尋</p>
              )}
            </div>
          </div>
          <div className="autocomplete__searchbar" id="getOff">
            <div className="autocomplete__searchbar__icon">
              <i
                style={
                  searchBarState === 'destination'
                    ? { color: 'var(--primaryColor)' }
                    : { color: '#999' }
                }
                className="icon-marker"
                aria-hidden="true"
              />
            </div>
            <div
              className={`autocomplete__searchbar__input ${
                searchBarState === 'destination' ? 'active' : ''
              }`}
            >
              <div
                className="address__list"
                style={searchBarState === 'destination' ? null : { maxWidth: '100%' }}
                onClick={() => destinationInputRef.current.focus()}
              >
                <span>{destinationPlaceForDisplay}</span>
                <input
                  maxLength="50"
                  type="text"
                  ref={destinationInputRef}
                  autoComplete="off"
                  value={destinationPlace || ''}
                  placeholder={isOnlinePay ? t('024') : t('007')}
                  onFocus={() => focusHandler('destination')}
                  onCompositionStart={compositioningHandle}
                  onCompositionUpdate={compositioningHandle}
                  onCompositionEnd={(e) => compositionHandle(e, 'destination')}
                  onChange={(e) => searchBarHandler(e, 'destination')}
                  onKeyPress={searchBarKeypressHandler}
                />
              </div>
              {searchBarState !== 'destination' || destinationPlace === '' ? null : (
                <i className="icon-clear" onClick={() => clear('destination')} aria-hidden="true" />
              )}
              {searchBarState !== 'destination' ||
              destinationPlace === '' ||
              !predictDisplay ? null : (
                <p onClick={() => setDebounceCount((pre) => pre + 1)}>搜尋</p>
              )}
            </div>
          </div>
          <i className="icon-down" aria-hidden="true" />
        </div>
      )}

      <div className="pageSwitch">
        {children}
        <div
          className="mapContainer"
          style={formDisplay || predictDisplay ? { opacity: 0 } : { opacity: 1 }}
        >
          <div id="arrow" className={`arrow ${isDraging ? 'arrowMove' : ''}`}>
            <i className="icon-map-marker" aria-hidden="true" />
          </div>
          <div id="map"></div>
          <div className="gpsBtn" onClick={() => backToGPS()}>
            <i className="icon-gps-2" />
          </div>
          {!addNewPreferMode ? (
            <button
              disabled={isDragingUseDisableButton}
              className="okBtn"
              onClick={() => switchToForm()}
            >
              <Ripple color="#484848" duration={300} />
              <span>{t('A036')}</span>
            </button>
          ) : (
            <button className="okBtn" onClick={() => setPredictDisplay(true)}>
              <Ripple color="#484848" duration={300} />
              <span>{t('A036')}</span>
            </button>
          )}
        </div>
        <div className={`predictResult ${predictDisplay ? 'active' : 'inActive'}`}>
          {!addNewPreferMode ? (
            <div
              className="predictResult__itemContainer"
              style={predictResult.length !== 0 ? { display: 'none' } : null}
            >
              <div
                className={`predictResult__headerItem ${historyDisplay ? 'active' : ''}`}
                onClick={() => expendList('history')}
              >
                <div className="headerIcon">
                  <i className="icon-history-4" aria-hidden="true" />
                </div>
                <span>{t('A032')}</span>
              </div>
              <div
                className={`predictResult__headerItem ${homeDisplay ? 'active' : ''}`}
                onClick={() => expendList('home')}
              >
                <div className="headerIcon">
                  <i className="icon-home" aria-hidden="true" />
                </div>
                <span>{t('A033')}</span>
              </div>
              <div
                className={`predictResult__headerItem ${preferDisplay ? 'active' : ''}`}
                onClick={() => expendList('prefer')}
              >
                <div className="headerIcon">
                  <i className="icon-heart-3" aria-hidden="true" />
                </div>
                <span>{t('A034')}</span>
              </div>
              <div className="predictResult__slider"></div>
            </div>
          ) : null}
          {addNewPreferMode ? (
            <div
              className="predictResult__itemContainer"
              style={isValidAddress ? { order: 999 } : { display: 'none' }}
              onClick={() => prefixAddOrEdit()}
            >
              <div className="editActive">完成</div>
            </div>
          ) : null}
          <div ref={predictListRef}>
            {!(addNewPreferMode && isValidAddress) &&
            !(preferDisplay || homeDisplay || historyDisplay) &&
            predictResult &&
            predictResult.length !== 0
              ? predictResult.map((r, i) => (
                  <AddressItem
                    key={i}
                    index={i}
                    lat={r.latitude}
                    lng={r.longitude}
                    type="predict"
                    description={r.full_address}
                    main={r.name}
                    secondary={r.full_address}
                    placeId="predict"
                    onClick={(e) => predictHandler(e)}
                  >
                    <i className="icon-marker" />
                  </AddressItem>
                ))
              : null}
            {preferDisplay && preferResult && preferResult.length !== 0
              ? preferResult.map((r, i) =>
                  r.memo !== '公司' ? (
                    <AddressItem
                      key={i}
                      index={i}
                      lat={r.lat}
                      lng={r.lon}
                      type="prefer"
                      description={r.address}
                      main={r.memo}
                      secondary={r.address}
                      placeId="prefer"
                      onClick={(e) => predictHandler(e)}
                    >
                      <i className="icon-heart-2" />
                    </AddressItem>
                  ) : (
                    <AddressItem
                      key={i}
                      index={i}
                      lat={r.lat}
                      lng={r.lon}
                      type="prefer"
                      description={r.address}
                      main={r.memo}
                      secondary={r.address}
                      placeId="prefer"
                      onClick={(e) => predictHandler(e)}
                    >
                      <i className="icon-company-2" />
                    </AddressItem>
                  )
                )
              : null}
            {homeDisplay && homeResult && homeResult.length !== 0
              ? homeResult.map((r, i) => (
                  <AddressItem
                    key={i}
                    index={i}
                    lat={r.Latitude}
                    lng={r.Longitude}
                    type="prefer"
                    description={r.FormatedAddress}
                    main={r.Community}
                    secondary={r.FormatedAddress}
                    placeId="home"
                    onClick={(e) => predictHandler(e)}
                  >
                    <i className="icon-home-2" />
                  </AddressItem>
                ))
              : null}
            {!addNewPreferMode &&
              historyDisplay &&
              processedHistoryResult.map((r, i) =>
                i < 11 ? (
                  <AddressItem
                    key={i}
                    index={i}
                    type="history"
                    lat={r.lat}
                    lng={r.lng}
                    description={r.address}
                    main={r.mainText}
                    secondary={r.address}
                    placeId="history"
                    onClick={(e) => predictHandler(e)}
                    addToPrefer={(e) => addToPrefer(r, i, e)}
                    isPrefer={r.isPrefer}
                  >
                    <i className="icon-history-2" />
                  </AddressItem>
                ) : null
              )}
            {addNewPreferMode &&
              (predictResult === null || predictResult.length === 0) &&
              !isValidAddress &&
              processedHistoryResult.map((r, i) =>
                !r.isPrefer ? (
                  <AddressItem
                    key={i}
                    index={i}
                    type="history"
                    lat={r.lat}
                    lng={r.lng}
                    description={r.address}
                    main={r.mainText}
                    secondary={r.address}
                    placeId="history"
                    onClick={(e) => predictHandler(e)}
                  >
                    <i className="icon-history-2" />
                  </AddressItem>
                ) : null
              )}
            {(!addNewPreferMode || !isValidAddress) && predictResult.length !== 0 ? (
              // 只在搜尋列表顯示時，才出現地圖選擇按鈕 (因成本考量目前先移除地圖選擇功能)
              <div
                style={{ display: 'none' }}
                className="predictResult__itemContainer"
                onClick={() => choosePositionByMap()}
              >
                <Ripple />
                <div className="predictResult__icon">
                  <i className="icon-map-choice-2" aria-hidden="true" />
                </div>
                <div className="predictResult__item" key="mapMode">
                  {t('A035')}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <Modali.Modal {...overAreaModal} />
      <Modali.Modal {...generalErrorModal}>
        <p className="general-modal__text">{generalModalErrorMsg}</p>
      </Modali.Modal>
      <Modali.Modal {...gpsModal} />
      <Modali.Modal {...checkFareModal} />

      <Modali.Modal {...addToPreferModal}>
        <span className="inputItem__span">{addToPreferModalBody}</span>
        <input
          maxLength="10"
          className="inputItem__input"
          placeholder={preferPlaceholder}
          value={addToPreferModalNameText}
          onChange={(e) => setAddToPreferModalNameText(e.target.value)}
        />
      </Modali.Modal>
      <Modali.Modal {...addModal} />
      <Modali.Modal {...deleteModal} />
    </div>
  );
}

const AddressItem = ({
  index = 1,
  type,
  lat = 0,
  lng = 0,
  description,
  main,
  secondary,
  placeId,
  onClick,
  children,
  isPrefer,
  addToPrefer = undefined,
}) => {
  return (
    <div
      key={index}
      className="predictResult__itemContainer"
      data-type={type}
      data-lat={lat}
      data-lng={lng}
      data-description={description}
      data-main={main}
      data-id={placeId}
      data-secondary={secondary}
      onClick={onClick}
    >
      <Ripple />
      <div className="predictResult__icon">{children}</div>
      <div className="predictResult__item">
        <p className="mainText">{main}</p>
        <p className="secondaryText">{secondary}</p>
      </div>
      {addToPrefer ? (
        <div
          className={`predictResult__isPrefer ${isPrefer ? 'active' : ''}`}
          onClick={addToPrefer}
        >
          <i className="icon-heart" />
        </div>
      ) : null}
    </div>
  );
};

export default Map;
//useScript custom hooks from the site
let cachedScripts = [];

function useScript(src) {
  // Keeping track of script loaded and error state
  const [state, setState] = useState({
    loaded: false,
    error: false,
  });
  useEffect(
    () => {
      // If cachedScripts array already includes src that means another instance ...
      // ... of this hook already loaded this script, so no need to load again.
      if (cachedScripts.includes(src)) {
        setState({
          loaded: true,
          error: false,
        });
      } else {
        cachedScripts.push(src);
        // Create script
        let script = document.createElement('script');
        // async defer
        script.src = src;
        script.async = true;
        // Script event listener callbacks for load and error
        const onScriptLoad = () => {
          setState({
            loaded: true,
            error: false,
          });
        };
        const onScriptError = () => {
          // Remove from cachedScripts we can try loading again
          const index = cachedScripts.indexOf(src);
          if (index >= 0) cachedScripts.splice(index, 1);
          script.remove();
          setState({
            loaded: true,
            error: true,
          });
        };
        script.addEventListener('load', onScriptLoad);
        script.addEventListener('error', onScriptError);
        // Add script to document body
        document.body.appendChild(script);
        // Remove event listeners on cleanup
        return () => {
          script.removeEventListener('load', onScriptLoad);
          script.removeEventListener('error', onScriptError);
        };
      }
    },
    [src] // Only re-run effect if script src changes
  );
  return [state.loaded, state.error];
}
