import { useState, useEffect, useContext } from 'react';
import './AdBanner.scss';
import { FirebaseContext } from '../utils/firebase';

export const useAdBanner = () => {
  const firebase = useContext(FirebaseContext);
  // * dev 會優先採用 test 的設定, 如果失效的話才會使用 prod 的
  const isTest = process.env.REACT_APP_ENV === 'development';
  const ref = firebase.database();
  const [clickUrl, setClickUrl] = useState('');
  const [backgroundImage, setBackgroundImage] = useState('');
  const [bannerStyle, setBannerStyle] = useState({
    height: '44px',
    backgroundImage: 'none',
  });
  const [isShow, setIsShow] = useState(false);
  // * 註冊 Firebase 提示視窗監聽
  useEffect(() => {
    ref.ref(`TaxiNotification/banner`).on('value', (snapshot) => {
      // * firebase 提供四種參數
      // * development 環境採用測試數據
      const target = isTest ? snapshot.val().test : snapshot.val();
      const { maxHeight, imageUrl, open = false, url = '' } = target || {};
      setIsShow(open);
      setClickUrl(url);
      setBackgroundImage(imageUrl);
      setBannerStyle({
        maxHeight: maxHeight ? maxHeight : undefined,
      });
    });
  }, []);

  const handleClickBanner = () => {
    clickUrl && window.location.replace(clickUrl);
  };

  const AdBannerComponent = isShow && (
    <div className="banner" onClick={handleClickBanner} style={bannerStyle}>
      <img src={backgroundImage} />
    </div>
  );

  return {
    AdBannerComponent,
  };
};
