import Modali, { useModali } from './Modal';
import './AlertPopupModal.scss';

interface AlertPopupModalProps {
  imgSrc: string;
  imgAlt: string;
  content: React.ReactNode;
  title: string;
  buttonText: string;
}

export const useAlertPopupModal = ({
  imgSrc,
  imgAlt,
  content,
  title,
  buttonText,
}: AlertPopupModalProps) => {
  const [alertPopupModal, toggleAlertPopupModal] = useModali({
    animated: true,
    overlayClose: false,
    medium: true,
  });

  const ModalComponent = (
    <Modali.Modal {...alertPopupModal}>
      <div className="alert-popup-modal-img">
        <img loading="lazy" src={imgSrc} alt={imgAlt} />
      </div>
      <div className="alert-popup-modal-title">
        <p>{title}</p>
      </div>
      <div className="alert-popup-modal-content">{content}</div>
      <div className="alert-popup-modal-footer">
        <button
          className="alert-popup-modal-footer__button"
          onClick={() => toggleAlertPopupModal()}
        >
          {buttonText}
        </button>
      </div>
    </Modali.Modal>
  );

  return {
    toggleAlertPopupModal,
    alertPopupModal: ModalComponent,
  };
};
