import { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { getParams } from 'utils';
import ReactLoading from 'react-loading';
export default function DriverAccidentPage(props) {
  const history = useHistory();
  const cancelSource = getParams('cancelSource') || '';
  const showContentText = useMemo(() => {
    if (['21', '25'].includes(cancelSource)) {
      return '很抱歉，司機在前往途中出了些狀況,若仍有叫車需求請點擊下方按鈕重新叫車';
    } else if (cancelSource === '22') {
      return '很抱歉，司機已抵達上車地點且等候您超過3分鐘故取消行程，若仍有叫車需求請點擊下方按鈕重新叫車';
    } else {
      return '很抱歉，司機在前往途中出了些狀況，點擊下方按鈕，我們將重新安排司機盡快前往。';
    }
  }, [cancelSource]);

  const showContentTips = useMemo(() => {
    if (['21', '22'].includes(cancelSource)) {
      return '本次將向您收取消費用40元，系統最晚將於三個工作天內退回其他預收車資';
    }
    return '';
  }, [cancelSource]);

  useEffect(() => {
    props.setHistoryResult((oldArray) => {
      if (oldArray.length !== 0 && oldArray[0].isCancel && oldArray[0].cancelSource === 'fake-1') {
        oldArray.shift();
        return [...oldArray];
      } else {
        return oldArray;
      }
    });
  }, []);

  const backToHomePage = () => {
    props.setOrderPlaceDetail();
    history.push('/');
  };
  return (
    <div className="statusPage">
      <div className="statusPage-header">
        <span>車隊已取消本次行程</span>
        <p>{showContentText}</p>
      </div>
      <div className="statusPage-body">
        <div className="statusPage-img rwd-scale rwd-driver-accident">
          <div className="statusPage-asset statusPage-driver-accident-board" />
          <div className="statusPage-asset statusPage-driver-accident" />
          <div className="statusPage-asset statusPage-driver-accident-sorry" />
        </div>
      </div>
      <div className="statusPage-tips">
        <p className="warning-tips">{showContentTips}</p>
      </div>
      <div className="statusPage-footer">
        {!props.isOnlinePay && (
          <button className="btn-fill" onClick={props.callTaxi}>
            {props.callTaxiLock ? (
              <div style={{ width: 0, transform: 'translateX(-25px)' }}>
                <ReactLoading type="spokes" color={'#fff'} height={20} width={20} />
              </div>
            ) : null}
            <span>相同條件，再搜一次</span>
          </button>
        )}
        <button className="btn-default" onClick={backToHomePage}>
          返回首頁，重新叫車
        </button>
      </div>
    </div>
  );
}
