import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export default function DriverCancelPage(props) {
  const history = useHistory();

  useEffect(() => {
    props.setHistoryResult((oldArray) => {
      if (oldArray.length !== 0 && oldArray[0].isCancel && oldArray[0].cancelSource === 'fake-1') {
        oldArray.shift();
        return [...oldArray];
      } else {
        return oldArray;
      }
    });
  }, []);

  const backToHomePage = () => history.push('/');

  return (
    <div className="statusPage">
      <div className="statusPage-header">
        <span>司機端系統異常</span>
        <p>
          {'很抱歉，目前司機端系統出了些問題，我們正在為您努力排除，如有任何問題歡迎'}

          <a
            onClick={() =>
              window.location.assign(
                `https://inapp.kingnetsmart.com.tw/ad.html?page=linkRule&params1=7&params2=https%3A%2F%2Flin.ee%2FappSyju&title=返回社區叫車&referrer=${encodeURIComponent(
                  process.env.REACT_APP_API_ENDPOINT
                )}`
              )
            }
          >
            聯絡我們
          </a>
          {'。'}
        </p>
      </div>
      <div className="statusPage-body">
        <div className="statusPage-img rwd-scale">
          <div className="statusPage-asset statusPage-driver-error" />
          <div className="statusPage-asset statusPage-driver-error-info" />
        </div>
      </div>
      <div className="statusPage-footer">
        <button className="btn-fill" onClick={backToHomePage}>
          確認
        </button>
      </div>
    </div>
  );
}
