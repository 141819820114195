import { createContext, useReducer } from 'react';
export const placeContext = createContext({});

// reducer
export const UPDATE_ORIGIN_PLACE = 'UPDATE_ORIGIN_PLACE';
export const UPDATE_ORIGIN_PLACE_LATLNG = 'UPDATE_ORIGIN_PLACE_LATLNG';
export const UPDATE_DESTINATION_PLACE = 'UPDATE_DESTINATION_PLACE';
export const UPDATE_DESTINATION_PLACE_LATLNG = 'UPDATE_DESTINATION_PLACE_LATLNG';
export const RESET_PLACE = 'RESET_PLACE';

function formatPlaceData(prefix) {
  const PREFIX_PLACE = `${prefix}Place`;
  return {
    [`${PREFIX_PLACE}`]: '',
    [`${PREFIX_PLACE}Display`]: '',
    [`${PREFIX_PLACE}Id`]: '',
    [`${PREFIX_PLACE}Lat`]: 0,
    [`${PREFIX_PLACE}Lng`]: 0,
  };
}

// 到時候如果分main 和 sub就deep clone
const reducer = (state, action) => {
  switch (action.type) {
    case UPDATE_ORIGIN_PLACE:
      //maybe 檢查格式 ??
      return {
        ...state,
        originPlace: action.payload.place,
        originPlaceDisplay: action.payload.placeDisplay,
        originPlaceId: action.payload.placeId,
        originPlaceTag: action.payload.placeTag,
      };
    case UPDATE_ORIGIN_PLACE_LATLNG:
      return {
        ...state,
        originPlaceLat: action.payload.lat,
        originPlaceLng: action.payload.lng,
      };
    case UPDATE_DESTINATION_PLACE:
      //maybe 檢查格式 ??
      return {
        ...state,
        destinationPlace: action.payload.place,
        destinationPlaceDisplay: action.payload.placeDisplay,
        destinationPlaceId: action.payload.placeId,
        destinationPlaceTag: action.payload.placeTag,
      };
    case UPDATE_DESTINATION_PLACE_LATLNG:
      return {
        ...state,
        destinationPlaceLat: action.payload.lat,
        destinationPlaceLng: action.payload.lng,
      };
    case RESET_PLACE:
      return {
        ...formatPlaceData('origin'),
        ...formatPlaceData('destination'),
      };
    default:
      return state;
  }
};

export const Place = (props) => {
  const [place, dispatch] = useReducer(reducer, {
    ...formatPlaceData('origin'),
    ...formatPlaceData('destination'),
  });
  return (
    <placeContext.Provider value={{ place, dispatch }}>{props.children}</placeContext.Provider>
  );
};
