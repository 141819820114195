import { useState, useEffect, useRef } from 'react';
import Modali, { useModali } from '../components/Modal';
import * as api from '../api';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { useTranslation } from 'react-i18next';
import arrayMove from 'array-move';
// Modal need
export default function PreferPage(props) {
  const [originPreferResult, setOriginPreferResult] = useState([]);
  const { t } = useTranslation();
  const listEl = useRef(null);
  const [isSort, setIsSort] = useState(false);

  useEffect(() => {
    setOriginPreferResult(props.preferResult);
    window.addEventListener('popstate', function () {
      if (window.localStorage.getItem('pageStatus') === 'sortTab') {
        window.history.pushState('tab', null, `./sort`);
        toggleEndSortModal();
      }
    });
    return () => clearAllBodyScrollLocks();
  }, []);
  // drawer
  const [drawerModal, toggleDrawerModal] = useModali({
    isDrawer: true,
    animated: true,
    buttons: [
      <Modali.Button
        key="ModaliButton"
        label={t('003')}
        isStyleDestructive
        onClick={() => toggleDrawerModal()}
      />,
    ],
  });
  // delete modal
  const [deleteItem, setDeleteItem] = useState('');
  const [deleteModal, toggleDeleteModal] = useModali({
    animated: true,
    title: t('A014'),
    buttons: [
      <Modali.Button
        key="ModaliButton"
        label={t('003')}
        isTwoButton
        onClick={() => toggleDeleteModal()}
      />,
      <Modali.Button
        key="ModaliButton"
        label={t('002')}
        isStyleDestructive
        isTwoButton
        onClick={() => deleteModalConfirm()}
      />,
    ],
  });
  // sort end modal
  const [endSortModal, toggleEndSortModal] = useModali({
    animated: true,
    message: t('A066'),
    buttons: [
      <Modali.Button
        key="ModaliButton"
        label={t('003')}
        isTwoButton
        onClick={() => endSortModalCancel()}
      />,
      <Modali.Button
        key="ModaliButton"
        label={t('002')}
        isStyleDestructive
        isTwoButton
        onClick={() => endSortModalConfirm()}
      />,
    ],
  });
  // input modal
  const [inputModalNameText, setInputModalNameText] = useState('');
  const [inputModalTitle, setInputModalTitle] = useState('編輯地點名稱');
  const [inputModalBody, setInputModalBody] = useState(t('004'));
  const [inputModal, toggleInputModal] = useModali({
    title: inputModalTitle,
    animated: true,
    buttons: [
      <Modali.Button
        key="ModaliButton"
        label={t('003')}
        isTwoButton
        onClick={() => toggleInputModal()}
      />,
      <Modali.Button
        key="ModaliButton"
        label={t('002')}
        isStyleDestructive
        isTwoButton
        onClick={() => inputModalConfirm()}
      />,
    ],
  });
  const [currentKey, setCurrentKey] = useState({});

  function endSortModalConfirm() {
    setIsSort(false);
    window.localStorage.setItem('pageStatus', 'mainTab');
    window.history.back();
    toggleEndSortModal();
  }

  function endSortModalCancel() {
    toggleEndSortModal();
  }

  function deleteModalConfirm() {
    api.kingnetTaxiUserDeleteAddress({ id: currentKey.id }).then(() => {
      const result = props.preferResult.filter((e) => e.id !== currentKey.id);
      props.setPreferResult(result);
      setOriginPreferResult(result);
    });
    toggleDeleteModal();
  }

  function inputModalConfirm() {
    if (inputModalNameText === '') {
      setInputModalTitle('設立地點名稱');
      setInputModalBody(t('020'));
      return;
    }
    const inspect = props.preferResult.find((e) => e.memo === inputModalNameText);
    if (inspect === undefined) {
      const target = {
        ...currentKey,
        memo: inputModalNameText,
      };
      api
        .kingnetTaxiUserUpdateAddress({
          addresses: [target],
        })
        .then(() => {
          const result = props.preferResult.filter((e) => e.id !== currentKey.id);
          result.push(target);
          props.setPreferResult(result);
          setOriginPreferResult(result);
        });
      toggleInputModal();
    } else {
      setInputModalTitle('名稱重複');
      setInputModalBody(t('005'));
    }
  }
  const moreBtnHandle = (e) => {
    setCurrentKey(e);
    toggleDrawerModal();
    setInputModalNameText(e.memo);
    setDeleteItem(e.memo);
  };
  const companyDeleteBtnHandle = (e) => {
    setCurrentKey(e);
    setDeleteItem(e.memo);
    toggleDeleteModal();
  };
  const deleteAddress = () => {
    toggleDrawerModal();
    toggleDeleteModal();
  };
  const sortAddress = () => {
    window.history.pushState('tab', null, `./sort`);
    window.localStorage.setItem('pageStatus', 'sortTab');
    toggleDrawerModal();
    setIsSort(true);
    disableBodyScroll(listEl, {
      allowTouchMove: (el) => {
        while (el && el !== document.body) {
          if (el.getAttribute('body-scroll-lock-ignore') !== null) {
            return true;
          }

          el = el.parentNode;
        }
      },
    });
  };
  const sortStartHandler = () => {
    // disableBodyScroll(listEl)
  };
  const sortEndHandler = ({ oldIndex, newIndex }) => {
    setOriginPreferResult((preferResult) => arrayMove(preferResult, oldIndex, newIndex));
  };
  const confirmSortEnd = () => {
    clearAllBodyScrollLocks();
    setIsSort(false);
    window.localStorage.setItem('pageStatus', 'mainTab');
    let result = originPreferResult.map((e, i) => ({ ...e, sort: i }));
    props.preferResult.map((e, i) => {
      if (e.id !== result[i].id) {
        let found = result.find((element) => element.id === e.id);
        api
          .kingnetTaxiUserUpdateAddress({ addresses: [found] })
          .then(() => {
            props.setLogListResult((pre) => [...pre, 'sort OK']);
          })
          .catch((err) => {
            console.log(err);
            props.setLogListResult((pre) => [...pre, 'sort ERR']);
          });
      }
      return e;
    });
    props.setPreferResult(result);
  };
  return (
    <div className="list">
      {props.preferResult.length === 0 ? (
        <div className="nodata nodata__preferlist">
          <div />
          <p>{t('A010')}</p>
        </div>
      ) : null}
      <div className="listItem__header">
        <i className="icon-heart-2" aria-hidden="true" />
        <span>{t('A005')}</span>
        {isSort ? (
          <div className="sortokBtn" onClick={() => confirmSortEnd()}>
            {t('A016')}
          </div>
        ) : null}
      </div>
      <div className="listItem__scrollBox" ref={listEl}>
        <SortableList
          items={originPreferResult}
          isSort={isSort}
          Handler={moreBtnHandle}
          onSortStart={sortStartHandler}
          onSortEnd={sortEndHandler}
          CompanyDel={companyDeleteBtnHandle}
          lockToContainerEdges={true}
          useDragHandle
          lockOffset={['1%', '101%']}
          lockAxis="y"
        />
      </div>
      {!isSort ? <div className="addNewPrefer" onClick={props.addNewPrefer}></div> : null}
      <Modali.Modal {...drawerModal}>
        <div className="drawerModal__button" onClick={() => props.addNewPrefer(currentKey)}>
          {t('A012')}
        </div>
        <div className="drawerModal__button" onClick={() => sortAddress()}>
          {t('A013')}
        </div>
        <div className="drawerModal__button" onClick={() => deleteAddress()}>
          {t('A014')}
        </div>
      </Modali.Modal>
      <Modali.Modal {...deleteModal}>
        <div className="deleteItem">
          <span>請確定是否刪除{deleteItem} ?</span>
          <span>( 這將從常用地點移除此位置 )</span>
        </div>
      </Modali.Modal>
      <Modali.Modal {...endSortModal}></Modali.Modal>
      <Modali.Modal {...inputModal}>
        <span className="inputItem__span">{inputModalBody}</span>
        <input
          maxLength="10"
          className="inputItem__input"
          value={inputModalNameText}
          onChange={(e) => setInputModalNameText(e.target.value)}
        />
      </Modali.Modal>
    </div>
  );
}
const SortableItem = SortableElement(({ address, onClick, isSort, icon }) => (
  <div className="listItem__container">
    <div className="listItem__icon">{icon}</div>
    <div className="listItem__item" style={{ pointerEvents: 'none' }}>
      <p className="mainText">{address ? address.memo : 'unknown'}</p>
      <p className="secondaryText">{address ? address.address : 'unknown'}</p>
    </div>
    {isSort ? <DragHandle /> : <div className="moreBtn" onClick={onClick} />}
  </div>
));
const SortableList = SortableContainer(({ items, Handler, isSort }) => {
  return (
    <div body-scroll-lock-ignore="true">
      {items.map((val, i) => (
        <SortableItem
          key={`item-${i}`}
          isSort={isSort}
          index={i}
          address={val}
          onClick={() => Handler(val, i)}
          icon={
            <i
              className={val.memo !== '公司' ? 'icon-heart-2' : 'icon-company-2'}
              aria-hidden="true"
            />
          }
        />
      ))}
    </div>
  );
});
const DragHandle = SortableHandle(() => (
  <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
    <div className="sortBtn">
      <div />
    </div>
  </div>
));
