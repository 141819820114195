import axios from 'axios';
import { parseApiStatus, ToCapitalize } from '../utils/unit';
import { CommentModal, KnstData } from './model/BEModel';
import { CreateOrderCommentBff, CustomerGetInfoReply, UserActionRecordData } from './model/FEModel';

const ecsApiRequest = axios.create({
  baseURL: process.env.REACT_APP_ECS_API,
});

const taxiApiRequest = axios.create({
  baseURL: process.env.REACT_APP_TAXI_API,
});

export const setAuthHeader = (token) => {
  taxiApiRequest.defaults.headers.common['Authorization'] = token;
};

/**
 * Swagger {@link https://taxi-api-dev.kingnetsmart.com.tw/index.html#/Token/post_api_KingnetTaxi_Token}
 */
export const kingnetTaxiToken = (data) => taxiApiRequest.post('/api/KingnetTaxi/Token', data); // Token: string
/**
 * Swagger {@link https://taxi-api-dev.kingnetsmart.com.tw/index.html#/User/get_api_KingnetTaxi_User_Profile}
 */
export const getKingnetTaxiUserProfile = () => taxiApiRequest.get('/api/KingnetTaxi/User/Profile');
/**
 * Swagger {@link https://taxi-api-dev.kingnetsmart.com.tw/index.html#/User/get_api_KingnetTaxi_User_Tablet}
 */
export const getKingnetTaxiUserTablet = () => taxiApiRequest.get('/api/KingnetTaxi/User/Tablet');

// About call taxi
/**
 * Swagger {@link https://taxi-api-dev.kingnetsmart.com.tw/index.html#/User/post_api_KingnetTaxi_User_Call_V2}
 */
export const kingnetTaxiUserCall = (data) =>
  taxiApiRequest.post('/api/KingnetTaxi/User/Call_V3', data);
/**
 * Swagger {@link https://taxi-api-dev.kingnetsmart.com.tw/index.html#/User/post_api_KingnetTaxi_User_Cancel}
 */
export const kingnetTaxiUserCancel = (data) =>
  taxiApiRequest.post('/api/KingnetTaxi/User/Cancel', data);

// ! Rating
/**
 * Swagger {@link https://taxi-api-dev.kingnetsmart.com.tw/index.html#/User/get_api_KingnetTaxi_User_SearchCommentOption}
 */
const commentModalBff = (res) => {
  return {
    data: {
      Data: res.data.Data.map(({ tagList, ...rest }) => ({
        ...rest,
        tag: tagList.map((tag) => ({
          id: tag.id,
          commentId: tag.comment_option_id,
          name: tag.tag_name,
          subTitle: tag.type_name,
          rate: tag.tag_star,
          sort: tag.tag_sort,
          subTitleSort: tag.type_sort,
        })),
      })),
    },
  };
};
export const kingnetTaxiUserSearchCommentOption = () =>
  parseApiStatus(
    taxiApiRequest.get<KnstData<Array<CommentModal>>>('/api/KingnetTaxi/User/SearchCommentOption'),
    commentModalBff
  );

/**
 * Swagger {@link https://taxi-api-dev.kingnetsmart.com.tw/index.html#/User/post_api_KingnetTaxi_User_CreateOrderComment}
 */
const handleCreateOrderCommentParams = (data: CreateOrderCommentBff) => {
  const { id, addition, tag, ...omitData } = data;
  return {
    ...omitData,
    feedback: addition,
    comment_option_id: id,
    commentTagList: tag.map(([tag_name, type_name]) => ({ tag_name, type_name })),
  };
};
export const kingnetTaxiUserCreateOrderComment = (data: CreateOrderCommentBff) =>
  parseApiStatus(
    taxiApiRequest.post(
      '/api/KingnetTaxi/User/CreateOrderComment',
      handleCreateOrderCommentParams(data)
    )
  );
/*{
  "comment_option_id": 2,
  "guid": "b700deab-b8b9-4927-8c29-f1a458359124",
  "score": 5
}*/

/**
 * Swagger {@link https://taxi-api-dev.kingnetsmart.com.tw/index.html#/User/get_api_KingnetTaxi_User_SearchCommonAddress}
 */
export const kingnetTaxiUserSearchCommonAddress = () =>
  taxiApiRequest.get('/api/KingnetTaxi/User/SearchCommonAddress');

/**
 * Swagger {@link https://taxi-api-dev.kingnetsmart.com.tw/index.html#/User/put_api_KingnetTaxi_User_Address}
 */
export const kingnetTaxiUserUpdateAddress = (data) =>
  taxiApiRequest.put('/api/KingnetTaxi/User/Address', data);

/**
 * Swagger {@link https://taxi-api-dev.kingnetsmart.com.tw/index.html#/User/post_api_KingnetTaxi_User_Address}
 */
export const kingnetTaxiUserPostAddress = (data) =>
  taxiApiRequest.post('/api/KingnetTaxi/User/Address', data);

/**
 * Swagger {@link https://taxi-api-dev.kingnetsmart.com.tw/index.html#/User/delete_api_KingnetTaxi_User_Address}
 */
export const kingnetTaxiUserDeleteAddress = (data) =>
  taxiApiRequest.delete('/api/KingnetTaxi/User/Address', { data: data });

/**
 * Swagger {@link https://taxi-api-dev.kingnetsmart.com.tw/index.html#/User/get_api_KingnetTaxi_User_GetUserOrderHistory}
 */
export const kingnetTaxiUserSearchOrders = ({ MaxCount }) => {
  return taxiApiRequest.get(`/api/KingnetTaxi/User/GetUserOrderHistory`, {
    params: {
      Offset: 0,
      MaxCount,
    },
  }); // GetUserOrderHistory
};

/**
 * Swagger {@link https://taxi-api-dev.kingnetsmart.com.tw/index.html#/User/delete_api_KingnetTaxi_User_DeleteKingnetSmartTaxiFirebase}
 */
export const kingnetTaxiDeleteKingnetSmartTaxiFirebase = (data) =>
  taxiApiRequest.delete('/api/KingnetTaxi/User/DeleteKingnetSmartTaxiFirebase', { data: data });

/**
 * Swagger {@link https://taxi-api-dev.kingnetsmart.com.tw/index.html#/User/get_api_KingnetTaxi_User_SearchOrders}
 */
export const kingnetTaxiGUIDSearchOrders = ({
  guid,
  MaxCount,
  status,
}: {
  guid?: string;
  MaxCount: number;
  status?: number;
}) => {
  return taxiApiRequest.get(`/api/KingnetTaxi/User/SearchOrders`, {
    params: {
      Offset: 0,
      guid,
      MaxCount,
      status,
    },
  });
};

const handleInsertCustomerGetInfoReply = (data) => ToCapitalize(data);

export const kingnetTaxiInsertCustomerGetInfoReply = (data: CustomerGetInfoReply) =>
  parseApiStatus(
    taxiApiRequest.post(
      '/api/KingnetTaxi/User/InsertCustomerGetInfoReply',
      handleInsertCustomerGetInfoReply(data)
    )
  );

/**
 * Swagger {@link https://ecs-dev.kingnetsmart.com.tw/index.html#/Regions/get_api_Regions_GetCityByCoordinate}
 */
export const kingnetTaxiGetCityByCoordinate = ({ lat, lng }) => {
  const reqStr = `?lat=${lat}&lng=${lng}`;
  return ecsApiRequest.get(`/api/Regions/GetCityByCoordinate${reqStr}`);
};
export const kingnetTaxiRecord = (data: UserActionRecordData) =>
  parseApiStatus(taxiApiRequest.post('/api/KingnetTaxi/User/InsertApiCostRecord', data));
