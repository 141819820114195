import React from 'react';

import './PaymentDetail.scss';

import { TripPaymentInfo } from 'types/trip';

interface PaymentDetailProps {
  paymentInfo: TripPaymentInfo;
}

export const PaymentDetail: React.FC<PaymentDetailProps> = ({ paymentInfo }) => {
  if (!paymentInfo.amount && !paymentInfo.refundAmount && !paymentInfo.additionalAmount)
    return null;
  return (
    <div className="history-detail-block history-payment-detail">
      <p className="history-detail-block__title">付款詳情</p>
      <div className="history-detail-block__content">
        {paymentInfo.amount > 0 && paymentInfo.formattedTime && (
          <div className="history-payment-detail__block">
            <div className="history-payment-detail__item">
              <p>付款金額</p>
              <p>${paymentInfo.amount}</p>
            </div>
            <p className="history-payment-detail__desc">{paymentInfo.formattedTime}</p>
          </div>
        )}
        {paymentInfo.additionalAmount > 0 && (
          <div className="history-payment-detail__block">
            <div className="history-payment-detail__item">
              <p>應付金額</p>
              <p className="primary">${paymentInfo.additionalAmount}</p>
            </div>
            <p className="history-payment-detail__desc">
              因行程費用異動，請重新付款。付款後前筆金額將立即退回。
            </p>
          </div>
        )}
        {paymentInfo.refundAmount > 0 && (
          <div className="history-payment-detail__block">
            <div className="history-payment-detail__item">
              <p>應退金額</p>
              <p className={paymentInfo.refundTime ? '' : 'primary'}>${paymentInfo.refundAmount}</p>
            </div>
            <p className="history-payment-detail__desc">
              {paymentInfo.refundTime
                ? `${paymentInfo.refundTime} 已退款`
                : '應退金額最晚將於三個工作天內完成退款'}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
