import { useState, useEffect, useCallback, ReactNode, FC } from 'react';
import './BottomPopup.scss';
import { useDiscountInputFocus } from 'context/DiscountInputFocusProvider';

interface BottomPopupProps {
  isOpen: boolean;
  height?: string;
  customStyle?: React.CSSProperties;
  title?: string;
  startBottom?: string;
  onClose?: () => void;
  children: ReactNode;
}

const BottomPopup: FC<BottomPopupProps> = ({
  height,
  title,
  customStyle,
  children,
  startBottom = '-100%',
  isOpen,
  onClose,
}) => {
  const { discountInputFocus } = useDiscountInputFocus();

  const startBottomValue = startBottom.endsWith('px')
    ? parseInt(startBottom, 10)
    : (parseFloat(startBottom) * window.innerHeight) / 100;

  const heightValue =
    height && height.endsWith('px')
      ? parseInt(height, 10)
      : (parseFloat(height || '100') * window.innerHeight) / 100;

  const defaultTransition = 'all 0.3s cubic-bezier(0.17, 0.04, 0.03, 0.94)';

  const [transition, setTransition] = useState(defaultTransition);
  const [isRendered, setIsRendered] = useState(isOpen);
  const [transform, setTransform] = useState<string>(
    `translateY(${startBottomValue + heightValue}px)`
  );

  const handleClose = useCallback(() => {
    setTransform(`translateY(${startBottomValue + heightValue}px)`);
    onClose && onClose();
  }, [startBottomValue, heightValue, onClose]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    const cleanup = () => clearTimeout(timeoutId);

    if (!isOpen) {
      handleClose();
      timeoutId = setTimeout(() => setIsRendered(false), 300);
      return cleanup;
    }

    setIsRendered(true);
    timeoutId = setTimeout(() => setTransform('translateY(0)'), 20);

    return cleanup;
  }, [isOpen, handleClose]);

  useEffect(() => {
    if (discountInputFocus) {
      setTransform(`translateY(0px)`);
      setTransition('none');
    } else {
      setTimeout(() => {
        setTransition(defaultTransition);
      }, 500);
    }
  }, [discountInputFocus, heightValue, startBottomValue]);

  const style: React.CSSProperties = {
    bottom: startBottomValue,
    height,
    transform,
    transition,
    ...customStyle,
  };

  return isRendered ? (
    <>
      <div className={`bottom-popup__overlay ${isOpen ? 'open' : ''}`} onClick={handleClose} />
      <div style={style} className={`bottom-popup ${isOpen ? 'open' : ''}`}>
        <div className="bottom-popup__header">
          <div className="bottom-popup__close-btn" onClick={handleClose}>
            <i className="icon-close-circle"></i>
          </div>
          {title && <h2 className="bottom-popup__header__title">{title}</h2>}
        </div>
        <div className="bottom-popup__content">{children}</div>
      </div>
    </>
  ) : null;
};

export default BottomPopup;
