import React from 'react';

import './TripDetail.scss';

import Ripple from 'components/Ripple';

import { useTranslation } from 'react-i18next';

import { TransformedTrip, TripStatus } from 'types/trip';
import { TRIP_PAYMENT_METHOD } from 'constants/index';

interface TripDetailProps {
  tripDetail: TransformedTrip;
  onClick?: () => void;
}

export const TripDetail: React.FC<TripDetailProps> = ({ tripDetail, onClick }) => {
  const { t } = useTranslation();

  const canClick = !!onClick;
  const isOnlinePay = tripDetail.payMethod === TRIP_PAYMENT_METHOD.CREDIT_CARD;

  const isCompleted = tripDetail?.tripStatus?.status === TripStatus.Completed;

  function showDispatchPlate(history) {
    return history.dispatchPlate && history.tripStatus.status !== TripStatus.Cancelled;
  }

  if (!tripDetail) return null;

  return (
    <div className="history history-detail-block" onClick={onClick}>
      {canClick && <Ripple />}
      <div className="history__left">
        <div className="history__left--header">
          <span>{tripDetail.origin.formattedTime}</span>

          {tripDetail.comment && (
            <div className="history__left__star history__left__item">
              <i className="icon-star-full" aria-hidden="true" />
              <p>{tripDetail.comment}</p>
            </div>
          )}
        </div>
        <div className="history__left--body">
          {isOnlinePay ? (
            <div className="history__left__pay">
              <span className="amount">${tripDetail.tripTotalAmount}</span>
              {tripDetail.payAmount > 0 && (
                <>
                  <span className="divided">|</span>
                  <span className="method">{tripDetail.payMethod}</span>
                </>
              )}
            </div>
          ) : (
            <>{isCompleted && <span className="method">{tripDetail.payMethod}</span>}</>
          )}
          {showDispatchPlate(tripDetail) && (
            <div className="history__left__car-info">
              <div className="history__left__car-info__license">
                <span>車牌 {tripDetail.dispatchPlate}</span>
              </div>
            </div>
          )}
          <div className="history__left__address">
            <i className="icon-car" aria-hidden="true" />
            <span>{tripDetail.origin.address}</span>
          </div>
          <div className="history__left__down">
            <i className="icon-down" aria-hidden="true" />
          </div>
          <div className="history__left__location">
            <i className="icon-marker" aria-hidden="true" />
            <span>
              {tripDetail.destination && tripDetail.destination.address
                ? tripDetail.destination.address
                : t('A069')}
            </span>
          </div>
        </div>
      </div>
      <div className="history__right">
        <div className="history__right--header">
          <span className={`${tripDetail.tripStatus.color} history__right__status`}>
            {tripDetail.tripStatus.text}
          </span>
        </div>
        {canClick && (
          <div className="history__right--body">
            <div className="icon-container">
              <i className="icon-arrow-right"></i>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
