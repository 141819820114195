import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTappay } from '../../hooks/useTappay';

import { isNullOrUnDef } from 'utils/is';
import { isExpired, formatDiscountText } from 'utils';
import classNames from 'classnames';
import { useFetchDiscount } from 'hooks/useFetchDiscount';

import Modali, { useModali } from 'components/Modal';
import { setToastAppear } from 'components/Toast/Toast';
import { Button } from 'components/Button/Button';

import './creditCardPaymentPage.scss';

import TapPayLogo from 'assets/payment/TapPay_logo.png';

import * as taxiTripAPI from 'api/taxiTrip';
import { EstimatedFareItem, UberTripsInfo } from 'api/taxiTrip/model/FEModel';
import { AccountsReceivablesModel } from 'api/taxiTrip/model/BEModel';

interface CardInfoValidate {
  cardNumber: boolean | null;
  expirationDate: boolean | null;
  ccv: boolean | null;
}

interface CardLabelInputProps {
  label: string;
  displayWidth: string;
  isError: boolean;
  tappayFieldId: string;
  errorMessage: string;
}

const CardLabelInput = ({
  label,
  displayWidth,
  isError,
  tappayFieldId,
  errorMessage,
}: CardLabelInputProps) => {
  const [showInputErrorMsg, setShowInputErrorMsg] = useState(false);
  const containerClass = classNames({
    'card-detail__field': true,
    'card-detail__field--full': displayWidth === 'full',
    'card-detail__field--half': displayWidth === 'half',
  });

  useEffect(() => {
    const tappayField = document.getElementById(tappayFieldId);
    const observer = new MutationObserver(() => {
      setShowInputErrorMsg(tappayField?.classList.contains('card-detail__input--error') || false);
    });

    if (tappayField) {
      observer.observe(tappayField, { attributes: true, attributeFilter: ['class'] });
    }

    return () => observer.disconnect();
  }, [tappayFieldId]);

  return (
    <div className={containerClass}>
      <p className="field__title">{label}</p>
      <div
        id={tappayFieldId}
        className={`card-detail__input ${isError ? 'card-detail__input--error' : ''}`}
      ></div>
      {showInputErrorMsg && <p className="field__error">{errorMessage}</p>}
    </div>
  );
};

export const CreditCardPaymentPage = ({
  GUID,
  paymentOriginPage,
  setShouldRequestPosition,
  setEstimateFareTimeoutControl,
  setOrderPlaceDetail,
}) => {
  const history = useHistory();
  const { isTappayLoadedSuccess, cardSetup, onCardUpdate, getPrime } = useTappay();
  const [canPay, setCanPay] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRequestInProgress, setIsRequestInProgress] = useState(false);
  const [isRemember, setIsRemember] = useState(true);
  // about navigation
  const [isBlocking, setIsBlocking] = useState(true);
  const [validate, setValidate] = useState<CardInfoValidate>({
    cardNumber: null,
    expirationDate: null,
    ccv: null,
  });
  const [originTripInfo, setOriginTripInfo] = useState<UberTripsInfo>({});
  const [changedEstimateFare, setChangedEstimateFare] = useState<EstimatedFareItem>({
    productId: '',
    displayName: '',
    fareId: '',
    fare: 0,
    expiresUtc: '',
    cancelThresholdSecs: 0,
    minCalcelFee: 0,
  });
  const [estimatedFare, setEstimatedFare] = useState<Number>();

  const [confirmFareChangedInfo, setConfirmFareChangedInfo] = useState({
    originFare: 0,
    changedFare: 0,
    options: { discountShowText: '' },
  });
  const [confirmFareChangedModal, toggleConfirmFareChangedModal] = useModali({
    animated: true,
    title: '車資異動',
    overlayClose: false,
    buttons: [
      <Modali.Button
        key="ModaliButton"
        label="取消叫車"
        isTwoButton
        backgroundColor="var(--systemWhite)"
        onClick={() => handleCancelCallTaxi()}
      />,
      <Modali.Button
        key="ModaliButton"
        label="確認叫車"
        isStyleDestructive
        isTwoButton
        backgroundColor="var(--systemWhite)"
        onClick={() => handleCallTaxiPay()}
      />,
    ],
  });

  const [estimatedFareErrorMessage, setEstimatedFareErrorMessage] = useState<string>('');
  const [estimatedFareErrorModal, toggleEstimatedFareErrorModal] = useModali({
    animated: true,
    overlayClose: false,
    title: '連線異常',
    message: estimatedFareErrorMessage,
    buttons: [
      <Modali.Button
        key="ModaliButton"
        label="確認"
        isTwoButton
        isStyleDestructive
        onClick={() => toggleEstimatedFareErrorModal()}
      />,
    ],
  });
  const [confirmCancelCallTaxi, toggleConfirmCancelCallTaxiModal] = useModali({
    animated: true,
    title: '取消叫車',
    message: '是否要取消叫車？',
    buttons: [
      <Modali.Button
        key="ModaliButton"
        label="取消叫車"
        isTwoButton
        onClick={() => handleCancelCallTaxi()}
      />,
      <Modali.Button
        key="ModaliButton"
        label="繼續付款"
        isStyleDestructive
        isTwoButton
        onClick={() => handleCancelRouterBack()}
      />,
    ],
  });
  const [unpaidTripData, setUnpaidTripData] = useState<AccountsReceivablesModel | null>(null);

  const { getDiscountedOrderAmount } = useFetchDiscount();

  const payAfterTaxiFinished =
    paymentOriginPage === 'history' ||
    paymentOriginPage === 'comment' ||
    paymentOriginPage === 'payUnpaidTrip';

  useEffect(() => {
    if (!GUID) {
      setShouldRequestPosition(true);
      setEstimateFareTimeoutControl({ action: 'idle' });
      const initLocation = {
        pathname: '/',
        state: {
          initMainPage: true,
        },
      };
      history.replace(initLocation);
    }

    const blockNavigation = (nextLocation, action) => {
      if (
        isBlocking &&
        nextLocation.pathname === '/' &&
        paymentOriginPage === 'home' &&
        action === 'POP' &&
        !payAfterTaxiFinished
      ) {
        if (!confirmCancelCallTaxi.status()) toggleConfirmCancelCallTaxiModal();
        return false;
      }
    };

    const unblock = history.block(blockNavigation);

    getEstimatedFare();

    return () => {
      unblock();
      if (confirmFareChangedModal.status()) toggleConfirmFareChangedModal();
      if (confirmCancelCallTaxi.status()) toggleConfirmCancelCallTaxiModal();
    };
  }, []);

  useEffect(() => {
    if (isTappayLoadedSuccess) {
      cardSetup({
        fields: {
          number: {
            element: '#card-number',
            placeholder: '請輸入16位數卡號',
          },
          expirationDate: {
            element: '#card-expiration-date',
            placeholder: 'MM / YY',
          },
          ccv: {
            element: '#card-ccv',
            placeholder: '卡片背面3碼',
          },
        },
        styles: {
          input: {
            color: '#484848',
            'font-size': '15px',
          },
          ':focus': {
            color: '#484848',
          },
          '::placeholder': {
            color: '#d2d2d2',
          },
          '.valid': {
            color: '#46B49A',
          },
          '.invalid': {
            color: '#F14F50',
          },
          '@media screen and (max-width: 400px)': {
            input: {
              color: 'orange',
            },
          },
        },
      });
      onCardUpdate(onCardUpdateCallback);
    }
  }, [isTappayLoadedSuccess]);

  function updateValidation(type: string, status: boolean | null) {
    setValidate((prevRes) => ({
      ...prevRes,
      [type]: status,
    }));
  }

  function onCardUpdateCallback(update) {
    if (update.canGetPrime) {
      setCanPay(true);
    } else {
      setCanPay(false);
    }

    // 信用卡卡號
    if (update.status.number === 2) {
      // 錯誤
      updateValidation('cardNumber', false);
    } else if (update.status.number === 0) {
      // 正確
      updateValidation('cardNumber', true);
    } else {
      // 無
      updateValidation('cardNumber', null);
    }

    // 有效日期
    if (update.status.expiry === 2) {
      // 錯誤
      updateValidation('expirationDate', false);
    } else if (update.status.expiry === 0) {
      // 正確
      updateValidation('expirationDate', true);
    } else {
      // 無
      updateValidation('expirationDate', null);
    }

    // 檢核碼
    if (update.status.ccv === 2) {
      // 錯誤
      updateValidation('ccv', false);
    } else if (update.status.ccv === 0) {
      // 正確
      updateValidation('ccv', true);
    } else {
      // 無
      updateValidation('ccv', null);
    }
  }

  async function handleCancelCallTaxi() {
    try {
      setIsLoading(false);
      if (confirmFareChangedModal.status()) toggleConfirmFareChangedModal();
      if (confirmCancelCallTaxi.status()) toggleConfirmCancelCallTaxiModal();
      await taxiTripAPI.CancelCallUberTaxi({ tripGuid: GUID });
      setIsBlocking(false);
      setShouldRequestPosition(true);
      setToastAppear('取消叫車成功');
      setOrderPlaceDetail();
      const initLocation = {
        pathname: '/',
        state: {
          initMainPage: true,
        },
      };
      history.replace(initLocation);
    } catch (error) {
      setToastAppear('取消叫車失敗');
    }
  }

  function getConfirmEstimatedFare(changedEstimateFare, callTaxiData) {
    // 如果有重取車資，則拿changedEstimateFare叫車，若無則直接取callTaxiData時的車資
    if (changedEstimateFare.fareId) {
      return changedEstimateFare;
    }

    const {
      uberProductId,
      uberProductName,
      uberFareId,
      oriUberFare,
      uberFareExpiresUtc,
      uberCancelThresholdSecs,
      uberMinCancelFee,
    } = callTaxiData;

    return {
      productId: uberProductId,
      displayName: uberProductName,
      fareId: uberFareId,
      fare: oriUberFare,
      expiresUtc: uberFareExpiresUtc,
      cancelThresholdSecs: uberCancelThresholdSecs,
      minCalcelFee: uberMinCancelFee,
    };
  }

  function handleCancelRouterBack() {
    if (confirmCancelCallTaxi.status()) toggleConfirmCancelCallTaxiModal();
  }

  async function handleCallTaxiPay(callTaxiData = originTripInfo) {
    if (isRequestInProgress) {
      return;
    }
    setIsRequestInProgress(true);

    if (confirmFareChangedModal.status()) toggleConfirmFareChangedModal();

    getPrime(async (prime) => {
      if (prime && prime.status !== 0) {
        setToastAppear('信用卡驗證失敗，請重新輸入');
        setIsRequestInProgress(false);
        setIsLoading(false);
        return;
      }

      const paymentData = {
        cardPrime: prime.card.prime,
        rememberCard: isRemember,
        tripGuid: GUID,
        redirectUrl:
          window.location.origin +
          '/?guid=' +
          GUID +
          '&isCreditCardPay=true&paymentOriginPage=' +
          paymentOriginPage,
      };

      let needToCallCar = true;
      if (payAfterTaxiFinished) {
        if (!unpaidTripData) {
          setToastAppear('未找到未付款訂單');
          setIsRequestInProgress(false);
          setIsLoading(false);
          return;
        }
        const { hasCompleted } = unpaidTripData;
        if (hasCompleted) {
          needToCallCar = false;
        }
      }

      const [result, error] = needToCallCar
        ? await taxiTripAPI.CallCarAfterCreditCardPay({
            ...paymentData,
            ...getConfirmEstimatedFare(changedEstimateFare, callTaxiData),
          })
        : await taxiTripAPI.CreditCardPay(paymentData);

      try {
        if (error) throw error;
        if (result) {
          handleCallTaxiResult(result);
        }
      } catch (error) {
        handlePaymentError(error);
      } finally {
        setIsLoading(false);
        setIsRequestInProgress(false);
      }
    });
  }

  function handleCallTaxiResult(result) {
    const { paymentUrl, paymentInfo } = result?.data?.data || {};
    if (paymentUrl) {
      window.location.replace(result.data.data.paymentUrl);
      return;
    }
    if (paymentInfo) {
      updateConfirmFareChangedInfo(paymentInfo);
      toggleConfirmFareChangedModal();
    }
  }

  function updateConfirmFareChangedInfo(paymentInfo) {
    // 檢查 originTripInfo.uberFare 是否存在
    if (isNullOrUnDef(originTripInfo?.uberFare)) {
      throw new Error('originTripInfo.uberFare 未定義或為 null');
    }

    const { payAmount = 0, discountAmount = 0, bonusUsed = 0 } = paymentInfo;

    const discountList = [
      { key: 'discountAmount', prefix: '優惠折抵$', value: discountAmount },
      { key: 'bonusUsed', prefix: '紅利$', value: bonusUsed },
    ];

    setConfirmFareChangedInfo((info) => ({
      ...info,
      originFare: originTripInfo.uberFare,
      changedFare: payAmount,
      options: {
        discountShowText: formatDiscountText(discountList),
      },
    }));
  }

  function handlePaymentError(error) {
    if (confirmFareChangedModal.status()) toggleConfirmFareChangedModal();
    setToastAppear(error?.response?.data?.ErrorMessage?.Description || '發生錯誤，請稍後再試');
  }

  async function handleGoPay() {
    if (payAfterTaxiFinished) {
      const { hasCompleted } = unpaidTripData || {};
      if (hasCompleted) {
        await handleCallTaxiPay();
      } else {
        await checkEstimatedFareIsChanged();
      }
    } else {
      if (paymentOriginPage === 'home') {
        return await checkEstimatedFareIsChanged();
      }
      history.replace('/');
    }
  }

  async function checkEstimatedFareIsChangedResult({
    changedEstimateFare,
    originDiscountedFare,
    bonusInfo,
  }) {
    const { fare, fareId } = changedEstimateFare;
    const { discountCode, ticketInstanceId, useBonus } = bonusInfo;
    const changedDiscountedFare = await getDiscountedOrderAmount({
      fare,
      fareId,
      discountCode,
      ticketInstanceId,
      useBonus,
    });
    changedDiscountedFare === originDiscountedFare
      ? await handleCallTaxiPay()
      : toggleConfirmFareChangedModal();
    setConfirmFareChangedInfo((info) => {
      return {
        ...info,
        originFare: originDiscountedFare,
        changedFare: changedDiscountedFare,
      };
    });
  }

  async function checkEstimatedFareIsChanged() {
    if (isLoading) return;
    setIsLoading(true);
    const [resultEstimateOrigin] = await taxiTripAPI.GetRegisteredUberTaxiTrip({ tripGuid: GUID });
    let callTaxiData: UberTripsInfo;
    if (resultEstimateOrigin) {
      callTaxiData = resultEstimateOrigin.data.data.trip;
      setOriginTripInfo(callTaxiData);
      if (!isExpired(callTaxiData.uberFareExpiresUtc)) {
        await handleCallTaxiPay(callTaxiData);
        return;
      }
    } else {
      setEstimatedFareErrorMessage('服務目前異常，請稍後再試');
      toggleEstimatedFareErrorModal();
      setIsLoading(false);
      return;
    }
    const { pickupLat, pickupLon, pickupAddress, dropoffLat, dropoffLon, dropoffAddress, options } =
      callTaxiData;
    function checkOptionExist(optionId) {
      return options.some((option) => option.service_option_id === optionId);
    }
    const postData = {
      pickupLat,
      pickupLon,
      dropoffLat,
      dropoffLon,
      pickupAddress,
      dropoffAddress,
      option: {
        baggage: checkOptionExist(1),
        wheelchair: checkOptionExist(2),
        pet: checkOptionExist(3),
      },
      checkPickupAdd: false,
      checkDropoffAdd: false,
    };
    const [result, error] = await taxiTripAPI.estimatedTripFare(postData);
    if (result) {
      const changedEstimateFare = result?.data?.data?.[0];
      const bonusInfo = resultEstimateOrigin?.data?.data?.bonusInfo || {};
      setChangedEstimateFare(changedEstimateFare);
      await checkEstimatedFareIsChangedResult({
        changedEstimateFare,
        originDiscountedFare: callTaxiData.uberFare,
        bonusInfo,
      });
    } else {
      console.log(error);
      setEstimatedFareErrorMessage(error.response.data.ErrorMessage.Description);
      toggleEstimatedFareErrorModal();
      setIsLoading(false);
    }
  }

  async function getEstimatedFare() {
    if (isLoading) return;
    setIsLoading(true);
    if (payAfterTaxiFinished) {
      const [result, _error] = await taxiTripAPI.GetAccountReceivableTrip();
      setIsLoading(false);
      if (result) {
        const unpaidTrips = result?.data?.data || [];
        const unpaidTrip = unpaidTrips.find((trip) => trip.tripGuid === GUID);
        if (!unpaidTrip) {
          setEstimatedFareErrorMessage('未找到未付款訂單');
          const initLocation = {
            pathname: '/',
            state: {
              initMainPage: true,
            },
          };
          history.replace(initLocation);
          return;
        }
        setUnpaidTripData(unpaidTrip);
        setEstimatedFare(Math.ceil(unpaidTrip?.amount || 0));
      } else {
        setEstimatedFareErrorMessage('服務目前異常，請稍後再試');
        toggleEstimatedFareErrorModal();
      }
    } else {
      const [resultEstimateOrigin] = await taxiTripAPI.GetRegisteredUberTaxiTrip({
        tripGuid: GUID,
      });
      let callTaxiData: UberTripsInfo;
      setIsLoading(false);
      if (resultEstimateOrigin) {
        callTaxiData = resultEstimateOrigin.data.data.trip;
        setEstimatedFare(callTaxiData.uberFare);
      } else {
        setEstimatedFareErrorMessage('服務目前異常，請稍後再試');
        toggleEstimatedFareErrorModal();
      }
    }
  }

  return (
    <div className="credit-card-payment">
      <div className="credit-card-payment__block">
        <span className="credit-card-payment__block__title">訂單總額</span>
        <span className="credit-card-payment__block__value">
          {estimatedFare ? `$ ${estimatedFare}` : '---'}
        </span>
      </div>
      <div className="credit-card-payment__title">
        <p className="credit-card-payment__title__main">信用卡資訊</p>
      </div>
      <div className="credit-card-payment__card-detail">
        <CardLabelInput
          label="信用卡卡號"
          displayWidth="full"
          isError={validate.cardNumber === false}
          tappayFieldId="card-number"
          errorMessage="請輸入16位數卡號"
        />
        <CardLabelInput
          label="有效日期"
          displayWidth="full"
          isError={validate.expirationDate === false}
          tappayFieldId="card-expiration-date"
          errorMessage="請輸入有效日期"
        />
        <CardLabelInput
          label="檢核碼"
          displayWidth="full"
          isError={validate.ccv === false}
          tappayFieldId="card-ccv"
          errorMessage="請輸入CVC檢核碼"
        />
        <div className="card-detail__field--full">
          <label className="card-detail__checkbox">
            <input
              onChange={() => setIsRemember(!isRemember)}
              type="checkbox"
              checked={isRemember}
              autoComplete="off"
            />
            <span>綁定信用卡讓下次結帳更方便</span>
          </label>
        </div>
      </div>
      <div className="credit-card-payment__info">
        <p>
          本平台採用喬睿科技
          <img src={TapPayLogo} alt="TapPay" />
          金流交易系統，榮獲PCI-DSS國際安全認證。消費者刷卡直接在銀行端系統中交易，本平台不會保留完整的信用卡資訊，以保障您的權益，資料傳輸全程採用SSL
          2048bit加密技術保護 。
        </p>
      </div>
      <div className="credit-card-payment__footer">
        <Button
          width="full"
          color="primary"
          disabled={!canPay || isLoading}
          onButtonClick={() => handleGoPay()}
          isLoading={isLoading}
        >
          <span>確定付款</span>
        </Button>
      </div>
      <Modali.Modal {...estimatedFareErrorModal}></Modali.Modal>
      <Modali.Modal {...confirmFareChangedModal}>
        <div className="confirm-fare-changed-modal__content">
          <p>
            車資已從
            <span className="confirm-fare-changed-modal__content__price">
              ${confirmFareChangedInfo.originFare}
            </span>
            調整為
            <span className="confirm-fare-changed-modal__content__price primary">
              ${confirmFareChangedInfo.changedFare}
            </span>
            {confirmFareChangedInfo.options.discountShowText && (
              <p>{confirmFareChangedInfo.options.discountShowText}</p>
            )}
          </p>
          <p>是否確認叫車？</p>
        </div>
      </Modali.Modal>
      <Modali.Modal {...confirmCancelCallTaxi}></Modali.Modal>
    </div>
  );
};
