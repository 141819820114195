interface Location {
  tag: 'history';
  time: string;
  formattedTime: string;
  lat: number;
  lng: number;
  address: string;
  mainText: string;
}

export interface TripPaymentInfo {
  amount: number;
  time: string;
  formattedTime: string;
  refundAmount: number;
  refundTime: string;
  additionalAmount: number;
}

export interface TripBonusDetail {
  discountAmount: number;
  bonusAmount: number;
  ticketDiscountAmount: number;
  totalDiscountAmount: number;
}

export enum TripStatus {
  Completed,
  Cancelled,
  Unpaid,
}

interface TripStatusInfo {
  text: string;
  color: string;
  status: TripStatus;
}

export interface TransformedTrip {
  tag: 'history';
  guid: string;
  comment: string;
  origin: Location;
  destination: Location;
  status: string;
  isDispatch: boolean;
  dispatchPlate: string;
  fleetTel: string;
  isCancel: boolean;
  cancelSource: string;
  cancelFare: number;
  hasReceipt: boolean;
  tripStatusDesc: string;
  payMethod: string;
  payAmount: number;
  subtotal: number;
  tripTotalAmount: number;
  estimatedFare: number;
  paymentInfo: TripPaymentInfo;
  bonusDetail: TripBonusDetail;
  tripStatus: TripStatusInfo;
}
