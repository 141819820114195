import React from 'react';

const DiscountInputFocusContext = React.createContext<{
  discountInputFocus: boolean;
  setDiscountInputFocus: (isFocus: boolean) => void;
}>({
  discountInputFocus: false,
  setDiscountInputFocus: () => {},
});

export function useDiscountInputFocus() {
  const ctx = React.useContext(DiscountInputFocusContext);
  if (!ctx) {
    throw new Error('useDiscountInputFocus must be used within a DiscountInputFocusProvider');
  }
  return ctx;
}

export function DiscountInputFocusProvider({ children }: { children: React.ReactNode }) {
  const [discountInputFocus, setDiscountInputFocus] = React.useState<boolean>(false);
  return (
    <DiscountInputFocusContext.Provider value={{ discountInputFocus, setDiscountInputFocus }}>
      {children}
    </DiscountInputFocusContext.Provider>
  );
}
