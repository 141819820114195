import { useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import './Ripple.scss';

const useDebouncedRippleCleanUp = (rippleCount, duration, cleanUpFunction) => {
  useLayoutEffect(() => {
    let bounce = null;
    if (rippleCount > 0) {
      clearTimeout(bounce);

      bounce = setTimeout(() => {
        cleanUpFunction();
        clearTimeout(bounce);
      }, duration);
    }

    return () => clearTimeout(bounce);
  }, [rippleCount, duration, cleanUpFunction]);
};

const Ripple = ({ duration = 300, color = '#999' }) => {
  const [rippleArray, setRippleArray] = useState([]);
  const [hoverRippleArray, setHoverRippleArray] = useState([]);

  const addRipple = (event) => {
    const rippleContainer = event.currentTarget.getBoundingClientRect();
    const size =
      rippleContainer.width > rippleContainer.height
        ? rippleContainer.width
        : rippleContainer.height;
    const x = event.pageX - rippleContainer.x - rippleContainer.width / 2;
    const y = event.pageY - rippleContainer.y - rippleContainer.width / 2;
    const newRipple = { x, y, size };

    setRippleArray((pre) => [...pre, newRipple]);
  };

  const addHoverRipple = (event) => {
    const rippleContainer = event.currentTarget.getBoundingClientRect();
    const size =
      rippleContainer.width > rippleContainer.height
        ? rippleContainer.width
        : rippleContainer.height;
    const x = event.pageX - rippleContainer.x - rippleContainer.width / 2;
    const y = event.pageY - rippleContainer.y - rippleContainer.width / 2;
    const newRipple = { x, y, size };

    setHoverRippleArray((pre) => [...pre, newRipple]);
  };

  useDebouncedRippleCleanUp(rippleArray.length, duration, () => setRippleArray([]));

  return (
    <div
      className="ripple"
      onClick={addRipple}
      onTouchStart={addHoverRipple}
      onTouchEnd={() => setHoverRippleArray([])}
    >
      {rippleArray.length > 0 &&
        rippleArray.map((ripple, index) =>
          index === rippleArray.length - 1 ? (
            <span
              key={'ripple-' + index}
              style={{
                top: ripple.y,
                left: ripple.x,
                width: ripple.size,
                height: ripple.size,
                backgroundColor: color,
                animationDuration: `${duration}ms`,
              }}
            />
          ) : null
        )}
      {hoverRippleArray.length > 0 &&
        hoverRippleArray.map((ripple, index) =>
          index === hoverRippleArray.length - 1 ? (
            <span
              key={'ripple-' + index}
              style={{
                top: ripple.y | 0,
                left: ripple.x | 0,
                width: ripple.size,
                height: ripple.size,
                backgroundColor: color,
                animationDuration: `800ms`,
                animationDelay: '400ms',
              }}
            />
          ) : null
        )}
    </div>
  );
};

Ripple.propTypes = {
  duration: PropTypes.number,
  color: PropTypes.string,
};

export default Ripple;
