import React from 'react';

import { useHistory } from 'react-router-dom';

import paymentFailedImage from 'assets/payment/payment-failed.png';
import addLineButton from 'assets/add-line-button.png';

import { Button } from 'components/Button/Button';
import { setToastAppear } from 'components/Toast/Toast';

import './paymentFailedPage.scss';
import { CancelCallUberTaxi, GetAccountReceivableTrip } from 'api/taxiTrip';

type PaymentFailedPageProps = {
  setOrderPlaceDetail: () => void;
  setPaymentOriginPage: (page: string) => void;
  GUID: string;
};

export const PaymentFailedPage: React.FC<PaymentFailedPageProps> = ({
  setOrderPlaceDetail,
  setPaymentOriginPage,
  GUID,
}) => {
  const history = useHistory();

  const backToHomePage = async () => {
    try {
      const [result, _error] = await GetAccountReceivableTrip();
      if (result) {
        const unpaidTrips = result?.data?.data || [];
        const unpaidTrip = unpaidTrips.find((trip) => trip.tripGuid === GUID);
        if (unpaidTrip) {
          const { hasCompleted } = unpaidTrip;
          if (!hasCompleted) {
            await CancelCallUberTaxi({ tripGuid: GUID });
            setOrderPlaceDetail();
            history.replace('/');
          } else {
            const initLocation = {
              pathname: '/',
              state: {
                initMainPage: true,
              },
            };
            history.push(initLocation);
          }
        }
      }
    } catch (err) {
      console.log(err);
      setToastAppear('取消叫車失敗');
    }
  };

  return (
    <div className="payment-failed">
      <div className="payment-failed__title">
        <h3 className="payment-failed__title__text">付款失敗</h3>
        <img className="payment-failed__title__img" src={paymentFailedImage} alt="付款失敗" />
      </div>
      <div className="payment-failed__description">
        <p className="payment-failed__description__title">以下是可能授權失敗的原因：</p>
        <ul className="payment-failed__list">
          <li className="payment-failed__list__item">1. OTP密碼輸入錯誤</li>
          <li className="payment-failed__list__item">2. 密碼有效期間到期</li>
          <li className="payment-failed__list__item">
            3. 網路傳輸環境壅塞，無法傳輸完整有效的訊息。
          </li>
        </ul>
      </div>
      <div className="payment-failed__note">
        <p className="payment-failed__note__title">注意事項</p>
        <ul className="payment-failed__list note">
          <li className="payment-failed__list__item">
            1. 若您一直無法通過3D驗證碼，請您與您的發卡銀行聯繫。
          </li>
          <li className="payment-failed__list__item">
            2. 若對服務內容有任何疑問，歡迎加入官方帳號詢問。
          </li>
        </ul>
      </div>
      <div className="payment-failed__line">
        <button
          className="payment-failed__line__button"
          onClick={() => {
            window.open('https://ec.knst.tw/5k7v4s', '_blank');
          }}
        >
          <img src={addLineButton} alt="加入好友" />
        </button>
      </div>
      <div className="payment-failed__footer">
        <Button
          className="payment-failed__footer__button"
          width="full"
          height="44px"
          color="primary"
          onButtonClick={() => {
            console.log('選擇其他張信用卡');
            setPaymentOriginPage('payUnpaidTrip');
            history.replace('/SelectCreditCard');
          }}
          variant="outlined"
        >
          <span>選擇其他張信用卡</span>
        </Button>
        <Button
          className="payment-failed__footer__button"
          width="full"
          height="44px"
          color="primary"
          onButtonClick={backToHomePage}
          variant="outlined"
        >
          <span>返回首頁，重新叫車</span>
        </Button>
      </div>
    </div>
  );
};
