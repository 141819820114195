import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { TripDetail } from 'components/History/TripDetail';

export default function HistoryPage({ historyResult }) {
  const { t } = useTranslation();
  const history = useHistory();
  const [bufResult, setBufResult] = useState([]);

  function handleClickHistory(tripDetail) {
    history.push(`/HistoryDetail/${tripDetail.guid}`);
  }

  useEffect(() => {
    setBufResult(historyResult);
  }, [historyResult]);
  // true false 之後要拿掉
  let historyList = bufResult.map((r, i) => {
    return (
      <TripDetail
        key={'history-' + i}
        history={r}
        tripDetail={r}
        onClick={() => handleClickHistory(r)}
      />
    );
  });

  return (
    <div className="list">
      {historyResult.length === 0 ? (
        <div className="nodata nodata__historylist">
          <div />
          <p>{t('A009')}</p>
        </div>
      ) : null}
      <div className="listItem__header">
        <i className="icon-history-3" aria-hidden="true" />
        <span>{t('A002')}</span>
      </div>
      {historyResult && historyResult.length !== 0 ? (
        <div className="listItem__scrollBox">
          <div>{historyList}</div>
        </div>
      ) : null}
    </div>
  );
}
