import './SideBar.scss';
import { useState } from 'react';
import Ripple from './Ripple';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { GetEcToken } from 'api/taxiTrip';
import { setToastAppear } from 'components/Toast/Toast';

function SideBar(props) {
  const history = useHistory();
  const { t } = useTranslation();
  const [clickVersionCount, setClickVersionCount] = useState(1);

  function backToHomePage() {
    const location = {
      pathname: '/',
    };
    history.push(location);
    const initLocation = {
      pathname: '/',
      state: {
        initMainPage: true,
      },
    };
    history.push(initLocation);
    props.openTab('close');
  }

  async function goCreditCardBindingPage() {
    const [result, error] = await GetEcToken();
    if (error) {
      setToastAppear('服務異常，請稍後再試');
      return;
    }
    if (result) {
      const ecToken = encodeURIComponent(result.data.token);
      window.location.replace(
        `${process.env.REACT_APP_EC_WEB_URI}member/creditCardBinding?appAuth=${ecToken}&isFromTaxi=true`
      );
    }
  }

  return (
    <div className={`fliter ${props.isopen ? 'active' : 'inActive'}`} onClick={props.closeSideBar}>
      <div
        className={`SideBar ${props.isopen ? 'active' : 'inActive'}`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="SideBar__items">
          <div className="SideBar__item" onClick={() => backToHomePage()}>
            <Ripple />
            <div>
              <i className="icon-car-2" aria-hidden="true" />
            </div>
            <span>{t('A001')}</span>
          </div>
          <div className="SideBar__item" onClick={() => props.openTab('history')}>
            <Ripple />
            <div>
              <i className="icon-history-3" aria-hidden="true" />
            </div>
            <span>
              {t('A002')}
              {props.hasRedDotDisplay && <div className="SideBar__redDot" />}
            </span>
          </div>
          <div className="SideBar__item" onClick={() => props.openTab('prefer')}>
            <Ripple />
            <div>
              <i className="icon-heart-2" aria-hidden="true" />
            </div>
            <span>{t('A005')}</span>
          </div>
          <div className="SideBar__item" onClick={() => goCreditCardBindingPage()}>
            <Ripple />
            <div>
              <i className="icon-credit-card" aria-hidden="true" />
            </div>
            <span>{t('A085')}</span>
          </div>
          <div
            className="SideBar__item"
            onClick={() =>
              window.location.assign(
                `https://inapp.kingnetsmart.com.tw/ad.html?page=linkRule&params1=7&params2=https%3A%2F%2Flin.ee%2FappSyju&title=返回社區叫車&referrer=${encodeURIComponent(
                  process.env.REACT_APP_API_ENDPOINT
                )}`
              )
            }
          >
            <Ripple />
            <div>
              <i className="icon-contact" aria-hidden="true" />
            </div>
            <span>聯絡我們</span>
          </div>
          <div
            className="SideBar__item"
            onClick={() => window.location.assign('https://www.smartdaily.com.tw/qa-car.html')}
          >
            <Ripple />
            <div>
              <i className="icon-question" aria-hidden="true" />
            </div>
            <span>常見問題</span>
          </div>
          {process.env.REACT_APP_ENV === 'development' && clickVersionCount % 12 === 0 && (
            <div className="SideBar__item" onClick={() => props.openTab('loglist')}>
              <Ripple />
              <div>
                <i className="icon-bin" aria-hidden="true" />
              </div>
              <span>Log紀錄</span>
            </div>
          )}
          <div
            style={{
              position: 'absolute',
              pointerEvents: process.env.REACT_APP_ENV === 'development' ? 'auto' : 'none',
              right: 0,
              bottom: 0,
            }}
          >
            <span onClick={() => setClickVersionCount((c) => c + 1)}>
              {'v2.1.0.' + process.env.REACT_APP_VERSION}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SideBar;
