import axios from 'axios';
import {
  AddressModal,
  TripLocation,
  CallUberTaxiParams,
  BindCreditCardParams,
  ChangeCreditCardParams,
  GetUberTaxiParams,
  GetTaxiTripParams,
  CancelUberTaxiParams,
  CreditCardPayParams,
  CallCarAfterCreditCardPayParams,
  GetTripHistoriesParams,
  GetDiscountTicketsParams,
  GetDiscountBonusParams,
  ReceiveDiscountCodeParmas,
} from './model/FEModel';
import {
  KnstData,
  PredictAddresses,
  EstimatedFareItemModel,
  CallUberTaxiModel,
  BindCreditCardModel,
  UberTripCombinedInfoModel,
  CreditCardPayModel,
  UserBindCardInfoModel,
  AccountsReceivablesModel,
  TripHistoriesModel,
  DiscountTicketModel,
  DiscountBonusModel,
  ReceiveDiscountModel,
  ECTokenModel,
} from './model/BEModel';
import { parseApiStatus } from '../../utils/unit';

const taxiTripApiRequest = axios.create({
  baseURL: process.env.REACT_APP_TAXI_TRIP_API,
  headers: {
    'Cache-Control': 'no-cache',
  },
});

export const setAuthHeader = (token) => {
  taxiTripApiRequest.defaults.headers.common['Authorization'] = token;
};

export const setSendboxRunId = (runId) => {
  taxiTripApiRequest.defaults.headers.common['x-uber-sandbox-runuuid'] = runId;
};

// dev測試用，取得沙盒runId
export const getSandboxRunId = () =>
  parseApiStatus(taxiTripApiRequest.get('/api/v1/TEST/new-sandbox'));

// 取得建議地址
export const predictAddress = (data: AddressModal) =>
  parseApiStatus(
    taxiTripApiRequest.get<PredictAddresses>('/api/v1/Uber/predictaddress', {
      params: data,
    })
  );

// 取得預估車資
export const estimatedTripFare = (data: TripLocation) =>
  parseApiStatus(
    taxiTripApiRequest.post<KnstData<EstimatedFareItemModel>>('/api/v1/Uber/estimate-trips', data)
  );

// 註冊叫車
export const CallUberTaxi = (data: CallUberTaxiParams) =>
  taxiTripApiRequest.post<KnstData<CallUberTaxiModel>>('/api/v1/Uber/Trips', data);

// 取得註冊叫車資料
export const GetRegisteredUberTaxiTrip = (data: GetUberTaxiParams) =>
  parseApiStatus(
    taxiTripApiRequest.get<KnstData<UberTripCombinedInfoModel>>(
      `/api/v1/Uber/Trips/${data.tripGuid}`,
      {
        params: data,
      }
    )
  );

// 取得叫車資料
export const GetTaxiTrip = (data: GetTaxiTripParams) =>
  parseApiStatus(
    taxiTripApiRequest.get<KnstData<UberTripCombinedInfoModel>>(
      `/api/v1/Taxi/Trips/${data.taxiType}/${data.tripGuid}`,
      {
        params: data,
      }
    )
  );
// 用戶回應以上車
export const CallUberTaxiPickUp = (data) =>
  taxiTripApiRequest.post(`/api/v1/Uber/Trips/${data.tripGuid}/PickUp`, data);

export const CancelCallUberTaxi = (data: CancelUberTaxiParams) =>
  taxiTripApiRequest.delete(`/api/v1/Uber/Trips/${data.tripGuid}`, { params: data });

// 綁定信用卡
export const BindCreditCard = (data: BindCreditCardParams) =>
  parseApiStatus(taxiTripApiRequest.post<KnstData<BindCreditCardModel>>('/api/v1/Cards', data));

// 變更信用卡
export const ChangeCreditCard = (data: ChangeCreditCardParams) =>
  parseApiStatus(
    taxiTripApiRequest.put<KnstData<BindCreditCardModel>>(`/api/v1/Cards/${data.cardGuid}`, data)
  );

// 取得信用卡
export const GetUserBindCard = () =>
  parseApiStatus(taxiTripApiRequest.get<KnstData<UserBindCardInfoModel>>('/api/v1/Cards'));

// 信用卡付款(補款用，不叫車)
export const CreditCardPay = (data: CreditCardPayParams) =>
  parseApiStatus(
    taxiTripApiRequest.post<KnstData<CreditCardPayModel>>(
      `/api/v1.1/Uber/Trips/${data.tripGuid}/TapPay`,
      data
    )
  );

// 信用卡付款(付款成功後自動叫車)
export const CallCarAfterCreditCardPay = (data: CallCarAfterCreditCardPayParams) =>
  parseApiStatus(
    taxiTripApiRequest.post<KnstData<CreditCardPayModel>>(
      `/api/v1/Uber/Trips/${data.tripGuid}/CallCar`,
      data
    )
  );

// 取得未付款訂單
export const GetAccountReceivableTrip = () =>
  parseApiStatus(
    taxiTripApiRequest.get<KnstData<AccountsReceivablesModel[]>>('/api/v1/Uber/AccountsReceivables')
  );

// 取得行程紀錄
export const GetTripHistories = (data: GetTripHistoriesParams) =>
  parseApiStatus(
    taxiTripApiRequest.get<KnstData<TripHistoriesModel>>('/api/v1/Uber/TripHistories', {
      params: data,
    })
  );

// 評價Uber叫車
export const CommentsTaxiTripOrder = (data) =>
  parseApiStatus(taxiTripApiRequest.post(`/api/v1/Uber/Trips/${data.tripGuid}/Comments`, data));

// 取得車資收據
export const GetEmailReceipt = (data) =>
  parseApiStatus(taxiTripApiRequest.post(`/api/v1/Uber/Trips/${data.tripGuid}/EmailReceipt`, data));

// 取得可使用的優惠券
export const GetDiscountTickets = (data: GetDiscountTicketsParams) =>
  parseApiStatus(
    taxiTripApiRequest.get<DiscountTicketModel>('/api/v1/Discount/Service/Tickets', {
      params: data,
    })
  );

// 取得可使用的紅利點數
export const GetDiscountBonus = (data: GetDiscountBonusParams) =>
  parseApiStatus(
    taxiTripApiRequest.get<DiscountBonusModel>('/api/v1/Discount/Order/CanUseBonus', {
      params: data,
    })
  );

// 取得代碼資訊＆領禮物
export const ReceiveDiscountCode = (data: ReceiveDiscountCodeParmas) =>
  parseApiStatus(
    taxiTripApiRequest.post<ReceiveDiscountModel>('/api/v1/Discount/ReceiveDiscountCode', data)
  );

// 取得EC Token
export const GetEcToken = () =>
  parseApiStatus(taxiTripApiRequest.get<ECTokenModel>('/api/v1/Token/ECToken'));
