import React, { useEffect, useState } from 'react';

import ReactLoading from 'react-loading';

import { useParams } from 'react-router-dom';

import { GetTripHistories } from 'api/taxiTrip';
import { processHistoryApiResult } from 'utils/historyHelper';
import { TransformedTrip, TripStatus } from 'types/trip';
import { TRIP_PAYMENT_METHOD } from 'constants/index';

import Header from 'components/Header';
import Ripple from 'components/Ripple';
import { Button } from 'components/Button/Button';
import { TripDetail } from 'components/History/TripDetail';
import { FeeDetail } from 'components/History/FeeDetail';
import { PaymentDetail } from 'components/History/PaymentDetail';

import './historyDetailPage.scss';
import 'components/History/Share.scss';

interface RouteParams {
  tripGuid: string;
}

interface CommentFareInfo {
  showPrice: boolean;
  isOnlinePay: boolean;
  fare: number;
  originFare: number;
}

interface EvaluationModal {
  isShown: boolean;
  isModalVisible: boolean;
  hide: (arg: boolean) => void;
  status: () => boolean;
  options: Record<string, any>;
}

interface HistoryDetailPageProps {
  openTab: (tabName: string) => void;
  tokenAlready: boolean;
  historyResult: TransformedTrip[];
  evaluationModal: EvaluationModal;
  setCommentFareInfo: (
    arg: CommentFareInfo | ((prevCommentFareInfo: CommentFareInfo) => CommentFareInfo)
  ) => void;
  historyEvaluation: (historyGUID: string, payMethod: string) => void;
  setGUID: (guid: string) => void;
  setPaymentOriginPage: (originPage: string) => void;
  handlePaymentRedirect: () => void;
}

export const HistoryDetailPage: React.FC<HistoryDetailPageProps> = ({
  openTab,
  tokenAlready,
  historyResult,
  evaluationModal,
  setCommentFareInfo,
  historyEvaluation,
  setGUID,
  setPaymentOriginPage,
  handlePaymentRedirect,
}) => {
  const { tripGuid } = useParams<RouteParams>();
  const [isLoading, setIsLoading] = useState(true);
  const [tripDetail, setTripDetail] = useState<TransformedTrip | null>(null);

  const isUnpaid = tripDetail?.tripStatus.status === TripStatus.Unpaid;
  const isCompleted = tripDetail?.tripStatus.status === TripStatus.Completed;
  const isCash = tripDetail?.payMethod === TRIP_PAYMENT_METHOD.CASH;
  function backToHistoryList() {
    history.back();
    openTab('history');
  }

  function overFiveDayCheck(timeString: string): boolean {
    if (Number(new Date()) - Number(new Date(timeString)) > Number(432000000)) {
      return true;
    }
    return false;
  }

  function handleFindLost(trip) {
    window.location.href = `tel:${trip.fleetTel}`;
  }

  function handlePay(order) {
    setGUID(order.guid);
    setPaymentOriginPage('history');
    handlePaymentRedirect();
  }

  function handleGoComment(tripDetail) {
    setCommentFareInfo((commentFareInfo: CommentFareInfo) => {
      return {
        ...commentFareInfo,
        showPrice: false,
      };
    });
    historyEvaluation(tripDetail.guid, tripDetail.payMethod);
  }

  function findHistoryFromHistoryList(tripGuid) {
    return historyResult.find((history) => history.guid === tripGuid);
  }

  useEffect(() => {
    async function getHistoryDetails() {
      if (!tokenAlready) return;
      try {
        const existTrip = findHistoryFromHistoryList(tripGuid);
        if (existTrip) {
          setTripDetail(existTrip);
          return;
        }
        setIsLoading(true);
        const [result, _error] = await GetTripHistories({
          tripGuid,
          offset: 0,
          maxCount: 1,
        });
        if (result) {
          setTripDetail(processHistoryApiResult(result?.data?.data?.orders)?.[0]);
        }
      } catch (error) {
        console.error('Failed to fetch history details:', error);
      } finally {
        setIsLoading(false);
      }
    }
    if (!evaluationModal.status()) {
      getHistoryDetails();
    }
  }, [tripGuid, evaluationModal.status()]);

  if (isLoading) {
    return (
      <div className="loading">
        <ReactLoading type="bars" color={'#46B49A'} height={50} width={50} />
      </div>
    );
  }

  return (
    <div className="history-detail-page">
      <Header headerText="行程紀錄" toggleSideBar={backToHistoryList} menuBarBack={true} />
      {tripDetail ? (
        <div className={`history-detail-scroll-container ${isUnpaid ? 'footer-padding' : ''}`}>
          {<TripDetail tripDetail={tripDetail} />}
          {!isCash && (
            <FeeDetail
              subtotal={tripDetail.subtotal}
              bonusDetail={tripDetail.bonusDetail}
              tripTotalAmount={tripDetail.tripTotalAmount}
              cancelFare={tripDetail.cancelFare}
            />
          )}
          {tripDetail?.paymentInfo && <PaymentDetail paymentInfo={tripDetail.paymentInfo} />}
          {
            <div className="history-detail-block list-btn">
              {!tripDetail.comment && isCompleted && (
                <ListBtn title={'我要評價'} onClick={() => handleGoComment(tripDetail)} />
              )}
              {!overFiveDayCheck(tripDetail.origin.time) && tripDetail.fleetTel && (
                <ListBtn title={'找尋遺失物'} onClick={() => handleFindLost(tripDetail)} />
              )}
            </div>
          }
          {isUnpaid && (
            <div className="history-detail-footer">
              <Button width="full" height="44px" onButtonClick={() => handlePay(tripDetail)}>
                <span>前往付款</span>
              </Button>
            </div>
          )}
        </div>
      ) : (
        <p className="empty-wrap">無資料</p>
      )}
    </div>
  );
};

interface ListBtnProps {
  title: string;
  onClick?: () => void;
}

function ListBtn({ title, onClick }: ListBtnProps) {
  return (
    <div className="history-list-btn" onClick={onClick}>
      <Ripple />
      <p className="history-list-btn__title">{title}</p>
      <i className="history-list-btn__icon icon-arrow-right"></i>
    </div>
  );
}
