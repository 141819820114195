import React, { useState, useEffect } from 'react';
import './CommentModal.scss';
import Modali, { useModali } from './Modal';
import { Button } from './Button/Button';
import Rating from 'rc-rate';
import 'rc-rate/assets/index.css';
import { kingnetTaxiUserSearchCommentOption, kingnetTaxiUserCreateOrderComment } from '../api';
import { CommentsTaxiTripOrder } from '../api/taxiTrip';
import type { CommentOption, TagComment } from '../api/model/FEModel';
import { superEntity, EntityState } from '../utils/unit';
import { useHistory } from 'react-router-dom';

export const useCommentModal = ({
  setHistoryResult,
  commentFareInfo,
  commentOrderIsOnlinePay,
  setPaymentOriginPage,
}) => {
  const [commentQuestionGUID, setCommentQuestionGUID] = useState('');
  const [commentEnd, setCommentEnd] = useState(false);
  const [eventFlag, setEventFlag] = useState<'Init' | 'Submit'>('Init');
  const [totalCommentRecord, setTotalCommentRecord] = useState(0);
  const [currentMoreComment, setCurrentMoreComment] = useState('');
  const [commentQuestion, setCommentQuestion] = useState<
    Array<Comment & { subTitleSortKeyPair: Record<string, number> }>
  >([]);
  const [textAreaModal, toggleTextAreaModal] = useModali({
    animated: true,
    backgroundColor: 'var(--systemWhite)',
    title: '意見回饋',
    buttons: [
      <Modali.Button
        key="ModaliButton"
        label="清除"
        backgroundColor="var(--systemWhite)"
        isStyleDestructive
        disabled={currentMoreComment === ''}
        onClick={() => setCurrentMoreComment('')}
      />,
      <Modali.Button
        key="ModaliButton"
        label="填寫完成"
        backgroundColor="var(--systemWhite)"
        isStyleDestructive
        onClick={() => toggleTextAreaModal()}
      />,
    ],
  });

  // * 設定 Firebase 提示視窗
  const [commentModal, toggleCommentModal] = useModali({
    animated: true,
    backgroundColor: 'var(--systemWhite)',
    fullScreen: true,
    title: '',
  });

  // * 彩蛋
  const [runTaxi, setRunTaxi] = useState(false);
  const [canClickTaxi, setCanClickTaxi] = useState(false);
  const [runTaxiTimer, setRunTaxiTimer] = useState<NodeJS.Timeout>();
  const [initTaxiTimer, setInitTaxiTimer] = useState<NodeJS.Timeout>();
  const clickTaxi = () => {
    if (canClickTaxi) {
      if (runTaxiTimer) clearTimeout(runTaxiTimer);
      setRunTaxi(true);
      setRunTaxiTimer(setTimeout(() => setRunTaxi(false), 5000));
    }
  };
  const initEgg = () => {
    if (initTaxiTimer) clearTimeout(initTaxiTimer);
    setCanClickTaxi(false);
    setRunTaxi(false);
    setInitTaxiTimer(setTimeout(() => setCanClickTaxi(true), 60000));
  };

  useEffect(() => {
    if (commentModal.isModalVisible || commentModal.isShown) {
      initEgg();
      initQuestion();
    }
  }, [commentModal.isModalVisible, commentModal.isShown]);

  const initQuestion = async () => {
    setTotalCommentRecord(0);
    setEventFlag('Init');
    setCommentEnd(false);
    setCurrentMoreComment('');
    if (commentQuestion.length) return;
    const [apiResponse] = await kingnetTaxiUserSearchCommentOption();
    if (apiResponse) {
      setCommentQuestion(prefixCommentOptions(apiResponse.data.Data));
    }
  };

  const confirm = () => {
    setEventFlag('Submit');
  };

  const ModalComponent: React.ReactNode = (
    <>
      <Modali.Modal {...commentModal}>
        <div className="comment-modal">
          {commentEnd && (
            <span className="comment-modal-thankTitle">{'感謝您 \n 我們已經收到回饋與建議！'}</span>
          )}
          {!commentEnd && commentQuestion.length ? (
            <QuestionComponent
              eventFlag={eventFlag}
              guid={commentQuestionGUID}
              setCommentEnd={setCommentEnd}
              setHistoryResult={setHistoryResult}
              setTotalCommentRecord={setTotalCommentRecord}
              toggleTextAreaModal={toggleTextAreaModal}
              currentMoreComment={currentMoreComment}
              question={commentQuestion[0]}
              commentFareInfo={commentFareInfo}
              commentOrderIsOnlinePay={commentOrderIsOnlinePay}
              commentModal={commentModal}
              toggleCommentModal={toggleCommentModal}
              setPaymentOriginPage={setPaymentOriginPage}
            />
          ) : (
            <div className="comment-modal-questionContent"></div>
          )}
          {
            <div
              className={`comment-modal-image comment-modal-welcomeImage ${
                !commentEnd && totalCommentRecord === 0 ? '' : 'hide'
              }`}
            >
              <div className="comment-modal--asset comment-modal--back" />
              <div className="comment-modal--asset comment-modal--starAlert" />
              <div className="comment-modal--asset comment-modal--phone" />
              <div
                className="comment-modal--asset comment-modal--car"
                style={
                  runTaxi
                    ? { pointerEvents: 'none', transform: 'translate(235px, 165px)' }
                    : undefined
                }
                onClick={clickTaxi}
              />
            </div>
          }
          {
            <>
              <div
                className={`comment-modal-image comment-modal-thank ${commentEnd ? '' : 'hide'}`}
              >
                <div className="comment-modal--asset comment-modal--back" />
                <div className="comment-modal--asset comment-modal--3starBg" />
                <div className="comment-modal--asset comment-modal--2starBg" />
                <div className="comment-modal--asset comment-modal--3star" />
                <div className="comment-modal--asset comment-modal--2star" />
                <div className="comment-modal--asset comment-modal--screen" />
                <div className="comment-modal--asset comment-modal--message1" />
                <div className="comment-modal--asset comment-modal--message2" />
                <div className="comment-modal--asset comment-modal--message3" />
                <div className="comment-modal--asset comment-modal--message4" />
                <div className="comment-modal--asset comment-modal--left" />
                <div className="comment-modal--asset comment-modal--right" />
                <div className="comment-modal--asset comment-modal--car" />
              </div>
            </>
          }
          {!commentEnd && totalCommentRecord > 0 ? (
            <button className="comment-modal-submit" onClick={confirm}>
              送出評價
            </button>
          ) : !(commentFareInfo.fare > commentFareInfo.originFare) ? (
            <div className="comment-modal-footer">
              <Button
                width="full"
                height="44px"
                variant="outlined"
                color="primary"
                onButtonClick={() => {
                  toggleCommentModal();
                  setPaymentOriginPage('home');
                }}
              >
                <span>關閉</span>
              </Button>
            </div>
          ) : null}
        </div>
      </Modali.Modal>
      <Modali.Modal {...textAreaModal}>
        <textarea
          placeholder="請告訴我們如何為您做得更好 ?"
          className="comment-modal-more-textarea"
          value={currentMoreComment}
          maxLength={300}
          onChange={(event) => setCurrentMoreComment(event.target.value)}
        ></textarea>
      </Modali.Modal>
    </>
  );

  return {
    CommentModal: ModalComponent,
    evaluationModal: commentModal,
    toggleEvaluationModal: toggleCommentModal,
    setCommentQuestionGUID,
  };
};

function prefixCommentOptions(commentOption: Array<CommentOption>) {
  return commentOption
    .filter((comment) => comment.is_check)
    .map((comment) => {
      // * 找到 type 的 sort 數值對
      const subTitleSortStringList = [
        ...new Set(comment.tag.map(({ subTitle, subTitleSort }) => `${subTitle}--${subTitleSort}`)),
      ];
      const subTitleSortKeyPair = subTitleSortStringList.map(
        (subTitleSortString): [string, number] => {
          const [key, value] = subTitleSortString.split('--');
          return [key, +value];
        }
      );

      const subTitleSortObject = Object.fromEntries(subTitleSortKeyPair);

      const tagEntity = superEntity(comment.tag, 'rate', 'subTitle');

      // * 這裡加入 type 的排序
      return {
        questionText: comment.question,
        questionId: comment.id,
        addition: tagEntity,
        subTitleSortKeyPair: subTitleSortObject,
      };
    });
}

interface Comment {
  questionText: string;
  questionId: number;
  addition: EntityState<string | number>;
}

const QuestionComponent = (props) => {
  const [tagAnswer, setTagAnswer] = useState<Array<TagComment>>([]);
  const [currentRate, setCurrentRate] = useState(0);

  useEffect(() => {
    if (props.eventFlag === 'Submit') handleSubmitComment(props.commentOrderIsOnlinePay);
  }, [props.eventFlag]);

  const handleClickTag = (tag) => {
    setTagAnswer((tags) =>
      tags.some((t) => t.id === tag.id) ? tags.filter((t) => t.id !== tag.id) : tags.concat([tag])
    );
  };

  const handleSubmitComment = async (isOnlinePay) => {
    if (isOnlinePay) {
      const [, err] = await CommentsTaxiTripOrder({
        tripGuid: props.guid,
        comment_option_id: props.question.questionId,
        score: currentRate,
        feedback: props.currentMoreComment,
        commentTagList: tagAnswer
          .filter((t) => t.rate === currentRate)
          .map((t) => {
            return {
              tag_name: t.name,
              type_name: t.subTitle,
            };
          }),
      });
      if (err) console.log(err);
    } else {
      const [, err] = await kingnetTaxiUserCreateOrderComment({
        guid: props.guid,
        id: props.question.questionId,
        score: currentRate,
        tag: tagAnswer.filter((t) => t.rate === currentRate).map((t) => [t.name, t.subTitle]),
        addition: props.currentMoreComment,
      });
      if (err) console.error('評價失敗');
    }
    props.setCommentEnd(true);
    props.setHistoryResult((pre) => {
      const found = pre.findIndex((e) => e.guid === props.guid);
      return pre.map((e, i) => {
        return i === found
          ? {
              ...e,
              comment: currentRate,
            }
          : e;
      });
    });
  };

  const handleRateChange = (rateValue) => {
    props.setTotalCommentRecord((oldSum) => oldSum - currentRate + +rateValue);
    setCurrentRate(+rateValue);
  };

  const rateInfoText = () => {
    if (currentRate > 3) return '感謝您的支持，智生活會努力做得更好！';
    return currentRate ? '不太滿意，請告訴我們有哪些問題？' : '';
  };

  const sortKeyPair = props.question.subTitleSortKeyPair;

  const needPay = props.commentFareInfo.fare > props.commentFareInfo.originFare;

  return (
    <div className="comment-modal-body">
      <span className="comment-modal-title">行程已完成！</span>
      {!needPay && (
        <>
          <span className="comment-modal-question">{props.question.questionText}</span>
          <div className="comment-modal-rate">
            <Rating character={<i className="knst-icon-star" />} onChange={handleRateChange} />
            <span className={currentRate ? '' : 'hide'}>{rateInfoText()}</span>
          </div>
        </>
      )}
      {currentRate <= 0 && (
        <div className={`comment-modal-question-fakeContent ${needPay ? 'no-rating' : ''}`}></div>
      )}
      {currentRate <= 0 && props.commentFareInfo.isOnlinePay && props.commentFareInfo.showPrice && (
        <PriceBlock
          commentFareInfo={props.commentFareInfo}
          commentModal={props.commentModal}
          toggleCommentModal={props.toggleCommentModal}
          setPaymentOriginPage={props.setPaymentOriginPage}
        ></PriceBlock>
      )}
      <div className={`comment-modal-questionContent ${currentRate <= 0 && 'hide'}`}>
        {currentRate > 0 &&
          props.question.addition.entity[currentRate].ids
            .sort((a, b) => sortKeyPair[a] - sortKeyPair[b])
            .map((subTitle) => (
              <React.Fragment key={subTitle}>
                <div className="comment-modal-tagType" key={'tagType-' + subTitle}>
                  {subTitle}
                </div>
                <div className="comment-modal-tagList" key={'tagList-' + subTitle}>
                  {props.question.addition.entity[currentRate].entity[subTitle]
                    .sort((a, b) => a.sort - b.sort)
                    .map((t) => (
                      <TagComponent
                        key={t.id}
                        name={t.name}
                        active={tagAnswer.some((ta) => ta.id === t.id)}
                        click={() => handleClickTag(t)}
                      />
                    ))}
                </div>
              </React.Fragment>
            ))}
        {currentRate > 0 && (
          <div className="comment-modal-more">
            {props.currentMoreComment.length ? (
              <div className="comment-modal-more-text-enable">
                <div>
                  <span>意見回饋</span>
                  <span onClick={props.toggleTextAreaModal}>編輯</span>
                </div>
                <p>{props.currentMoreComment}</p>
              </div>
            ) : (
              <span className="comment-modal-more-text" onClick={props.toggleTextAreaModal}>
                <p>我想回饋更多內容</p>
                <span></span>
              </span>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const TagComponent = (props) => {
  return (
    <span
      className={`comment-modal-tag ${props.active && 'comment-modal-tag-active'}`}
      onClick={props.click}
    >
      {props.name}
    </span>
  );
};

const PriceBlock = ({
  commentFareInfo,
  toggleCommentModal,
  commentModal,
  setPaymentOriginPage,
}) => {
  const history = useHistory();
  const tips = (() => {
    if (commentFareInfo.fare > commentFareInfo.originFare)
      return ['因行程費用高於原預估車資，請重新付款。', '付款後前筆金額將立即退回。'];
    if (commentFareInfo.fare < commentFareInfo.originFare)
      return ['因行程費用低於原預估車資，', '差額最晚將於三個工作天內完成退款。'];
    return [];
  })();

  function handleGoPay() {
    if (commentModal.status()) toggleCommentModal();
    setPaymentOriginPage('comment');
    history.replace('/SelectCreditCard');
  }
  return (
    <>
      <div className="comment-modal-price">
        <div className="comment-modal-price__fare comment-modal-price__item">
          <span>此次行程費用</span>
          <span className="fare">${commentFareInfo.fare}</span>
        </div>
        <div className="comment-modal-price__origin comment-modal-price__item">
          <span>預估車資</span>
          <span>${commentFareInfo.originFare}</span>
        </div>
      </div>
      {tips.length > 0 ? (
        <div className="comment-modal-tips">
          {tips.map((tip) => (
            <span key={tip} className="comment-modal-tips__item">
              {tip}
            </span>
          ))}
        </div>
      ) : null}
      {commentFareInfo.fare > commentFareInfo.originFare ? (
        <Button
          className="comment-modal-pay"
          color="primary"
          width="full"
          onButtonClick={() => handleGoPay()}
        >
          <span>前往付款</span>
        </Button>
      ) : null}
    </>
  );
};
