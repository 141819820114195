import type { Order } from '../api/model/BEModel';

import { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { FirebaseContext } from '../utils/firebase';
import * as api from '../api';
import * as taxiTripAPI from '../api/taxiTrip';

import { TRIP_PAYMENT_METHOD } from 'constants/index';

export const useStatus = (
  cancelModel,
  toggleCancelModel,
  lockModal,
  toggleLockModal,
  setDriverDispatch,
  evaluationModal,
  toggleEvaluationModal,
  setCommentFareInfo,
  setShowCancelFareWarning,
  setShowCancelFarePayWarning,
  setCommentQuestionGUID,
  setMonitorStatusAlready
) => {
  const [detectPopActionInStatusPage, setDetectPopActionInStatusPage] = useState(false);
  const [shouldBlock, setShouldBlock] = useState(true);
  const firebase = useContext(FirebaseContext);
  const ref = firebase.database();
  const history = useHistory();
  const [monitoringGUID, setMonitoringGUID] = useState('');
  /** 紀錄上次檢查結果與這次檢查結果是否相同 => 有可能會出現 firebase 比較早觸發造成訂單狀態來不及更新的情形
   *
   * const [orderStatusBuffer, setOrderStatusBuffer] = useState<{
   *   cancelSource: number;
   *   isCancel: boolean;
   *   status: number;
   * }>({ cancelSource: 0, isCancel: false, status: 0 });
   */
  const [checkAgainTimer, setCheckAgainTimer] = useState<ReturnType<typeof setTimeout> | null>(
    null
  );
  const retryTimePeriod = 3 * 1000;

  useEffect(() => {
    if (detectPopActionInStatusPage) {
      console.log('[detectPopActionInStatusPage history.location]', history.location);
      // * 這邊會取到返回時的網址, 先改用 localStorage
      if (
        sessionStorage.getItem('page') === 'WaitTaxiPage' ||
        sessionStorage.getItem('page') === 'CarPage' ||
        sessionStorage.getItem('page') === 'CarRedispatchPage'
      ) {
        toggleCancelModel();
      } else if (
        sessionStorage.getItem('page') === 'ArrivedPage' ||
        sessionStorage.getItem('page') === 'SharePage'
      ) {
        toggleLockModal();
      } else {
        if (cancelModel.status()) toggleCancelModel();
        if (lockModal.status()) toggleLockModal();
      }
      setDetectPopActionInStatusPage(false);
    }
  }, [detectPopActionInStatusPage]);

  useEffect(() => {
    if (shouldBlock) {
      document.addEventListener('visibilitychange', async () => {
        if (document.visibilityState === 'visible') {
          const lastTimeGUID = sessionStorage.getItem('MONITOR_GUID');
          const isOnlinePay = sessionStorage.getItem('MONITOR_IS_ONLINE_PAY') === 'true';
          console.log('lastTimeGUID', lastTimeGUID);
          if (lastTimeGUID) firebaseTriggerToInspectOrder(lastTimeGUID, isOnlinePay);
        }
      });
      history.listen((newLocation, action) => {
        // * 只要 action === 'POP', newLocation 是狀態頁面的, 全部拒絕
        // * 只要 action === 'POP', newLocation 是等車或車子資訊的, 彈出取消視窗
        console.log('%c History Change', 'color: lightgreen; font-size: 25px');
        console.table({
          ...newLocation,
          action,
        });
        const { pathname, state = {} } = newLocation;
        if (action === 'PUSH' && pathname === '/' && state.initMainPage) {
          // * 重置循環比對
          // setOrderStatusBuffer({ cancelSource: 0, isCancel: false, status: 0 });
          sessionStorage.removeItem('orderStatusBuffer');
          sessionStorage.removeItem('page');
          setDetectPopActionInStatusPage(true);
        }
      });
      const unblock = history.block((location, action) => {
        const { pathname, state = {} } = location;
        if (action === 'POP' && pathname === '/' && state.statusBuffer) {
          console.log('%c Block History ', 'color: red; font-size: 25px');
          setDetectPopActionInStatusPage(true);
          return false;
        }
        return true;
      });
      return () => {
        unblock();
      };
    }
  }, [shouldBlock]);

  const startStatusMonitor = (guid, isOnlinePay) => {
    setMonitorStatusAlready(false);
    console.log('%c isOnlinePay', 'font-size: 25px');
    console.log(isOnlinePay);
    setShouldBlock(true);
    sessionStorage.setItem('MONITOR_GUID', guid);
    sessionStorage.setItem('MONITOR_IS_ONLINE_PAY', isOnlinePay);
    setMonitoringGUID(guid);
    // * 創建狀態頁使用之 buffer history, 返回時會偵測到 "status-Buffer", 從而進行阻擋
    const location = {
      pathname: `/`,
      state: { statusBuffer: true },
    };
    history.push(location);
    ref.ref(`KingnetSmartTaxi/${guid}`).on('value', (snapshot) => {
      // * 提取 Firebase 提示視窗資料
      console.log('%c KingnetSmartTaxi Trigger', 'font-size: 25px');
      if (snapshot.val()) firebaseTriggerToInspectOrder(guid, isOnlinePay);
    });

    ref.ref(`KingnetSmartTaxiDetail/${guid}`).on('value', (snapshot) => {
      // * 提取 Firebase 提示視窗資料
      console.log('%c KingnetSmartTaxiDetail Trigger', 'font-size: 25px');
      if (snapshot.val() || sessionStorage.getItem('page'))
        firebaseTriggerToInspectOrder(guid, isOnlinePay);
    });
  };

  const startStatusMonitorOff = () => {
    // * 重置循環比對
    // setOrderStatusBuffer({ cancelSource: 0, isCancel: false, status: 0 });
    sessionStorage.removeItem('orderStatusBuffer');
    sessionStorage.removeItem('MONITOR_GUID');
    sessionStorage.removeItem('MONITOR_IS_ONLINE_PAY');
    ref.ref(`KingnetSmartTaxi/${monitoringGUID}`).off();
    ref.ref(`KingnetSmartTaxiDetail/${monitoringGUID}`).off();
    window.localStorage.removeItem('GUID');
    setMonitorStatusAlready(true);
  };
  const formatUberOrder = (order) => {
    // 轉換成transferRawOrder所需資料的格式
    console.log('%c formatUberOrder', 'font-size: 30px');
    console.log(order);
    return {
      guid: order.tripGuid,
      create_time: order.tripCreateUtc,
      outset: order.pickupAddress,
      target: {
        destination: order.dropoffAddress,
      },
      fleet_info: {
        tel: order.uberDriverPhone,
        fleet: order.uberProductName,
      },
      dispatch: {
        create_date: order?.dispatch?.utc,
        license_plate: order.uberVehicleLicensePlate,
        minute: order?.dispatch?.minutesToDestination,
      },
      get_into: {
        create_time: order?.getInto?.utc,
      },
      get_to: {
        create_time: order?.getTo?.utc,
      },
      get_out: {
        create_time: order?.getOut?.utc,
      },
      redispatch: {
        create_time: order?.redispatch?.utc,
      },
      is_cancel: order.isCancel,
      cancel_source: order.cancelSource,
      fare: {
        origin: order.ledgeAmount,
        final: order.uberFare,
      },
    };
  };
  const handleGUIDSearchUberOrder = (guid: number) =>
    taxiTripAPI
      .GetRegisteredUberTaxiTrip({ tripGuid: guid })
      .then(([d]) => {
        console.log('%c 取到Uber叫車資訊', 'font-size: 30px; color: pink;');
        console.log('trip', d);
        if (d) {
          return formatUberOrder(d.data.data.trip);
        }
      })
      .then((formatD) => [transRawOrder(formatD, true), null])
      .catch((error) => {
        console.log(error);
      });

  const handleGUIDSearchOrder = (guid: number) =>
    api
      .kingnetTaxiGUIDSearchOrders({ guid, MaxCount: 1 })
      .then((d): [{ isCancel: boolean; cancelSource: number; status: number }, null] => [
        transRawOrder(d.data.Data.orders[0], false),
        null,
      ])
      .catch((_): [null, true] => [null, true]);

  let cancelFareWarningTimeoutId;
  let cancelFarePayWarningTimeoutId;

  const setWarningTimeouts = (createDate: Date) => {
    const now = new Date();
    // Adjust for time zone: UTC+8
    createDate.setHours(createDate.getHours() + 8);

    const diffInMilliseconds = now.getTime() - createDate.getTime();

    cancelFareWarningTimeoutId = setTimeout(() => {
      setShowCancelFareWarning(true);
    }, 165000 - diffInMilliseconds); // Remaining time

    cancelFarePayWarningTimeoutId = setTimeout(() => {
      setShowCancelFarePayWarning(true);
    }, 180000 - diffInMilliseconds); // Remaining time
  };

  // * 定義訂單上的欄位推理結果
  const transRawOrder = (order: Order, isOnlinePay: boolean) => {
    console.log('%c transRawOrder', 'font-size: 30px');
    console.log(order);
    let cancelSource = 0;

    // * 後端無車時 cancelSource 會等於 2 ( 無訂單資料 )
    // * 後端廠商取消時 cancelSource 也會等於 2 ( 有訂單資料 )
    if (order.is_cancel) {
      if (isOnlinePay) {
        console.log('%c 訂單已被取消', 'font-size: 30px');
        cancelSource = order.cancel_source;
      } else if (order.dispatch.create_date) {
        console.log('%c 訂單已被車隊取消', 'font-size: 30px');
        cancelSource = 3;
      } else {
        console.log('%c 訂單無車自動取消', 'font-size: 30px');
        cancelSource = order.cancel_source;
      }
    }

    const isCancel = !!order.is_cancel;
    let status = 0;

    if (order.dispatch.create_date && order.payMethod !== TRIP_PAYMENT_METHOD.CASH) {
      // Clear the previous timeouts if they exist
      if (cancelFareWarningTimeoutId) {
        clearTimeout(cancelFareWarningTimeoutId);
      }
      if (cancelFarePayWarningTimeoutId) {
        clearTimeout(cancelFarePayWarningTimeoutId);
      }

      setWarningTimeouts(new Date(order.dispatch.create_date));
    }

    // * 未下車前都應該持續關注此訂單
    setDriverDispatch({
      guid: order.guid,
      createTime: order.create_time,
      license_plate: order.dispatch?.license_plate,
      originPlace: order.outset,
      destinationPlace: order.target?.destination,
      phone: order.fleet_info?.tel,
      fleet: order.fleet_info?.fleet,
      minute: order.dispatch?.minute,
    });
    // * API 取消來源 1使用者 2廠商 3平台管理者

    // * cancelSource 0 沒有被取消
    // * cancelSource 1 無車
    // * cancelSource 2 司機 GG
    // * cancelSource 3 系統 GG
    // * cancelSource 21  Uber司機未知原因取消、要收手續費40元
    // * cancelSource 22  Uber司機找不到乘客取消、要收手續費40元
    // * cancelSource 23  最新的預估車資大於原本預估車資
    // * cancelSource 24 Uber異常

    if (order.is_cancel) {
      // * 訂單已被取消
      console.log('%c 訂單已被取消', 'font-size: 30px');
      status = 0;
    } else if (order.get_out.create_time) {
      // * 乘客已經下車
      console.log('%c  乘客已經下車', 'font-size: 30px');
      status = 5;
    } else if (order.get_into.create_time) {
      // * 訂單上車欄位啟動
      console.log('%c  訂單上車欄位啟動', 'font-size: 30px');
      status = 4;
    } else if (order.get_to.create_time) {
      // * 訂單抵達欄位啟動
      console.log('%c  訂單抵達欄位啟動', 'font-size: 30px');
      status = 3;
    } else if (order.redispatch?.create_date) {
      // * 訂單已被派遣
      console.log('%c  訂單已重新搜車', 'font-size: 30px');
      status = 6;
    } else if (order.dispatch.create_date) {
      // * 訂單已被派遣
      console.log('%c  訂單已被派遣', 'font-size: 30px');
      status = 2;
    } else {
      // * 訂單已被建立
      console.log('%c  訂單已被建立', 'font-size: 30px');
      status = 1;
    }

    const fare = { origin: order?.fare?.origin, final: order?.fare?.final };

    return {
      cancelSource,
      isCancel,
      status,
      fare,
    };
  };

  const firebaseTriggerToInspectOrder = async (guid, isOnlinePay: boolean, retry?: number) => {
    // * 檢查目前 history 是在哪一頁
    const { pathname, state = {} } = history.location;
    console.log('%c  firebaseTriggerToInspectOrder', 'font-size: 30px; color: green;');
    console.log(`[firebaseTriggerToInspectOrder history.location ${guid}]`, history.location);
    let historyAction = 'replace';
    if (pathname === '/' && state['statusBuffer']) {
      // * buffer => push, other => replace ( no change )
      console.log('detect firebase statusBuffer');
      historyAction = 'push';
    }
    // * 這邊可能要過一層 API 轉換層, 訂單 API 過於未經處理, 會造成非常多邏輯堆疊
    const [firstOrder] = isOnlinePay
      ? await handleGUIDSearchUberOrder(guid)
      : await handleGUIDSearchOrder(guid);
    if (firstOrder !== null) {
      // * 檢查是否與上次檢查結果相同
      const newDataSq = Object.entries(firstOrder).map(([key, value]) => `${key}-${value}`);
      // const oldDataSq = Object.entries(orderStatusBuffer).map(([key, value]) => `${key}-${value}`);
      const oldDataSq = Object.entries(
        JSON.parse(
          sessionStorage.getItem('orderStatusBuffer') ||
            JSON.stringify({ cancelSource: 0, isCancel: false, status: 0 })
        )
      ).map(([key, value]) => `${key}-${value}`);
      const result = [...new Set([...newDataSq, ...oldDataSq])].length === oldDataSq.length;
      console.log('比對 newDataSq', newDataSq);
      console.log('比對 oldDataSq', oldDataSq);
      console.log('比對結果', result);
      console.log('重新注冊次數', retry || 3);
      if (retry !== 0) {
        if (result) {
          // * 跟上次結果一樣, 註冊下一次定時更新
          const currentRetry = retry || 3;
          if (checkAgainTimer) clearTimeout(checkAgainTimer);
          setCheckAgainTimer(
            setTimeout(
              () => firebaseTriggerToInspectOrder(guid, isOnlinePay, currentRetry - 1),
              retryTimePeriod
            )
          );
          return;
        } else {
          sessionStorage.setItem('orderStatusBuffer', JSON.stringify(firstOrder));
          // setOrderStatusBuffer(firstOrder);
        }
      } else {
        return;
      }
      // * 檢查完畢
      const location: Record<string, any> = {
        pathname: `/`,
        state: { initMainPage: true },
      };
      let changeHistory = true;
      // * 跳轉前 假設取消彈窗是開著的話, 自動關閉
      if (cancelModel.status()) toggleCancelModel();
      if (firstOrder.isCancel) {
        // * 訂單被取消, 需要看取消來源
        if (firstOrder.cancelSource === 2) {
          sessionStorage.removeItem('page');
          // * 無車就不用在監聽任何事了
          startStatusMonitorOff();
          location.pathname = '/NoCarPage';
          location.state = undefined;
        } else if ([3, 21, 22, 25].includes(firstOrder.cancelSource)) {
          sessionStorage.removeItem('page');
          startStatusMonitorOff();
          location.pathname = '/DriverAccidentPage';
          location.search = `?cancelSource=${firstOrder.cancelSource}`;
          location.state = undefined;
        } else if (firstOrder.cancelSource === 23) {
          sessionStorage.removeItem('page');
          // 最新的預估車資大於原本預估車資
          startStatusMonitorOff();
          location.pathname = '/EstimatedPriceChangePage';
          location.state = undefined;
        } else if (firstOrder.cancelSource === 24) {
          sessionStorage.removeItem('page');
          // 司機端系統異常
          startStatusMonitorOff();
          location.pathname = '/DriverErrorPage';
          location.state = undefined;
        }
      } else {
        // *  正常情形下, 根據狀態進行跳轉
        if (firstOrder.status === 1) {
          sessionStorage.setItem('page', 'WaitTaxiPage');
          location.pathname = '/WaitTaxiPage';
        } else if (firstOrder.status === 2) {
          sessionStorage.setItem('page', 'CarPage');
          location.pathname = '/CarPage';
        } else if (firstOrder.status === 3) {
          sessionStorage.setItem('page', 'ArrivedPage');
          location.pathname = '/ArrivedPage';
        } else if (firstOrder.status === 4) {
          sessionStorage.setItem('page', 'SharePage');
          location.pathname = '/SharePage';
        } else if (firstOrder.status === 5) {
          if (!evaluationModal.status()) toggleEvaluationModal();
          if (checkAgainTimer) clearTimeout(checkAgainTimer);
          startStatusMonitorOff();
          history.replace({
            pathname: '/',
            state: undefined,
          });
          history.push({
            pathname: '/',
            state: { statusBuffer: false, skipCheckUnpaid: true },
          });
          setShouldBlock(false);
          changeHistory = false;
          sessionStorage.removeItem('page');
          setCommentFareInfo({
            isOnlinePay: true,
            fare: firstOrder.fare.final,
            originFare: firstOrder.fare.origin,
            showPrice: true,
          });
          setCommentQuestionGUID(guid);
        } else if (firstOrder.status === 6) {
          sessionStorage.setItem('page', 'CarRedispatchPage');
          location.pathname = '/CarRedispatchPage';
        }
      }
      if (changeHistory) {
        history[historyAction](location);
      }
      setMonitorStatusAlready(true);
    }
  };

  return {
    startStatusMonitor,
    startStatusMonitorOff,
  };
};
