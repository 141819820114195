import Clipboard from 'react-clipboard.js';
import Modali, { useModali } from '../../components/Modal';
import { useGoogleAdBanner } from '../../components/googleAdBanner';
import { setToastAppear } from 'components/Toast/Toast';

export default function SharePage(props) {
  const { GoogleAdBanner } = useGoogleAdBanner();
  // * 車隊名稱 - props.driverDispatch.fleet
  // * 車牌號碼 - props.driverDispatch.license_plate
  // * 抵達時間 - props.driverDispatch.minute
  // * 司機電話 - props.driverDispatch.phone

  // * 等以後智生活開放自定義再改
  // const share = () => {
  //   window.location.replace(
  //     `${process.env.REACT_APP_API_ENDPOINT}?KNSTMethod=share&title=${encodeURIComponent(shareText)}`
  //   );
  // };

  const shareText = () => `社區叫車
    我目前正搭乘的計程車資訊在此分享給您！
    車隊名稱： ${props.driverDispatch.fleet || '無資料'}
    車牌號碼： ${props.driverDispatch.license_plate || '無資料'}
    上車地點：${props.driverDispatch.originPlace || '無填寫'}
    下車地點：${props.driverDispatch.destinationPlace || '無填寫'}`;

  const [shareModal, toggleShareModal] = useModali({
    isDrawer: true,
    animated: true,
    buttons: [
      <Modali.Button
        key="ModaliButton"
        label="取消"
        isStyleDestructive
        onClick={() => toggleShareModal()}
      />,
    ],
  });

  function copySuccess() {
    toggleShareModal();
    setToastAppear('複製成功');
  }

  return (
    <div className="statusPage">
      <div className="statusPage-body">
        <div className="statusPage-img h-200 margin-top-0 rwd-scale">
          <div className="statusPage-asset statusPage-back-s" />
          <div className="statusPage-asset statusPage-phone-s" />
          <div className="statusPage-asset statusPage-sign" />
          <div className="statusPage-asset statusPage-waveBox">
            <div className="statusPage-wave" />
          </div>
        </div>
        <div className="statusPage-list">
          <div>
            <span>車隊名稱</span>
            <span>{props.driverDispatch.fleet}</span>
          </div>
          <div>
            <span>車牌號碼</span>
            <span>{props.driverDispatch.license_plate}</span>
          </div>
          <div>
            <span>上車地點</span>
            <span>{props.driverDispatch.originPlace}</span>
          </div>
          <div>
            <span>下車地點</span>
            <span>{props.driverDispatch.destinationPlace || '無填寫'}</span>
          </div>
        </div>
      </div>
      <div className="statusPage-footer two-btn">
        <button className="btn-alert" onClick={() => (location.href = `tel:110`)}>
          SOS
        </button>
        <button className="btn-fill" onClick={toggleShareModal}>
          分享乘車資訊
        </button>
      </div>
      {GoogleAdBanner}
      <Modali.Modal {...shareModal}>
        <div className="drawerModal__button">
          <Clipboard option-text={shareText} onSuccess={() => copySuccess()}>
            <div>複製到剪貼簿</div>
          </Clipboard>
        </div>
      </Modali.Modal>
    </div>
  );
}
