import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export default function EstimatedPriceChangePage(props) {
  const history = useHistory();

  useEffect(() => {
    props.setHistoryResult((oldArray) => {
      if (oldArray.length !== 0 && oldArray[0].isCancel && oldArray[0].cancelSource === 'fake-1') {
        oldArray.shift();
        return [...oldArray];
      } else {
        return oldArray;
      }
    });
  }, []);

  const backToHomePage = () => {
    props.setOrderPlaceDetail();
    history.push('/');
  };

  return (
    <div className="statusPage">
      <div className="statusPage-header">
        <span>行程金額異動</span>
        <p>{'很抱歉，因行程金額異動，'}</p>
        <p>{'請返回首頁確認車資後重新叫車。'}</p>
      </div>
      <div className="statusPage-body">
        <div className="statusPage-img rwd-scale rwd-driver-accident">
          <div className="statusPage-asset statusPage-driver-accident-board" />
          <div className="statusPage-asset statusPage-driver-accident" />
          <div className="statusPage-asset statusPage-driver-accident-sorry" />
        </div>
      </div>
      <div className="statusPage-footer">
        <button className="btn-default" onClick={backToHomePage}>
          返回首頁，重新叫車
        </button>
      </div>
    </div>
  );
}
