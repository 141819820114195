import React from 'react';

import BottomPopup from '../Popup/BottomPopup';
import DiscountInput from './DiscountInput';
import DiscountList from './DiscountList';

interface DiscountPopupProps {
  isOpen: boolean;
  onClose: () => void;
  footerHeight: number;
}

const DiscountPopup: React.FC<DiscountPopupProps> = ({ isOpen, onClose, footerHeight }) => {
  return (
    <BottomPopup
      startBottom={footerHeight + 'px'}
      title="優惠與推薦"
      isOpen={isOpen}
      onClose={onClose}
    >
      <div>
        <DiscountInput />
      </div>
      <div>
        <DiscountList />
      </div>
    </BottomPopup>
  );
};

export default DiscountPopup;
