export default function WaitTaxiPage() {
  const devText =
    process.env.REACT_APP_ENV === 'development' ? `\n${localStorage.getItem('GUID')}` : undefined;

  return (
    <div className="statusPage">
      <div className="statusPage-header busy-notice">
        <span>{'系統正在為您找尋車輛中 \n 請稍候...'}</span>
        {devText && (
          <>
            <p style={{ bottom: '-32px' }}>{devText}</p>
            <p style={{ color: 'red', bottom: '-60px' }}>dev test only</p>
          </>
        )}
      </div>
      <div className="statusPage-body">
        <div className="statusPage-img rwd-scale">
          <div className="statusPage-asset statusPage-back" />
          <div className="statusPage-asset statusPage-phone" />
          <div className="statusPage-asset statusPage-waitTaxi-taxi" />
          <div className="statusPage-asset statusPage-glass" />
        </div>
      </div>
      <div className="statusPage-footer">
        <button className="btn-cancel" onClick={() => window.history.back()}>
          取消叫車
        </button>
      </div>
    </div>
  );
}
