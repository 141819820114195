import './logList.scss';

export default function LogPage(props) {
  return (
    <div className="list">
      <div className="listItem__header">
        <i className="icon-bin" aria-hidden="true" />
        <span>Log紀錄</span>
      </div>
      <div className="listItem__scrollBox">
        <div className="logList-dashboard">
          <h2>快速通道</h2>
          <div className="logList-card">
            <span onClick={() => props.testPage('car')}>資訊頁面</span>
            <span onClick={() => props.testPage('share')}>分享頁面</span>
            <span onClick={() => props.testPage('wait')}>等車</span>
            <span onClick={() => props.testPage('nocar')}>沒車</span>
            <span onClick={() => props.testPage('arrived')}>抵達</span>
            <span onClick={() => props.testPage('driverError')}>廠商壞掉</span>
            <span onClick={() => props.testPage('driverAccident')}>廠商意外</span>
            <span onClick={() => props.testPage('carRedispatch')}>重新派車</span>
          </div>
          <h2>執行方法</h2>
          <div className="logList-card">
            <span onClick={() => props.toggleUserAgreementModal()}>規範</span>
            <span onClick={props.toggleEvaluationModal}>評價</span>
            <span onClick={props.toggleCancelEventModal}>取消車子</span>
            <span onClick={() => window.localStorage.removeItem('ecsToken')}>刪除 token</span>
            <span
              onClick={() => {
                props.setGiftModalContent(
                  <p>
                    恭喜您獲得了【紅利點數 <span className="primary bold">10</span> 點】
                    <br />
                    點數已存入您的帳戶！
                  </p>
                );
                props.toggleGiftReceivedModal();
              }}
            >
              開啟禮物彈窗
            </span>
            <span
              onClick={() => {
                props.toggleSelectTicketModal();
              }}
            >
              開啟優惠券選擇彈窗
            </span>
            <span>
              <a href="tel:110">測試撥打電話</a>
            </span>
          </div>
        </div>
        {props.logListResult.map((e, i) => (
          <div key={i} className="listItem" style={{ padding: '0 2rem', height: 'auto' }}>
            <div className="listItem__text">
              <span style={{ userSelect: 'text' }}>{JSON.stringify(e)}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
