import { isObject, isArray } from './is';

interface CodeErrorModel {
  Code: number;
  Message: string;
}

/**
 * @description 100 % 保證成功包裝, 指定成功結果為 Array[0], 錯誤結果為 Array[1]
 */
export function parseApiStatus<T>(promise: Promise<T>): Promise<[T, null] | [null, CodeErrorModel]>;
export function parseApiStatus<T, R>(
  promise: Promise<T>,
  bff: (res: T) => R
): Promise<[R, null] | [null, CodeErrorModel]>;
export function parseApiStatus<T, R>(
  promise: Promise<T>,
  bff?: (res: T) => R
): Promise<[T | R, null] | [null, CodeErrorModel]> {
  return bff
    ? promise.then((d: T): [R, null] => [bff(d), null]).catch((e) => [null, e])
    : promise.then((d: T): [T, null] => [d, null]).catch((e) => [null, e]);
}

/**
 * @description 轉換 前端 Value - interface 成 後端 Value - interface, 小駝峰轉大駝峰
 */
export function ToCapitalize<T>(valueObj: T[]): Array<TransCapitalize<T>>;
export function ToCapitalize<T>(valueObj: T): TransCapitalize<T>;
export function ToCapitalize(valueObj) {
  if (isArray(valueObj)) {
    return valueObj.map((v) => ToCapitalize(v));
  }

  if (isObject(valueObj)) {
    const entries = Object.entries(valueObj);
    const mappedEntries = entries.map(([k, v]) => [
      `${k.substr(0, 1).toUpperCase()}${k.substr(1)}`,
      ToCapitalize(v),
    ]);
    return Object.fromEntries(mappedEntries);
  }

  return valueObj;
}

/**
 * @description 轉換 後端 Value - interface 成 前端 Value - interface, 大駝峰轉小駝峰
 */

export function ToUnCapitalize<T>(valueObj: T[]): Array<TransUncapitalize<T>>;
export function ToUnCapitalize<T>(valueObj: T): TransUncapitalize<T>;
export function ToUnCapitalize(valueObj) {
  if (isArray(valueObj)) {
    return valueObj.map((v) => ToUnCapitalize(v));
  }

  if (isObject(valueObj)) {
    const entries = Object.entries(valueObj);
    const mappedEntries = entries.map(([k, v]) => [
      `${k.substr(0, 1).toLowerCase()}${k.substr(1)}`,
      ToUnCapitalize(v),
    ]);
    return Object.fromEntries(mappedEntries);
  }

  return valueObj;
}

export function superEntity<T>(tagArray: Array<T>): Array<T>;
export function superEntity<T, U extends keyof T>(
  tagArray: Array<T>,
  key1: U,
  ...restKey: Array<U>
): EntityState<T[U]>;
export function superEntity<T, U extends keyof T>(
  tagArray: Array<T>,
  key1?: U,
  ...restKey: Array<U>
): Array<T> | EntityState<T[U]> {
  // * recurse end
  if (!key1) return tagArray;

  // * do ids
  const ids = [...new Set(tagArray.map((tag) => tag[key1]))];

  // * do Entity
  const finalEntity = ids.reduce((entity, id) => {
    const [newKey1, ...restNewKey] = restKey;
    entity[id as unknown as string | number] = superEntity(
      tagArray.filter((tag) => tag[key1] === id),
      newKey1,
      ...restNewKey
    );
    return entity;
  }, {});

  return { ids, entity: finalEntity };
}

export interface EntityState<T> {
  ids: Array<T>;
  entity: Dictionary<T>;
}

interface DictionaryNum<T> {
  [id: number]: T | undefined;
}

interface Dictionary<T> extends DictionaryNum<T> {
  [id: string]: T | undefined;
}

// * 使用於轉換後端 Model 至前端 BFF Model 所使用
// * 支援簡單巢狀結構與陣列型態與可選型態
/**
 * @description 轉換 前端 Interface 成 後端 Interface, 小駝峰轉大駝峰
 */
export type TransCapitalize<T> = {
  [P in keyof T as `${Capitalize<string & P>}`]: Exclude<T[P], undefined> extends number | string
    ? T[P]
    : Exclude<T[P], undefined> extends Array<infer E>
    ? Array<TransCapitalize<E>>
    : TransCapitalize<T[P]>;
};

/**
 * @description 轉換 後端 Interface 成 前端 Interface, 大駝峰轉小駝峰
 */
export type TransUncapitalize<T> = {
  [P in keyof T as `${Uncapitalize<string & P>}`]: Exclude<T[P], undefined> extends number | string
    ? T[P]
    : Exclude<T[P], undefined> extends Array<infer E>
    ? Array<TransUncapitalize<E>>
    : TransUncapitalize<T[P]>;
};

export function convertFullToHalf(str: string) {
  if (!str) return;
  let temp = '';
  for (let i = 0; i < str.length; i++) {
    // space
    if (str.charCodeAt(i) === 12288) {
      temp += String.fromCharCode(str.charCodeAt(i) - 12256);
      continue;
    }
    // full-width
    if (str.charCodeAt(i) > 65280 && str.charCodeAt(i) < 65375) {
      temp += String.fromCharCode(str.charCodeAt(i) - 65248);
    } else {
      temp += String.fromCharCode(str.charCodeAt(i));
    }
  }
  return temp;
}
