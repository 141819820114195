import { useHistory } from 'react-router-dom';
import { kingnetTaxiInsertCustomerGetInfoReply } from '../../api/index';
import { CallUberTaxiPickUp } from '../../api/taxiTrip';
import { useGoogleAdBanner } from '../../components/googleAdBanner';

export default function CarArrivedPage(props) {
  // * 車隊名稱 - props.driverDispatch.fleet
  // * 車牌號碼 - props.driverDispatch.license_plate
  // * 抵達時間 - props.driverDispatch.minute
  // * 司機電話 - props.driverDispatch.phone

  const history = useHistory();
  const { GoogleAdBanner } = useGoogleAdBanner();

  const contactDriver = () =>
    (location.href = `tel:${
      props.driverDispatch && props.driverDispatch.phone ? props.driverDispatch.phone : ''
    }`);

  // * 反模式 Smart UI
  const userToggleGetInTo = async () => {
    if (props.isOnlinePay) {
      await CallUberTaxiPickUp({ tripGuid: props.driverDispatch.guid });
    } else {
      await kingnetTaxiInsertCustomerGetInfoReply({ orderGuid: props.driverDispatch.guid });
    }
    sessionStorage.setItem('page', 'SharePage');
    const location = {
      pathname: '/SharePage',
      state: { initMainPage: true },
    };
    history.replace(location);
  };

  return (
    <div className="statusPage">
      <div className="statusPage-header">
        <span>司機已抵達，請至上車地點搭車</span>
      </div>
      <div className="statusPage-body">
        <div className="statusPage-img margin-top-0 rwd-scale">
          <div className="statusPage-asset statusPage-arrived" />
          <div className="statusPage-asset statusPage-taxi-animate" />
        </div>
        <div className="statusPage-list">
          <div>
            <span>車隊名稱</span>
            <span>{props.driverDispatch.fleet}</span>
          </div>
          <div>
            <span>車牌號碼</span>
            <span>{props.driverDispatch.license_plate}</span>
          </div>
          <div>
            <span>上車地點</span>
            <span>{props.driverDispatch ? props.driverDispatch.originPlace : 'unknown'}</span>
          </div>
        </div>
      </div>
      <div className="statusPage-footer two-btn">
        <button className="btn-default" onClick={contactDriver}>
          聯絡車隊
        </button>
        <button className="btn-fill" onClick={userToggleGetInTo}>
          我已上車
        </button>
      </div>
      {GoogleAdBanner}
    </div>
  );
}
