import { FC } from 'react';
import BottomPopup from './Popup/BottomPopup';

import { useDiscount, BONUS_STATES } from 'context/DiscountProvider';
import { useEstimatedFare } from 'context/EstimatedFareProvider';

import './FeeDetailsBottomPopup.scss';

interface FeeDetailsBottomPopupProps {
  isOpen: boolean;
  onClose: () => void;
  footerHeight: number;
}

const FeeDetailsBottomPopup: FC<FeeDetailsBottomPopupProps> = ({
  onClose,
  isOpen,
  footerHeight,
}) => {
  const { bonusState, discountTotal } = useDiscount();

  const bonusAmount = bonusState.state === BONUS_STATES.USED ? bonusState.price : 0;
  const discountAmount = discountTotal - bonusAmount;

  return (
    <BottomPopup
      startBottom={footerHeight + 'px'}
      title="費用詳情"
      isOpen={isOpen}
      onClose={onClose}
    >
      <FeeDetailsDisplay
        bonusAmount={bonusAmount}
        discountAmount={discountAmount}
        discountTotal={discountTotal}
      ></FeeDetailsDisplay>
    </BottomPopup>
  );
};

interface FeeDetailsDisplayProps {
  bonusAmount: number;
  discountAmount: number;
  discountTotal: number;
}

const FeeDetailsDisplay: FC<FeeDetailsDisplayProps> = ({
  bonusAmount,
  discountAmount,
  discountTotal,
}) => {
  const { estimatedFare } = useEstimatedFare();
  const estimatedFareDisplay = Math.max(0, (estimatedFare?.fare ?? 0) - discountTotal);
  return (
    <div className="fee-details-display">
      <div className="fee-details-display__subtotal">
        <div className="fee-details-display__subtotal-item">
          <span className="fee-details-display__label">小計：</span>
          <span className="fee-details-display__price">${estimatedFare.fare}</span>
        </div>
        <div className="fee-details-display__subtotal-item">
          <span className="fee-details-display__label">優惠折抵：</span>
          <span className="fee-details-display__price">-${discountAmount}</span>
        </div>
        <div className="fee-details-display__subtotal-item">
          <span className="fee-details-display__label">紅利：</span>
          <span className="fee-details-display__price">-${bonusAmount}</span>
        </div>
      </div>
      <div className="fee-details-display__total">
        <div className="fee-details-display__total-item">
          <span className="fee-details-display__sum-label">預估車資</span>
          <span className="fee-details-display__sum-price">${estimatedFareDisplay}</span>
        </div>
      </div>
    </div>
  );
};

export default FeeDetailsBottomPopup;
