import { useState, useEffect, useRef } from 'react';

interface CountdownData {
  endTime: number;
  storedId: string;
  duration: number;
}

const useCountdown = () => {
  const [timeLeft, setTimeLeft] = useState<number>(0);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [counterId, setCounterId] = useState<string>('');
  const [duration, setDuration] = useState<number>(0);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (isActive && timeLeft > 0) {
      timerRef.current = setInterval(() => {
        setTimeLeft((prevTime) => {
          const newTime = prevTime - 60000; // 每分鐘減少 60000 毫秒
          return newTime < 0 ? 0 : newTime; // 確保不會小於零
        });
      }, 60000);
    } else if (timeLeft <= 0) {
      if (timerRef.current) {
        clearInterval(timerRef.current);
        timerRef.current = null;
      }
      setIsActive(false);
    }

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
        timerRef.current = null;
      }
    };
  }, [isActive, timeLeft, counterId, duration]);

  const startTimer = (inputDuration: number, id: string) => {
    // 清除之前的計時器
    if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }

    const savedData: CountdownData | null = JSON.parse(localStorage.getItem('countdown') || 'null');
    let remainingTime = 0;
    const now = Date.now();

    // 如果有存取的資料，且 id 與 duration 一致，則繼續倒數
    if (savedData && savedData.storedId === id && savedData.duration === inputDuration) {
      remainingTime = savedData.endTime - now;
      if (remainingTime < 0) remainingTime = 0;
    } else {
      if (localStorage.getItem('countdown')) {
        localStorage.removeItem('countdown');
      }
      remainingTime = inputDuration * 60000; // 轉換為毫秒
      localStorage.setItem(
        'countdown',
        JSON.stringify({ endTime: now + remainingTime, storedId: id, duration: inputDuration })
      );
    }

    setTimeLeft(remainingTime);
    setDuration(inputDuration);
    if (id) {
      setCounterId(id);
    }
    setIsActive(true);
  };

  const getMinutesLeft = () => {
    return Math.ceil(timeLeft / 60000);
  };

  const stopTimer = () => {
    setIsActive(false);
  };

  return {
    timeLeft,
    isActive,
    startTimer,
    getMinutesLeft,
    stopTimer,
  };
};

export default useCountdown;
