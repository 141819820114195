import React from 'react';
import Switch from 'components/Switch/Switch';
import { useSelectTicketModal } from 'components/SelectTicketModal';
import { useFetchDiscount } from 'hooks/useFetchDiscount';

import './DiscountList.scss';

import {
  useDiscount,
  REMOVE_DISCOUNT,
  REMOVE_TICKET,
  UPDATE_BONUS,
  BONUS_STATES,
} from 'context/DiscountProvider';

import { DiscountType, TicketInfo, BonusInfo, DiscountInfo } from 'types/discount';
import { useEstimatedFare } from 'context/EstimatedFareProvider';

// Define constants for status types
enum STATUS_TYPES {
  DELETE = 'delete',
  SWITCH = 'switch',
  DISABLE = 'disable',
  SELECT_TICKET = 'select-ticket',
}

type DiscountStatus = 'delete' | 'switch' | 'disable' | 'select-ticket';

interface DiscountListItem {
  key: DiscountType;
  icon: string;
  text: string;
  price: number;
  statusType: DiscountStatus;
  switchStatus?: boolean;
  message?: string;
  state?: string;
}

interface DiscountItemProps {
  discount: DiscountListItem;
  handleSwitchChange: (key: string, status: boolean) => void;
  handleDeleteDiscount: (key: string) => void;
  toggleSelectTicketModal: () => void;
}

// DiscountItem component
const DiscountItem: React.FC<DiscountItemProps> = ({
  discount,
  handleSwitchChange,
  handleDeleteDiscount,
  toggleSelectTicketModal,
}) => {
  return (
    <div key={discount.key} className="discount-list__item">
      <i className={`discount-list__item__icon ${discount.icon}`} />
      <span className="discount-list__item__type">{discount.text}</span>
      {discount.price > 0 && (
        <span className="discount-list__item__price">折抵${discount.price}</span>
      )}
      {discount.statusType === STATUS_TYPES.DELETE && (
        <div
          className="discount-list__item__status"
          onClick={() => {
            handleDeleteDiscount(discount.key);
          }}
        >
          <i className="discount-list__item__status-icon icon-close-circle" />
        </div>
      )}
      {discount.statusType === STATUS_TYPES.SWITCH && (
        <div className="discount-list__item__status">
          <Switch
            onClick={() => {
              handleSwitchChange(discount.key, !discount.switchStatus);
            }}
            active={discount.switchStatus || false}
          />
        </div>
      )}
      {discount.statusType === STATUS_TYPES.DISABLE && discount.message && (
        <div className="discount-list__item__status">
          <p className="discount-list__item__status-text disabled">{discount.message}</p>
        </div>
      )}
      {discount.statusType === STATUS_TYPES.SELECT_TICKET && (
        <div
          className="discount-list__item__status"
          onClick={() => {
            toggleSelectTicketModal();
          }}
        >
          <p className="discount-list__item__status-text disabled">選擇優惠券</p>
          <i className="discount-list__item__status-icon icon-arrow-right"></i>
        </div>
      )}
    </div>
  );
};

const DiscountList: React.FC = () => {
  const { SelectTicketModal, toggleSelectTicketModal } = useSelectTicketModal();

  const STATUS_TYPE_MAP: {
    [key: string]: ((discount) => DiscountStatus) | DiscountStatus;
  } = {
    recommend: 'delete',
    discount: 'delete',
    ticket: (discount) => (discount.price === 0 ? 'select-ticket' : 'delete'),
    bonus: (discount) =>
      [BONUS_STATES.USED, BONUS_STATES.NOT_USED].includes(discount.state || '')
        ? 'switch'
        : 'disable',
  };

  const DISCOUNT_TEXT_MAP = {
    recommend: '推薦優惠',
    discount: '折扣碼',
    ticket: '優惠券',
    bonus: '紅利點數',
  };

  const MESSAGES_MAP = {
    [BONUS_STATES.DISABLE]: '您的點數無法在此服務使用',
    [BONUS_STATES.EMPTY]: '尚無紅利可折抵',
  };

  const {
    ticketState,
    bonusState,
    discountState,
    ticketDispatch,
    bonusDispatch,
    discountDispatch,
  } = useDiscount();

  const { updateDiscountUsage } = useFetchDiscount();

  const { estimatedFare } = useEstimatedFare();
  const orderAmount = estimatedFare?.fare || 0;

  const discounts: DiscountListItem[] = [
    discountState ? createDiscountListItem(discountState as DiscountInfo) : null,
    createDiscountListItem(ticketState as TicketInfo),
    createDiscountListItem(bonusState as BonusInfo),
  ].filter(Boolean) as DiscountListItem[];

  function getStatusType(discount: DiscountInfo): DiscountStatus {
    const statusTypeFunction = STATUS_TYPE_MAP[discount.key];
    if (typeof statusTypeFunction === 'function') {
      return statusTypeFunction(discount);
    }
    return statusTypeFunction || 'disable';
  }

  function getDiscountText(key: DiscountType): string {
    return DISCOUNT_TEXT_MAP[key] || '';
  }

  function getMessages(discount): string {
    if (discount.key === 'bonus' && discount.state) {
      return MESSAGES_MAP[discount.state] || '';
    }
    return '';
  }

  function createDiscountListItem(discount): DiscountListItem {
    if (!discount) {
      return {
        key: 'ticket',
        icon: '',
        text: '',
        price: 0,
        statusType: 'disable',
        switchStatus: false,
        message: '',
        state: '',
      };
    }

    const key = discount.key;
    const iconMap = {
      recommend: 'icon-recommend',
      discount: 'icon-recommend',
      ticket: 'icon-ticket',
      bonus: 'icon-bonus',
    };

    return {
      key,
      icon: iconMap[key],
      text: getDiscountText(key),
      price: discount.price || 0,
      statusType: getStatusType(discount),
      switchStatus: discount.state === BONUS_STATES.USED,
      message: getMessages(discount),
    };
  }

  function handleSwitchChange(key: DiscountType, newStatus: boolean): void {
    if (key === 'bonus') {
      bonusDispatch({
        type: UPDATE_BONUS,
        payload: {
          key: 'bonus',
          state: newStatus ? BONUS_STATES.USED : BONUS_STATES.NOT_USED,
          price: bonusState.price,
          info: bonusState.info,
        },
      });
    }
  }

  function handleDeleteDiscount(key: DiscountType): void {
    switch (key) {
      case 'recommend':
      case 'discount':
        discountDispatch({
          type: REMOVE_DISCOUNT,
        });
        updateDiscountUsage({
          updatedOrderAmount: orderAmount,
          startTrigger: 'ticket',
        });
        break;
      case 'ticket':
        ticketDispatch({
          type: REMOVE_TICKET,
        });
        updateDiscountUsage({
          updatedOrderAmount: orderAmount - (discountState?.price || 0),
          startTrigger: 'bonus',
        });
        break;
      default:
        return;
    }
  }

  return (
    <>
      <div className="discount-list">
        {discounts.map((discount) => (
          <DiscountItem
            key={discount.key}
            discount={discount}
            handleSwitchChange={handleSwitchChange}
            handleDeleteDiscount={handleDeleteDiscount}
            toggleSelectTicketModal={toggleSelectTicketModal}
          />
        ))}
      </div>
      {SelectTicketModal}
    </>
  );
};

export default DiscountList;
