import React, { createContext, useReducer, useMemo } from 'react';
import {
  TicketState,
  BonusState,
  DiscountState,
  TicketAction,
  BonusAction,
  DiscountAction,
  DiscountContextType,
} from 'types/discount';

export const DiscountContext = createContext<DiscountContextType | undefined>(undefined);

export const UPDATE_DISCOUNT = 'UPDATE_DISCOUNT';
export const REMOVE_DISCOUNT = 'REMOVE_DISCOUNT';
export const UPDATE_TICKET = 'UPDATE_TICKET';
export const REMOVE_TICKET = 'REMOVE_TICKET';
export const UPDATE_BONUS = 'UPDATE_BONUS';

export const BONUS_STATES = {
  EMPTY: 'empty',
  DISABLE: 'disable',
  NOT_USED: 'notused',
  USED: 'used',
};

const initialTicketState: TicketState = { key: 'ticket', price: 0, info: {} };
const initialBonusState: BonusState = {
  key: 'bonus',
  state: BONUS_STATES.EMPTY,
  price: 0,
  info: {},
};
const initialDiscountState: DiscountState = null;

function ticketReducer(state: TicketState, action: TicketAction): TicketState {
  switch (action.type) {
    case UPDATE_TICKET:
      return action.payload;
    case REMOVE_TICKET:
      return {
        key: 'ticket',
        price: 0,
        info: {},
      };
    default:
      return state;
  }
}

function bonusReducer(state: BonusState, action: BonusAction): BonusState {
  switch (action.type) {
    case UPDATE_BONUS:
      return action.payload;
    default:
      return state;
  }
}

function discountReducer(state: DiscountState, action: DiscountAction): DiscountState {
  switch (action.type) {
    case UPDATE_DISCOUNT:
      return action.payload;
    case REMOVE_DISCOUNT:
      return null;
    default:
      return state;
  }
}

export function useDiscount() {
  const ctx = React.useContext(DiscountContext);
  if (!ctx) {
    throw new Error('useDiscount must be used within a DiscountContext');
  }
  return ctx;
}

export const DiscountProvider: React.FC = ({ children }) => {
  const [ticketState, ticketDispatch] = useReducer(ticketReducer, initialTicketState);
  const [bonusState, bonusDispatch] = useReducer(bonusReducer, initialBonusState);
  const [discountState, discountDispatch] = useReducer(discountReducer, initialDiscountState);

  const discountTotal = useMemo(() => {
    const total = ticketState.price + (discountState?.price || 0);
    return bonusState.state === BONUS_STATES.USED ? total + bonusState.price : total;
  }, [ticketState, bonusState, discountState]);

  const discountDetails = useMemo(() => {
    const useBonus = bonusState.state === BONUS_STATES.USED;
    return {
      ticketInstanceId: ticketState.info?.ticketInstanceId || null,
      ticketDiscountAmount: ticketState.price,
      useBonus,
      bonusUsed: useBonus ? bonusState.price : 0,
      discountCode: discountState?.code || null,
      discountAmount: discountState?.price || 0,
    };
  }, [ticketState, bonusState, discountState]);

  function resetAllDiscount() {
    ticketDispatch({ type: REMOVE_TICKET });
    bonusDispatch({ type: UPDATE_BONUS, payload: initialBonusState });
    discountDispatch({ type: REMOVE_DISCOUNT });
  }

  const discountContextData = useMemo(
    () => ({
      ticketState,
      bonusState,
      discountState,
      discountTotal,
      ticketDispatch,
      bonusDispatch,
      discountDispatch,
      resetAllDiscount,
      discountDetails,
    }),
    [ticketState, bonusState, discountState, discountTotal, discountDetails]
  );

  return (
    <DiscountContext.Provider value={discountContextData}>{children}</DiscountContext.Provider>
  );
};
