import React from 'react';
import './Switch.scss';

type SwitchProps = {
  active: boolean;
  onClick?: () => void;
};

const Switch: React.FC<SwitchProps> = ({ active, onClick }) => {
  return <div onClick={onClick} className={`switch-box ${active ? 'switch-box--checked' : ''}`} />;
};

export default Switch;
