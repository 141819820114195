import React, { useState, useEffect, useRef } from 'react';

import './SelectTicketModal.scss';

import Modali, { useModali } from './Modal';
import { Button } from './Button/Button';
import { setToastAppear } from './Toast/Toast';

import { useDiscount, UPDATE_TICKET } from 'context/DiscountProvider';
import { useEstimatedFare } from 'context/EstimatedFareProvider';

import { useFetchDiscount } from 'hooks/useFetchDiscount';

import { TicketResult } from 'api/taxiTrip/model/FEModel';

export const useSelectTicketModal = () => {
  const { ticketState, discountState, ticketDispatch } = useDiscount();
  const [selectTicketModal, toggleSelectTicketModal] = useModali({
    animated: true,
    backgroundColor: 'var(--systemWhite)',
    fullScreen: true,
    title: '',
    fullContent: true,
  });
  const { isTicketLoading, fetchDiscountTickets, updateDiscountBonus } = useFetchDiscount();
  const [ticketsData, setTicketData] = useState<TicketResult[]>([]);
  const [isCollapse, setIsCollapse] = useState(true);
  const [ticketListHeight, setTicketListHeight] = useState('0px');
  const ticketRef = useRef<HTMLDivElement>(null);

  const updateTicketListHeight = () => {
    const height = ticketRef?.current?.scrollHeight || 0;
    const ticketListHeight = `${height}px`;
    setTicketListHeight(ticketListHeight);
  };

  const { estimatedFare } = useEstimatedFare();
  const codeDiscountAmount = discountState?.price || 0;
  const orderAmount = estimatedFare?.fare - codeDiscountAmount || 0;

  const selectedTicketId = ticketState?.info?.ticketInstanceId || 0;

  function onTicketClick(val: TicketResult) {
    ticketDispatch({
      type: UPDATE_TICKET,
      payload: {
        key: 'ticket',
        price: val.orderDiscount,
        info: val,
      },
    });
  }

  function confirmUseTicket() {
    updateDiscountBonus();
    toggleSelectTicketModal();
  }

  useEffect(() => {
    async function fetchData() {
      if (selectTicketModal.status()) {
        const { res: ticketRes, error } = await fetchDiscountTickets();
        if (ticketRes) {
          setTicketData(ticketRes);
        }
        if (error) {
          setToastAppear('取得優惠券失敗，請稍後再試');
          setTimeout(() => {
            toggleSelectTicketModal();
          }, 300);
        }
      }
    }
    fetchData();
    setIsCollapse(true);
  }, [selectTicketModal.status()]);

  useEffect(() => {
    if (ticketRef.current && !isTicketLoading) {
      updateTicketListHeight();
    }
  }, [isCollapse, ticketRef.current]);

  const hasTicket = ticketsData.length > 0;

  const ModalComponent: React.ReactNode = (
    <Modali.Modal {...selectTicketModal}>
      <div className="select-ticket-modal">
        <div className="select-ticket-modal__title-wrap">
          <p className="select-ticket-modal__title">選擇優惠券</p>
          <div className="select-ticket-modal__info">
            <p className="select-ticket-modal__info__title">優惠券折抵</p>
            <div className="select-ticket-modal__info__spacer"></div>
            <div className="select-ticket-modal__discount-display">
              {hasTicket ? (
                selectedTicketId ? (
                  <span>
                    已使用優惠券折抵
                    <span className="select-ticket-modal__discount-display__price">
                      {' $'}
                      {ticketState.price}
                    </span>
                  </span>
                ) : (
                  <span className="select-ticket-modal__discount-display__select">
                    選擇一張優惠券折抵
                  </span>
                )
              ) : (
                <span>無符合折抵條件的優惠券</span>
              )}
            </div>
          </div>
        </div>
        <div className="select-ticket-modal__content">
          {(hasTicket || isTicketLoading) && (
            <>
              <div
                ref={ticketRef}
                style={{
                  maxHeight: isCollapse ? ticketListHeight : '',
                }}
                className={`select-ticket-modal__ticket-wrap ${
                  isCollapse || isTicketLoading ? '' : 'select-ticket-modal__ticket-wrap--collapse'
                }`}
              >
                {isTicketLoading ? (
                  <Ticket
                    isLoading={true}
                    ticketData={{}}
                    isActive={false}
                    onClick={() => {}}
                    isOverOrderAmount={false}
                  />
                ) : (
                  ticketsData.map((v) => (
                    <Ticket
                      key={v.ticketInstanceId}
                      isLoading={isTicketLoading}
                      ticketData={v}
                      isActive={v.ticketInstanceId === selectedTicketId}
                      onClick={(val) => onTicketClick(val)}
                      isOverOrderAmount={v.orderDiscount > orderAmount}
                    />
                  ))
                )}
              </div>
              {!isTicketLoading && ticketsData.length > 1 && (
                <div
                  className="select-ticket-modal__collapse-btn"
                  onClick={() => {
                    setIsCollapse(!isCollapse);
                  }}
                >
                  {isCollapse ? '顯示較少' : '顯示較多'}
                  <i className={`${isCollapse ? 'icon-arrow-up' : 'icon-arrow-down'}`}></i>
                </div>
              )}
            </>
          )}
        </div>
        <div className="select-ticket-modal__footer">
          <Button
            className="select-ticket-modal__footer__btn"
            width="full"
            color="primary"
            onButtonClick={() => confirmUseTicket()}
          >
            <span>確認</span>
          </Button>
        </div>
      </div>
    </Modali.Modal>
  );

  return {
    SelectTicketModal: ModalComponent,
    toggleSelectTicketModal,
  };
};

const Ticket = ({ ticketData, isLoading, isActive, onClick, isOverOrderAmount }) => {
  return isLoading ? (
    <div className="ticket-template ticket-template--fake">
      <div className="ticket-template__left">
        <div className="ticket-template__wrap--front"></div>
        <div className="ticket-template__wrap--middle">
          <p className="ticket-template__title ticket-template__line">&nbsp;</p>
          <p className="ticket-template__desc ticket-template__line">&nbsp;</p>
          <p className="ticket-template__date-time ticket-template__line">&nbsp;</p>
        </div>
        <div className="ticket-template__wrap--end"></div>
      </div>
      <div className="ticket-template__right">
        <div className="ticket-template__wrap--middle"></div>
      </div>
    </div>
  ) : (
    <div
      className={`ticket-template ${isActive ? 'ticket-template--active' : ''} ${
        isOverOrderAmount ? 'ticket-template--invalid' : ''
      }`}
      onClick={() => {
        if (isOverOrderAmount) return;
        onClick(ticketData);
      }}
    >
      {isOverOrderAmount && <div className="ticket-template__disable-mark">折扣超過訂單總額</div>}
      <div className="ticket-template__left">
        <div className="ticket-template__wrap--front"></div>
        <div className="ticket-template__wrap--middle">
          <p className="ticket-template__title">{ticketData.name}</p>
          <p className="ticket-template__desc">{ticketData.desc}</p>
          <p className="ticket-template__date-time">
            使用期限：{formatTicketTime(ticketData.validDue)}
          </p>
        </div>
        <div className="ticket-template__wrap--end"></div>
      </div>
      <div className="ticket-template__right">
        <div className="ticket-template__wrap--middle ticket-template__radio">
          <input type="radio" checked={isActive} onChange={() => {}} autoComplete="off" />
        </div>
      </div>
    </div>
  );
};

function formatTicketTime(time: string) {
  return time.split('T')[0].replace(/-/g, '/');
}
