import { isArray } from 'utils/is';

// 取得網址參數
export const getParams = (key: string): string | null => {
  return new URLSearchParams(window.location.search).get(key);
};

export function validateAndFormatAddress(city: string, area: string, address: string): string {
  if (address.includes(city)) {
    address = address.replace(city, '').trim();
  }

  if (!address.includes(area)) {
    address = area + address;
  }

  if (!address.includes(city)) {
    address = city + address;
  }

  return address;
}

export function extractAddress(address: string): string {
  const extractChars = address.slice(-15);

  const afterQu = extractChars.split('區').pop() || '';

  const afterLi = afterQu.split('里').pop() || '';

  const afterLin = afterLi.split('鄰').pop() || '';

  const beforeHao = afterLin.split('號')[0] + '號';

  return beforeHao === '號' ? '' : beforeHao;
}

// 切割地址前的 "台灣" ( 顯示長短問題 )
export function cutAddress(fullAddress) {
  if (!fullAddress) return '';
  const taiwanReg = /(^\u53f0\u7063)|(^\u81fa\u7063)/;
  const fullAddressBuf = fullAddress.replace(taiwanReg, '');

  let mainAddress = '歷史紀錄';
  const reg =
    /(^[\u4E00-\u9FA5]{2}\u5e02[\u4E00-\u9FA5]{1,2}\u5340)|(^[\u4E00-\u9FA5]{2}\u7e23[\u4E00-\u9FA5]{2}\u5e02)|(^[\u4E00-\u9FA5]{2}\u7e23)/;
  const reg2 = /(^[\u4E00-\u9FA5]{2}\u7e23)|(^[\u4E00-\u9FA5]{2}\u5e02)/;
  if (reg.test(fullAddressBuf)) {
    mainAddress = fullAddressBuf.replace(reg, '');
    return mainAddress;
  } else if (reg2.test(fullAddressBuf)) {
    mainAddress = fullAddressBuf.replace(reg2, '');
    return mainAddress;
  }
  return mainAddress;
}

export function isExpired(targetTime) {
  return Date.now() > new Date(targetTime).getTime();
}

/**
 * 格式化折扣文案
 *
 * @param {Array<Object>} discountList - 折扣列表
 * @param {string} discountList[].key - 折扣類型的鍵
 * @param {string} discountList[].prefix - 折扣類型的前綴
 * @param {number} discountList[].value - 折扣金額
 * @throws {Error} 如果 discountList 不是陣列，則拋出錯誤
 * @returns {string} 格式化後的折扣文案。如果沒有有效折扣，則返回空字符串
 */
export function formatDiscountText(discountList) {
  if (!isArray(discountList)) {
    throw new Error('discountList 必須是一個陣列');
  }

  const validatedDiscounts = discountList.map(({ key, prefix, value }) => ({
    key,
    prefix,
    value: Math.max(0, value),
  }));
  const discountShowText = validatedDiscounts
    .reduce((acc, { prefix, value }) => {
      return value > 0 ? [...acc, `${prefix}${value}`] : acc;
    }, [])
    .join('、');

  return discountShowText ? `(已使用${discountShowText})` : '';
}

export function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  console.log('results', results);
  if (!results) return null;
  if (results && !results[2]) return '';
  // return decodeURIComponent(results[2].replace(/\+/g, ' '));
  return results[2];
}
