import { cutAddress } from 'utils';
import { TransformedTrip, TripStatus } from 'types/trip';
import { TRIP_PAYMENT_METHOD } from 'constants/index';

function getCommentAverage(commentArray) {
  if (
    Object.prototype.toString.call(commentArray) === '[object Array]' &&
    commentArray[0] &&
    commentArray[0].score
  ) {
    const sum = commentArray.reduce((ac, cr) => ac + +cr.score, 0);
    return Math.round((sum / commentArray.length) * 10) / 10;
  } else {
    return null;
  }
}

const statusProperties = {
  [TripStatus.Cancelled]: { text: '已取消', color: 'disabled', status: TripStatus.Cancelled },
  [TripStatus.Unpaid]: { text: '待付款', color: 'systemOrange', status: TripStatus.Unpaid },
  [TripStatus.Completed]: { text: '已完成', color: 'primary', status: TripStatus.Completed },
};

function formatTripStatus({
  isCancel,
  isPaymentConfirmed,
  payMethod,
}: {
  isCancel: boolean;
  isPaymentConfirmed: boolean;
  payMethod: string;
}) {
  let statusId: TripStatus;
  const isCash = payMethod === TRIP_PAYMENT_METHOD.CASH;

  if (!isPaymentConfirmed && !isCash) {
    statusId = TripStatus.Unpaid;
  } else if (isCancel) {
    statusId = TripStatus.Cancelled;
  } else {
    statusId = TripStatus.Completed;
  }

  return statusProperties[statusId];
}

export function processHistoryApiResult(orderArray): TransformedTrip[] {
  return orderArray.map((order) => {
    const {
      guid,
      comment,
      status,
      is_cancel: isCancel,
      cancel_source: cancelSource,
      create_time: time,
      order_cancel,
      outset_lat: originLat,
      outset_lon: originLon,
      outset: originAddress,
      target: {
        destination: destinationAddress = '',
        destination_lat: destinationLat = 0,
        destination_lon: destinationLon = 0,
      } = {},
      dispatch: { license_plate: dispatchPlate = '', create_date: isDispatch = false } = {},
      fleet_info: { tel: fleetTel = '' } = {},
      hasReceipt,
      tripStatusDesc,
      payMethod,
      payAmount,
      estimatesFare,
      bonusDetail,
      payment_time,
      refundAmount,
      refundTime,
      isPaymentConfirmed,
      pendingAmount,
    } = order;

    const formatTime = (time: string) =>
      String(time.replace(/-/g, '/').replace('T', ' ')).slice(0, 16);

    const origin = {
      tag: 'history',
      time,
      formattedTime: formatTime(time || ''),
      lat: originLat || 0,
      lng: originLon || 0,
      address: originAddress,
      mainText: cutAddress(originAddress),
    };

    const destination = {
      tag: 'history',
      time: formatTime(time || ''),
      lat: destinationLat || 0,
      lng: destinationLon || 0,
      address: destinationAddress,
      mainText: cutAddress(destinationAddress),
    };

    const discountAmount = bonusDetail?.discountAmount || 0;
    const bonusAmount = bonusDetail?.bonusUsed || 0;
    const ticketDiscountAmount = bonusDetail?.ticketDiscountAmount || 0;

    const convertBounsDetail = {
      discountAmount,
      bonusAmount,
      ticketDiscountAmount,
      totalDiscountAmount: discountAmount + ticketDiscountAmount + bonusAmount,
    };

    const cancelFare = order_cancel?.cancelFare || 0;

    // 小計
    const subtotal = isCancel ? 0 : estimatesFare || 0;

    // 行程費用 = 小計 + 取消費用 - 折扣金額
    const tripTotalAmount = subtotal + cancelFare - convertBounsDetail.totalDiscountAmount || 0;

    const paymentInfo = {
      time: payment_time,
      formattedTime: formatTime(payment_time || ''),
      amount: payAmount,
      refundAmount,
      refundTime: formatTime(refundTime || ''),
      additionalAmount: pendingAmount,
    };

    return {
      tag: 'history',
      guid,
      comment: getCommentAverage(comment),
      origin,
      destination,
      status,
      isDispatch: !!isDispatch,
      dispatchPlate,
      fleetTel,
      isCancel,
      cancelSource,
      hasReceipt,
      tripStatusDesc,
      payMethod,
      payAmount,
      subtotal,
      tripTotalAmount,
      estimatedFare: estimatesFare || 0,
      bonusDetail: convertBounsDetail,
      paymentInfo,
      cancelFare,
      tripStatus: formatTripStatus({ isCancel, isPaymentConfirmed, payMethod }),
    };
  });
}
