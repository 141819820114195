import React, { useContext, useState, forwardRef } from 'react';
import ReactLoading from 'react-loading';

import './FooterConfirm.scss';

import { useDiscount } from 'context/DiscountProvider';
import { useEstimatedFare } from 'context/EstimatedFareProvider';

import Modali, { useModali } from 'components/Modal';
import { setToastAppear } from 'components/Toast/Toast';

import { placeContext } from '../../place.js';

interface FooterConfirmProps {
  isOnlinePay: boolean;
  isLoadingFare: boolean;
  children: React.ReactNode;
  isDiscountOpen: boolean;
  isFeeDetailsOpen: boolean;
  onBottomPopupClose: () => void;
  setIsDiscountOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsFeeDetailsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const FooterConfirm = forwardRef<HTMLDivElement, FooterConfirmProps>(
  (
    {
      isOnlinePay,
      children,
      isLoadingFare,
      isDiscountOpen,
      isFeeDetailsOpen,
      onBottomPopupClose,
      setIsDiscountOpen,
      setIsFeeDetailsOpen,
    },
    ref
  ) => {
    const { discountTotal } = useDiscount();
    const { place } = useContext(placeContext);
    const { estimatedFare } = useEstimatedFare();

    const estimatedFareDisplay = Math.max(0, (estimatedFare?.fare ?? 0) - discountTotal);

    const [isAnimating, setIsAnimating] = useState(false);
    const [feeDetailsIsAnimating, setFeeDetailsIsAnimating] = useState(false);

    const [estimatedFareErrorModal, toggleEstimatedFareErrorModal] = useModali({
      animated: true,
      overlayClose: false,
      title: '連線異常',
      message: '服務目前異常，請稍後再試',
      buttons: [
        <Modali.Button
          key="ModaliButton"
          label="確認"
          isTwoButton
          isStyleDestructive
          onClick={() => toggleEstimatedFareErrorModal()}
        />,
      ],
    });

    function toggleDiscountPopup() {
      if (!estimatedFare?.fare) {
        if (!place.originPlace || !place.destinationPlace) {
          setToastAppear('請輸入上下車地點以使用優惠');
        } else if (!isLoadingFare) {
          toggleEstimatedFareErrorModal();
        }
        return;
      }
      if (isAnimating) {
        return;
      }
      setIsAnimating(true);
      setIsDiscountOpen((prevIsDiscountOpen) => {
        if (prevIsDiscountOpen) {
          onBottomPopupClose();
        }
        setTimeout(() => {
          setIsAnimating(false);
        }, 300);
        return !prevIsDiscountOpen;
      });
      if (isFeeDetailsOpen) {
        setIsFeeDetailsOpen(false);
      }
    }

    function toggleFeeDetailsPopup() {
      if (feeDetailsIsAnimating || !estimatedFare?.fare) {
        return;
      }
      setFeeDetailsIsAnimating(true);
      setIsFeeDetailsOpen((prevIsFeeDetailsOpen) => {
        if (prevIsFeeDetailsOpen) {
          onBottomPopupClose();
        }
        setTimeout(() => {
          setFeeDetailsIsAnimating(false);
        }, 300);
        return !prevIsFeeDetailsOpen;
      });
      if (isDiscountOpen) {
        setIsDiscountOpen(false);
      }
    }

    return (
      <div className="footer-confirm__wrap" ref={ref}>
        {isOnlinePay && (
          <div
            className="footer-confirm footer-confirm__discount"
            onClick={() => toggleDiscountPopup()}
          >
            <div>
              <span className="footer-confirm__title">優惠與推薦</span>
            </div>
            <div className="footer-confirm__toggle">
              {discountTotal > 0 ? (
                <span className="footer-confirm__toggle--active">已套用</span>
              ) : (
                <span>未套用</span>
              )}
              {isDiscountOpen ? (
                <i className="icon-arrow-down footer-confirm__toggle__icon"></i>
              ) : (
                <i className="icon-arrow-up footer-confirm__toggle__icon"></i>
              )}
            </div>
          </div>
        )}
        <div className="footer-confirm footer-confirm__fare">
          {isOnlinePay && (
            <div onClick={() => toggleFeeDetailsPopup()}>
              <div className="footer-confirm__fare__price">
                <span className="footer-confirm__title">預估車資</span>
                {isLoadingFare ? (
                  <span className="loading">
                    <ReactLoading type="bars" color="#15beae" height={20} width={20} />
                  </span>
                ) : (
                  <span className={`price ${estimatedFare.fare ? 'has-price' : 'no-price'}`}>
                    {estimatedFare?.fare ? `$${estimatedFareDisplay}` : '---'}
                  </span>
                )}
              </div>
              {estimatedFare?.fare ? (
                <div className="footer-confirm__fare-detail">
                  <span className="footer-confirm__fare-text">費用詳情</span>
                  {discountTotal > 0 && (
                    <span className="footer-confirm__origin-fare">${estimatedFare?.fare}</span>
                  )}
                  {isFeeDetailsOpen ? (
                    <i className="icon-arrow-down footer-confirm__toggle__icon"></i>
                  ) : (
                    <i className="icon-arrow-up footer-confirm__toggle__icon"></i>
                  )}
                </div>
              ) : null}
            </div>
          )}
          {children}
        </div>
        <Modali.Modal {...estimatedFareErrorModal}></Modali.Modal>
      </div>
    );
  }
);

export default FooterConfirm;
