import { createContext, useState, useContext, useCallback } from 'react';

import { estimatedTripFare } from 'api/taxiTrip';

import { EstimatedFareItem } from 'api/taxiTrip/model/FEModel';

const defaultEstimatedFare: EstimatedFareItem = {
  productId: '',
  displayName: '',
  fareId: '',
  fare: 0,
  expiresUtc: '',
  cancelThresholdSecs: 0,
  minCalcelFee: 0,
};

interface UpdateEstimatedFareParams {
  canClearFare?: boolean;
  canUpdateFare?: boolean;
  updatedPlace: {
    originPlace: string;
    originPlaceLat: number;
    originPlaceLng: number;
    originPlaceTag: string;
    destinationPlace: string;
    destinationPlaceLat: number;
    destinationPlaceLng: number;
    destinationPlaceTag: string;
  };
  orderDetail: {
    uberOptions: { id: string; isChecked: boolean }[];
  };
}

interface EstimatedFareContextType {
  estimatedFare: EstimatedFareItem;
  setEstimatedFare: (fare: EstimatedFareItem) => void;
  resetEstimatedFare: () => void;
  updateEstimatedFare: (
    params: UpdateEstimatedFareParams
  ) => Promise<{ res: EstimatedFareItem | null; error: any }>;
}

const EstimatedFareContext = createContext<EstimatedFareContextType | null>(null);

export function useEstimatedFare() {
  const context = useContext(EstimatedFareContext);
  if (!context) {
    throw new Error('useEstimatedFare must be used within an EstimatedFareProvider');
  }
  return context;
}

export function EstimatedFareProvider({ children }) {
  const [estimatedFare, setEstimatedFare] = useState<EstimatedFareItem>(defaultEstimatedFare);

  const resetEstimatedFare = useCallback(() => {
    setEstimatedFare(defaultEstimatedFare);
  }, [setEstimatedFare]);

  const updateEstimatedFare = useCallback(
    async ({
      canClearFare = true,
      canUpdateFare = true,
      updatedPlace,
      orderDetail,
    }: UpdateEstimatedFareParams) => {
      const {
        originPlace,
        originPlaceLat,
        originPlaceLng,
        originPlaceTag,
        destinationPlace,
        destinationPlaceLat,
        destinationPlaceLng,
        destinationPlaceTag,
      } = updatedPlace;

      const hasAllCoordinates = [
        originPlaceLat,
        originPlaceLng,
        destinationPlaceLat,
        destinationPlaceLng,
      ].every((coord) => coord != null && coord !== 0);

      if (!hasAllCoordinates) {
        resetEstimatedFare();
        return { res: null, error: null };
      }

      const PLACE_TAG_PREDICT = 'predict';

      const getOptionChecked = (options) => (elementId) =>
        options.find((option) => option.id === elementId)?.isChecked ?? false;

      const getOptions = getOptionChecked(orderDetail.uberOptions);

      const postData = {
        pickupLat: originPlaceLat,
        pickupLon: originPlaceLng,
        dropoffLat: destinationPlaceLat,
        dropoffLon: destinationPlaceLng,
        option: {
          baggage: getOptions('input1'),
          pet: getOptions('input3'),
        },
        pickupAddress: originPlace,
        checkPickupAdd: originPlaceTag !== PLACE_TAG_PREDICT,
        dropoffAddress: destinationPlace,
        checkDropoffAdd: destinationPlaceTag !== PLACE_TAG_PREDICT,
      };

      const resetFareIfNeeded = () => {
        if (canClearFare && window.history.state !== 'tab') {
          resetEstimatedFare();
        }
      };

      const [result, error] = await estimatedTripFare(postData);

      if (error) {
        resetFareIfNeeded();
        return { res: null, error };
      }

      const updatedEstimatedFare = result?.data?.data?.[0];

      if (!updatedEstimatedFare) {
        resetFareIfNeeded();
        return { res: null, error: null };
      }

      if (canUpdateFare) setEstimatedFare(updatedEstimatedFare);
      return { res: updatedEstimatedFare, error: null };
    },
    [setEstimatedFare, resetEstimatedFare]
  );

  return (
    <EstimatedFareContext.Provider
      value={{ estimatedFare, resetEstimatedFare, setEstimatedFare, updateEstimatedFare }}
    >
      {children}
    </EstimatedFareContext.Provider>
  );
}
