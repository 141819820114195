import * as api from './api';

enum ApiName {
  MAP_API = 'MAP_API',
  PLACE_API = 'PLACE_API',
  GEO_API = 'GEO_API',
  KNST_API = 'KNST_API',
}

enum UserAction {
  MAP_INIT = 'MapCreateInitialFlow',
  GPS_INIT = 'LocationChargeFlow',
  MAP_MOVE = 'MapGestureFlow',
  AUTOCOMPLETE = 'AutoCompletePredictFlow',
  MAP_PLACE_INIT = 'PredictSetMapCenterFlow',
  CALL_TAXI_INIT = 'callTaxiFlow',
  YOXI_TAXI_INIT = 'yoxiFlow',
  GOOGLE_PLACE_CALL = 'GooglePlaceUseCallTaxi',
  PREFER_PLACE_CALL = 'PreferPlaceUseCallTaxi',
  HISTORY_PLACE_CALL = 'HistoryPlaceUseCallTaxi',
  COMMUNITY_PLACE_CALL = 'CommunityPlaceUseCallTaxi',
}

export function useRecord() {
  const serviceAccountAId = +(window.localStorage.getItem('ServiceAccountAId') || '');

  const recordActionMapCreateInitialFlow = async () => {
    await api.kingnetTaxiRecord({
      apiName: ApiName.MAP_API,
      serviceAccountAId,
      userAction: UserAction.MAP_INIT,
    });
  };

  const recordActionLocationChargeFlow = async (lat, lon) => {
    await api.kingnetTaxiRecord({
      apiName: ApiName.GEO_API,
      serviceAccountAId,
      userAction: UserAction.GPS_INIT,
      lat,
      lon,
    });
  };

  const recordActionMapGestureFlow = async () => {
    await api.kingnetTaxiRecord({
      apiName: ApiName.GEO_API,
      serviceAccountAId,
      userAction: UserAction.MAP_MOVE,
    });
  };

  const recordActionAutoCompletePredictFlow = async () => {
    await api.kingnetTaxiRecord({
      apiName: ApiName.PLACE_API,
      serviceAccountAId,
      userAction: UserAction.AUTOCOMPLETE,
    });
  };

  const recordActionPredictSetMapCenterFlow = async () => {
    await api.kingnetTaxiRecord({
      apiName: ApiName.GEO_API,
      serviceAccountAId,
      userAction: UserAction.MAP_PLACE_INIT,
    });
  };

  const recordActionCallTaxiFlow = async () => {
    await api.kingnetTaxiRecord({
      apiName: ApiName.GEO_API,
      serviceAccountAId,
      userAction: UserAction.CALL_TAXI_INIT,
    });
  };

  const recordActionYoxiFlow = async () => {
    await api.kingnetTaxiRecord({
      apiName: ApiName.GEO_API,
      serviceAccountAId,
      userAction: UserAction.YOXI_TAXI_INIT,
    });
  };

  const recordActionPlaceUseCallTaxi = async (result) => {
    let userAction = UserAction.GOOGLE_PLACE_CALL;
    if (result === 'prefer') userAction = UserAction.PREFER_PLACE_CALL;
    if (result === 'history') userAction = UserAction.HISTORY_PLACE_CALL;
    if (result === 'community') userAction = UserAction.COMMUNITY_PLACE_CALL;
    await api.kingnetTaxiRecord({
      apiName: ApiName.KNST_API,
      serviceAccountAId,
      userAction,
    });
  };

  return {
    recordActionMapCreateInitialFlow,
    recordActionLocationChargeFlow,
    recordActionMapGestureFlow,
    recordActionAutoCompletePredictFlow,
    recordActionPredictSetMapCenterFlow,
    recordActionCallTaxiFlow,
    recordActionYoxiFlow,
    recordActionPlaceUseCallTaxi,
  };
}
