export let defaultMode = [
  { elementType: 'geometry.stroke', stylers: [{ visibility: 'off' }] },
  {
    featureType: 'landscape.man_made',
    elementType: 'geometry.fill',
    stylers: [{ color: '#ecf7f4' }],
  },
  {
    featureType: 'landscape.natural',
    elementType: 'geometry.fill',
    stylers: [{ color: '#ecf7f4' }],
  },
  {
    featureType: 'landscape.natural.landcover',
    elementType: 'geometry.fill',
    stylers: [{ color: '#a8f9db' }],
  },
  {
    featureType: 'landscape.natural.terrain',
    elementType: 'geometry.fill',
    stylers: [{ color: '#a8f9db' }],
  },
  { featureType: 'poi.attraction', elementType: 'geometry.fill', stylers: [{ color: '#ecf7f4' }] },
  { featureType: 'poi.attraction', elementType: 'labels.icon', stylers: [{ color: '#bdbdbd' }] },
  {
    featureType: 'poi.attraction',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#6e6e6e' }],
  },
  { featureType: 'poi.business', elementType: 'geometry.fill', stylers: [{ color: '#ecf7f4' }] },
  { featureType: 'poi.business', elementType: 'labels.icon', stylers: [{ color: '#a8a8a8' }] },
  { featureType: 'poi.business', elementType: 'labels.text.fill', stylers: [{ color: '#15beae' }] },
  { featureType: 'poi.government', elementType: 'geometry.fill', stylers: [{ color: '#ecf7f4' }] },
  { featureType: 'poi.government', elementType: 'labels.icon', stylers: [{ color: '#828282' }] },
  {
    featureType: 'poi.government',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#6e6e6e' }],
  },
  { featureType: 'poi.medical', elementType: 'geometry.fill', stylers: [{ color: '#ecf7f4' }] },
  { featureType: 'poi.medical', elementType: 'labels.icon', stylers: [{ color: '#6e6e6e' }] },
  { featureType: 'poi.medical', elementType: 'labels.text.fill', stylers: [{ color: '#6e6e6e' }] },
  { featureType: 'poi.park', elementType: 'geometry.fill', stylers: [{ color: '#a8f9db' }] },
  { featureType: 'poi.park', elementType: 'labels.icon', stylers: [{ color: '#a8f9db' }] },
  { featureType: 'poi.park', elementType: 'labels.text.fill', stylers: [{ color: '#15beae' }] },
  {
    featureType: 'poi.place_of_worship',
    elementType: 'geometry.fill',
    stylers: [{ color: '#ecf7f4' }],
  },
  {
    featureType: 'poi.place_of_worship',
    elementType: 'labels.icon',
    stylers: [{ color: '#949494' }],
  },
  {
    featureType: 'poi.place_of_worship',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#6e6e6e' }],
  },
  { featureType: 'poi.school', elementType: 'geometry.fill', stylers: [{ color: '#ecf7f4' }] },
  { featureType: 'poi.school', elementType: 'labels.icon', stylers: [{ color: '#828282' }] },
  { featureType: 'poi.school', elementType: 'labels.text.fill', stylers: [{ color: '#6e6e6e' }] },
  {
    featureType: 'poi.sports_complex',
    elementType: 'geometry.fill',
    stylers: [{ color: '#ecf7f4' }],
  },
  { featureType: 'road.highway', elementType: 'geometry.fill', stylers: [{ color: '#90d2c2' }] },
  { featureType: 'road.highway', elementType: 'geometry.stroke', stylers: [{ color: '#b5e1d7' }] },
  { featureType: 'road.highway', elementType: 'labels.text.fill', stylers: [{ color: '#15beae' }] },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.stroke',
    stylers: [{ color: '#ffffff' }],
  },
  {
    featureType: 'road.highway.controlled_access',
    elementType: 'geometry.fill',
    stylers: [{ color: '#6bc3ae' }],
  },
  {
    featureType: 'road.highway.controlled_access',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#6bc3ae' }],
  },
  {
    featureType: 'transit.station.airport',
    elementType: 'geometry.fill',
    stylers: [{ color: '#daf0eb' }],
  },
];
