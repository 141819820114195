import { useEffect, useState, useContext } from 'react';

import { CreditCard } from './component/CreditCardSelect';
import { Button } from 'components/Button/Button';
import Modali, { useModali } from 'components/Modal';
import { setToastAppear } from 'components/Toast/Toast';

import { CreditCardType } from 'types/creditCard';
import { isNullOrUnDef } from 'utils/is';
import { isExpired, formatDiscountText } from 'utils';

import './selectCreditCardPage.scss';

import * as taxiTripAPI from 'api/taxiTrip';
import { EstimatedFareItem, UberTripsInfo, UserBindCardInfo } from 'api/taxiTrip/model/FEModel';

import { useHistory } from 'react-router-dom';
import { useFetchDiscount } from 'hooks/useFetchDiscount';
import { placeContext } from 'place';
import { AccountsReceivablesModel } from 'api/taxiTrip/model/BEModel';

interface SelectCreditCardPageProps {
  GUID: string;
  isBindCardSuccess: boolean;
  tokenAlready: boolean;
  setIsBindCardSuccess: Function;
  paymentOriginPage: string;
  setShouldRequestPosition: Function;
  setOrderPlaceDetail: Function;
  selectedCard: CreditCardInfo | null;
  setSelectedCard: Function;
}

interface CreditCardShowProps {
  type: CreditCardType;
  alt: string;
  id: string | undefined;
  radioButtonText?: string;
  bank: string;
  encryptedCardNumber: string;
  isDefault: boolean;
}

export type CreditCardInfo = CreditCardShowProps & UserBindCardInfo;

export const SelectCreditCardPage = ({
  GUID,
  isBindCardSuccess,
  tokenAlready,
  setIsBindCardSuccess,
  paymentOriginPage,
  setShouldRequestPosition,
  setOrderPlaceDetail,
  selectedCard,
  setSelectedCard,
}: SelectCreditCardPageProps) => {
  const history = useHistory();
  const payAfterTaxiFinished =
    paymentOriginPage === 'history' ||
    paymentOriginPage === 'comment' ||
    paymentOriginPage === 'payUnpaidTrip';
  // about navigation
  const [isBlocking, setIsBlocking] = useState(true);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isRequestInProgress, setIsRequestInProgress] = useState(false);
  const [compareEstimatedFareRes, setCompareEstimatedFareRes] = useState<boolean | string | null>(
    null
  );
  const [originTripInfo, setOriginTripInfo] = useState<UberTripsInfo>({});
  const [changedEstimateFare, setChangedEstimateFare] = useState<EstimatedFareItem>({
    productId: '',
    displayName: '',
    fareId: '',
    fare: 0,
    expiresUtc: '',
    cancelThresholdSecs: 0,
    minCalcelFee: 0,
  });
  const [estimatedFare, setEstimatedFare] = useState<Number>();
  const [creditCardList, setCreditCardList] = useState<CreditCardInfo[]>([]);
  const [tempSelectedCard, setTempSelectedCard] = useState<CreditCardInfo | null>(null);

  const [confirmFareChangedInfo, setConfirmFareChangedInfo] = useState({
    originFare: 0,
    changedFare: 0,
    options: { discountShowText: '' },
  });
  const [confirmFareChangedModal, toggleConfirmFareChangedModal] = useModali({
    animated: true,
    title: '車資異動',
    overlayClose: false,
    buttons: [
      <Modali.Button
        key="ModaliButton"
        label="取消叫車"
        isTwoButton
        onClick={() => handleCancelCallTaxi()}
      />,
      <Modali.Button
        key="ModaliButton"
        label="確認叫車"
        isStyleDestructive
        isTwoButton
        onClick={() => handleCallTaxiPay()}
      />,
    ],
  });
  const [estimatedFareErrorMessage, setEstimatedFareErrorMessage] = useState<string>('');
  const [estimatedFareErrorModal, toggleEstimatedFareErrorModal] = useModali({
    animated: true,
    overlayClose: false,
    title: '連線異常',
    message: estimatedFareErrorMessage,
    buttons: [
      <Modali.Button
        key="ModaliButton"
        label="確認"
        isTwoButton
        isStyleDestructive
        onClick={() => toggleEstimatedFareErrorModal()}
      />,
    ],
  });

  const [confirmCancelCallTaxi, toggleConfirmCancelCallTaxiModal] = useModali({
    animated: true,
    title: '取消叫車',
    message: '是否要取消叫車？',
    buttons: [
      <Modali.Button
        key="ModaliButton"
        label="取消叫車"
        isTwoButton
        onClick={() => handleCancelCallTaxi()}
      />,
      <Modali.Button
        key="ModaliButton"
        label="繼續付款"
        isStyleDestructive
        isTwoButton
        onClick={() => handleCancelRouterBack()}
      />,
    ],
  });
  const [unpaidTripData, setUnpaidTripData] = useState<AccountsReceivablesModel | null>(null);

  const { getDiscountedOrderAmount } = useFetchDiscount();
  const { place } = useContext(placeContext);

  /**
   * Formats the card type based on the given cardType.
   *
   * @param {string} cardType - The card type to be formatted.
   * @returns {string} The formatted card type.
   *
   * @see {@link https://docs.tappaysdk.com/tutorial/zh/advanced.html#bind-card-api}
   */
  const cardTypeMap = {
    VISA: 'VISA',
    MasterCard: 'Mastercard',
    JCB: 'JCB',
    AMEX: 'Amex',
    'Union Pay': 'UnionPay',
  };

  function formatCardType(cardType) {
    return cardTypeMap[cardType] || '';
  }

  function setDefaultCard(cards) {
    const defaultCardIndex = cards.findIndex((card) => card.isDefault);
    if (defaultCardIndex > -1) {
      setTempSelectedCard(cards[defaultCardIndex]);
    }
  }

  function setFormatCreditCard(cards) {
    const formatedCreditCards = cards.map((card) => ({
      ...card,
      id: card.guid,
      type: formatCardType(card.cardAssociation),
      alt: card.cardAssociation,
      radioButtonText: '預設',
      encryptedCardNumber: `****${card.lastFour}`,
      bank: card.issueBank,
    }));

    const isSelectedCradExist = formatedCreditCards.some(
      (card) => selectedCard && card.guid === selectedCard.guid
    );

    setTempSelectedCard(selectedCard);

    if (formatedCreditCards.length > 0 && (!selectedCard || !isSelectedCradExist)) {
      setDefaultCard(formatedCreditCards);
    }

    setCreditCardList(formatedCreditCards);
  }

  useEffect(() => {
    const blockNavigation = (nextLocation, action) => {
      if (
        isBlocking &&
        nextLocation.pathname === '/' &&
        paymentOriginPage === 'home' &&
        action === 'POP' &&
        !payAfterTaxiFinished
      ) {
        if (!confirmCancelCallTaxi.status()) toggleConfirmCancelCallTaxiModal();
        return false;
      }
    };

    const unblock = history.block(blockNavigation);

    // 如果非從選擇信用卡頁面進入，則取得車資，若沒有guid則返回首頁
    if (paymentOriginPage !== 'selectCreditCard') {
      if (!GUID) {
        const initLocation = {
          pathname: '/',
          state: {
            initMainPage: true,
          },
        };
        history.replace(initLocation);
        return;
      }
      getEstimatedFare();
    }

    return () => {
      unblock();
      if (confirmFareChangedModal.status()) toggleConfirmFareChangedModal();
      if (confirmCancelCallTaxi.status()) toggleConfirmCancelCallTaxiModal();
    };
  }, []);

  useEffect(() => {
    if (isBindCardSuccess) {
      setToastAppear('新增成功');
      setIsBindCardSuccess(null);
    } else if (isBindCardSuccess === false) {
      setToastAppear('新增失敗');
      setIsBindCardSuccess(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBindCardSuccess]);

  useEffect(() => {
    if (tokenAlready) {
      taxiTripAPI
        .GetUserBindCard()
        .then(([res]) => {
          if (res) {
            setFormatCreditCard(res.data.data.cards);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [tokenAlready]);

  useEffect(() => {
    if (compareEstimatedFareRes === 'error') {
      toggleEstimatedFareErrorModal();
    } else if (compareEstimatedFareRes === false) {
      toggleConfirmFareChangedModal();
    } else if (compareEstimatedFareRes === true) {
      handleCallTaxiPay();
    }
    setCompareEstimatedFareRes(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compareEstimatedFareRes]);

  function handleSelectCreditCard(card) {
    if (!card) {
      setTempSelectedCard(null);
      return;
    }
    setTempSelectedCard(card);
  }

  async function handleCancelCallTaxi() {
    try {
      setIsLoading(false);
      if (confirmFareChangedModal.status()) toggleConfirmFareChangedModal();
      if (confirmCancelCallTaxi.status()) toggleConfirmCancelCallTaxiModal();
      await taxiTripAPI.CancelCallUberTaxi({ tripGuid: GUID });
      setIsBlocking(false);
      setShouldRequestPosition(true);
      setToastAppear('取消叫車成功');
      setOrderPlaceDetail();
      const initLocation = {
        pathname: '/',
        state: {
          initMainPage: true,
        },
      };
      history.replace(initLocation);
    } catch (error) {
      setToastAppear('取消叫車失敗');
    }
  }

  function getConfirmEstimatedFare(changedEstimateFare, callTaxiData) {
    // 如果有重取車資，則拿changedEstimateFare叫車，若無則直接取callTaxiData時的車資
    if (changedEstimateFare.fareId) {
      return changedEstimateFare;
    }

    const {
      uberProductId,
      uberProductName,
      uberFareId,
      oriUberFare,
      uberFareExpiresUtc,
      uberCancelThresholdSecs,
      uberMinCancelFee,
    } = callTaxiData;

    return {
      productId: uberProductId,
      displayName: uberProductName,
      fareId: uberFareId,
      fare: oriUberFare,
      expiresUtc: uberFareExpiresUtc,
      cancelThresholdSecs: uberCancelThresholdSecs,
      minCalcelFee: uberMinCancelFee,
    };
  }

  function handleCancelRouterBack() {
    if (confirmCancelCallTaxi.status()) toggleConfirmCancelCallTaxiModal();
  }

  async function handleCallTaxiPay(callTaxiData = originTripInfo) {
    if (isRequestInProgress) {
      return;
    }
    setIsRequestInProgress(true);

    if (confirmFareChangedModal.status()) toggleConfirmFareChangedModal();

    const paymentData = {
      tripGuid: GUID,
      cardGuid: tempSelectedCard && tempSelectedCard.guid,
      redirectUrl:
        window.location.origin +
        '/?guid=' +
        GUID +
        '&isCreditCardPay=true&paymentOriginPage=' +
        paymentOriginPage,
    };

    let needToCallCar = true;
    if (payAfterTaxiFinished) {
      if (!unpaidTripData) {
        setToastAppear('未找到未付款訂單');
        setIsRequestInProgress(false);
        return;
      }
      const { hasCompleted } = unpaidTripData;
      if (hasCompleted) {
        needToCallCar = false;
      }
    }

    const [result, error] = needToCallCar
      ? await taxiTripAPI.CallCarAfterCreditCardPay({
          ...paymentData,
          ...getConfirmEstimatedFare(changedEstimateFare, callTaxiData),
        })
      : await taxiTripAPI.CreditCardPay(paymentData);

    try {
      if (error) throw error;
      if (result) {
        handleCallTaxiResult(result);
      }
    } catch (error) {
      handlePaymentError(error);
    } finally {
      setIsRequestInProgress(false);
    }
  }

  function handleCallTaxiResult(result) {
    const { paymentUrl, paymentInfo } = result?.data?.data || {};
    if (paymentUrl) {
      window.location.replace(result.data.data.paymentUrl);
      return;
    }
    if (paymentInfo) {
      updateConfirmFareChangedInfo(paymentInfo);
      toggleConfirmFareChangedModal();
    }
  }

  function updateConfirmFareChangedInfo(paymentInfo) {
    // 檢查 originTripInfo.uberFare 是否存在
    if (isNullOrUnDef(originTripInfo?.uberFare)) {
      throw new Error('originTripInfo.uberFare 未定義或為 null');
    }

    const { payAmount = 0, discountAmount = 0, bonusUsed = 0 } = paymentInfo;

    const discountList = [
      { key: 'discountAmount', prefix: '優惠折抵$', value: discountAmount },
      { key: 'bonusUsed', prefix: '紅利$', value: bonusUsed },
    ];

    setConfirmFareChangedInfo((info) => ({
      ...info,
      originFare: originTripInfo.uberFare,
      changedFare: payAmount,
      options: {
        discountShowText: formatDiscountText(discountList),
      },
    }));
  }

  function handlePaymentError(error) {
    if (confirmFareChangedModal.status()) toggleConfirmFareChangedModal();
    setToastAppear(error?.response?.data?.ErrorMessage?.Description || '發生錯誤，請稍後再試');
  }

  async function handleRedirectToBindCard() {
    // 紀錄當前上下車地點
    sessionStorage.setItem('selectedPlace', JSON.stringify(place));
    const [result, error] = await taxiTripAPI.GetEcToken();
    if (error) {
      setToastAppear('服務異常，請稍後再試');
      return;
    }
    if (result) {
      const ecToken = encodeURIComponent(result.data.token);
      window.location.replace(
        `${process.env.REACT_APP_EC_WEB_URI}addCreditCard?appAuth=${ecToken}&isFromTaxiAdd=true`
      );
    }
  }

  async function handleGoPay() {
    const { hasCompleted } = unpaidTripData || {};
    if (payAfterTaxiFinished) {
      if (!tempSelectedCard) {
        history.replace('/CreditCardPayment');
        return;
      } else {
        setSelectedCard(tempSelectedCard);
      }
      if (hasCompleted) {
        await handleCallTaxiPay();
      } else {
        await checkEstimatedFareIsChanged();
      }
    } else {
      if (paymentOriginPage === 'home') {
        if (!tempSelectedCard) {
          history.replace('/CreditCardPayment');
          return;
        } else {
          setSelectedCard(tempSelectedCard);
        }
        return await checkEstimatedFareIsChanged();
      }
      setSelectedCard(tempSelectedCard);
      const initLocation = {
        pathname: '/',
        state: {
          initMainPage: true,
        },
      };
      history.replace(initLocation);
    }
  }

  async function checkEstimatedFareIsChangedResult({
    changedEstimateFare,
    originDiscountedFare,
    bonusInfo,
  }) {
    const { fare, fareId } = changedEstimateFare;
    const { discountCode, ticketInstanceId, useBonus } = bonusInfo;
    const changedDiscountedFare = await getDiscountedOrderAmount({
      fare,
      fareId,
      discountCode,
      ticketInstanceId,
      useBonus,
    });
    setCompareEstimatedFareRes(changedDiscountedFare === originDiscountedFare);
    setConfirmFareChangedInfo((info) => {
      return {
        ...info,
        originFare: originDiscountedFare,
        changedFare: changedDiscountedFare,
      };
    });
  }

  async function checkEstimatedFareIsChanged() {
    if (isLoading) return;
    setIsLoading(true);
    const [resultEstimateOrigin] = await taxiTripAPI.GetRegisteredUberTaxiTrip({ tripGuid: GUID });
    let callTaxiData: UberTripsInfo;
    if (resultEstimateOrigin) {
      callTaxiData = resultEstimateOrigin.data.data.trip;
      setOriginTripInfo(callTaxiData);
      if (!isExpired(callTaxiData.uberFareExpiresUtc)) {
        handleCallTaxiPay(callTaxiData);
        return;
      }
    } else {
      setEstimatedFareErrorMessage('服務目前異常，請稍後再試');
      setCompareEstimatedFareRes('error');
      setIsLoading(false);
      return;
    }
    const { pickupLat, pickupLon, pickupAddress, dropoffLat, dropoffLon, dropoffAddress, options } =
      callTaxiData;
    function checkOptionExist(optionId) {
      return options.some((option) => option.service_option_id === optionId);
    }
    const postData = {
      pickupLat,
      pickupLon,
      dropoffLat,
      dropoffLon,
      pickupAddress,
      dropoffAddress,
      option: {
        baggage: checkOptionExist(1),
        wheelchair: checkOptionExist(2),
        pet: checkOptionExist(3),
      },
      checkPickupAdd: false,
      checkDropoffAdd: false,
    };
    const [result, error] = await taxiTripAPI.estimatedTripFare(postData);
    console.log(result);
    if (result) {
      const changedEstimateFare = result?.data?.data?.[0];
      const bonusInfo = resultEstimateOrigin?.data?.data?.bonusInfo || {};
      setChangedEstimateFare(changedEstimateFare);
      await checkEstimatedFareIsChangedResult({
        changedEstimateFare,
        originDiscountedFare: callTaxiData.uberFare,
        bonusInfo,
      });
    } else {
      console.log(error);
      setEstimatedFareErrorMessage(error.response.data.ErrorMessage.Description);
      setCompareEstimatedFareRes('error');
    }
    setIsLoading(false);
  }

  async function getEstimatedFare() {
    if (isLoading) return;
    setIsLoading(true);
    if (payAfterTaxiFinished) {
      const [result, _error] = await taxiTripAPI.GetAccountReceivableTrip();
      setIsLoading(false);
      if (result) {
        const unpaidTrips = result?.data?.data || [];
        const unpaidTrip = unpaidTrips.find((trip) => trip.tripGuid === GUID);
        if (!unpaidTrip) {
          setEstimatedFareErrorMessage('未找到未付款訂單');
          const initLocation = {
            pathname: '/',
            state: {
              initMainPage: true,
            },
          };
          history.replace(initLocation);
          return;
        }
        setUnpaidTripData(unpaidTrip);
        setEstimatedFare(Math.ceil(unpaidTrip?.amount || 0));
      } else {
        setEstimatedFareErrorMessage('服務目前異常，請稍後再試');
        toggleEstimatedFareErrorModal();
      }
    } else {
      const [resultEstimateOrigin] = await taxiTripAPI.GetRegisteredUberTaxiTrip({
        tripGuid: GUID,
      });
      let callTaxiData: UberTripsInfo;
      setIsLoading(false);
      if (resultEstimateOrigin) {
        callTaxiData = resultEstimateOrigin.data.data.trip;
        setEstimatedFare(callTaxiData.uberFare);
      } else {
        setEstimatedFareErrorMessage('服務目前異常，請稍後再試');
        toggleEstimatedFareErrorModal();
      }
    }
  }

  return (
    <div className="select-credit-card">
      {paymentOriginPage !== 'selectCreditCard' && GUID && (
        <div className="select-credit-card__block">
          <span className="select-credit-card__block__title">訂單總額</span>
          <span className="select-credit-card__block__value">
            {estimatedFare ? `$ ${estimatedFare}` : '---'}
          </span>
        </div>
      )}
      <div className="select-credit-card__title">
        <p className="select-credit-card__title__main">信用卡付款</p>
      </div>
      <div className="select-credit-card__card-list">
        {creditCardList &&
          creditCardList.map((creditCard) => {
            return (
              <CreditCard
                key={creditCard.id}
                creditCard={creditCard}
                selectedCard={tempSelectedCard}
                onClick={handleSelectCreditCard}
              />
            );
          })}
        {payAfterTaxiFinished || paymentOriginPage === 'home' ? (
          <div className="credit-card" onClick={() => handleSelectCreditCard(false)}>
            <div className="credit-card__button">
              <div className="credit-card__radio">
                <input
                  id="other"
                  type="radio"
                  checked={!tempSelectedCard}
                  autoComplete="off"
                  readOnly
                />
              </div>
            </div>
            <div className="credit-card__text">
              <p>使用其他信用卡</p>
            </div>
          </div>
        ) : (
          <div className="credit-card" onClick={() => handleRedirectToBindCard()}>
            <div className="credit-card__button">
              <i className="icon-add"></i>
            </div>
            <div className="credit-card__text">
              <p>新增信用卡</p>
            </div>
          </div>
        )}
      </div>
      <div className="select-credit-card__footer">
        <Button
          width="full"
          color="primary"
          onButtonClick={handleGoPay}
          disabled={isLoading || isRequestInProgress}
          isLoading={isLoading || isRequestInProgress}
        >
          <span>確認</span>
        </Button>
      </div>
      <Modali.Modal {...estimatedFareErrorModal}></Modali.Modal>
      <Modali.Modal {...confirmFareChangedModal}>
        <div className="confirm-fare-changed-modal__content">
          <p>
            車資已從
            <span className="confirm-fare-changed-modal__content__price">
              ${confirmFareChangedInfo.originFare}
            </span>
            調整為
            <span className="confirm-fare-changed-modal__content__price primary">
              ${confirmFareChangedInfo.changedFare}
            </span>
            {confirmFareChangedInfo.options.discountShowText && (
              <p>{confirmFareChangedInfo.options.discountShowText}</p>
            )}
          </p>
          <p>是否確認叫車？</p>
        </div>
      </Modali.Modal>
      <Modali.Modal {...confirmCancelCallTaxi}></Modali.Modal>
    </div>
  );
};
