import './Header.scss';
import { useMemo } from 'react';

function Header({
  hasRedDotDisplay,
  menuBarBack,
  historyDisplay,
  preferDisplay,
  loglistDisplay,
  needToNoticeTrip,
  toggleSideBar,
  headerText,
}) {
  const shouldDisplayRedDot = hasRedDotDisplay && !menuBarBack;
  const pathname = location.pathname;

  const isMenuBtnHide = useMemo(() => {
    return !historyDisplay && !preferDisplay && !loglistDisplay && pathname === '/';
  }, [historyDisplay, preferDisplay, loglistDisplay, pathname]);

  return (
    <div className={`header ${!needToNoticeTrip?.showNotification ? 'header__noHeight' : ''}`}>
      {!isMenuBtnHide && (
        <div
          className={`${menuBarBack ? 'header__backBar' : 'header__menuBar'}`}
          onClick={toggleSideBar}
        >
          <div />
          {shouldDisplayRedDot && <div className="header__redDot" />}
        </div>
      )}
      {headerText && <div className="header__text">{headerText}</div>}
    </div>
  );
}

export default Header;
