import ReactLoading from 'react-loading';
import './Button.scss';
import { useMemo } from 'react';
import Ripple from 'components/Ripple';

export interface ButtonProp {
  disabled?: boolean;
  onButtonClick: React.MouseEventHandler;
  color?: string;
  variant?: string;
  width?: string;
  isLoading?: boolean;
  children: React.ReactNode | string;
  shape?: 'round' | 'square' | 'default';
  size?: 'tiny' | 'small' | 'medium' | 'large';
  height?: string;
  style?: React.CSSProperties;
  className?: string;
}

export const Button = ({
  color,
  variant,
  onButtonClick,
  disabled,
  width,
  isLoading,
  children,
  shape,
  size,
  height,
  style,
  className,
}: ButtonProp) => {
  const buttonStyle = useMemo(() => {
    let buttonStyle: React.CSSProperties = {
      width: width === 'full' ? '100%' : width,
    };
    if (color === 'primary') {
      buttonStyle.backgroundColor = 'var(--primaryColor)';
      buttonStyle.color = 'var(--systemWhite)';
      if (variant === 'outlined') {
        buttonStyle.backgroundColor = 'var(--systemWhite)';
        buttonStyle.color = 'var(--primaryColor)';
        buttonStyle.border = '1px solid var(--primaryColor)';
      }
    }
    if (disabled) {
      buttonStyle.backgroundColor = '#d2d2d2';
      buttonStyle.border = 'none';
    }
    if (shape === 'round') {
      buttonStyle.borderRadius = '18px';
    }
    if (size === 'tiny') {
      buttonStyle.padding = '4px';
    } else if (size === 'small') {
      buttonStyle.padding = '9px';
    } else if (size === 'medium') {
      buttonStyle.padding = '12px';
    } else if (size === 'large') {
      buttonStyle.padding = '15px';
    }
    if (height) {
      buttonStyle.height = height;
    }
    if (style) {
      buttonStyle = {
        ...buttonStyle,
        ...style,
      };
    }
    return buttonStyle;
  }, [width, color, variant, disabled, shape, size, height, style]);
  return (
    <button
      className={`default-button ${className || ''}`}
      style={buttonStyle}
      onClick={(event) => {
        if (!disabled) {
          onButtonClick(event);
        }
      }}
    >
      {isLoading ? (
        <ReactLoading className="btn-loading" type="spokes" color={'#fff'} height={20} width={20} />
      ) : (
        <div />
      )}
      {!disabled && <Ripple />}
      {children}
    </button>
  );
};
