import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Modal.scss';

const Button = ({
  color,
  backgroundColor,
  onClick,
  label,
  isStyleDefault,
  isStyleCancel,
  isStyleDestructive,
  isTwoButton,
  isMutiButton,
  disabled,
}) => {
  const buttonClass = classNames({
    modali__button: true,
    modali__button__cancel: isStyleCancel,
    modali__button__default: isStyleDefault,
    modali__button__destructive: isStyleDestructive,
    modali__button__two: isTwoButton,
    modali__button__muti: isMutiButton,
  });
  const textColor = disabled ? '#D2D2D2' : color;
  return (
    <button
      type="button"
      style={{ color: textColor, backgroundColor }}
      className={buttonClass}
      onClick={onClick}
      disabled={disabled}
    >
      {label}
    </button>
  );
};

Button.defaultProps = {
  isStyleDefault: false,
  isStyleCancel: false,
  isStyleDestructive: false,
  isTwoButton: false,
  isMutiButton: false,
  disabled: false,
};

Button.propTypes = {
  color: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  isStyleDefault: PropTypes.bool,
  isStyleCancel: PropTypes.bool,
  isStyleDestructive: PropTypes.bool,
  isTwoButton: PropTypes.bool,
  isMutiButton: PropTypes.bool,
  disabled: PropTypes.bool,
};

const Modal = ({ isModalVisible, hide, options, children }) => {
  function handleOverlayClicked(e) {
    if (!(e.target.className && e.target.className.includes('modali__wrapper'))) {
      return;
    }

    if (options === undefined) {
      hide();
    } else {
      if (options.overlayClose !== false) {
        hide();
      }
      if (options.onOverlayClicked) {
        options.onOverlayClicked();
      }
    }
  }

  function handleCloseBtnClick() {
    hide();
  }

  function renderBody() {
    if (children) {
      return children;
    }
    if (options && options.message) {
      return <div className="modali__body__style">{options.message}</div>;
    }
    return false;
  }

  function renderFooter() {
    const { buttons } = options;
    let footerStyle = {};
    if (buttons && buttons.length && buttons.length > 2) {
      footerStyle = {
        flexDirection: 'column',
      };
    }
    if (options.isDrawer) {
      footerStyle = {
        marginTop: '1rem',
        ...footerStyle,
      };
    }
    return (
      <div className="modali__footer" style={footerStyle}>
        {buttons.map((button, i) => (
          <React.Fragment key={i}>{button}</React.Fragment>
        ))}
      </div>
    );
  }

  const modaliWrapperClass = classNames({
    modali__wrapper: true,
    modali__wrapper__fullScreen: options && options.fullScreen,
    modali__wrapper__centered: options && options.centered,
    modali__wrapper__drawer: options && options.isDrawer,
  });

  const modaliClass = classNames({
    modali: true,
    modali__size__fullScreen: options && options.fullScreen,
    modali__size__large: options && options.large,
    modali__size__medium: options && options.medium,
    modali__size__regular: !options || (options && !options.large && !options.isDrawer),
    'modali__animated modali__animation__fade__in': options && options.animated,
    modali__transparent: options && options.isDrawer,
    modali__fullContent: options && options.fullContent,
  });

  return isModalVisible
    ? ReactDOM.createPortal(
        <React.Fragment>
          <div className="modali__overlay" />
          <div
            className={modaliWrapperClass}
            aria-modal
            aria-hidden
            tabIndex={-1}
            role="dialog"
            onClick={handleOverlayClicked}
          >
            <div className={modaliClass} style={{ backgroundColor: options.backgroundColor }}>
              <div className="modali__content">
                {options && options.closeButton && (
                  <div className="modali__close" onClick={handleCloseBtnClick} />
                )}
                <div className="modali__header">
                  {options !== undefined && options.title !== undefined && (
                    <div className="modali__title">{options.title}</div>
                  )}
                </div>
                <div className="modali__body" style={{ backgroundColor: options.backgroundColor }}>
                  {renderBody()}
                </div>
                {options && options.buttons && options.buttons.length > 0 && renderFooter()}
              </div>
            </div>
          </div>
        </React.Fragment>,
        document.body
      )
    : null;
};

const Modali = () => undefined;
Modali.Button = Button;
Modali.Modal = Modal;
export default Modali;

export const useModali = (options) => {
  const [hasToggledBefore, setHasToggledBefore] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const isModalVisibleRef = useRef(isModalVisible);
  isModalVisibleRef.current = isModalVisible;
  let timeoutHack;

  function status() {
    return isModalVisibleRef.current;
  }

  function toggle() {
    timeoutHack = setTimeout(() => {
      setIsModalVisible(!isModalVisibleRef.current);
      clearTimeout(timeoutHack);
    }, 10);
    setIsShown(!isShown);
    setHasToggledBefore(true);
  }

  function handleKeyDown(event) {
    if (event.keyCode !== 27 || (options && options.keyboardClose === false)) return;
    toggle();
    if (options && options.onEscapeKeyDown) {
      options.onEscapeKeyDown();
    }
  }

  useEffect(() => {
    if (isShown) {
      if (options && options.onShow) {
        options.onShow();
      }
      document.addEventListener('keydown', handleKeyDown);
      document.body.classList.add('modali__open');
    }
    if (!isShown && hasToggledBefore) {
      if (options && options.onHide) {
        options.onHide();
      }
      document.body.classList.remove('modali__open');
    }
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [isShown]);

  return [
    {
      isShown,
      isModalVisible,
      hide: toggle,
      status: status,
      options,
    },
    toggle,
  ];
};
