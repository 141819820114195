import { useState, useEffect, useContext } from 'react';
import './AlertModal.scss';
import Modali, { useModali } from './Modal';
import { FirebaseContext } from '../utils/firebase';
import defaultAlertImage from '../assets/maintenance.png';

export const useAlertModal = () => {
  const [firebaseAlertData, setFirebaseAlertData] = useState(null);
  const firebase = useContext(FirebaseContext);
  const ref = firebase.database();

  // * 註冊 Firebase 提示視窗監聽
  useEffect(() => {
    ref.ref(`TaxiNotification/alert`).on('value', (snapshot) => {
      // * 提取 Firebase 提示視窗資料
      setFirebaseAlertData(snapshot.val());
      if (snapshot && snapshot.val() && snapshot.val().open) {
        // * 處理提示視窗打開時, 維護結束
        if (!taxiNotificationModal.status()) toggleTaxiNotificationModal();
      } else {
        // * 處理維護結束時, 提示視窗自動關閉
        if (taxiNotificationModal.status()) toggleTaxiNotificationModal();
      }
    });
  }, []);

  const { button, closeOnClickOutside, message, stopLoad, open, title, image, imageSize } =
    firebaseAlertData || {};
  const {
    confirm: {
      color: confirmColor,
      clickClose: clickCloseConfirm,
      show: showConfirm,
      text: confirmText,
    } = {},
    cancel: {
      color: cancelColor,
      clickClose: clickCloseCancel,
      show: showCancel,
      text: cancelText,
    } = {},
  } = button || {};

  // * 設定 Firebase 提示視窗
  const [taxiNotificationModal, toggleTaxiNotificationModal] = useModali({
    animated: true,
    large: true,
    overlayClose: closeOnClickOutside,
    buttons: [
      showConfirm && (
        <Modali.Button
          key="ModaliButton"
          label={confirmText}
          color={confirmColor}
          isTwoButton
          isStyleDestructive
          onClick={() => (clickCloseConfirm ? shutDownBrowser() : toggleTaxiNotificationModal())}
        />
      ),
      showCancel && (
        <Modali.Button
          key="ModaliButton"
          label={cancelText}
          color={cancelColor}
          isTwoButton
          isStyleCancel
          onClick={() => (clickCloseCancel ? shutDownBrowser() : toggleTaxiNotificationModal())}
        />
      ),
    ],
  });

  const shutDownBrowser = () => {
    window.location.replace(`${process.env.REACT_APP_API_ENDPOINT}?KNSTMethod=close`);
  };

  // * 渲染 Firebase 提示視窗
  const ModalComponent = (
    <>
      <Modali.Modal {...taxiNotificationModal}>
        <div className="taxi-Notification">
          <div
            className="taxi-Notification-image"
            style={{
              backgroundImage: `url(${image}), url(${defaultAlertImage})`,
              backgroundSize: `${imageSize}`,
            }}
          />
          <span className="taxi-Notification-title">{title}</span>
          <span className="taxi-Notification-text">{message}</span>
        </div>
      </Modali.Modal>
    </>
  );

  return {
    TaxiNotificationModal: ModalComponent,
    stopLoad: open && stopLoad,
  };
};
