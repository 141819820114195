import { useEffect } from 'react';
import { useScript } from './useScript';
import { UpdateCallback } from 'types/tappay';
declare global {
  interface Window {
    TPDirect: any;
  }
}
export function useTappay() {
  const [tappayloaded, loadedError] = useScript('https://js.tappaysdk.com/sdk/tpdirect/v5.17.1');
  const isTappayLoadedSuccess = tappayloaded && !loadedError;

  useEffect(() => {
    if (isTappayLoadedSuccess) {
      window.TPDirect.setupSDK(
        12921,
        process.env.REACT_APP_TAPPAY_APP_KEY,
        process.env.REACT_APP_TAPPAY_SERVER_TYPE
      );
    }
  }, [isTappayLoadedSuccess]);

  function cardSetup(args) {
    window.TPDirect.card.setup(args);
  }

  function onCardUpdate(cb: UpdateCallback) {
    window.TPDirect.card.onUpdate(cb);
  }

  function getTappayFieldsStatus() {
    return window.TPDirect.card.getTappayFieldsStatus();
  }

  function getPrime(cb) {
    window.TPDirect.card.getPrime(cb);
  }

  return {
    isTappayLoadedSuccess,
    cardSetup,
    onCardUpdate,
    getTappayFieldsStatus,
    getPrime,
  };
}
